import { useEffect, useState } from "react";

import { ProgressBar } from "components/common";

import "./ProgressBar.scss";

interface Props {
  curQuestionIndex: number;
  countTotal: number;
}

const AssessmentProgressBar = ({ countTotal, curQuestionIndex }: Props) => {
  const [progressPercent, updateProgressPercent] = useState(() => {
    return createPercent({ countTotal, curQuestionIndex });
  });

  useEffect(() => {
    updateProgressPercent(createPercent({ countTotal, curQuestionIndex }));
  }, [curQuestionIndex, countTotal]);

  return (
    <div className="assessment-taker-progbar-cont">
      <div className="assessment-taker-progbar-text-wrapper">
        <h3 className="title-font assessment-taker-progbar-text-title">
          {progressPercent}%
        </h3>
        <p className="assessment-taker-progbar-text-summary">
          {curQuestionIndex + 1} of {countTotal}
        </p>
      </div>
      <ProgressBar
        progressDecimal={Math.min(curQuestionIndex / countTotal, 1)}
        progressCount={curQuestionIndex}
        totalCount={countTotal || 1}
        height={14}
        theme="success"
      />
    </div>
  );
};

function createPercent(props: Pick<Props, "countTotal" | "curQuestionIndex">) {
  if (!props.curQuestionIndex || !props.countTotal) {
    return 0;
  }

  return Math.round(
    (props.curQuestionIndex / Math.max(props.countTotal, 1)) * 100
  );
}

export default AssessmentProgressBar;
