import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";
import { Redirect } from "react-router-dom";

import { Button } from "components/common/";
import { INTERFACE_PATHS, SUB_INTERFACE_PATHS } from "data/constants";

import "./LandingPage.scss";

interface Props {
  discProfileMini?: TytoData.DISCProfileMini;
  loggedInUserID: number;
  onContinue: () => void;
}

const AssessmentTakerLandingPage = ({
  discProfileMini,
  loggedInUserID,
  onContinue,
}: Props) => {
  if (!!discProfileMini?.styleKey3 || !!discProfileMini?.atsInitialsAdaptive) {
    return (
      <Redirect
        to={`${INTERFACE_PATHS.PROFILE}/${loggedInUserID}/${SUB_INTERFACE_PATHS.PROFILE.HOW_TO_READ}`}
      />
    );
  }

  return (
    <article className="assessment-taker-landing">
      <h2 className="assessment-taker-landing-title title-font">
        DISC Assessment
      </h2>

      <p className="assessment-taker-landing-text">
        Through the R3 DISC assessment you can learn more about your innate
        abilities, as well as any corresponding weaknesses.
      </p>

      <p className="assessment-taker-landing-text">
        You will also learn about how you see yourself and how others see you.
        This knowledge that can not only help you understand your most valuable
        skills, but will also reveal the connection between your passions and
        your expertise.
      </p>

      <section className="assessment-taker-landing-cards-cont">
        <AssessmentLandingCard
          title="Here are some instructions and what to expect"
          msg="For each of the 24 questions in this assessment you will be presented with four words. Select one word that you feel is most like you and one word that you feel is least like you."
        />

        {/* <AssessmentLandingCard
          title="You can save your answers and exit at any time"
          msg="While we recommend completing the assessment in one session, you have the flexibility to pause and resume at any time. Your progress will be saved automatically."
        /> */}

        <AssessmentLandingCard
          title="You can edit your answers at any time "
          msg="As you take the assessment, you will be able to view and edit all of your previous answer choices, even if you quit and resume the assessment later."
        />

        <AssessmentLandingCard
          title="We may have a few additional questions for you"
          msg="If your assessment results need more clarity, we will ask you six additional questions to help us make a more accurate evaluation."
        />
      </section>

      <footer className="assessment-taker-landing-footer">
        <Button
          className="assessment-taker-landing-footer-btn"
          hoverBGSize="hidden"
          onClick={onContinue}
          theme="action"
          value="Start Assessment"
        />
      </footer>
    </article>
  );
};

interface AssessmentLandingCardProps {
  title: string;
  msg: string;
}

const AssessmentLandingCard = (props: AssessmentLandingCardProps) => {
  return (
    <div className="assessment-taker-landing-card">
      <h3 className="assessment-taker-landing-card-title title-font">
        {props.title}
      </h3>
      <p className="assessment-taker-landing-card-msg">{props.msg}</p>
    </div>
  );
};

export default AssessmentTakerLandingPage;
