import { ENDPOINT_DISC_PROFILES_MINI } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_DISC_PROFILES_MINI;

export const DISCProfilesMini = {
  get(
    params: Endpoints.Tyto.DISCProfilesMini.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.DISCProfiles.Mini.Get>;
  },

  post(
    params: Endpoints.Tyto.DISCProfilesMini.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("post", endpoint, params || {}, callOpts) as Promise<{
      discProfiles: TytoData.DISCProfileMini[];
      session: Data.SessionData;
    }>;
  },
};
