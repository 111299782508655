/*
 * Component Description
 */
import * as React from "react";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import * as _ from "lodash";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import { INTERFACE_PATHS } from "data/constants/";
import { StoreContext } from "data/stores/GeneralStore";
import { trackEvent } from "data/tracking/helpers";
import TextButton from "../text-button/";
import Icon, { IconProps } from "../icon/";

import "./style.scss";

/**
 * 
 * currentRoute hash: string;
    key: string; // window.History key
    pathname: string; // window.location.pathname
    search: string; // window.location.search
    state: {
      friendlyText: string;
      [x: string]: any;
    };
 *
 */

function createBackUpRoute(currentRoute: StoreAPI.HistoryItem) {
  if (!currentRoute) {
    return undefined;
  }

  const [__, interfaceName] = `${currentRoute.pathname}`.split("/");

  switch (`/${interfaceName}`) {
    case INTERFACE_PATHS.RESULTS:
      return {
        pathname: INTERFACE_PATHS.RESULTS,
        friendlyText: "Results",
      };
    case INTERFACE_PATHS.ASSESSMENT:
      return {
        pathname: INTERFACE_PATHS.ASSESSMENT,
        friendlyText: "Assessment Taker",
      };
    default:
      return {
        pathname: INTERFACE_PATHS.HOME,
        friendlyText: "Home",
      };
  }
}

interface Props {
  className?: string;
  iconProps?: Partial<IconProps>;
}

const RouterButton = (props: Props) => {
  let GeneralStore = React.useContext(StoreContext);
  const AppInsights = useAppInsightsContext();

  const history = useHistory();

  const { goBack, push: addHistoryItem } = history;

  // React.useEffect(() => {
  //   // TODO
  // }, [_.get(GeneralStore.state, "curRouteHistoryIdx", []), _.get(GeneralStore.state, "history", [])])

  const curRouteHistoryIdx = _.get(GeneralStore.state, "curRouteHistoryIdx", 0);
  const stateHistory = _.get(GeneralStore.state, "history", []);

  const targetItem = stateHistory[curRouteHistoryIdx + 1];
  const backupItem = targetItem
    ? undefined
    : createBackUpRoute(stateHistory[curRouteHistoryIdx]);
  const linkText = getButtonText({
    backupItem,
    targetItem,
  });

  return (
    <TextButton
      className={cx("cc-router-btn", props.className)}
      value={"Back"}
      onClick={() => {
        // * If no previous item, route intelligently
        if (!targetItem && !!backupItem) {
          addHistoryItem(backupItem.pathname);
        } else {
          goBack();
        }

        trackEvent(AppInsights, "Nav Link Clicked", {
          text: linkText,
          currentLength: stateHistory.length ?? 0,
          prevLocation: !!stateHistory.length
            ? stateHistory.slice(-2)[0]?.pathname
            : "NONE",
        } as Tracking.EventPaylods.NavLink);
      }}
    >
      <Icon
        className="cc-router-btn-icon"
        size={18}
        icon="angle-left"
        {...props.iconProps}
      />
      {linkText}
    </TextButton>
  );
};

function getButtonText({
  backupItem,
  targetItem,
}: {
  backupItem?: {
    pathname: string;
    friendlyText: string;
  };
  targetItem?: StoreAPI.HistoryItem;
}) {
  if (!targetItem && backupItem) {
    return backupItem.friendlyText;
  }

  return targetItem?.state?.friendlyText
    ? `Back to ${targetItem?.state?.friendlyText}`
    : "Back";
}

export default RouterButton;
