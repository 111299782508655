import React from "react";
import smoothscroll from "smoothscroll-polyfill";
import cx from "classnames";
import * as _ from "lodash";
import { QueryClientProvider } from "react-query";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";

import { reactPlugin } from "data/tracking/";
import RouterWrapper from "./routing/wrapper";
import {
  StoreContextProvider as GeneralStoreContextProvider,
  StoreContext as GeneralStoreContext,
} from "data/stores/GeneralStore";
import { StoreContextProvider as AppStoreContextProvider } from "data/stores/AppStore";
import { queryClient } from "data/network/";
import { SITE_THEMES } from "data/constants/";

import "./styles/main.scss";

smoothscroll.polyfill();

function AppWrapper() {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {/* @ts-ignore */}
      <AppInsightsErrorBoundary
        appInsights={reactPlugin}
        onError={() => <p>An error occurred.</p>}
      >
        {/* @ts-ignore */}
        <QueryClientProvider client={queryClient}>
          <GeneralStoreContextProvider>
            <AppStoreContextProvider>
              <AppThemeWrapper />
            </AppStoreContextProvider>
          </GeneralStoreContextProvider>
        </QueryClientProvider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
}

interface Props {}

const AppThemeWrapper = (props: Props) => {
  let GeneralStore = React.useContext(GeneralStoreContext);

  const [siteTheme, updateSiteTheme] = React.useState<
    keyof typeof SITE.SiteTheme
  >(_.get(GeneralStore, "state.siteTheme", SITE_THEMES.dark));

  React.useEffect(() => {
    const newSiteTheme = _.get(
      GeneralStore,
      "state.siteTheme",
      SITE_THEMES.dark
    );

    if (siteTheme !== newSiteTheme) {
      updateSiteTheme(newSiteTheme);
    }
    updateSiteTheme(_.get(GeneralStore, "state.siteTheme", SITE_THEMES.dark));
  }, [_.get(GeneralStore, "state.siteTheme", SITE_THEMES.dark)]);

  return (
    <App
      siteTheme={siteTheme}
      hasParentSite={GeneralStore.state?.hasParentSite}
    />
  );
};

interface AppProps {
  hasParentSite?: boolean;
  siteTheme: keyof typeof SITE.SiteTheme;
}

const App = (props: AppProps) => {
  return (
    <div
      className={cx("App")}
      data-theme={props.siteTheme}
      data-hasparent={props.hasParentSite ? "true" : "false"}
      id="main"
    >
      <RouterWrapper />
    </div>
  );
};

export default AppWrapper;
