import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { useStoredInputValue } from "data/helpers/Hooks";

const SUB_KEY = "directory-search";
const DEFAULT_DELAY_MS = 120;

export function useThrottledSearchTerm({
  delayMS = DEFAULT_DELAY_MS,
  leading = false,
  trailing = true,
  initialSearchTerm = "",
  subKey = SUB_KEY,
}: {
  delayMS?: number;
  initialSearchTerm?: string;
  leading?: boolean;
  trailing?: boolean;
  subKey?: string;
}) {
  const [searchTermTemp, updateSearchTermTemp, __clearStoredValue] =
    useStoredInputValue({
      subKey,
      initialValue: initialSearchTerm || undefined,
      storageType: "sessionStorage",
    });
  // const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [delayedSearchTerm, setDelayedSearchTerm] = useState(initialSearchTerm);

  const throttledUpdate = useCallback(
    debounce(
      (newSearchTerm: string) => {
        setDelayedSearchTerm(() => newSearchTerm);
      },
      delayMS,
      { leading, trailing }
    ),
    []
  );

  useEffect(() => {
    throttledUpdate(searchTermTemp);
  }, [searchTermTemp]);

  return {
    searchTermForInput: searchTermTemp,
    searchTermForFiltering: delayedSearchTerm,
    setSearchTerm: updateSearchTermTemp,
  };
}

export default useThrottledSearchTerm;
