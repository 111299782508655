import * as React from 'react';

import "./Perspective.scss";

interface Props {
    title: string;
    desc: string;
};

const Perspective = (props: Props) => {
    return (
      <div className='profile-subsection'>
          <h4 className="profile-section-title title-font">{props.title || ""}</h4>

          <p className="profile-perspective-text profile-section-text">{props.desc || ""}</p>
      </div>
  )
}

export default Perspective;