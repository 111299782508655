import * as _ from "lodash";
import { useQuery, useMutation, UseQueryResult } from "react-query";
import { queryClient } from "../";

import { REACT_QUERY_KEYS } from "../../constants/";
// import { makeLocalForageKey } from "../../helpers/";
import * as Storage from "../../storage/";
import TytoCalls from "../tyto/";

import { DEFAULT_RQ_OPTS, useLocalForage } from "./";

export function booleanToYesNo(boolValue?: boolean) {
  if (boolValue === undefined) {
    return "UNDEF";
  }

  return boolValue ? "YES" : "NO";
}

export function useAnswerMutation({
  examResponseID,
  examTaskInviteID,
  evaluate,
  sequenceGreaterThan,
  unasked,
  unanswered,
  onSuccess,
  onError,
}: {
  examResponseID: number;
  examTaskInviteID: number;
  evaluate?: boolean;
  sequenceGreaterThan: number;
  unasked?: boolean;
  unanswered?: boolean;
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  return useMutation(
    (updateData: {
      rTexts: string[];
      examResponseID?: number;
      examTaskInviteID?: number;
    }) => {
      debugger;

      return TytoCalls.Exam.Take.Answer.post({
        ...updateData,
        examResponseID,
        examTaskInviteID,
      });
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: (result, variables, context) => {
        debugger;
        if (evaluate) {
          try {
            TytoCalls.Exam.Take.Evaluate.Reponse.put({
              examResponseID,
              examTaskInviteID,
            }).then(() => {
              TytoCalls.Exam.Take.Question.get({
                examTaskInviteID,
                sequenceGreaterThan,
                unanswered,
                unasked,
              }).then((responseData) => {
                const scopeParams = [
                  examTaskInviteID,
                  sequenceGreaterThan,
                  booleanToYesNo(unanswered),
                  booleanToYesNo(unasked),
                ];

                queryClient.setQueryData(
                  [REACT_QUERY_KEYS.EXAM_QUESTION, ...scopeParams].join("/"),
                  responseData
                );

                // queryClient.invalidateQueries([
                //   REACT_QUERY_KEYS.EXAM_QUESTION,
                //   ...scopeParams,
                // ]);
              });
            });
          } catch (err) {
            const errorMsg = _.get(err, "msg", "Error Occurred.");

            onError(`${errorMsg}`);
          }
        } else {
          // * Don't need feedback text, so create fake objects and mutate local data
          const nowAsString = new Date().toISOString();
          const userID = Storage.SessionHandling.getUserIDOfActiveSession();

          const mutatedAnswers = variables.rTexts.map((rText, curIdx) => ({
            createdDate: `${nowAsString}`,
            rText,
            sequence: curIdx + 1,
            userID,
          }));

          const scopeParams = [
            examTaskInviteID,
            sequenceGreaterThan,
            booleanToYesNo(unanswered),
            booleanToYesNo(unasked),
          ];

          const currentQuestionQueryState = queryClient.getQueryState(
            [REACT_QUERY_KEYS.EXAM_QUESTION, ...scopeParams].join("/")
          );

          const currentQuestionValue = _.get(
            currentQuestionQueryState,
            "data"
          ) as Endpoints.Responses.Exam.Take.Question.Get;

          const newQuestionValue = _.update(
            { ...currentQuestionValue },
            "response.answers",
            () => {
              return mutatedAnswers || [];
            }
          );

          console.log("newQuestionValue: ", newQuestionValue);

          debugger;

          queryClient.setQueryData(
            [REACT_QUERY_KEYS.EXAM_QUESTION, ...scopeParams].join("/"),
            newQuestionValue
          );
        }

        debugger;
        console.log("Answer POST Success! ", variables);

        onSuccess();
      },
    }
  );
}

export function useInvitationMutation({
  enrollmentID,
  devTaskID,
  examTaskInviteID,
  onSuccess,
  onError,
}: {
  enrollmentID?: number;
  devTaskID?: number;
  examTaskInviteID: number;
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  return useMutation(
    (updateData: Partial<Endpoints.Tyto.Exam.Take.Complete.PutParameters>) => {
      // debugger;

      return TytoCalls.Exam.Take.Complete.put({
        examTaskInviteID,
        ...updateData,
      });
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: async (result, variables, context) => {
        // debugger;

        if (result.recordsAffected) {
          const scopeParams = [enrollmentID || devTaskID];

          // TytoCalls.Exam.Take.TrainingInvites.get({
          //   enrollmentID,
          //   devTaskID
          // }).then((responseData) => {
          //   // TODO
          // }).catch(err => {
          //   const errorMsg = _.get(err, "msg", "Error Occurred.");

          //   onError(`${errorMsg}`);
          // });

          queryClient.invalidateQueries(
            [REACT_QUERY_KEYS.EXAM_TRAINING_INVITES, ...scopeParams].join("/")
          );
        }

        onSuccess();
      },
    }
  );
}

export function useTrainingInviteMutation({
  enrollmentID,
  devTaskID,
  onSuccess,
  onError,
}: {
  enrollmentID?: number;
  devTaskID?: number;
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  return useMutation(
    (
      updateData: Partial<Endpoints.Tyto.Exam.Take.TrainingInvite.PutParameters>
    ) => {
      // debugger;

      return TytoCalls.Exam.Take.TrainingInvite.put({
        enrollmentID,
        devTaskID,
        ...updateData,
      });
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: async (result, variables, context) => {
        // debugger;

        if (result.recordsAffected) {
          const scopeParams = [enrollmentID || devTaskID];

          queryClient.invalidateQueries(
            [REACT_QUERY_KEYS.EXAM_TRAINING_INVITES, ...scopeParams].join("/")
          );
        }

        onSuccess();
      },
    }
  );
}

// export function useEvaluateResponseMutation({
//   examResponseID,
//   examTaskInviteID,
//   sequenceGreaterThan,
//   unasked,
//   unanswered,
//   onSuccess,
//   onError,
// }: {
//   examResponseID: number;
//   examTaskInviteID: number;
//   sequenceGreaterThan: number;
//   unasked?: boolean;
//   unanswered?: boolean;
//   onSuccess: () => void;
//   onError: (errorMsg: string) => void;
// }) {
//   // * https://react-query.tanstack.com/guides/mutations
//   // * This still needs handles for onSuccess mutating cached GET response info
//   return useMutation(
//     (updateData: {
//       examResponseID?: number;
//       examTaskInviteID?: number;
//     }) => {
//       debugger;

//       return TytoCalls.Exam.Take.Evaluate.Reponse.put({
//           examResponseID,
//           examTaskInviteID,
//           ...updateData,
//       });
//     },
//     {
//       onError: (error, variables, context) => {
//         const msg = _.get(error, "msg", "Error occurred.");

//         onError(`${msg}`);
//       },
//       onSuccess: (result, variables, context) => {
//         const scopeParams = [
//           examTaskInviteID,
//           sequenceGreaterThan,
//           booleanToYesNo(unanswered),
//           booleanToYesNo(unasked),
//         ];
//         debugger;
//         // * [1] - Update ReactQuery cache to mark enrollment completStatus and appropriately reflect what just happened server-side
//         const currentQuestionQuery = queryClient.getQueryState([
//           REACT_QUERY_KEYS.EXAM_QUESTION,
//           ...scopeParams,
//         ]);

//         const currentQuestionValue = _.get(
//           currentQuestionQuery,
//           "data"
//         ) as Endpoints.Responses.Exam.Take.Question.Get;

//         if (!currentQuestionQuery || !currentQuestionValue) {
//           // TODO: Handle weird scenario where such is undefined?
//           debugger;
//           onSuccess();
//           return;
//         }

//         // * Cycle through prereqEnrollments for course and update one with matching enrollmentID
//         const newQuestionValue = _.update(
//           { ...currentQuestionValue },
//           "response",
//           (response: TytoData.Exam.Response) => {
//             return _.set(response, "answers", variables.rTexts);
//           }
//         );

//         // * Update the cached ReacyQuery data with what we just updated locally
//         queryClient.setQueryData(
//           [REACT_QUERY_KEYS.EXAM_QUESTION, ...scopeParams],
//           newQuestionValue
//         );

//         onSuccess();
//       },
//     }
//   );
// }

interface UseExamQuestionParams
  extends Endpoints.Tyto.Exam.Take.Question.GetParameters {
  onError?: (errorData: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.Exam.Take.Question.Get) => void;
}

export function useExamQuestion(args: UseExamQuestionParams) {
  const scopeParams = [
    args.examTaskInviteID,
    args.sequenceGreaterThan,
    booleanToYesNo(args.unanswered),
    booleanToYesNo(args.unasked),
  ];

  // const storedValueQuery = useLocalForage(REACT_QUERY_KEYS.EXAM_QUESTION);

  return {
    //   storedValueQuery,
    ...useQuery(
      [REACT_QUERY_KEYS.EXAM_QUESTION, ...scopeParams].join("/"),
      async () => {
        const { examTaskInviteID, sequenceGreaterThan, unasked, unanswered } =
          args;

        const data = await TytoCalls.Exam.Take.Question.get({
          examTaskInviteID,
          sequenceGreaterThan,
          unasked,
          unanswered,
        });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        onSuccess: (data) => {
          if (args.onSuccess) {
            args.onSuccess(data);
          }
          // Storage.LF.setItem(
          //   makeLocalForageKey(REACT_QUERY_KEYS.EXAM_QUESTION),
          //   _.omit(data, ["session", "error"])
          // );
        },
      }
    ),
  };
}

interface UseTrainingInvitesParams
  extends Endpoints.Tyto.Exam.Take.TrainingInvites.GetParameters {
  isEnabled?: boolean;
  onError?: (errorData: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.Exam.Take.TrainingInvites.Get) => void;
}

export function useTrainingInvites(args: UseTrainingInvitesParams) {
  let scopeParam = args.enrollmentID || args.devTaskID;
  const { isEnabled = true } = args;

  // if (args.enrollmentID) {
  //   scopeParam = `enr`;
  // }

  // const storedValueQuery = useLocalForage(REACT_QUERY_KEYS.EXAM_QUESTION);

  return {
    //   storedValueQuery,
    ...useQuery(
      `${REACT_QUERY_KEYS.EXAM_TRAINING_INVITES}/${scopeParam}`,
      async () => {
        const { enrollmentID, devTaskID } = args;

        const data = await TytoCalls.Exam.Take.TrainingInvites.get({
          enrollmentID,
          devTaskID,
        });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        enabled: isEnabled,
        onSuccess: (data) => {
          if (args.onSuccess) {
            args.onSuccess(data);
          }
          // Storage.LF.setItem(
          //   makeLocalForageKey(REACT_QUERY_KEYS.EXAM_QUESTION),
          //   _.omit(data, ["session", "error"])
          // );
        },
      }
    ),
  };
}
