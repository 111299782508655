import * as React from "react";
import cx from "classnames";

import { PROFILE_SECTION_IDS } from "data/constants/";
import { DISCPlotGraph } from "components/disc/";
import { Props as DISCPlotGraphProps } from "components/disc/DISCPlotGraph/";
import { usePersonalDISCMini } from "data/network/hooks";
import { CheckBox, TextButton } from "components/common";
import { getGraphInfoFromMinis } from "../../../teams/subcomponents/sections/graphs";

interface GroupStyleItem {
  d: number;
  i: number;
  s: number;
  c: number;
  personName: string;
  personID: number;
  asset: Data.Asset;
}

function createSyleInfo(discMini?: TytoData.DISCProfileMini) {
  if (!discMini) {
    return undefined;
  }

  return {
    d: discMini.d3,
    i: discMini.i3,
    s: discMini.s3,
    c: discMini.c3,
    personName: discMini.personName,
    personID: discMini.personID,
    asset: discMini.profileImageAsset,
  };
}

function getFilteredCollection({
  discMiniProfile,
  personalDISCMini,
}: {
  discMiniProfile?: TytoData.DISCProfileMini;
  personalDISCMini?: TytoData.DISCProfileMini;
}) {
  const personalMini = createSyleInfo(discMiniProfile);
  const otherMini = createSyleInfo(personalDISCMini);

  const list: GroupStyleItem[] = [personalMini, otherMini].filter(
    (item) => !!item
  ) as any[];

  return list;
}

function useCompareCollection({
  discMiniProfile,
  personalDISCMini,
}: {
  discMiniProfile?: TytoData.DISCProfileMini;
  personalDISCMini?: TytoData.DISCProfileMini;
}) {
  const [collection, updateCollection] = React.useState(() => {
    return getFilteredCollection({
      discMiniProfile,
      personalDISCMini,
    });
  });

  React.useEffect(() => {
    updateCollection(
      getFilteredCollection({
        discMiniProfile,
        personalDISCMini,
      })
    );
  }, [discMiniProfile, personalDISCMini]);

  return collection;
}

interface Props {
  containerClassName?: string;
  discMiniProfile?: TytoData.DISCProfileMini;
  otherMiniProfile?: TytoData.DISCProfileMini;
  interactions?: TytoData.DISCCompareProfile;
  title?: string;
  plotGraphProps?: Partial<DISCPlotGraphProps>;
}

const CompareGraph = ({
  containerClassName,
  discMiniProfile,
  otherMiniProfile,
  interactions,
  plotGraphProps,
  title,
}: Props) => {
  const [showStressed, updateShowStressed] = React.useState(false);
  const personDISCMiniQuery = usePersonalDISCMini({});

  const personalDISCMini =
    personDISCMiniQuery?.data?.discProfiles?.[0] ??
    personDISCMiniQuery.storedValueQuery?.data?.discProfiles?.[0];

  const collection = useCompareCollection({
    discMiniProfile,
    personalDISCMini: otherMiniProfile ?? personalDISCMini,
  });
  const [collections, updateCollections] = React.useState(() => {
    if (discMiniProfile && personalDISCMini) {
      return getGraphInfoFromMinis([
        discMiniProfile,
        otherMiniProfile ? otherMiniProfile : personalDISCMini,
      ]);
    }
  });

  return (
    <section
      className={cx("profile-section", containerClassName)}
      data-section={PROFILE_SECTION_IDS.COMPARE_GRAPHS}
    >
      <h4 className="profile-section-title title-font">
        {title ?? `You and ${interactions?.result?.firstName ?? "Them"}`}
      </h4>

      <div className="profile-subsection">
        <DISCPlotGraph
          size={325}
          groupStyle={
            showStressed && collections ? collections?.stressed : collection
          }
          {...(plotGraphProps ?? {})}
        />
      </div>
      <div className="group-graphs-toggle-wrapper">
        <CheckBox
          className="group-graphs-stressed-toggle-checkbox"
          isChecked={showStressed}
          label="Show Stressed"
          onChange={(newVal) => {
            updateShowStressed(newVal);
          }}
          size={24}
        />

        <TextButton
          className="group-graphs-stressed-toggle-btn"
          value="Show Under Stress"
          onClick={() => {
            updateShowStressed(!showStressed);
          }}
        />
      </div>
    </section>
  );
};

export default CompareGraph;
