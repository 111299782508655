import { ENDPOINT_PERSON_GET_ADVANCED } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_PERSON_GET_ADVANCED;

export const PersonAdvanced = {
  get(
    params: Endpoints.Tyto.PersonAdvanced.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params,
      callOpts
    ) as Promise<Endpoints.Responses.Person.AdvancedSearched.Get>;
  },

  post(
    params: Endpoints.Tyto.PersonAdvanced.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "post",
      endpoint,
      params,
      callOpts
    ) as Promise<Endpoints.Responses.Person.AdvancedSearched.Get>;
  },
};
