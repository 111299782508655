import * as React from "react";
import cx from "classnames";
import Select from "react-select";

import { useTeamsByFunction } from "data/network/hooks/";

import "./style.scss";

interface Option {
  label: string;
  value: string | number;
}

export interface Props {
  className?: String;
  classNamePrefix?: string;
  disabled?: boolean;
  // labels: string[];
  // values: string[];
  options: Option[];
  styles?: any;
  placeHolder?: String;
  onChange: (value: any) => void;
  value: Option["value"];
}

const ReactSelect = (props: Props) => {
  // const [options, updateOptions] = React.useState(() => {
  //   if (props.labels.length === props.values.length) {
  //     props.labels?.map((label, value) => ({
  //       label: label,
  //       value: props.values[value],
  //     }));
  //   } else {
  //     return [{ label: "Error", value: "Error" }];
  //   }
  // });

  // React.useEffect(() => {
  //   updateOptions(
  //     props.labels?.map((label, value) => ({
  //       label: label,
  //       value: props.values[value],
  //     }))
  //   );
  // }, [props.labels, props.values]);
  const curActiveOptionIdx = React.useMemo(() => {
    if (!props.options?.length) {
      return undefined;
    }

    return props.options.findIndex((option) => option.value === props.value);
  }, [props.value, props.options]);

  let curValue = undefined;

  if (typeof curActiveOptionIdx === "number" && curActiveOptionIdx >= 0) {
    curValue = props.options?.[curActiveOptionIdx];
  }

  return (
    <Select
      className={cx("cc-react-select", props.className)}
      classNamePrefix={props.classNamePrefix ?? "cc-default-menu"}
      isDisabled={props.disabled}
      options={props.options}
      styles={props.styles}
      placeholder={props.placeHolder}
      onChange={props.onChange}
      value={curValue}
    />
  );
};

export default ReactSelect;

interface TeamPickerProps {
  className?: String;
  styles?: any;
  placeHolder?: String;
  passBack: (value: number) => void;
}

export const TeamPick = ({
  className,
  styles,
  placeHolder,
  passBack,
}: TeamPickerProps) => {
  var options;

  const { data: teamsData, isLoading: isLoadingTeamsData } = useTeamsByFunction(
    {
      extraOpts: {
        functionName: "Goal Sharing",
        operation: "ocVIEW",
      },
      isEnabled: true,
      onError: () => {},
      onSuccess: (data) => {},
    }
  );

  options = teamsData?.teams.map((team) => ({
    label: team.name,
    value: team.teamID,
  }));

  return (
    <Select
      className={cx("cc-react-select", className)}
      options={options}
      styles={styles}
      placeholder={placeHolder}
      onChange={updateTeamID}
    />
  );

  function updateTeamID(data: { label: string; value: number } | null) {
    if (data !== null && data.value !== null) {
      passBack(data.value);
    } else {
      console.log("Error");
    }
  }
};
