import * as React from "react";

import { PROFILE_SECTION_IDS } from "data/constants/";
import { ActiveSession } from "data/storage/";
import { usePersonCommTips } from "data/network/hooks/";

import type { ContentSection } from "data/network/tyto/vendor-247/types";
import { CommTipsEditable, DISCValueRender } from "components/disc/";
import DoAndDoNot247 from "components/disc/communication-tips-editable/CommTips247";

import DoAndDoNot from "./DoAndDoNot";

import "./style.scss";

interface Props {
  discMiniProfile: TytoData.DISCProfileMini;
  interactions?: TytoData.DISCCompareProfile;
  isMobile?: boolean;
}

export function getName(discMiniProfile?: TytoData.DISCProfileMini) {
  if (!discMiniProfile?.personName) {
    return "Their";
  }

  return `${discMiniProfile.personName.split(" ")[0]}'s`;
}

function determineIfIsOwnProfile(discMini: Props["discMiniProfile"]) {
  if (!discMini) {
    return false;
  }

  const userID = ActiveSession.userID();

  if (!userID) {
    return false;
  }

  return userID === discMini.personID;
}

const CommunicationTips = (props: Props) => {
  const [isOwnProfile, updateIsOwnProfile] = React.useState(() => {
    return determineIfIsOwnProfile(props.discMiniProfile);
  });
  const [name, updateName] = React.useState(() =>
    getName(props.discMiniProfile)
  );

  const personCommTips = usePersonCommTips({
    discID: props.interactions?.result.discID ?? 0,
    isEnabled: !!props.interactions?.result.discID,
  });

  React.useEffect(() => {
    updateName(getName(props.discMiniProfile));
    updateIsOwnProfile(determineIfIsOwnProfile(props.discMiniProfile));
  }, [props.discMiniProfile]);

  return (
    <DISCValueRender
      discMiniProfile={props.discMiniProfile}
      isCompareValue={false}
      targetKey="commTips"
      className="profile-comm-tips-valueRender"
    >
      <section
        className="profile-section comm-tips-section"
        data-section={PROFILE_SECTION_IDS.COMM_TIPS}
      >
        <h4 className="profile-section-title comm-tips-title title-font">
          {isOwnProfile ? "Your" : name} Communication Tips
        </h4>

        {props.discMiniProfile && props.interactions ? (
          <CommTipsEditable
            isMobile={props.isMobile}
            canEdit={isOwnProfile}
            ownProfile={isOwnProfile}
            compareProfile={props.interactions}
            personCommunicationTips={
              personCommTips.eagerData?.personCommunicationTips
            }
          />
        ) : (
          // <DoAndDoNot
          //   discMiniProfile={props.discMiniProfile}
          //   interactions={props.interactions}
          // />
          <p></p>
        )}
      </section>
    </DISCValueRender>
  );
};

interface Props247 extends Props {
  contentSectionDo?: ContentSection;
  contentSectionDont?: ContentSection;
}

export const CommunicationTips247 = (props: Props247) => {
  const [isOwnProfile, updateIsOwnProfile] = React.useState(() => {
    return determineIfIsOwnProfile(props.discMiniProfile);
  });
  const [name, updateName] = React.useState(() =>
    getName(props.discMiniProfile)
  );

  const personCommTips = usePersonCommTips({
    discID: props.interactions?.result.discID ?? 0,
    isEnabled: !!props.interactions?.result.discID,
  });

  React.useEffect(() => {
    updateName(getName(props.discMiniProfile));
    updateIsOwnProfile(determineIfIsOwnProfile(props.discMiniProfile));
  }, [props.discMiniProfile]);

  return (
    <DISCValueRender
      discMiniProfile={props.discMiniProfile}
      isCompareValue={false}
      targetKey="commTips"
      className="profile-comm-tips-valueRender"
    >
      <section
        className="profile-section comm-tips-section"
        data-section={PROFILE_SECTION_IDS.COMM_TIPS}
      >
        <h4 className="profile-section-title comm-tips-title title-font">
          {isOwnProfile ? "Your" : name} Communication Tips
        </h4>

        {props.discMiniProfile &&
        props.interactions &&
        props.contentSectionDo &&
        props.contentSectionDont ? (
          <DoAndDoNot247
            isMobile={props.isMobile}
            canEdit={isOwnProfile}
            ownProfile={isOwnProfile}
            dos247={props.contentSectionDo}
            donts247={props.contentSectionDont}
            interactions={props.interactions}
            personCommunicationTips={
              personCommTips.eagerData?.personCommunicationTips
            }
          />
        ) : (
          // <DoAndDoNot
          //   discMiniProfile={props.discMiniProfile}
          //   interactions={props.interactions}
          // />
          <p></p>
        )}
      </section>
    </DISCValueRender>
  );
};

export default CommunicationTips;
