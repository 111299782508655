/*
 * Renders top level component for Profile Interface
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { SessionHandling } from "data/storage/";
import { Nav, Menu, Footer } from "components/meta";

import Profile from "./Profile";

import "./style.scss";

function parseIDFromString(id?: string | number) {
  if (!id) {
    return 0;
  }

  const parsedNum = parseInt(`${id}`, 10);

  return Number.isNaN(parsedNum) ? 0 : parsedNum;
}

function userIDMatchesActiveSession(userID: number) {
  if (!userID) {
    return false;
  }

  const activeSessionUserID =
    SessionHandling.getPropertyFromActiveSession("userID");

  return activeSessionUserID === userID;
}

interface Props extends SITE.GeneralStoreInjectedProps {
  userID?: string | number;
  subInterface?: ProfilePage.Subinterface;
}

const ProfileWrapper = (props: Props) => {
  const [safeUserID, updateSafeUserID] = React.useState(() => {
    return (
      parseIDFromString(props.userID) ||
      SessionHandling.getPropertyFromActiveSession("userID") ||
      0
    );
  });
  const [isOwnProfile, updateIsOwnProfile] = React.useState(
    userIDMatchesActiveSession(safeUserID)
  );
  const [menuHasBeenOpen, updateMenuHasBeenOpen] = React.useState(
    _.get(props, "GeneralStore.state.showMenu", false)
  );

  React.useEffect(() => {
    const newSafeUserID =
      parseIDFromString(props.userID) ||
      SessionHandling.getPropertyFromActiveSession("userID") ||
      0;

    updateSafeUserID(newSafeUserID);
    updateIsOwnProfile(userIDMatchesActiveSession(newSafeUserID));
  }, [props.userID]);

  const storeMenuOpenValue = _.get(props, "GeneralStore.state.showMenu", false);

  // // * If config client has not previously loaded, do so now
  // React.useEffect(() => {
  //   const uploadURL = props.GeneralStore.state?.uploadURL;

  //   if (!uploadURL) {
  //     TytoCalls.Configuration.Client.get({})
  //       .then((resp) => {
  //         props.GeneralStore.dispatch?.({
  //           payload: {
  //             configurationClient: resp,
  //           },
  //           type: "GENERAL_STORE_CONFIGURATION_CLIENT_LOADED",
  //         });
  //       })
  //       .catch((err) => {
  //         // TODO: Handle error
  //       });
  //   }
  // }, []);

  // * Trigger flag so that turning menu off animates back left
  React.useEffect(() => {
    if (!menuHasBeenOpen && storeMenuOpenValue) {
      updateMenuHasBeenOpen(true);
    }
  }, [storeMenuOpenValue, menuHasBeenOpen]);

  const menuIsOpen = storeMenuOpenValue;
  const isMobile = !!props.GeneralStore?.state?.isMobile;

  return (
    <div className={cx("interface-wrapper", menuIsOpen && "menu-open")}>
      {!isMobile && (
        <Nav
          activeTab={isOwnProfile ? "Profile" : "Search"}
          className="profile-nav"
        />
      )}
      <Menu className="profile-menu" />

      <div
        className={cx(
          "interface-main-cont interface-profile",
          menuHasBeenOpen && "animate-back"
        )}
      >
        <Profile
          isMobile={isMobile}
          GeneralStore={props.GeneralStore}
          userID={safeUserID}
          isOwnProfile={isOwnProfile}
          subInterface={props.subInterface}
        />
      </div>

      {isMobile && <Footer activeTab={isOwnProfile ? "Profile" : "Search"} />}
    </div>
  );
};

export default ProfileWrapper;
