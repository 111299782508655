import * as React from "react";
import * as _ from "lodash";

import { PROFILE_SECTION_IDS } from "data/constants/";

import Perspective from "./Perspective";

interface Props {
  interactions?: TytoData.DISCCompareProfile;
}

function keyInteractions(interactions?: TytoData.DISCCompareProfile) {
  const keyed = _.keyBy(interactions?.interactions ?? [], "interactContext");

  return keyed;
}

const Perspectives = ({ interactions }: Props) => {
  const [interactionsKeyed, updateInteractionsKeyed] = React.useState(() =>
    _.keyBy(interactions?.interactions ?? [], "interactContext")
  );

  React.useEffect(() => {
    updateInteractionsKeyed(
      _.keyBy(interactions?.interactions ?? [], "interactContext")
    );
  }, [interactions]);

  return (
    <section
      className="profile-section"
      data-section={PROFILE_SECTION_IDS.PERSPECTIVES}
    >
      <Perspective
        title={`How you see ${interactions?.result.firstName ?? "them"}`}
        desc={interactionsKeyed?.["Insight"]?.interactText ?? ""}
      />
      <Perspective
        title={`How ${interactions?.result.firstName ?? "they will"} see you`}
        desc={interactionsKeyed?.["Presented"]?.interactText ?? ""}
      />
      <Perspective
        title="Tips for working together"
        desc={interactionsKeyed?.["Tips"]?.interactText ?? ""}
      />
    </section>
  );
};

export default Perspectives;
