import { AccountSession } from "./AccountSession";
import { AssetEncoding } from "./Asset.Encoding";
import { Block } from "./Block";
import { BlockEnrollment } from "./BlockEnrollment";
import { BlockPrerequisites } from "./Block.Prerequisites";
import { CatalogCurriculumPublication } from "./CatalogCurriculumPublication";
import { CurriculumPubCatalogItemsTrending } from "./CurriculumPubCatalogItemsTrending";
import { SearchLessonContent } from "./CatalogCurriculumPublication.SearchLessonContent";
import { ConfigurationClient } from "./Configuration.Client";
import { DevPlanEnrollment } from "./DevPlanEnrollment";
import { Domain } from "./Domain";
import { DomainImage } from "./Domain.Image";
import { DISCVendorForTake } from "./DISC.Vendor.ForTake";
import { DISCVendorForSearch } from "./DISC.Vendor.ForSearch";
import { DISC247Extension } from "./vendor-247/Extension";
import { DISC247FindSelfAssessments } from "./DISC247.FindSelfAssessments";
import { DISC247ImportSelfAssessment } from "./DISC247.ImportSelfAssessment";
import { DISC247QuestionnaireValidate } from "./vendor-247/Questionnaire.Validate";
import { DISCProfileInteractive } from "./DiscProfile.Interactive";
import { DISCProfileInteractivePersonCommTips } from "./DiscProfile.Interactive.PersonCommunicationTips";
import { DISCProfileInteractivePersonCommTip } from "./DiscProfile.Interactive.PersonCommunicationTip";
import { DISCProfileInteractiveMockup } from "./DiscProfile.Interactive.Mockup";
import { DISCProfileTeam } from "./DiscProfile.Team";
import { DISCProfileMini } from "./DiscProfile.Mini";
import { DISCProfiles } from "./DiscProfiles";
import { DISCProfilesEmailViewReady } from "./DiscProfiles.EmailViewReady";
import { DISCProfilesMini } from "./DiscProfilesMini";
import { DomainBilling } from "./DomainBilling";
import { DomainBillings } from "./DomainBillings";
import { DomainInvitationEmail } from "./DomainInvitationEmail";
import { DomainInvitationEmailTemplate } from "./DomainInvitationEmail.Template";
import { DomainMessage } from "./Domain.Message";
import { DomainMessages } from "./Domain.Messages";
import { EmailQueueStatus } from "./EmailQueueStatus";
import { EmailQueueStatusUser } from "./EmailQueueStatus.User";
import { EnrollmentVerificationRequest } from "./Enrollment.VerificationRequest";
import { FunctionsASP } from "./FunctionsASP";
import { Answer } from "./Exam.Take.Answer";
import { Complete } from "./Exam.Take.Complete";
import { EvaluateResponse } from "./Exam.Take.Evaluate.Response";
import { Question } from "./Exam.Take.Question";
import { TrainingInvite } from "./Exam.Take.TrainingInvite";
import { TrainingInvites } from "./Exam.Take.TrainingInvites";
import { LaunchEnrollment } from "./Launch.Enrollment";
import { Lesson } from "./Lesson";
import { LoginAuthenticate } from "./LoginAuthenticate";
import { LoginAuthenticate4 } from "./Login.Authenticate4";
import { LoginRecover } from "./Login.Recover";
import { LoginRecoverTeamTools } from "./Login.Recover.TeamTools";
import { LoginResetPassword } from "./Login.ResetPassword";
import { LoginResetPasswordValidate } from "./Login.ResetPassword.Validate";
import { Logout } from "./Session.Logout";
import { Menu } from "./Menu";
import { Person } from "./Person";
import { PersonMyPassword } from "./Person.MyPassword";
import { PersonPassword } from "./Person.Password";
import { PlanEnroll } from "./Plan.Enroll";
import { PrerequisiteEnrollments } from "./PrerequisiteEnrollments";
import { PeopleKeysCallbackReport } from "./PeopleKeys.Callback.Report";
import {
  PeopleKeysFindAssessment,
  PeopleKeysFindAssessmentV4,
} from "./PeopleKeys.FindAssessments";
import { PeopleKeysInitialize } from "./PeopleKeys.Initialize";
import { PeopleKeysNextPage } from "./PeopleKeys.NextPage";
import { PeopleKeysPages } from "./PeopleKeys.Pages";
import { PeopleKeysResponse } from "./PeopleKeys.Response";
import { PeopleKeysV4Import } from "./PeopleKeys.V4.Import";
import { ProfilePhoto } from "./Person.ProfilePhoto";
import { PersonAdvanced } from "./PersonAdvanced";
import { SaveForLesson } from "./SaveForLesson";
import { Tasks } from "./Tasks";
import { TaskStructure } from "./Task.Structure";
import { TaskVerificationRequest } from "./Task.VerificationRequest";
import { Team } from "./Team";
import { Teamboard } from "./Teamboard";
import { TeamsByFunction } from "./TeamsByFunction";
import { TeamMembershipPerson } from "./TeamMembership.Person";
import { TeamToolsConfig } from "./TeamTools.Config";
import { TeamToolsEmailLogin } from "./TeamTools.EmailLogin";
import { TeamToolsInvite } from "./TeamToolsInvite";
import { TeamToolsInviteEmail } from "./TeamToolsInvite.Email";
import { TeamToolsInviteTempSession } from "./TeamToolsInvite.tempSession";
import { Timezones } from "./TimeZone";
import { Training } from "./Training";
import { Upload } from "./Upload";
import { Vendor247Assessment } from "./vendor-247/Assessment";
import { Vendor247ConvertToResponse } from "./vendor-247/ConvertToResponse";
import { Vendor247DISCQuestionnaire } from "./vendor-247/DISCQuestionnaire";
import { Vendor247FindUsers } from "./vendor-247/FindUsers";
import { Vendor247Register } from "./vendor-247/Register";
import { Vendor247ValidateAnswered } from "./vendor-247/ValidateAnswered";

const API = {
  AccountSession,
  Asset: {
    Encoding: AssetEncoding,
  },
  Block: {
    ...Block,
    Enrollment: BlockEnrollment,
    Prerequisites: BlockPrerequisites,
  },
  CatalogCurriculumPublication: {
    ...CatalogCurriculumPublication,
    SearchLessonContent,
  },
  CurriculumPubCatalogItemsTrending,
  Configuration: {
    Client: ConfigurationClient,
  },
  DevPlan: {
    Enrollment: DevPlanEnrollment,
  },
  DISC: {
    Vendor: {
      ForTake: DISCVendorForTake,
      ForSearch: DISCVendorForSearch,
    },
  },
  DISC247: {
    FindSelfAssessments: DISC247FindSelfAssessments,
    ImportSelfAssessment: DISC247ImportSelfAssessment,
  },
  DISCProfiles: {
    ...DISCProfiles,
    EmailViewReady: DISCProfilesEmailViewReady,
  },
  DISCProfile: {
    Interactive: {
      ...DISCProfileInteractive,
      Mockup: DISCProfileInteractiveMockup,
      PersonCommunicationTips: DISCProfileInteractivePersonCommTips,
      PersonCommunicationTip: DISCProfileInteractivePersonCommTip,
    },
    Mini: DISCProfileMini,
    Team: DISCProfileTeam,
  },
  DISCProfilesMini,
  DomainBilling,
  DomainBillings,
  Domain: {
    ...Domain,
    Image: DomainImage,
    Message: DomainMessage,
    Messages: DomainMessages,
  },
  DomainInvitationEmail: {
    ...DomainInvitationEmail,
    Template: DomainInvitationEmailTemplate,
  },
  EmailQueueStatus: {
    ...EmailQueueStatus,
    User: EmailQueueStatusUser,
  },
  Enrollment: {
    VerificationRequest: EnrollmentVerificationRequest,
  },
  Exam: {
    Take: {
      Answer,
      Complete,
      Evaluate: {
        Reponse: EvaluateResponse,
      },
      Question,
      TrainingInvite,
      TrainingInvites,
    },
  },
  FunctionsASP,
  LaunchEnrollment,
  Lesson,
  LoginAuthenticate,
  // LoginRecover,
  Login: {
    Authenticate4: LoginAuthenticate4,
    ResetPassword: {
      ...LoginResetPassword,
      Validate: LoginResetPasswordValidate,
    },
    Recover: {
      ...LoginRecover,
      TeamTools: LoginRecoverTeamTools,
    },
  },
  Menu,
  Person: {
    ...Person,
    MyPassword: PersonMyPassword,
    Password: PersonPassword,
    ProfilePhoto,
  },
  PersonAdvanced,
  PeopleKeys: {
    Callback: {
      Report: PeopleKeysCallbackReport,
    },
    FindAssessments: {
      ...PeopleKeysFindAssessment,
      V4: PeopleKeysFindAssessmentV4,
    },
    Initialize: PeopleKeysInitialize,
    NextPage: PeopleKeysNextPage,
    Pages: PeopleKeysPages,
    Response: PeopleKeysResponse,
    V4: {
      Import: PeopleKeysV4Import,
    },
  },
  Plan: {
    Enroll: PlanEnroll,
  },
  PrerequisiteEnrollments,
  SaveForLesson,
  Session: {
    Logout,
  },
  Task: {
    Structure: TaskStructure,
    VerificationRequest: TaskVerificationRequest,
  },
  Tasks,
  Team,
  Teamboard,
  TeamsByFunction,
  TeamMembership: {
    Person: TeamMembershipPerson,
  },
  TeamTools: {
    Config: TeamToolsConfig,
    EmailLogin: TeamToolsEmailLogin,
  },
  TeamToolsInvite: {
    ...TeamToolsInvite,
    Email: TeamToolsInviteEmail,
    TempSession: TeamToolsInviteTempSession,
  },
  Timezones,
  Training,
  Upload,
  Vendor: {
    Definition: {
      ForTake: DISCVendorForTake,
    },
  },
  Vendor247: {
    Assessment: Vendor247Assessment,
    ConvertToResponse: Vendor247ConvertToResponse,
    DISCQuestionnaire: {
      ...Vendor247DISCQuestionnaire,
      Validate: DISC247QuestionnaireValidate,
    },
    Extension: DISC247Extension,
    FindUsers: Vendor247FindUsers,
    Register: Vendor247Register,
    ValidateAnswered: Vendor247ValidateAnswered,
  },
};

export default API;
