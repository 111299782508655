/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { Icon, Loading } from "components/common/";
import {
  getPersonValueRespectfully,
  getCompareValueRespectfully,
  RetrievedPersonValue,
} from "data/helpers/DISC";

import "./style.scss";

interface Props {
  children?: any;
  className?: string;
  compareProfile?: TytoData.DISCCompareProfile;
  discMiniProfile: TytoData.DISCProfileMini;
  hideRenderFunction?: React.FC;
  omitHiddenMsg?: boolean;
  iconSize?: number;
  isCompareValue: boolean;
  targetKey: string;
  targetKeys?: string[];
}

const DISCValueRender = (props: Props) => {
  const [parsedValue, updateParsedValue] = React.useState<RetrievedPersonValue>(
    () => calcValue(props)
  );
  const [allFail, updateAllFail] = React.useState(() => {
    if (!props.targetKeys || !props.targetKey.length) {
      return false;
    }
    return checkAllValuesFail(props);
  });

  React.useEffect(() => {
    updateParsedValue(calcValue(props));
    updateAllFail(() => {
      if (!props.targetKeys || !props.targetKey.length) {
        return false;
      }

      return checkAllValuesFail(props);
    });
  }, [props]);

  if (
    (props.isCompareValue && !props.compareProfile) ||
    !props.discMiniProfile
  ) {
    return (
      <div
        className={cx(
          "cc-discvaluerender-hidden-cont cc-discvaluerender-loading-cont",
          props.className
        )}
      >
        <span
          style={{ fontSize: "12px", fontWeight: "bold", fontStyle: "italix" }}
        >
          Loading...
        </span>
        {/* <Loading
          className=""
          type="squares"
          size={28}
        /> */}
      </div>
    );
  }

  if (
    allFail ||
    _.get(parsedValue, "permitItem.HIDE", true)
    // !parsedValue.value
  ) {
    const hiddenMsg = userFriendlyMessage({
      discMiniProfile: props.discMiniProfile,
      key: props.targetKey,
      parsedValue: parsedValue,
    });

    return (
      <div className={cx("cc-discvaluerender-hidden-cont", props.className)}>
        {props.hideRenderFunction ? (
          <props.hideRenderFunction />
        ) : (
          <div className="cc-discvaluerender-hidden-cont-inner">
            <Icon
              className="cc-discvaluerender-hidden-icon"
              icon="lock-alt"
              size={props.iconSize || 23}
              title={!!props.omitHiddenMsg ? hiddenMsg : undefined}
            />

            {!props.omitHiddenMsg && (
              <span className="cc-discvaluerender-hidden-msg">{hiddenMsg}</span>
            )}
          </div>
        )}
      </div>
    );
  }

  return <>{props.children || <span />}</>;
};

const calcValue = ({
  compareProfile,
  discMiniProfile,
  isCompareValue,
  targetKey: key,
}: Props) => {
  if (isCompareValue) {
    return getCompareValueRespectfully({
      key: key as keyof TytoData.DISCCompareProfile,
      discMiniProfile,
      compareProfile,
    });
  } else {
    return getPersonValueRespectfully({
      key: key as keyof TytoData.DISCProfileMini,
      discMiniProfile,
    });
  }
};

const checkAllValuesFail = ({
  compareProfile,
  discMiniProfile,
  isCompareValue,
  targetKeys,
}: Props) => {
  if (!targetKeys || !targetKeys.length) {
    return false;
  }
  const allFail = targetKeys.every((key) => {
    if (isCompareValue) {
      const checkedValue = getCompareValueRespectfully({
        key: key as keyof TytoData.DISCCompareProfile,
        discMiniProfile,
        compareProfile,
      });

      return !checkedValue.value || !!checkedValue.permitItem.HIDE;
    } else {
      const checkedValue = getPersonValueRespectfully({
        key: key as keyof TytoData.DISCProfileMini,
        discMiniProfile,
      });

      return !checkedValue.value || !!checkedValue.permitItem.HIDE;
    }
  });

  return allFail;
};

const userFriendlyMessage = ({
  discMiniProfile,
  key,
  parsedValue,
}: {
  discMiniProfile: TytoData.DISCProfileMini;
  key: string;
  parsedValue: RetrievedPersonValue;
}) => {
  const firstName =
    _.get(discMiniProfile, "personName", "").split(" ")[0] || "This user";

  // TODO circumvent switch-case if HIDDEN === false

  switch (_.get(parsedValue, "permitItem.reason", undefined)) {
    case "WAITADMINREVEAL":
      return `This information is currently unavailable and awaiting reveal by an Admin`;
    case "NOSPOILERS":
      return `Information about ${firstName} is currently not available`;
    case "OPTOUT":
      return `This information is not available`;
    case "SHOWSELF":
      return `You have not yet been granted permission to view others information`;
    case "SHOWOTHERS":
    default:
      return "This information is not available";
  }
};

export default DISCValueRender;
