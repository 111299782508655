import { ENDPOINT_R3_DISC_VENDOR_247_REGISTER } from "../../../constants/";
import { callWrapper, CallOpts } from "../utils";

import { RegisterPostParams, RegisterPostResponse } from "./types";

const endpoint = ENDPOINT_R3_DISC_VENDOR_247_REGISTER;

export const Vendor247Register = {
  post(params: RegisterPostParams, callOpts?: CallOpts) {
    return callWrapper(
      "post",
      endpoint,
      params,
      callOpts
    ) as Promise<RegisterPostResponse>;
  },
};
