import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { UserThumbByID } from "components/common/";

import "./style.scss";

const DEFAULT_SIZE = 45;

interface Props {
  noAction?: boolean;
  className?: string;
  discProfileMini: TytoData.DISCProfileMini;
  onClick?: (discProfileMini: TytoData.DISCProfileMini) => void;
  size?: number;
}

const PersonTile = ({
  className,
  discProfileMini,
  onClick,
  size = DEFAULT_SIZE,
}: Props) => {
  const [borderRadius] = React.useState(() => {
    const halfSize = size / 2;
    const smallerRadius = halfSize / 2.25;

    return `${halfSize}px ${smallerRadius}px ${smallerRadius}px ${halfSize}px`;
  });

  const [hasDisc, updateHasDisc] = React.useState(
    discProfileMini.discStatus !== "ocENABLED" ? false : true
  );

  return (
    <button
      title={hasDisc ? "" : "This user has not completed an R3 assessment"}
      disabled={!hasDisc}
      className={
        hasDisc
          ? cx("cd-persontile", className)
          : cx("cd-persontile has-no-disc", className)
      }
      style={{ borderRadius }}
      onClick={() => {
        onClick?.(discProfileMini);
      }}
    >
      <UserThumbByID
        className="cd-persontile-userthumb"
        size={size}
        userName={discProfileMini.personName ?? ""}
        userID={discProfileMini.personID}
      />

      <span className="cd-persontile-username">
        {discProfileMini.personName
          ? discProfileMini.personName.split(" ")[0] +
            ` ` +
            discProfileMini.personName.split(" ")[1].charAt(0) +
            `.`
          : ""}
      </span>
    </button>
  );
};

interface PersonTileDumbProps
  extends Omit<Props, "discProfileMini" | "onClick"> {
  personID: number;
  personName: string;
}

export const PersonTileDumb = ({
  className,
  personID,
  personName,
  size = DEFAULT_SIZE,
}: PersonTileDumbProps) => {
  const [borderRadius] = React.useState(() => {
    const halfSize = size / 2;
    const smallerRadius = halfSize / 2.25;

    return `${halfSize}px ${smallerRadius}px ${smallerRadius}px ${halfSize}px`;
  });

  return (
    <div className={cx("cd-persontile", className)} style={{ borderRadius }}>
      <UserThumbByID
        className="cd-persontile-userthumb"
        size={size}
        userName={personName ?? ""}
        userID={personID}
      />

      <span className="cd-persontile-username">{personName ?? ""}</span>
    </div>
  );
};

export default PersonTile;
