import { useEffect, useState } from "react";
import { keyBy } from "lodash";

import type {
  ContentCategory,
  CategoryName,
  CategoryPart,
  ContentSection,
} from "data/network/tyto/vendor-247/types";
import { use247DISCExtensionQuery } from "data/network/hooks/Vendor247/Extension";

export const SECTION_KEYS: Record<string, ContentCategory> = {
  INTRODUCTION: "BS",
  COMM_TIPS_DO: "DO",
  COMM_TIPS_DONT: "DONT",
  ENVIRONMENT: "JOB",
  AREAS_OF_IMPROVEMENT: "IMP",
  NEEDS: "MAN",
  MOTIVATORS: "MOT",
  STYLE_OVERVIEW: "STYOV",
  PERCIEVED_BY_OTHERS: "PERCEPO",
  PERCIEVED_BY_SELF: "PERCEPS",
  WHEN_STRESSED: "STRESSC",
  STESS_AVOIDERS: "STRESSH",
  STRESS_NEEDS: "STRESSN",
  STRENGTHS: "STRG",
  EMOTIONAL_CHARACTERISTICS: "STYEC",
  FEARS: "STYFE",
  GOALS: "STYGO",
  HOW_OTHERS_ARE_VALUED: "STYHO",
  INFLUENCES_GROUP: "STYIL",
  VALUE_TO_ORGANIZATION: "STYVT",
  CAUTIONS: "STYWO",
  UNDER_PRESSURE: "STYWU",
  WORK_STYLE_TENDENCIES: "WS",
};

export type RespData = NonNullable<
  ReturnType<typeof use247DISCExtensionQuery>["data"]
>;
export type KeyedType = Record<ContentCategory, ContentSection>;

export function useKeyedExtensionData({
  extensionURL,
  isEnabled = true,
}: {
  extensionURL?: string;
  isEnabled?: boolean;
}) {
  const exensionQuery = use247DISCExtensionQuery({
    suppliedPathURL: extensionURL ?? "",
    isEnabled: isEnabled,
  });

  const [keyedData, updateKeyedData] = useState<KeyedType>(() => {
    return keyByCategory(exensionQuery?.data?.ext?.content ?? []);
  });

  useEffect(() => {
    updateKeyedData(keyByCategory(exensionQuery?.data?.ext?.content ?? []));
  }, [exensionQuery?.data?.ext?.content]);

  return keyedData;
}

function keyByCategory(content: ContentSection[]): KeyedType {
  return content.reduce((acc: any, curr) => {
    acc[curr.category] = curr;

    return acc;
  }, {});
}
