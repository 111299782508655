import { ENDPOINT_R3_DISC_VENDOR_247_DISC_QUESTIONNAIRE_CONVERT_TO_RESPONSE } from "../../../constants/";
import { callWrapper, CallOpts } from "../utils";

import {
  ConvertToResponsePostParams,
  ConvertToResponsePostResponse,
} from "./types";

const endpoint =
  ENDPOINT_R3_DISC_VENDOR_247_DISC_QUESTIONNAIRE_CONVERT_TO_RESPONSE;

export const Vendor247ConvertToResponse = {
  post(params: ConvertToResponsePostParams, callOpts?: CallOpts) {
    return callWrapper(
      "post",
      endpoint,
      params,
      callOpts
    ) as Promise<ConvertToResponsePostResponse>;
  },
};
