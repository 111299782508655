import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
  DraggingStyle,
  NotDraggingStyle,
} from "react-beautiful-dnd";

import { Button, Icon } from "components/common/";

import "./Question.scss";

const DRAG_IDS = {
  QUESTIONS: "assessment-drag-questions",
};
const GRID = 8;

interface Props {
  questionIdx: number;
  questionOffsetNumber: number;
  questionData: TytoData.PeopleKeys.PageItem;
  isSaving: boolean;
  updateAnswerOrder: (data: {
    questionIdx: number;
    answers: TytoData.PeopleKeys.PageItemContentOption[];
  }) => void;
  nextQuestion: () => void;
}

const TakerWrapper = (props: Props) => {
  const answers = props.questionData?.content?.Options ?? [];

  const dragEnd = (result: DropResult, provided: ResponderProvided) => {
    // TODO
    if (!result.destination || !answers?.length) {
      return;
    }

    // debugger;
    const newList = reorderAnswers(
      answers,
      result.source.index,
      result.destination.index
    );

    props.updateAnswerOrder({
      questionIdx: props.questionIdx,
      answers: newList,
    });
  };

  // const getListStyle = (isDraggingOver: boolean) => ({
  //   // background: isDraggingOver ? "lightblue" : "lightgrey",
  //   border: "2px dotted transparent",
  //   borderColor: isDraggingOver ? "var(--font-color)" : "transparent",
  //   padding: GRID,
  //   // width: 250,
  // });

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle?: DraggingStyle | NotDraggingStyle
  ): React.CSSProperties => {
    if (isDragging) {
      // debugger;
    }

    const existingTransform = draggableStyle?.transform;

    return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      // padding: GRID * 2,
      // margin: `0 0 ${GRID}px 0`,

      // styles we need to apply on draggables
      outline: isDragging ? "2px solid var(--action)" : undefined,
      ...draggableStyle,
      ...(existingTransform && isDragging
        ? {
            transform: `${existingTransform} rotate(-3deg)`,
          }
        : {
            transform: `${existingTransform ?? ""} rotate(0deg)`,
          }),
    };
  };

  return (
    <DragDropContext onDragEnd={dragEnd}>
      <div
        className={cx(
          "assessment-taker-taker-question",
          props.isSaving && "is-saving"
        )}
      >
        <h2 className="assessment-taker-taker-question-range-marker most-like-me title-font">
          Most like me
        </h2>

        <Droppable droppableId={DRAG_IDS.QUESTIONS}>
          {(listProvided, listSnapshot) => (
            <ul
              className="assessment-taker-taker-question-answers"
              {...listProvided.droppableProps}
              ref={listProvided.innerRef}
              // style={getListStyle(snapshot.isDraggingOver)}
            >
              {answers.map((answer, curIdx) => (
                <Draggable
                  key={answer.Value}
                  isDragDisabled={props.isSaving}
                  draggableId={answer.Value}
                  index={curIdx}
                >
                  {(listItemProvided, listItemSnapshot) => (
                    <li
                      className="assessment-taker-taker-question-answer"
                      key={answer.Value}
                      ref={listItemProvided.innerRef}
                      {...listItemProvided.draggableProps}
                      {...listItemProvided.dragHandleProps}
                      style={getItemStyle(
                        listItemSnapshot.isDragging,
                        listItemProvided.draggableProps.style
                      )}
                    >
                      <Icon
                        className="assessment-taker-taker-question-answer-icon"
                        icon="drag-dots"
                        size={18}
                      />
                      {answer.Text}
                    </li>
                  )}
                </Draggable>
              ))}
            </ul>
          )}
        </Droppable>

        <h2 className="assessment-taker-taker-question-range-marker least-like-me title-font">
          Least like me
        </h2>

        <Button
          className="assessment-taker-taker-question-btn large-btn"
          onClick={props.nextQuestion}
          disabled={props.isSaving}
          hoverBGSize="hidden"
          theme="action"
          value="Next Question"
        />
      </div>
    </DragDropContext>
  );
};

function reorderAnswers(
  list: TytoData.PeopleKeys.PageItemContentOption[],
  startIdx: number,
  endIdx: number
) {
  if (startIdx === endIdx) {
    return list;
  }

  const result = Array.from(list);
  const [removed] = result.splice(startIdx, 1);
  result.splice(endIdx, 0, removed);

  return result;
}

export default TakerWrapper;
