/*
  * Component Description
*/
import * as React from "react";
import cx from "classnames";

import {
    getScopedEncoding,
    // getScopedEncodings,
    getEncodingViewAssetPath,
    // getViewAssetPath,
  } from "../../../../data/helpers/";

  import { Audio } from "../../";

import { Props as AssetProps } from "../";

interface Props extends AssetProps {
    childRef: any;
    autoplay?: boolean; // ! Not actually passed in
}

const AudioWrapper = (props: Props) => {
    const [encoding, updateEncoding] = React.useState(
        getScopedEncoding({
            encodings: props.asset.encodings as Data.Encoding[],
            targetEncoding: "ocORIGINAL",
            mimeTypeScope: "octet-stream",
        })
        );
        const [encodingPathURL, updateEncodingPathURL] = React.useState(
            getEncodingViewAssetPath({
                encoding,
                includeSessionKey: true,
            })
        );

  return (
    <Audio
        controls={true}
        className={cx("cc-asset cc-asset-audio", props.className)}
        encodingLength={encoding ? encoding.length : undefined}
        innerRef={props.childRef}
        {...props.audioProps}
        autoPlay={!!props.autoplay}
        src={encodingPathURL}
        // style={{
        // maxHeight,
        // minHeight,
        // ...(props.style || {}),
        // }}
  />
  );
}

export default AudioWrapper;