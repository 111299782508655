/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import {
  getScopedEncoding,
  getScopedEncodings,
  getEncodingViewAssetPath,
  getViewAssetPath,
} from "../../../../data/helpers/";
import { useLessonThumbnailOverride } from "../../../../data/helpers/Hooks";
import { BROWSER_META_DETA } from "../../../../data/constants/";

import Icon from "../../icon/";
import Image from "../../image/";
import Video from "../../video/";
import { Props as AssetProps } from "../";

import "./VideoWrapper.scss";

function getTargetVideoEncodingType({
  encodings,
}: {
  encodings: Data.Encoding[];
}) {
  // TODO
  return "ocDEFAULT" as keyof typeof TytoData.EncodingType;
}

interface Props extends AssetProps {
  assetType: keyof typeof TytoData.AssetType;
  childRef: any;
  onLaunch?: () => void;
}

const AssetVideoWrapper = (props: Props) => {
  const [showVideo, updateShowVideo] = React.useState(
    !_.get(props, "useImageForVideoUntilInteraction", true)
  );
  const [videoEncodings, updateVideoEncodings] = React.useState(
    getScopedEncodings({
      encodings: props.asset.encodings,
      mimeTypeScope: "video",
    })
  );
  const [encoding, updateEncoding] = React.useState(
    getScopedEncoding({
      encodings: props.asset.encodings as Data.Encoding[],
      targetEncoding: getTargetVideoEncodingType({ encodings: videoEncodings }),
      mimeTypeScope: "video",
    })
  );
  const [encodingPathURL, updateEncodingPathURL] = React.useState(
    getEncodingViewAssetPath({
      encoding,
      includeSessionKey: true,
    })
  );
  const thumbnailPathURL = useLessonThumbnailOverride(props.images);
  const [videoPosterURL, updateVideoPostURL] = React.useState(
    thumbnailPathURL ??
      getViewAssetPath({
        encodings: props.asset.encodings as Data.Encoding[],
        targetEncoding: "ocTHUMBNAIL",
        mimeTypeScope: "image",
        includeSessionKey: true,
      })
  );

  React.useEffect(() => {
    // * If something about the asset changed, recalc info in the case that it snapshotted outdated cached encodings
    const newVideoEncs = getScopedEncodings({
      encodings: props.asset.encodings,
      mimeTypeScope: "video",
    });
    const newEncoding = getScopedEncoding({
      encodings: props.asset.encodings as Data.Encoding[],
      targetEncoding: getTargetVideoEncodingType({ encodings: newVideoEncs }),
      mimeTypeScope: "video",
    });
    const newEncPathURL = getEncodingViewAssetPath({
      encoding: newEncoding,
      includeSessionKey: true,
    });
    const newVideoPosterURL =
      thumbnailPathURL ??
      getViewAssetPath({
        encodings: props.asset.encodings as Data.Encoding[],
        targetEncoding: "ocTHUMBNAIL",
        mimeTypeScope: "image",
        includeSessionKey: true,
      });

    updateVideoEncodings(newVideoEncs);
    updateEncoding(newEncoding);
    updateEncodingPathURL(newEncPathURL);
    updateVideoPostURL(newVideoPosterURL);
  }, [props.asset]);

  const styleData: React.CSSProperties = React.useMemo(() => {
    const { height, width } = encoding || {};

    const pluckedPropsStyles = _.omit(props.style || {}, [
      "maxHeight",
      "minHeight",
      "height",
    ]);

    if (BROWSER_META_DETA.SUPPORTS.ASPECT_RATIO && height && width) {
      return {
        aspectRatio: `${width} / ${height}`,
        ...pluckedPropsStyles,
      };
    } else {
      const maxHeightVal = Math.min(750, _.get(encoding, "height", 480));
      const maxHeight = `${maxHeightVal}px`;
      // TODO: Calculate expected scaled height of asset being used
      const minHeight = `${Math.max(360)}px`;

      return {
        maxHeight,
        minHeight,
        ...pluckedPropsStyles,
      };
    }
  }, [encoding]);

  // const maxHeightVal = Math.min(750, _.get(encoding, "height", 480));
  // const maxHeight = `${maxHeightVal}px`;
  // // TODO: Calculate expected scaled height of asset being used
  // const minHeight = `${Math.max(360)}px`;

  if (!showVideo) {
    return (
      <div className="cc-asset-video-poster-image-wrapper">
        <Image
          src={videoPosterURL}
          className="cc-asset-video-poster-image"
          style={{ ...styleData }}
          // style={{ maxHeight, minHeight, ...(props.style || {}) }}
        />

        <div
          className={cx(
            "cc-asset-video-play-overlay",
            props.videoPlayIsReplay && "is-replay"
          )}
        >
          <Icon
            className="cc-asset-video-play-btn"
            buttonProps={{
              className: "btn-touch-reaction",
            }}
            isButton={true}
            onClick={() => {
              updateShowVideo(true);
              props.videoProps?.onLaunch?.();
            }}
            icon={props.videoPlayIsReplay ? "replay" : "play-circle"}
            size={60}
          />

          {props.videoPlayIsReplay && (
            <p className="cc-asset-video-play-btn-text">Replay</p>
          )}
        </div>
      </div>
    );
  }

  return (
    <Video
      controls={true}
      className={cx("cc-asset cc-asset-video", props.className)}
      encodingLength={encoding ? encoding.length : undefined}
      innerRef={props.childRef}
      {...props.videoProps}
      autoPlay={true} // ! Be more intelligent about this
      src={encodingPathURL}
      poster={videoPosterURL}
      style={{
        ...styleData,
      }}
    />
  );
};

export default AssetVideoWrapper;
