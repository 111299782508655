import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import "./style.scss";

interface Props {
  groupProfile: TytoData.DISCTeamProfile;
}

const GroupFascilitatingMeeting = (props: Props) => {
  return (
    <section className="group-section group-fascilitating-meeting">
      <h4 className="group-section-title title-font">Facilitating a Meeting</h4>

      <p className="group-section-text">
        {props.groupProfile?.meetingTips ?? ""}
      </p>
    </section>
  );
};

export default GroupFascilitatingMeeting;
