import * as React from "react";

import { PROFILE_SECTION_IDS } from "data/constants/";
import { DISCValueRender } from "components/disc";

import ContributionList from "./ContributionList";

import "./style.scss";

interface Props {
  discMiniProfile: TytoData.DISCProfileMini;
  interactions?: TytoData.DISCCompareProfile;
  isOwnProfile?: boolean;
}

const Contributions = (props: Props) => {
  return (
    <DISCValueRender
      discMiniProfile={props.discMiniProfile}
      isCompareValue={false}
      targetKey="contribution"
      className="profile-graph-valueRender"
    >
      <section
        className="profile-section"
        data-section={PROFILE_SECTION_IDS.CONTRIBUTIONS}
      >
        <h4 className="profile-section-title title-font">
          {props.isOwnProfile
            ? "Your Contributions"
            : `What should you expect during your collaboration with 
        ${props.discMiniProfile?.styleName3 ?? "them"}?`}
        </h4>

        <ContributionList
          title={`${
            props.discMiniProfile?.styleName3 ??
            (props.isOwnProfile ? "Your" : "Their")
          } value to a team`}
          unparsedList={props.interactions?.style?.teamContributions ?? ""}
        />

        <ContributionList
          title={`How ${
            props.discMiniProfile?.styleName3
              ? `${props.discMiniProfile?.styleName3}s`
              : props.isOwnProfile
              ? "You"
              : "They"
          } Analyze`}
          unparsedList={props.interactions?.style?.analyticDispostion ?? ""}
        />
      </section>
    </DISCValueRender>
  );
};

export default Contributions;
