/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { Redirect } from "react-router-dom";

import { StoreContext as AppStoreContext } from "data/stores/AppStore";
import { ActiveSession } from "data/storage";
import { INTERFACE_PATHS, SUB_INTERFACE_PATHS } from "data/constants";

import ConfirmationPage from "./ConfirmationPage";
import AssessmentTakerLandingPage from "./LandingPage";
import RestartPage from "./RestartPage";
import Taker247 from "./Taker";

import type { AssessmentTakerSubInterface } from "./types";

import "./style.scss";

export interface Props extends SITE.GeneralStoreInjectedProps {
  isMobile: boolean;
  discProfileMini?: TytoData.DISCProfileMini;
  loggedInUserID: number;
}

const AssessmentTaker = ({
  discProfileMini,
  isMobile,
  GeneralStore,
}: Props) => {
  const [loggedInUserID] = React.useState(() => {
    return ActiveSession.userID() ?? 0;
  });
  const [curSubInterface, updateCurSubInterface] =
    React.useState<AssessmentTakerSubInterface>("welcome");

  //   if (!!discProfileMini?.styleKey3) {
  //     return (
  //       <Redirect
  //         to={`${INTERFACE_PATHS.PROFILE}/${loggedInUserID}/${SUB_INTERFACE_PATHS.PROFILE.HOW_TO_READ}`}
  //       />
  //     );
  //   }

  return (
    <div className="interface-assessment-taker-inner-wrapper">
      <main className="interface-assessment-taker-inner-cont centered-content-wrapper">
        <SubInterfaceRouter
          curSubInterface={curSubInterface}
          discProfileMini={discProfileMini}
          isMobile={isMobile}
          GeneralStore={GeneralStore}
          updateCurSubInterface={updateCurSubInterface}
          loggedInUserID={loggedInUserID}
        />
      </main>
    </div>
  );
};

interface SubInterfaceRouterProps
  extends AssessmentTakerInterface.UpdateSubInterfaceProps,
    Pick<
      Props,
      "discProfileMini" | "isMobile" | "GeneralStore" | "loggedInUserID"
    > {}

const SubInterfaceRouter = (props: SubInterfaceRouterProps) => {
  switch (props.curSubInterface) {
    case "taker":
      return (
        <Taker247
          curSubInterface={props.curSubInterface}
          isMobile={props.isMobile}
          updateCurSubInterface={props.updateCurSubInterface}
          loggedInUserID={props.loggedInUserID}
        />
      );
    case "completed":
      return (
        <ConfirmationPage
          isMobile={props.isMobile}
          updateCurSubInterface={props.updateCurSubInterface}
        />
      );
    case "restart":
      return (
        <RestartPage
          discProfileMini={props.discProfileMini}
          onContinue={() => {
            props.updateCurSubInterface?.("taker");
          }}
          loggedInUserID={props.loggedInUserID}
        />
      );
    case "welcome":
    default:
      return (
        <AssessmentTakerLandingPage
          discProfileMini={props.discProfileMini}
          onContinue={() => {
            props.updateCurSubInterface?.("taker");
          }}
          loggedInUserID={props.loggedInUserID}
        />
      );
  }
};

export default AssessmentTaker;
