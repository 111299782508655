import * as React from "react";

import { IconProps } from "../typings";

const Training = ({ className, color, gradientKey, size }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={size}
      width={size}
      fill={color}
    >
      {/* <g filter={`url(#${gradientKey})`}> */}
      <g>
        <path d="M21.6248 19.875H18.75C18.5919 19.3338 18.2625 18.8584 17.8113 18.5202C17.3602 18.182 16.8115 17.9991 16.2476 17.9991C15.6838 17.9991 15.1351 18.182 14.6839 18.5202C14.2327 18.8584 13.9034 19.3338 13.7452 19.875H1.5C1.30109 19.875 1.11032 19.954 0.96967 20.0947C0.829018 20.2353 0.75 20.4261 0.75 20.625C0.75 20.8239 0.829018 21.0147 0.96967 21.1553C1.11032 21.296 1.30109 21.375 1.5 21.375H13.7475C13.9063 21.9153 14.2358 22.3897 14.6866 22.7272C15.1375 23.0646 15.6856 23.247 16.2488 23.247C16.8119 23.247 17.36 23.0646 17.8109 22.7272C18.2617 22.3897 18.5912 21.9153 18.75 21.375H21.6225C21.8214 21.375 22.0122 21.296 22.1528 21.1553C22.2935 21.0147 22.3725 20.8239 22.3725 20.625C22.3725 20.4261 22.2935 20.2353 22.1528 20.0947C22.0122 19.954 21.8214 19.875 21.6225 19.875H21.6248ZM16.2502 21.75C16.0277 21.75 15.8102 21.684 15.6252 21.5604C15.4402 21.4368 15.296 21.2611 15.2109 21.0555C15.1257 20.85 15.1035 20.6238 15.1469 20.4055C15.1903 20.1873 15.2974 19.9868 15.4548 19.8295C15.6121 19.6722 15.8125 19.565 16.0308 19.5216C16.249 19.4782 16.4752 19.5005 16.6808 19.5856C16.8863 19.6708 17.062 19.815 17.1857 20C17.3093 20.185 17.3752 20.4025 17.3752 20.625C17.3752 20.9234 17.2567 21.2095 17.0457 21.4205C16.8348 21.6315 16.5486 21.75 16.2502 21.75Z" />
        <path d="M20.895 0.75H3.105C2.4806 0.750596 1.88194 0.998902 1.44042 1.44042C0.998902 1.88194 0.750596 2.4806 0.75 3.105V14.895C0.750596 15.5194 0.998902 16.1181 1.44042 16.5596C1.88194 17.0011 2.4806 17.2494 3.105 17.25H20.895C21.5194 17.2494 22.1181 17.0011 22.5596 16.5596C23.0011 16.1181 23.2494 15.5194 23.25 14.895V3.105C23.2494 2.4806 23.0011 1.88194 22.5596 1.44042C22.1181 0.998902 21.5194 0.750596 20.895 0.75ZM21.75 14.895C21.7498 15.1217 21.6597 15.3391 21.4994 15.4994C21.3391 15.6597 21.1217 15.7498 20.895 15.75H3.105C2.8783 15.7498 2.66094 15.6597 2.50064 15.4994C2.34034 15.3391 2.2502 15.1217 2.25 14.895V3.105C2.2502 2.8783 2.34034 2.66094 2.50064 2.50064C2.66094 2.34034 2.8783 2.2502 3.105 2.25H20.895C21.1217 2.2502 21.3391 2.34034 21.4994 2.50064C21.6597 2.66094 21.7498 2.8783 21.75 3.105V14.895Z" />
        <path d="M16.1475 8.364L10.1475 4.614C10.034 4.54305 9.90352 4.50377 9.76969 4.50026C9.63585 4.49674 9.50352 4.52912 9.38642 4.59402C9.26933 4.65892 9.17174 4.75398 9.10379 4.86933C9.03584 4.98468 9 5.11612 9 5.25V12.75C9 12.8839 9.03584 13.0153 9.10379 13.1307C9.17174 13.246 9.26933 13.3411 9.38642 13.406C9.50352 13.4709 9.63585 13.5033 9.76969 13.4997C9.90352 13.4962 10.034 13.457 10.1475 13.386L16.1475 9.636C16.256 9.56874 16.3456 9.47488 16.4077 9.36332C16.4698 9.25176 16.5024 9.12619 16.5024 8.9985C16.5024 8.87082 16.4698 8.74524 16.4077 8.63368C16.3456 8.52212 16.256 8.42826 16.1475 8.361V8.364ZM10.5 11.4V6.6L14.3348 9L10.5 11.4Z" />
      </g>
      <defs>
        <filter
          id={gradientKey}
          x="0"
          y="0"
          width={24}
          height={24}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result={`effect1_dropShadow_${gradientKey}`}
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2={`effect1_dropShadow_${gradientKey}`}
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Training;
