import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";
import { StoreContext as GeneralStoreContext } from "data/stores/GeneralStore";

import {
  useCreatePersonCommTipsMutation,
  usePersonCommTipsMutation,
} from "data/network/hooks/";
import {
  Icon,
  Input,
  MenuButton,
  TextButton,
  ToggleButton,
} from "components/common/";

interface Props {
  canEdit: boolean;
  interactions: TytoData.DISCCompareProfile;
  ownProfile?: boolean;
  personCommunicationTips?: TytoData.DISCCompareProfile.PersonCommTip[];
  isMobile?: boolean;
}

const DEFAULT_LISTS = [[], []];

function sortDosAndDonts(list?: TytoData.DISCCompareProfile.CommTip[]) {
  if (!list?.length) {
    return DEFAULT_LISTS;
  }

  return _.orderBy(list ?? [], ["seq"], ["asc"]).reduce(
    (accum: Array<TytoData.DISCCompareProfile.CommTip[]>, item) => {
      if (item.activeStatus === "ocDISABLED") {
        return accum;
      }

      if (item.blnDo) {
        accum[0].push(item);
      } else {
        accum[1].push(item);
      }

      return accum;
    },
    [[], []]
  );
}

function pullAndSortDoAndDoNots({
  interactions,
  personCommTips,
}: {
  interactions: TytoData.DISCCompareProfile;
  personCommTips?: TytoData.DISCCompareProfile.PersonCommTip[];
}) {
  const [suppliedDos, suppliedDonts] = sortDosAndDonts(
    interactions.communicationTips
  );
  const [personalDos, personalDonts] = sortDosAndDonts(personCommTips);

  return [suppliedDos, suppliedDonts, personalDos, personalDonts];
}

const DoAndDoNot = (props: Props) => {
  const [commTips, updateDoAndDoNot] = React.useState(() =>
    pullAndSortDoAndDoNots({
      interactions: props.interactions,
      personCommTips: props.personCommunicationTips,
    })
  );

  const GeneralStore = React.useContext(GeneralStoreContext);

  const isMobile = GeneralStore?.state?.isMobile;

  const [selectedItem, updateSelectedItem] = React.useState("do");

  React.useEffect(() => {
    updateDoAndDoNot(
      pullAndSortDoAndDoNots({
        interactions: props.interactions,
        personCommTips: props.personCommunicationTips,
      })
    );
  }, [props.interactions, props.personCommunicationTips]);

  const [suppliedDos, suppliedDonts, personalDos, personalDonts] = commTips;

  return (
    <section className="cd-commtips-main-wrapper">
      {isMobile && (
        <>
          <ToggleButton
            buttonItems={[
              { label: "Do's", value: "do" },
              { label: "Don'ts", value: "dont" },
            ]}
            activeItem={selectedItem}
            onChange={(value) => {
              updateSelectedItem(value);
            }}
            className="cd-cimmtips-main-selector"
          />
          {selectedItem === "do" ? (
            <TipsList
              blnDo={true}
              discID={props.interactions.result.discID}
              interactions={props.interactions}
              ownProfile={props.ownProfile}
              tips={suppliedDos}
              tipsPersonal={personalDos}
              canEdit={props.canEdit}
            />
          ) : (
            <TipsList
              blnDo={false}
              discID={props.interactions.result.discID}
              interactions={props.interactions}
              ownProfile={props.ownProfile}
              tips={suppliedDonts}
              tipsPersonal={personalDonts}
              canEdit={props.canEdit}
            />
          )}
        </>
      )}
      {!isMobile && (
        <>
          <TipsList
            blnDo={true}
            discID={props.interactions.result.discID}
            interactions={props.interactions}
            ownProfile={props.ownProfile}
            tips={suppliedDos}
            tipsPersonal={personalDos}
            canEdit={props.canEdit}
          />
          <TipsList
            blnDo={false}
            discID={props.interactions.result.discID}
            interactions={props.interactions}
            ownProfile={props.ownProfile}
            tips={suppliedDonts}
            tipsPersonal={personalDonts}
            canEdit={props.canEdit}
          />
        </>
      )}
    </section>
  );
};

// const DO_DESC = `These things when communicating with `;
// const DONT_DESC = `do these things`;

interface TipsListProps {
  canEdit: boolean;
  discID: number;
  blnDo: boolean;
  interactions: TytoData.DISCCompareProfile;
  ownProfile?: boolean;
  tips: TytoData.DISCCompareProfile.CommTip[];
  tipsPersonal: TytoData.DISCCompareProfile.CommTip[];
}

const TipsList = ({
  blnDo,
  canEdit,
  discID,
  interactions,
  ownProfile,
  tips,
  tipsPersonal,
}: TipsListProps) => {
  const [semanticName, updateSemanticName] = React.useState(() => {
    if (ownProfile) {
      return "Your";
    }

    let name = interactions?.result?.firstName;

    if (name) {
      name = `${name}'s`;
    } else {
      name = "Their";
    }

    return name ?? "Their";
  });

  React.useEffect(() => {
    let name = interactions?.result?.firstName;

    if (name) {
      name = `${name}'s`;
    } else {
      name = "Their";
    }

    if (ownProfile) {
      name = "Your";
    }

    updateSemanticName(name);
  }, [interactions]);

  return (
    <div className="cd-subsection">
      <ul className="cd-commtips-list">
        {tips?.map?.((tip) => (
          <CommTip
            className="cd-commtips-list-item"
            key={tip.discCommTipID}
            blnDo={blnDo}
            tip={tip}
          />
        )) ?? null}

        {(!!ownProfile || !!tipsPersonal?.length) && (
          <h3 className="cd-commtips-subtitle">
            {semanticName} Personal, Custom Tips
          </h3>
        )}

        {tipsPersonal?.map?.((personalTip) => (
          <PersonCommTip
            className="cd-commtips-list-item"
            canEdit={canEdit}
            discID={discID}
            key={personalTip.discCommTipSelectID}
            blnDo={blnDo}
            tip={personalTip}
          />
        )) ?? null}

        {canEdit && (
          <NewPersonalCommTip
            blnDo={blnDo}
            discID={discID}
            sequenceOffset={tipsPersonal?.length ?? 0}
          />
        )}
      </ul>
    </div>
  );
};

interface CommTipProps extends Pick<TipsListProps, "blnDo"> {
  canEdit?: boolean;
  className?: string;
  onEditToggle?: (editing?: boolean) => void;
  onDelete?: () => void;
  tip: TytoData.DISCCompareProfile.CommTip;
}

const CommTip = ({ blnDo, tip, ...props }: CommTipProps) => {
  return (
    <li className="cd-commtips-list-item" key={tip.discCommTipID}>
      <div className="cd-commtips-list-item-icon-cont">
        <Icon
          className={cx(
            "cd-commtips-list-item-icon",
            blnDo ? "commtip-icon-do" : "commtip-icon-do-not"
          )}
          icon={blnDo ? "check-circle-2" : "close-circle"}
          size={18}
        />
      </div>
      {tip.tipDescription}

      {props.canEdit && (
        <MenuButton
          className="cd-commtip-menubtn"
          menuItems={[
            {
              label: "Edit",
              onSelect: () => {
                props.onEditToggle?.(true);
              },
            },
            {
              label: "Delete",
              onSelect: () => {
                const confirmed = window.confirm(
                  `Are you sure you want to delete this Communication Tip?`
                );

                if (confirmed) {
                  props.onDelete?.();
                }
              },
            },
          ]}
        >
          <Icon icon="elipsis-h" size={26} />
        </MenuButton>
      )}
    </li>
  );
};

interface PersonCommTipProps {
  blnDo: boolean;
  discID: number;
  canEdit?: boolean;
  className?: string;
  tip: TytoData.DISCCompareProfile.CommTip;
}

const PersonCommTip = (props: PersonCommTipProps) => {
  const [isEditing, updateIsEditing] = React.useState(false);
  const [description, updateDescription] = React.useState(
    props.tip.tipDescription ?? ""
  );

  const editCommTipMutation = usePersonCommTipsMutation({
    discCommTipSelectID: props.tip.discCommTipSelectID ?? 0,
    discID: props.discID,
    onError: () => {},
    onSuccess: () => {
      //   updateDescription(props.tip.tipDescription ?? "");
      updateIsEditing(false);
    },
  });

  React.useEffect(() => {
    updateDescription(props.tip.tipDescription);
  }, [props.tip]);

  if (isEditing) {
    return (
      <EditableCommTip
        autoFocus={true}
        blnDo={props.blnDo}
        desc={description}
        onSave={() => {
          if (!editCommTipMutation.isLoading && description) {
            if (description === props.tip.tipDescription) {
              updateIsEditing(false);
            } else {
              editCommTipMutation.mutate({
                blnDo: props.blnDo,
                tipDescription: description,
                seq: props.tip.seq,
              });
            }
          }
        }}
        isLoading={editCommTipMutation.isLoading}
        sequence={props.tip.seq}
        updateDescription={updateDescription}
      />
    );
  }

  return (
    <CommTip
      className={props.className}
      canEdit={props.canEdit}
      blnDo={props.blnDo}
      onDelete={() => {
        if (!editCommTipMutation.isLoading) {
          editCommTipMutation.mutate({
            blnDo: props.blnDo,
            tipDescription: description,
            seq: props.tip.seq,
            activeStatus: "ocDISABLED",
          });
        }
      }}
      onEditToggle={() => {
        updateIsEditing(true);
      }}
      tip={props.tip}
    />
  );
};

interface NewPersonalCommTipProps {
  blnDo: boolean;
  discID: number;
  sequenceOffset: number;
}

const NewPersonalCommTip = (props: NewPersonalCommTipProps) => {
  const [description, updateDescription] = React.useState("");

  const createMutation = useCreatePersonCommTipsMutation({
    discID: props.discID,
    onError: () => {},
    onSuccess: () => {
      updateDescription("");
    },
  });

  return (
    <EditableCommTip
      blnDo={props.blnDo}
      desc={description}
      sequence={props.sequenceOffset + 1}
      isLoading={createMutation.isLoading}
      onSave={() => {
        if (!createMutation.isLoading && description) {
          createMutation.mutate({
            blnDo: props.blnDo,
            tipDescription: description,
            seq: props.sequenceOffset + 1,
          });
        }
      }}
      updateDescription={updateDescription}
    />
  );
};

interface EditableCommTipProps {
  autoFocus?: boolean;
  blnDo: boolean;
  // discID: number;
  desc: string;
  // sequenceOffset: number;
  sequence: number;
  isLoading: boolean;
  onSave: (data: { desc: string; seq: number; blnDo: boolean }) => void;
  updateDescription: (newVal: string) => void;
}

const EditableCommTip = (props: EditableCommTipProps) => {
  return (
    <li className="cd-commtips-list-item editable-list-item">
      <div className="cd-commtips-list-item-icon-cont new-tip-icon-cont">
        <Icon
          className={cx(
            "cd-commtips-list-item-icon",
            props.blnDo ? "commtip-icon-do" : "commtip-icon-do-not"
          )}
          icon={props.blnDo ? "plus" : "plus"}
          size={18}
        />
      </div>

      <Input
        containerClassName="cd-commtips-list-item-input-wrapper"
        className="cd-commtips-list-item-input"
        autoFocus={props.autoFocus}
        disabled={props.isLoading}
        name="New Communication Tip"
        placeholder="Add Something..."
        onChange={(newVal) => props.updateDescription(newVal)}
        type="textarea"
        rows={1}
        value={props.desc}
      />

      <TextButton
        className="cd-commtips-list-item-add-btn"
        disabled={props.isLoading || !props.desc}
        onClick={() => {
          if (!props.isLoading && props.desc) {
            props.onSave({
              blnDo: props.blnDo,
              desc: props.desc,
              seq: props.sequence,
            });
          }
        }}
        value="Done"
      />
      {/* <Icon
        buttonProps={{
          disabled: props.isLoading || !props.desc,
        }}
        onClick={() => {
          if (!props.isLoading && props.desc) {
            props.onSave({
              blnDo: props.blnDo,
              desc: props.desc,
              seq: props.sequence,
            });
          }
        }}
        size={16}
        isButton={true}
        icon="plus"
      /> */}
    </li>
  );
};

export default DoAndDoNot;
