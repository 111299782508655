/*
 * Component Description
 */
import * as React from "react";

import { SessionHandling } from "data/storage/";
import { makePathURLQualified } from "data/helpers/";

import UserThumb, { Props as UserThumbProps } from "../userthumb";

import "./style.scss";

interface Props extends UserThumbProps {}

const SessionUserThumb = (props: Props) => {
  const [userName] = React.useState(() => {
    return SessionHandling.getPropertyFromActiveSession("userName") ?? "";
  });
  const [loggedInUserThumbPath] = React.useState(() => {
    const profileThumbPath =
      SessionHandling.getPropertyFromActiveSession("profileThumbPath");

    return profileThumbPath ? makePathURLQualified(profileThumbPath) : "";
  });

  return (
    <UserThumb
      {...props}
      userName={userName}
      userThumbPathURL={loggedInUserThumbPath}
    />
  );
};

export default SessionUserThumb;
