import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";
import { Redirect } from "react-router-dom";
import { ActiveSession } from "data/storage";

import {
  INTERFACE_PATHS,
  GMAIL_PLUGIN_URL,
  OUTLOOK_ADD_IN_URL,
  PLUGIN_ICONS,
} from "data/constants";
import {
  Link,
  Icon,
  Image,
  Video,
  Button,
  SearchInput,
} from "components/common/";

import "./style.scss";
import { values } from "lodash";

interface Props {
  discProfileMini: TytoData.DISCProfileMini;
  isMobile: boolean;
}

const HomeHasResults = (props: Props) => {
  const [canView, updateCanView] = React.useState(() => {
    return !props.discProfileMini?.permitMatrix.SCORES_.HIDE;
  });
  const [redirectTarget, updateRedirectTarget] = React.useState("");

  const [loggedInUserID] = React.useState(() => {
    return ActiveSession.userID() ?? 0;
  });
  const [searchTerm, updateSearchTerm] = React.useState("");
  if (redirectTarget) {
    return <Redirect to={`${redirectTarget}`} />;
  }

  return (
    <article className="home-hasresults">
      <section className="home-hasresults-section">
        <Icon
          size={27}
          icon="search"
          className="home-hasresults-section-icon"
        />
        <div className="home-hasresults-section-search">
          <h1 className="home-hasresults-section-title">Search</h1>
          <p className="home-hasresults-section-desc">
            Use this tool to quickly find others in your organization’s R3
            profile to better understand their communication style and
            preferences.
          </p>
          <div>
            <form
              className="directory-search-wrapper"
              onSubmit={(e) => {
                e.preventDefault?.();
                updateRedirectTarget(`${INTERFACE_PATHS.SEARCH}/${searchTerm}`);
              }}
              id={"Link-to-search"}
            >
              <SearchInput
                autoFocus={false}
                className="directory-search-input font-size-medium"
                disabled={false}
                placeholder="Search for people"
                name="search-for-people"
                id="search-for-people"
                form={"Link-to-search"}
                onChange={(newSearchTerm: any) =>
                  updateSearchTerm(`${newSearchTerm}`)
                }
                onEnter={() => {
                  if (searchTerm) {
                    updateRedirectTarget(
                      `${INTERFACE_PATHS.SEARCH}/${searchTerm}`
                    );
                  }
                }}
                value={searchTerm}
              />
              {/* {!isMobile && ( */}
              <div className="directory-search-btn-cont">
                <Button
                  className="directory-search-btn"
                  disabled={false}
                  theme="action"
                  value={"Search"}
                  type="submit"
                  onClick={_.noop}
                />
              </div>
              {/* )} */}
            </form>
          </div>
        </div>
      </section>
      <section className="home-hasresults-section">
        <Icon
          size={27}
          icon="document"
          className="home-hasresults-section-icon"
        />
        <div className="home-hasresults-section-profile">
          <h1 className="home-hasresults-section-title">View Your Profile</h1>
          <p className="home-hasresults-section-desc">
            This is a link to view your R3 profile. You can view all aspects of
            your profile and download the full PDF.
          </p>
          <Button
            theme="action"
            onClick={() => {
              updateRedirectTarget(`${INTERFACE_PATHS.PROFILE}`);
            }}
            value="My Profile"
          />
        </div>
      </section>
      <section className="home-hasresults-section">
        <Icon size={27} icon="orbit" className="home-hasresults-section-icon" />
        <div className="home-hasresults-section-compare">
          <h1 className="home-hasresults-section-title">Team Collaborator</h1>
          <ul className="home-hasresults-section-desc">
            <li>
              Compare two people and see how each of them perceive each other
              and how best to work together.
            </li>
            <li>
              Select all or part of your team and display a heat map of styles.
              The tool will also provide tips to help improve your group
              dynamics.
            </li>
          </ul>
          <Button
            theme="action"
            onClick={() => {
              updateRedirectTarget(`${INTERFACE_PATHS.TEAMS}`);
            }}
            value="Team Collaborator"
          />
        </div>
      </section>
      <section className="home-hasresults-section">
        <Icon
          size={27}
          icon="training"
          className="home-hasresults-section-icon"
        />
        <div className="home-hasresults-section-learning">
          <h1 className="home-hasresults-section-title">Learning</h1>
          <p className="home-hasresults-section-desc">
            This video library contains helpful videos to better understand how
            to use the R3 tool and how to read and utilize the R3 tool in your
            organization.
          </p>
          <Button
            theme="action"
            onClick={() => {
              updateRedirectTarget(`${INTERFACE_PATHS.LEARNING}`);
            }}
            value="Video Library"
          />
        </div>
      </section>
      {/* {canView && (
        <section className="home-hasresults-section">
          <h2 className="home-hasresults-title title-font">
            Assessment Complete and Ready for Viewing!
          </h2>
          <Link
            className="home-hasresults-profile-link-btn"
            buttonProps={{
              className: "large-btn",
              hoverBGSize: "hidden",
              onClick: _.noop,
              theme: "action",
              value: "View Profile",
            }}
            href={`${INTERFACE_PATHS.PROFILE}/${loggedInUserID}`}
            type="button"
            value="View Profile"
          />
        </section>
      )}
      {!canView && (
        <section className="home-hasresults-section">
          <h2 className="home-hasresults-title title-font">
            Assessment Complete!
          </h2>
          <p className="home-hasresults-text">
            Your report has been generated and someone will reach out shortly to
            go over it with you.
          </p>
        </section>
      )}

      <Video
        className="home-introduction-video"
        poster="R3 Introduction Video"
        src="https://oc.mocaworks.com/v2/domains/1825957/assets/videos/r3v2e2-intro.mp4"
        type="video/mp4"
        controls={true}
      /> */}

      {
        // TODO update plugins to be R3 branded
        /* <section className="home-hasresults-section">
        {!canView && (
          <p className="home-hasresults-text while-you-wait">
            <Icon
              className="home-hasresults-text while-you-wait-icon"
              icon="clock"
              size={18}
            />
            While you wait...
          </p>
        )}
        {canView && (
          <p className="home-hasresults-text while-you-wait">
            <Icon
              className="home-hasresults-text while-you-wait-icon"
              icon="clock"
              size={18}
            />
            If you haven't already...
          </p>
        )}

        <h3 className="home-hasresults-title smaller-title title-font">
          Setup Email Integration
        </h3>

        <p className="home-hasresults-text">
          Access all this valueable communication information right in your
          email.
        </p>
      </section>

      <section className="home-hasresults-section links-section">
        <div className="home-hasresults-video-placeholder">
          <Image src={PLUGIN_ICONS} />
        </div>
        {!props.isMobile && (
          <div className="home-hasresults-plugin-links">
            <Link
              className="home-hasresults-plugin-link"
              type="button"
              buttonProps={{
                className: "home-hasresults-plugin-link-btn",
                onClick: _.noop,
                theme: "action",
                value: "Get Gmail Plugin",
                hoverBGSize: "hidden",
              }}
              value=""
              href={
                {
                  pathname: GMAIL_PLUGIN_URL,
                  search: "",
                } ?? ""
              }
              target="_blank"
            />

            <Link
              className="home-hasresults-plugin-link"
              type="button"
              buttonProps={{
                className: "home-hasresults-plugin-link-btn",
                onClick: _.noop,
                theme: "action",
                value: "Get Outlook Plugin",
                hoverBGSize: "hidden",
              }}
              value=""
              href={
                {
                  pathname: OUTLOOK_ADD_IN_URL,
                  search: "",
                } ?? ""
              }
              target="_blank"
            />
          </div>
        )}
      </section> */
      }
    </article>
  );
};

export default HomeHasResults;
