import * as React from "react";
import * as _ from "lodash";
import cx from "classnames";

import "./found-item.scss";

interface Props {
  importing: boolean;
  foundItem: TytoData.FindAssessments.Found247Assessment;
  onSelect: (foundItem: Props["foundItem"]) => void;
}

const Found247Item = (props: Props) => {
  const [dateString] = React.useState(() => {
    return getDateString(props.foundItem.creationStamp);
  });

  return (
    <li className="disc-import-modal-found-item">
      <button
        className={cx(
          "disc-import-modal-found-item-inner-button",
          !props.foundItem.completed && "incomplete-assessment"
        )}
        disabled={props.importing || !props.foundItem.completed}
        onClick={() => {
          props.onSelect(props.foundItem);
        }}
      >
        <span
          className="disc-import-modal-found-item-personname"
          style={{ flexGrow: 1 }}
        >
          {props.foundItem.userFirstName} {props.foundItem.userLastName}
        </span>

        {!props.foundItem.completed && (
          <div className="disc-import-modal-found-item-stylename">
            Incomplete
          </div>
        )}

        <div>{dateString}</div>
      </button>
    </li>
  );
};

interface Props {}

function getDateString(dateString: string): string {
  const asDate = new Date(dateString);

  if (Number.isNaN(+asDate)) {
    return "";
  }

  return `${asDate.getMonth() + 1}/${asDate.getDate()}/${asDate.getFullYear()}`;
}

export default Found247Item;
