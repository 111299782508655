import * as _ from "lodash";
import { useQuery } from "react-query";

import { REACT_QUERY_KEYS } from "../../../constants/";
import TytoCalls from "../../tyto/";

import { DEFAULT_RQ_OPTS } from "../";

import { DISCExtensionGetParams } from "../../tyto/vendor-247/types";

export function use247DISCExtensionQuery(
  _args: DISCExtensionGetParams & {
    suppliedPathURL: string;
    isEnabled?: boolean;
  }
) {
  const { isEnabled, ...args } = _args;

  const isExpectedPath = isExpectedURL(args.suppliedPathURL);

  const scopeParams = [args.suppliedPathURL ?? ""];

  return {
    ...useQuery(
      [REACT_QUERY_KEYS.VENDOR_247_DISC_EXTENSION, ...scopeParams].join("/"),
      async () => {
        const data = await TytoCalls.Vendor247.Extension.get({
          ...args,
        });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        enabled: isExpectedPath && isEnabled,
      }
    ),
  };
}

function isExpectedURL(rawPathString: string) {
  return /(\/tyto\/)?api\/r3Disc\/Vendor247\/Extension/i.test(rawPathString);
}
