import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { INTERFACE_PATHS } from "data/constants/";
import { iconType } from "components/common/icon/typings";
import { Icon, Link } from "components/common/";
import { usePersonalDISCMini } from "data/network/hooks/";

import "./style.scss";
import { isMobileDevice } from "react-select/dist/declarations/src/utils";
import { StoreContext as GeneralStoreContext } from "data/stores/GeneralStore";

export enum ActiveTab {
  Home,
  Search,
  Assessment,
  Results,
  Profile,
  Team,
  Learning,
  Settings,
}

interface Props {
  activeTab?: keyof typeof ActiveTab;
}

interface FooterButton {
  href: string;
  icon: iconType;
  label: keyof typeof ActiveTab;
}

const FOOTER_BUTTONS: FooterButton[] = [
  {
    href: `${INTERFACE_PATHS.HOME}`,
    icon: "home-filled",
    label: "Home",
  },
  {
    href: `${INTERFACE_PATHS.SEARCH}`,
    icon: "search",
    label: "Search",
  },
  {
    href: `${INTERFACE_PATHS.ASSESSMENT}`,
    icon: "document",
    label: "Assessment",
  },
  {
    href: `${INTERFACE_PATHS.PROFILE}`,
    icon: "document",
    label: "Profile",
  },
  {
    href: `${INTERFACE_PATHS.TEAMS}`,
    icon: "orbit",
    label: "Team",
  },
  {
    href: `${INTERFACE_PATHS.LEARNING}`,
    icon: "training",
    label: "Learning",
  },
  {
    href: `${INTERFACE_PATHS.SETTINGS}`,
    icon: "cog",
    label: "Settings",
  },
];

export const FooterContent = (props: Props) => {
  const { data, storedValueQuery, isFetching } = usePersonalDISCMini({});

  const discMini =
    data?.discProfiles?.[0] ?? storedValueQuery?.data?.discProfiles?.[0];
  let GeneralStore = React.useContext(GeneralStoreContext);
  const isMobile = _.get(GeneralStore, "state.isMobile", false);

  return (
    <ul className="cm-footer-list">
      {FOOTER_BUTTONS.map((footerButton) => {
        if (footerButton.label === "Assessment") {
          if (!!discMini?.styleKey3) {
            return null;
          }
        } else if (footerButton.label === "Profile") {
          if (!discMini?.styleKey3) {
            return null;
          }
        }

        //Making Settings Button NOT show in Desktop(Because it has thumbnail from nav)
        if (footerButton.label === "Settings" && !isMobile) {
          return null;
        }

        return (
          <FooterLink
            key={footerButton.label}
            {...footerButton}
            isActiveTab={footerButton.label === props.activeTab}
          />
        );
      })}
    </ul>
  );
};

interface FooterLinkProps extends FooterButton {
  isActiveTab: boolean;
}

const FooterLink = (props: FooterLinkProps) => {
  return (
    <li className="cm-footer-list-item" title={props.label}>
      <Link
        className={cx("cm-footer-link", props.isActiveTab && "is-active-link")}
        href={props.href}
        type="link"
        value={props.label}
      >
        <Icon size={25} className="cm-footer-link-icon" icon={props.icon} />

        <h3 className="cm-footer-link-text">{props.label}</h3>
      </Link>
    </li>
  );
};

interface FooterWrapperProps extends Props {}

const FooterWrapper = (props: FooterWrapperProps) => {
  return (
    <footer className="cm-footer">
      <FooterContent {...props} />
    </footer>
  );
};

export default FooterWrapper;
