import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { PROFILE_SECTION_IDS } from "data/constants";

import "./style.scss";

interface Props {
  interactions?: TytoData.DISCCompareProfile;
}

const GrowthAreas = (props: Props) => {
  const [personGrowthAreasList, updatePersonGrowthAreasList] = React.useState(
    () => {
      return splitString(props.interactions?.style?.personalGrowthAreas);
    }
  );

  React.useEffect(() => {
    updatePersonGrowthAreasList(
      splitString(props.interactions?.style?.personalGrowthAreas)
    );
  }, [props.interactions]);

  return (
    <section
      className="profile-section"
      data-section={PROFILE_SECTION_IDS.GROWTH_AREAS}
    >
      <h2 className="profile-section-title title-font">Growth Areas</h2>

      <ul className="profile-section-list">
        {personGrowthAreasList.map((growthArea) => (
          <li className="profile-section-list-item" key={growthArea}>
            {growthArea}
          </li>
        ))}
      </ul>
    </section>
  );
};

const DEFAULT_ARR: string[] = [];

function splitString(data?: string) {
  if (!data) {
    return DEFAULT_ARR;
  }

  return data.split("\n") ?? DEFAULT_ARR;
}

export default GrowthAreas;
