import * as React from "react";
import { Redirect } from "react-router-dom";

import { INTERFACE_PATHS } from "data/constants";
import { Message, Button } from "components/common/";
import OwnProfile from "./OwnProfile";

// import { SessionHandling } from "data/storage/";

import "./style.scss";

interface Props {
  canDeleteDISC: boolean;
  canImportDISC: boolean;
  discMiniProfile?: TytoData.DISCProfileMini;
  isLoading: boolean;
  isMobile: boolean;
  subInterface?: ProfilePage.Subinterface;
  userID: number;
}

const OwnProfileWrapper = (props: Props) => {
  //   const [loggedInUserID] = React.useState(() => {
  //     return SessionHandling.getUserIDOfActiveSession() ?? 0;
  //   });

  if (!props.discMiniProfile) {
    return (
      <div className="other-profile-no-access-cont">
        <LockedProfile message={`No DISC Information Found.`} />
        <Button
          className="other-profile-no-access-btn white-ghost"
          theme={"action"}
          value={"Back to Search"}
          onClick={() => (window.location.href = INTERFACE_PATHS.SEARCH)}
        />
      </div>
    );
  } else if (
    !props.discMiniProfile.styleKey3 &&
    !props.discMiniProfile.atsInitialsAdaptive
  ) {
    if (true) {
      return <Redirect to={INTERFACE_PATHS.ASSESSMENT} />;
    }

    // return (
    //   <LockedProfile
    //     message={`${props.discMiniProfile.personName} does not have a completed DISC Profile.`}
    //   />
    // );
  }
  // else if ( //!! Not needed since this component only renders when viewing your own profile
  //   props.discMiniProfile?.permitMatrix?.discStyle?.characteristic1?.HIDE
  // ) {
  //   return (
  //     <LockedProfile
  //       message={`${props.discMiniProfile.personName} DISC Profile is currently unavailable to view.`}
  //     />
  //   );
  // }

  return (
    <div className="">
      <OwnProfile
        canDeleteDISC={props.canDeleteDISC}
        canImportDISC={props.canImportDISC}
        discMiniProfile={props.discMiniProfile}
        isMobile={props.isMobile}
        userID={props.userID}
        subInterface={props.subInterface}
      />
    </div>
  );
};

interface LockedProfileProps {
  message: string;
  className?: string;
}

const LockedProfile = (props: LockedProfileProps) => {
  return (
    <div className={props.className}>
      <Message text={props.message} />
    </div>
  );
};

export default OwnProfileWrapper;
