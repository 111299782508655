/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import PkgJSON from "../../../../package.json";
import { StoreContext } from "../../../data/stores/GeneralStore";
import * as Storage from "../../../data/storage/";
import { SITE_THEMES } from "../../../data/constants/";
// import * as MetaVariables from "../../../data/constants/meta";

import DefaultMenu from "./subcomponents/default/";
import GenericMenuContent from "./GenericMenuContent";

import "./style.scss";

// export interface Props {
//   blockID?: number;
//   className?: string;
//   taskID?: number;
//   menuType?: keyof typeof CMMenu.MenuType;
//   version: string;
// }

const Menu = (props: CMMenu.MenuProps) => {
  let GeneralStore = React.useContext(StoreContext);

  const menuIsShowing = _.get(GeneralStore, "state.showMenu", false);
  const [userID] = React.useState(() => {
    return Storage.SessionHandling.getUserIDOfActiveSession();
  });
  const [versionString] = React.useState(
    `${PkgJSON?.version ?? "0.1.0"}` || `${process.env.BUILD_NUM || ""}`
  );
  const [jenkinsBuildNumber] = React.useState(
    `${process.env.REACT_APP_BUILD_NUM || ""}`
    // `${(MetaVariables as any)?.BUILD_NUM || ""}`
  );

  const hasParentSite = !!GeneralStore.state?.hasParentSite;

  // if (!menuIsShowing) {
  //   return null;
  // }

  const siteTheme: keyof typeof SITE.SiteTheme = _.get(
    GeneralStore,
    "state.siteTheme",
    SITE_THEMES.system
  );

  switch (props.menuType) {
    case "default":
    default:
      return (
        <GenericMenuContent
          GeneralStore={GeneralStore}
          hasParentSite={hasParentSite}
          menuIsShowing={menuIsShowing}
          userID={userID}
          siteTheme={siteTheme}
          buildNumber={jenkinsBuildNumber}
          version={versionString}
          {...props}
        >
          <DefaultMenu hasParentSite={hasParentSite} />
        </GenericMenuContent>
      );
  }

  // return (
  //   <
  // );

  // return (
  //   <aside className={cx("cm-menu", props.className)}>
  //     <section className="cm-menu-inner-cont">
  //       {/* <ul className="cm-menu-list">
  //         <li className="cm-menu-item">Fake Item</li>
  //       </ul> */}
  //       <SubMenuRouter {...props} isMobile={isMobile} userID={userID} />
  //     </section>

  //     {menuIsShowing && isMobile && (
  //       <button
  //         className="cm-menu-black-overlay"
  //         onClick={() => {
  //           if (GeneralStore.dispatch) {
  //             GeneralStore.dispatch({
  //               payload: {},
  //               type: "GENERAL_STORE_TOGGLE_NAVMENU",
  //             });
  //           }
  //         }}
  //       />
  //     )}

  //     <footer className="cm-menu-footer">
  //       <span className="cm-menu-build text-color">
  //         Build: <span className="cm-menu-build-num">{version}</span>
  //       </span>

  //       <TextButton
  //         className="cm-menu-footer-sitetheme-toggle text-color"
  //         onClick={() => {
  //           let newSiteTheme = "dark";

  //           if (siteTheme === "dark") {
  //             newSiteTheme = "light";
  //           }

  //           if (GeneralStore.dispatch) {
  //             GeneralStore.dispatch({
  //               payload: {
  //                 siteTheme: newSiteTheme,
  //               },
  //               type: "GENERAL_STORE_UPDATE_SITE_THEME",
  //             });
  //           }
  //         }}
  //         value={
  //           siteTheme === "dark"
  //             ? "Switch to Light Mode"
  //             : "Switch to Dark Mode (default)"
  //         }
  //       />
  //     </footer>
  //   </aside>
  // );
};

// interface SubMenuRouterProps extends Props {
//   isMobile: boolean;
//   userID: number;
// }

// const SubMenuRouter = ({
//   menuType = "default",
//   ...restOfProps
// }: SubMenuRouterProps) => {
//   switch (menuType) {
//     case "training":
//       return <TrainingMenu {...restOfProps} />;
//     case "default":
//     default:
//       return <DefaultMenu />;
//   }
// };

export default Menu;
