export function replaceYouText(text: string) {
  if (!text) {
    return "";
  }

  return text
    .replaceAll(/yourself/gi, "themself")
    .replaceAll(/your/gi, "their")
    .replace(/they'll/gi, "they'll")
    .replaceAll(/you/gi, "they");
}
