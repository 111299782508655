/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { Icon, Link } from "components/common/";
import { useLessonThumbnailOverrideData } from "data/helpers/Hooks";
import { getEncodingExplicitly, getViewAssetPath } from "data/helpers/";
// import { getFormattedOverridePathURLFromImage } from "data/helpers/Hooks";

import { Props as AssetProps } from "../";

import "./URLWrapper.scss";

export interface URLDisplayProps {
  name?: string;
  display?: "plain" | "href" | "image" | "icon" | "full";
}

interface Props extends AssetProps, URLDisplayProps {
  encodingPathURL: string;
}

const URLWrapper = (props: Props) => {
  const [storedExternalThumbnailEncoding] = React.useState(
    getEncodingExplicitly(props.asset?.encodings ?? [], "ocTHUMBNAIL")
  );
  const [storedExternalThumbnailPathURL] = React.useState(
    getViewAssetPath({
      encodings: storedExternalThumbnailEncoding
        ? [storedExternalThumbnailEncoding]
        : [],
      targetEncoding: "ocTHUMBNAIL",
      includeSessionKey: true,
    })
  );
  // const [storedExternalThumbnailURL] = React.useState(getFormattedOverridePathURLFromImage({ image: storedExternalThumbnail }))
  const { thumbnailOverride, thumbnailOverridePathURL } =
    useLessonThumbnailOverrideData(props.images);

  let display = props.display;

  if (
    display === "full" &&
    !thumbnailOverridePathURL &&
    !storedExternalThumbnailEncoding
  ) {
    display = "href";
  } else if (
    display === "image" &&
    !thumbnailOverridePathURL &&
    !storedExternalThumbnailEncoding
  ) {
    display = "icon";
  }

  switch (display) {
    case "full":
      return (
        <FullDisplayURL
          {...props}
          storedExternalThumbnailEncoding={storedExternalThumbnailEncoding}
          storedExternalThumbnailPathURL={storedExternalThumbnailPathURL}
          thumbnailOverride={thumbnailOverride}
          thumbnailOverridePathURL={thumbnailOverridePathURL}
        />
      );
    case "icon":
      return <Icon className="block-style-link-icon" icon="link" size={64} />;
    case "image":
      return (
        <div className="cc-url-image-style-link">
          <img
            className="cc-url-image-style-link-image"
            src={thumbnailOverridePathURL ?? storedExternalThumbnailPathURL}
          />
        </div>
      );
    case "href":
      return (
        <a
          className="cc-url-block-style-link cc-asset-url"
          href={props.encodingPathURL}
          target="_blank"
          onClick={props.onClick}
        >
          <BlockURLContents {...props} />
        </a>
      );
    case "plain":
    default:
      return (
        <Link
          className={cx("cc-asset cc-asset-url cc-asset-url", props.className)}
          href={props.encodingPathURL}
          onClick={props.onClick}
          target="_blank"
          value={props.asset.assetName}
          style={props.style}
        />
      );
  }
  //   return (
  //     <Link
  //       className={cx("cc-asset cc-asset-url", props.className)}
  //       href={props.encodingPathURL}
  //       target="_blank"
  //       value={props.asset.assetName}
  //       style={props.style}
  //     />
  //   );
};

interface BlockURLProps extends Props {}

const BlockURLContents = (props: BlockURLProps) => {
  return (
    <>
      <Icon className="block-style-link-icon" icon="link" size={34} />

      <div className="cc-asset-url-block-style-link-label  cc-asset-url">
        {props.name ?? props.asset.assetName}
      </div>

      <Icon className="block-style-link-icon" icon="external-link" size={28} />
    </>
  );
};

interface FullDisplayURLProps extends Props {
  storedExternalThumbnailEncoding?: Data.Encoding;
  storedExternalThumbnailPathURL?: string;
  thumbnailOverride?: Data.LessonImage;
  thumbnailOverridePathURL?: string;
}

const FullDisplayURL = (props: FullDisplayURLProps) => {
  const [aspectRatio, updateAspectRatio] = React.useState(() => {
    if (props.thumbnailOverride) {
      return props.thumbnailOverride?.width && props.thumbnailOverride?.height
        ? `${props.thumbnailOverride?.width} / ${props.thumbnailOverride?.height}`
        : undefined;
    }

    return props.storedExternalThumbnailEncoding?.width &&
      props.storedExternalThumbnailEncoding?.height
      ? `${props.storedExternalThumbnailEncoding?.width} / ${props.storedExternalThumbnailEncoding?.height}`
      : undefined;
  });
  //   const ratio =
  //     props.thumbnailOverride?.width && props.thumbnailOverride?.height
  //       ? `${props.thumbnailOverride?.width} / ${props.thumbnailOverride?.height}`
  //       : undefined;

  return (
    <a
      className="cc-url-full-style-link cc-asset-url"
      href={props.encodingPathURL}
      target="_blank"
      onClick={props.onClick}
    >
      <div
        className="cc-url-full-style-link-image-cont"
        style={aspectRatio ? { aspectRatio } : undefined}
      >
        <img
          className="cc-url-full-style-link-image"
          src={
            props.thumbnailOverridePathURL ??
            props.storedExternalThumbnailPathURL
          }
        />

        <div className="cc-url-full-style-link-overlay">
          <Icon
            className="cc-url-full-style-link-overlay-icon"
            icon="link"
            size={58}
          />
        </div>
      </div>
      <div className="cc-url-block-style-link">
        <BlockURLContents {...props} />
      </div>
    </a>
  );
};

export default URLWrapper;
