/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import Icon from "../icon/";

import "./style.scss";

interface Props {
  className?: string;
  disabled?: boolean;
  isChecked?: boolean;
  label: string;
  name?: string;
  htmlFor?: string;
  onChange: (newVal: boolean) => void;
  showLabel?: boolean;
  size?: number;
  native?: boolean;
  theme?: SITE.ComponentTheme;
}

const CheckBox = ({
  className,
  disabled,
  isChecked = false,
  label,
  name,
  native,
  htmlFor,
  onChange,
  size = 12,
  showLabel = false,
  theme,
}: Props) => {
  const [iconSize] = React.useState(size * 0.5);

  if (native) {
    return (
      <>
        <input
          className={cx("cc-checkbox-input", className)}
          disabled={disabled}
          type="checkbox"
          checked={isChecked}
          name={name}
          id={label}
          onChange={(e) => {
            onChange?.(!!(e.target as HTMLInputElement)?.checked);
          }}
        />
        {showLabel && (
          <label className="cc-checkbox-label" htmlFor={label}>
            {label}
          </label>
        )}
      </>
    );
  }

  const sizeInPX = `${size}px`;

  return (
    <button
      className={cx("cc-checkbox-btn", theme, className)}
      disabled={disabled}
      data-checked={isChecked ? "true" : "false"}
      onClick={() => onChange(!isChecked)}
      style={{
        height: sizeInPX,
        width: sizeInPX,
        aspectRatio: "1 / 1",
      }}
      type="button"
    >
      {isChecked && (
        <Icon className="cc-checkbox-btn-icon" size={iconSize} icon="check" />
      )}
    </button>
  );
};

export default CheckBox;
