/*
  * Takes in Audio Asset and renders HTML5 Audio Player
*/
import * as React from "react";
import cx from "classnames";

import { createPseudoRandomString } from "../../../data/helpers/";
import { TrackingStatus } from "../video/helpers";
import { Props as CCVideoProps } from "../video/";

import "./style.scss";


interface Props extends Omit<CCVideoProps, "poster"> {
    // controls?: boolean;
    // src: string;
}

const Audio = (props: Props) => {
    const [id] = React.useState(createPseudoRandomString());
    const [trackingStatus, updateTrackingStatus] =
        React.useState<TrackingStatus>("void");

    // const bookmarkUtils = useBookmarkPoll({
    //     domID: id,
    //     encodingLengthInSeconds: props.encodingLength || 0,
    //     initialBookmark: `${props.initialStartTime}`,
    //     status: trackingStatus,
    //     onUpdate: (newBookMark) => {
    //     if (props.onBookMarkUpdate && newBookMark) {
    //         props.onBookMarkUpdate(newBookMark, props.encodingLength);
    //     }
    //     },
    //     watchForUpdates: !!props.onBookMarkUpdate,
    // });

  return (
    <audio
        className={cx("cc-audio", props.className)}
        crossOrigin="anonymous"
        autoPlay={props.autoPlay}
        playsInline={props.playsInline}
        controls={props.controls}
        controlsList="nodownload"
        id={id}
        loop={props.loop}
        muted={props.muted}
        onEnded={(e: any) => {
            props.onEnded?.(e, id);

            updateTrackingStatus("void");
        }}
        onLoadedData={(e) => {
            if (props.initialStartTime) {
                // * Video Data has loaded, set the 'currentTime' of the video to be the supplied 'initialStartTime', if such is supplied.
                e.currentTarget.currentTime = props.initialStartTime || 0;
            }
        }}
        onPause={(e: any) => {
            props.onPause?.(e, id);

            updateTrackingStatus("pause");
        }}
        onPlay={(e: any) => {
            props.onPlay?.(e, id);

            updateTrackingStatus("play");
        }}
        ref={props.innerRef}
        style={props.style}
        src={props.src}
    >
        Your browser does not support the
        <code>audio</code> element.
    </audio>
  );
}

export default Audio