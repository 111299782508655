import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { ActiveSession } from "data/storage";
import { INTERFACE_PATHS, SUB_INTERFACE_PATHS } from "data/constants";
import { Button, Link, TextButton } from "components/common/";
import { DocumentViewerModal } from "components/modals/";

import DISCGraphs from "../disc-graphs/";

import "./style.scss";

interface Props {
  discProfileMini: TytoData.DISCProfileMini;
  interactions?: TytoData.DISCCompareProfile;
  isMobile: boolean;
}

const AssessmentResults = (props: Props) => {
  const [loggedInUserID] = React.useState(() => {
    return ActiveSession.userID() ?? 0;
  });
  const [showFullDescription, updateShowFullDescription] = React.useState(
    !props.isMobile
  );
  const [showPDFModal, updateShowPDFModal] = React.useState(false);
  const [styleNameHasVowelSound, updateStyleNameHasVowelSound] = React.useState(
    () => {
      return isVowelSound(props.discProfileMini?.styleName3);
    }
  );

  React.useEffect(() => {
    updateStyleNameHasVowelSound(
      isVowelSound(props.discProfileMini?.styleName3)
    );
  }, [props.discProfileMini]);

  const description = props.interactions?.style?.descriptionGeneral ?? "";

  return (
    <div className="centered-content-wrapper">
      <section className="profile-assessment-results profile-subinterface">
        <h2 className="profile-section-title title-font">
          You're a{styleNameHasVowelSound ? "n" : ""}{" "}
          {props.discProfileMini?.styleName3 ?? ""}
        </h2>
        <h3 className="profile-section-subtitle">
          {props.interactions?.style?.styleTraits}
        </h3>

        <DISCGraphs
          discMiniProfile={props.discProfileMini}
          isMobile={props.isMobile}
          userID={loggedInUserID}
        />

        {description && (
          <p className="profile-section-text general-description">
            {showFullDescription
              ? description
              : `${description.slice(0, 150)}...`}
            {!showFullDescription && (
              <div className="general-description-btn-cont">
                <TextButton
                  className="general-description-btn"
                  onClick={() => {
                    updateShowFullDescription(true);
                  }}
                  value="Show More"
                />
              </div>
            )}
          </p>
        )}

        <div className="profile-buttons-cont">
          <Button
            className="profile-btn large-btn"
            onClick={() => {
              updateShowPDFModal(true);
            }}
            hoverBGSize="hidden"
            theme="white-ghost"
            value="View Full Report"
          />

          <Link
            className="profile-link-btn"
            buttonProps={{
              className: "large-btn",
              hoverBGSize: "hidden",
              onClick: _.noop,
              theme: "action",
              value: "Communication Tips",
            }}
            href={`${INTERFACE_PATHS.PROFILE}/${loggedInUserID}/${SUB_INTERFACE_PATHS.PROFILE.COMM_TIPS_OVERVIEW}`}
            type="button"
            value="Communication Tips"
          />
        </div>

        {!!props.discProfileMini.pdfLessonID && (
          <DocumentViewerModal
            isOpen={showPDFModal}
            lessonID={props.discProfileMini.pdfLessonID}
            onDismiss={() => {
              updateShowPDFModal(false);
            }}
          />
        )}
      </section>
    </div>
  );
};

function isVowelSound(styleName?: string) {
  return /^(a|e|i|o|u)/i.test(styleName ?? "");
}

export default AssessmentResults;
