import { ENDPOINT_PEOPLE_KEYS_NEXT_PAGE } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_PEOPLE_KEYS_NEXT_PAGE;

export const PeopleKeysNextPage = {
  get(
    params: Endpoints.Tyto.PeopleKeys.NextPage.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      { ...params },
      callOpts
    ) as Promise<Endpoints.Responses.PeopleKeys.NextPage.Get>;
  },
};
