import { PROFILE_SECTION_IDS } from "data/constants";

import { replaceYouText } from "../../utils-247";

interface Props {
  provideItems: string[];
  firstName: string;
  isOwnProfile: boolean;
  hotSwapYouText?: boolean;
}

const Provide = (props: Props) => {
  return (
    <section
      className="profile-section"
      data-section={PROFILE_SECTION_IDS.PROVIDE}
    >
      <h2 className="profile-section-title title-font">
        {props.isOwnProfile ? "You tend" : <>{props.firstName} tends</>} to be
        most effective in environments that provide:
      </h2>

      <ul className="profile-section-list">
        {props.provideItems.map((provideItem) => (
          <li className="profile-section-list-item" key={provideItem}>
            {props.hotSwapYouText ? replaceYouText(provideItem) : provideItem}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Provide;
