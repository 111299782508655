import * as React from "react";

import { PROFILE_SECTION_IDS } from "data/constants/";

import { DISCValueRender } from "components/disc";

import Characteristic from "./Characteristic";

import "./style.scss";

interface Props {
  discMiniProfile: TytoData.DISCProfileMini;
  interactions?: TytoData.DISCCompareProfile;
  isOwnProfile?: boolean;
}

const Characteristics = (props: Props) => {
  return (
    <DISCValueRender
      discMiniProfile={props.discMiniProfile}
      isCompareValue={false}
      targetKey="characteristic1"
      className="profile-graph-valueRender"
    >
      <section
        className="profile-section profile-characteristics-section"
        data-section={PROFILE_SECTION_IDS.CHARACTERISTICS}
      >
        <h4 className="profile-section-title title-font">
          3 Main things to know about{" "}
          {props.discMiniProfile?.styleName3 ??
            (props.isOwnProfile ? "yourself" : "them")}
          {props.discMiniProfile?.styleName3 ? "s" : ""}
          {props.interactions?.style?.styleHeadline ? " - " : ""}
          {props.interactions?.style?.styleHeadline ?? ""}
        </h4>
        <p></p>

        {props.interactions?.style ? (
          <>
            <Characteristic
              title={props.interactions.style.characteristic1}
              desc={props.interactions.style.characteristic1details}
            />
            <Characteristic
              title={props.interactions.style.characteristic2}
              desc={props.interactions.style.characteristic2details}
            />
            <Characteristic
              title={props.interactions.style.characteristic3}
              desc={props.interactions.style.characteristic3details}
            />
          </>
        ) : (
          <p></p>
        )}
      </section>
    </DISCValueRender>
  );
};

export default Characteristics;
