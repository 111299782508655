import { ENDPOINT_DISC_PROFILES } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_DISC_PROFILES;

export const DISCProfiles = {
  delete(
    params: Endpoints.Tyto.DISCProfiles.DeleteParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("delete", endpoint, params || {}, {
      ...(callOpts || {}),
      paramsAsData: true,
    }) as Promise<{}>;
  },
  get(params: Endpoints.Tyto.DISCProfiles.GetParameters, callOpts?: CallOpts) {
    return callWrapper("get", endpoint, params || {}, callOpts) as Promise<{
      discProfiles: any[];
      session: Data.SessionData;
    }>;
  },
};
