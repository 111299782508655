import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import "./GroupStyleKey.scss";

interface Props {
  styleKey?: keyof typeof TytoData.DISCStyleKey;
}

const GroupStyleKey = (props: Props) => {
  const [styleKeyAsArray, updateStyleKeyAsArray] = React.useState(() => {
    return splitKey(props.styleKey);
  });

  React.useEffect(() => {
    updateStyleKeyAsArray(splitKey(props.styleKey));
  }, [props.styleKey]);

  if (!styleKeyAsArray?.length) {
    return null;
  }
  return (
    <>
      {styleKeyAsArray.map((discLetter) => (
        <span
          className={cx(
            "group-style-letter",
            discLetter && `disc-letter-${discLetter}`
          )}
        >
          {discLetter.toString() === "c" ? "a" : discLetter}
        </span>
      ))}
    </>
  );
};

const DEFAULT_STYLE_KEY_ARR: Array<TytoData.DISCLetter> = [];

function splitKey(styleKey: Props["styleKey"]) {
  if (!styleKey) {
    return DEFAULT_STYLE_KEY_ARR;
  }

  const arr = styleKey.split("").filter((token) => !!token) as any[];

  return arr as Array<TytoData.DISCLetter>;
}

export default GroupStyleKey;
