import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import "./style.scss";

interface Props {
  groupProfile: TytoData.DISCTeamProfile;
}

const ReadingHeatMap = (props: Props) => {
  return (
    <section className="group-section group-decision-making">
      <h4 className="group-section-title title-font">
        Reading the Team Heat Map
      </h4>

      <p className="group-section-text">
        Your R3 profile is based on four core styles: Driver, Influencer,
        Stabilizer and Analyzer. The values of each of these core styles is
        important in determining the results of your profile. When looking at
        the Team Graph, each member of your team is represented by a dot. The
        dot will always be placed in your top scoring core style. Based on the
        scores in all four quadrants, the dot will be placed closer to the outer
        or inner corners of each quadrant. The further your dot is from the
        center of the quadrants, the more intense your core style is. The color
        of the Team Graph is based on two things: an individual teammate’s
        intensity in a core style and the intensity of the team as a whole in a
        specific core style. Cool colors indicate a lower team or individual
        intensities, while warm colors indicate higher team or individual
        intensities.
      </p>
    </section>
  );
};

export default ReadingHeatMap;
