import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import {
  GMAIL_PLUGIN_URL,
  OUTLOOK_ADD_IN_URL,
  PROFILE_SECTION_IDS,
  REACT_QUERY_KEYS,
} from "data/constants/";
import { Asset, Link, Icon } from "components/common/";
import { useAssetEncoding, useLesson } from "data/network/hooks/";
import { DocumentViewerModal } from "components/modals/";
import { getViewAssetPath } from "data/helpers";
import { queryClient } from "data/network";

import "./style.scss";

interface Props {
  discProfileMini: TytoData.DISCProfileMini;
  isMobile: boolean;
  title?: string;
}

const Downloads = (props: Props) => {
  const [showPDF, updateShowPDF] = React.useState(false);

  const { eagerData, isLoading } = useLesson({
    lessonID: props.discProfileMini?.pdfLessonID ?? 0,
    isEnabled: !!props.discProfileMini?.pdfLessonID,
  });

  const { isLoading: isCreatingEncodings, data: creatingEncodingsData } =
    useAssetEncoding({
      isEnabled: Boolean(
        !isLoading &&
          !!eagerData?.lesson &&
          !!eagerData.lesson.assets?.length &&
          (eagerData.lesson.assets?.[0]?.encodings?.length ?? 0) < 2
      ),
      assetID: eagerData?.lesson?.assets?.[0]?.assetID ?? 0,
      onError: () => {
        // TODO?
      },
      onSuccess: (_data) => {
        const pdfLessonID = props.discProfileMini.pdfLessonID ?? 0;

        queryClient.invalidateQueries([REACT_QUERY_KEYS.LESSON, pdfLessonID]);
      },
    });

  const lessonAsset = eagerData?.lesson?.assets?.[0];
  const safeAsset = creatingEncodingsData?.job?.asset ?? lessonAsset;

  return (
    <section
      className="profile-section profile-section-downloads"
      data-section={PROFILE_SECTION_IDS.DOWNLOADS}
    >
      <h2 className="profile-section-title title-font">
        {props.title ?? "Download"}
      </h2>

      <div className="profile-downloads-pdf">
        {safeAsset && (
          <>
            {isCreatingEncodings ? (
              <span>Loading...</span>
            ) : (
              <button
                className="profile-downloads-pdf-btn"
                onClick={() => {
                  updateShowPDF(true);
                }}
              >
                <Asset
                  className="profile-downloads-pdf-thumbnail"
                  asset={safeAsset}
                />
              </button>
            )}
          </>
        )}
        {safeAsset?.encodings && (
          <a
            title="Download PDF"
            className="profile-downloads-pdf-download"
            target="_blank"
            href={getViewAssetPath({
              encodings: safeAsset.encodings,
              mimeTypeScope: "pdf",
              includeSessionKey: true,
            })}
            download={eagerData?.lesson.lessonName}
          >
            <Icon
              size={42}
              icon="download"
              className="profile-downloads-pdf-download-icon"
            />
          </a>
        )}
      </div>
      {
        //TODO rebrand R3 Plugins
        /* {!props.isMobile && (
        <div className="profile-downloads-plugin-links">
          <Link
            className="profile-downloads-plugin-link"
            type="button"
            buttonProps={{
              className: "profile-downloads-plugin-link-btn",
              onClick: _.noop,
              theme: "action",
              value: "Get Gmail Plugin",
              hoverBGSize: "hidden",
            }}
            value=""
            href={
              {
                pathname: GMAIL_PLUGIN_URL,
                search: "",
              } ?? ""
            }
            target="_blank"
          />

          <Link
            className="profile-downloads-plugin-link"
            type="button"
            buttonProps={{
              className: "profile-downloads-plugin-link-btn",
              onClick: _.noop,
              theme: "action",
              value: "Get Outlook Plugin",
              hoverBGSize: "hidden",
            }}
            value=""
            href={
              {
                pathname: OUTLOOK_ADD_IN_URL,
                search: "",
              } ?? ""
            }
            target="_blank"
          />
        </div>
      )} */
      }

      <DocumentViewerModal
        lessonID={props.discProfileMini?.pdfLessonID}
        isOpen={showPDF}
        onDismiss={() => {
          updateShowPDF(false);
        }}
      />
    </section>
  );
};

export default Downloads;
