import { ENDPOINT_R3_DISC_VENDOR_247_FIND_USERS } from "../../../constants/";
import { callWrapper, CallOpts } from "../utils";

import { FindUsersGetParams, FindUsersGetResponse } from "./types";

const endpoint = ENDPOINT_R3_DISC_VENDOR_247_FIND_USERS;

export const Vendor247FindUsers = {
  get(params: FindUsersGetParams, callOpts?: CallOpts) {
    return callWrapper(
      "get",
      endpoint,
      params,
      callOpts
    ) as Promise<FindUsersGetParams>;
  },
};
