import * as _ from "lodash";

import { LOC_STOR, SES_STOR } from "./browser-storage";
import { IS_LOCAL_HOST } from "data/constants/";
import { keys } from "./constants";

let ACTIVE_SESSION_KEY: string | undefined;

const MEMOIZED_SESSIONS: {
  [sessionKey: string]: Data.SessionData | null;
} = {};

// * Pull what should be an Array of Session Data Objects from localStorage
export function getAllSessions(): Data.SessionData[] {
  const storedSessionsRaw = LOC_STOR.get(keys.STORED_SESSIONS);

  if (!storedSessionsRaw) {
    return [];
  }

  if (typeof storedSessionsRaw !== "string") {
    if (Array.isArray(storedSessionsRaw)) {
      return storedSessionsRaw;
    } else {
      // TODO
    }
  }

  try {
    const parsedData = JSON.parse(storedSessionsRaw);

    if (!Array.isArray(parsedData)) {
      return [];
    }

    return parsedData;
  } catch (err) {
    console.log("Error parsing sessions data from storage");
    return [];
  }
}

// * Retrieve and return the session with matching key from list stored in localStorage
export function getSessionData(sessionKey: string) {
  if (!sessionKey) {
    return;
  }

  const allSessions = getAllSessions();

  if (!allSessions || !allSessions.length) {
    return undefined;
  }

  return allSessions.find(
    (sessionData) => sessionData.sessionKey === sessionKey
  );
}

// * Remove a specific Session Object from the list stored in localStorage
export function removeSessionData(sessionKey: string) {
  if (!sessionKey) {
    return;
  }

  if (MEMOIZED_SESSIONS[sessionKey]) {
    MEMOIZED_SESSIONS[sessionKey] = null;
  }

  const allSessions = getAllSessions();

  if (!allSessions || !allSessions.length) {
    return undefined;
  }

  const newSessionsList = allSessions.filter(
    (sessionData) => sessionData.sessionKey !== sessionKey
  );
  const asString = JSON.stringify(newSessionsList);

  try {
    LOC_STOR.set(keys.STORED_SESSIONS, asString);
  } catch (err) {
    console.log("Error: ", err);
  }
}

// * Store a Session Object in the list of Sessions in localStorage
// * Returns Boolean of whether it was successful or not
export function storeSessionData(session: Data.SessionData) {
  if (!session) {
    return false;
  }

  const allCurrentSessions = getAllSessions();

  // TODO Handle storing a new session that is a duplicate (same userID)
  const matchesExistingStoredSession = (allCurrentSessions || []).some(
    (storedSession) => storedSession.sessionKey === session.sessionKey
  );

  if (matchesExistingStoredSession) {
    return true;
  }

  // * Redundant ensure same session isn't stored twice, and ensure multiples sessions for same userID are not stored
  const newSessionsListAsString = JSON.stringify(
    _.uniqBy(
      _.uniqBy([session, ...(allCurrentSessions || [])], "sessionKey"),
      "userID"
    )
  );

  try {
    LOC_STOR.set(keys.STORED_SESSIONS, newSessionsListAsString);

    return true;
  } catch (err) {
    console.log("Error: ", err);

    return false;
  }
}

// * Pull activeSessionKey from sessionStorage
export function getActiveSessionKey() {
  if (ACTIVE_SESSION_KEY) {
    return ACTIVE_SESSION_KEY;
  }

  try {
    const activeSessKey = SES_STOR.get(keys.ACTIVE_SESSION_KEY);

    if (activeSessKey) {
      ACTIVE_SESSION_KEY = activeSessKey;
    }

    return activeSessKey;
  } catch (err) {
    console.log("Error: ", err);
  }
}

// * Attempt to find and return session in localStorage matching hypothetical key in sessionStorage
export function getActiveSession() {
  const activeSessionKey = getActiveSessionKey();

  if (!activeSessionKey) {
    if (IS_LOCAL_HOST) {
      console.log("No active sessionKey found");
    }
    return undefined;
  }

  if (MEMOIZED_SESSIONS[activeSessionKey]) {
    return MEMOIZED_SESSIONS[activeSessionKey];
  }

  const allCurrentSessions = getAllSessions();

  const activeSessionData = (allCurrentSessions || []).find(
    (session) => session.sessionKey === activeSessionKey
  );

  if (IS_LOCAL_HOST) {
    console.log("SessionData: ", activeSessionData);
  }

  if (activeSessionData) {
    MEMOIZED_SESSIONS[activeSessionKey] = activeSessionData;
  }

  return activeSessionData;
}

export function getPropertyFromActiveSession<K extends keyof Data.SessionData>(key: K) {
  const curActiveSession = getActiveSession();

  return curActiveSession?.[key];
}

export function getUserIDOfActiveSession() {
  const activeSessionData = getActiveSession();

  return _.get(activeSessionData, "userID", 0);
}

export const ActiveSession = {
  userID: () => {
    return getPropertyFromActiveSession("userID");
  },
  domainID: () => {
    return getPropertyFromActiveSession("domainID");
  },
  teamRootID: () => {
    return getPropertyFromActiveSession("teamRootID");
  },
  sessionKey: () => {
    return getPropertyFromActiveSession("sessionKey");
  },
  profileThumbPath: () => {
    return getPropertyFromActiveSession("profileThumbPath");
  },
  teamListSyncDate: () => {
    return getPropertyFromActiveSession("teamListSyncDate");
  },
  adminID: () => {
    return getPropertyFromActiveSession("adminID");
  },
  isImpersonationSession: () => {
    return !!getPropertyFromActiveSession("adminID");
  },
  changePassword: () => {
    return getPropertyFromActiveSession("changePassword");
  },
  userName: () => {
    const userName = getPropertyFromActiveSession("userName");
    const [firstName = "", lastName = ""] = userName?.split(" ") ?? [];

    return {
      firstName,
      lastName,
      fullName: userName ?? ""
    };
  },
  koPermissionSyncDate: () => {
    return getPropertyFromActiveSession("koPermissionSyncDate");
  },
  termsOfServiceSignatureRequired: () => {
    return getPropertyFromActiveSession("termsOfServiceSignatureRequired");
  },
  timeOutMnts: () => {
    return getPropertyFromActiveSession("timeOutMnts");
  },
  getSessionData: () => {
    return getActiveSession();
  },
};

// * Make sure a Session is still found in localStorage and if so, set the key in sessionStorage
// * Returns Boolean of whether it was successful or not
export function clearActiveSession() {
  const currentActiveSessionKey = getActiveSessionKey();

  if (!currentActiveSessionKey) {
    return false;
  }

  if (MEMOIZED_SESSIONS[currentActiveSessionKey]) {
    MEMOIZED_SESSIONS[currentActiveSessionKey] = null;
  }

  if (ACTIVE_SESSION_KEY) {
    ACTIVE_SESSION_KEY = undefined;
  }

  const allStoredSessions = getAllSessions();

  if (!allStoredSessions || !allStoredSessions.length) {
    return true;
  }

  const sessionsFiltered = (allStoredSessions || []).filter(
    (session) => session.sessionKey !== currentActiveSessionKey
  );
  const storedSessionsAsString = JSON.stringify(sessionsFiltered);

  const updatedSuccessfully = LOC_STOR.set(
    keys.STORED_SESSIONS,
    storedSessionsAsString
  );

  if (updatedSuccessfully) {
    // * method returns 'true' always
    return removeActiveSessionKey();
  }

  return false;
}

export function getSafeToExposeSessionKey(length?: number) {
  // * Where 8 is the arbitrary default length in slices off the start
  const len = length ?? 8;

  const sessionKey = getActiveSessionKey();

  return sessionKey ? `${sessionKey}`.slice(0, len) : undefined;
}

// * Make sure a Session is still found in localStorage and if so, set the key in sessionStorage
// * Returns Boolean of whether it was successful or not
export function removeActiveSessionKey() {
  SES_STOR.remove(keys.ACTIVE_SESSION_KEY);

  if (ACTIVE_SESSION_KEY) {
    ACTIVE_SESSION_KEY = undefined;
  }

  return true;
}

// * Make sure a Session is still found in localStorage and if so, set the key in sessionStorage
// * Returns Boolean of whether it was successful or not
export function setActiveSession(sessionKey: string) {
  const allCurrentSessions = getAllSessions();

  const isFoundInList = (allCurrentSessions || []).some(
    (session) => session.sessionKey === sessionKey
  );

  if (isFoundInList) {
    SES_STOR.set(keys.ACTIVE_SESSION_KEY, sessionKey);

    ACTIVE_SESSION_KEY = sessionKey;

    return true;
  } else {
    return false;
  }
}
