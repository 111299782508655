import { ENDPOINT_PEOPLE_KEYS_V4_IMPORT } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_PEOPLE_KEYS_V4_IMPORT;

export const PeopleKeysV4Import = {
  get(
    params: Endpoints.Tyto.PeopleKeys.V4.Import.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      { ...params },
      callOpts
    ) as Promise<Endpoints.Responses.PeopleKeys.V4.Import.Get>;
  },
};
