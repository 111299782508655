import { PROFILE_SECTION_IDS } from "data/constants";

import { replaceYouText } from "../../utils-247";

interface Props {
  needs: string[];
  firstName: string;
  isOwnProfile: boolean;
  hotSwapYouText?: boolean;
}

const Needs = (props: Props) => {
  return (
    <section
      className="profile-section"
      data-section={PROFILE_SECTION_IDS.NEEDS}
    >
      <h2 className="profile-section-title title-font">
        People with {props.isOwnProfile ? "your" : <>{props.firstName}’s</>}{" "}
        communication style tend to need:
      </h2>

      <ul className="profile-section-list">
        {props.needs.map((need) => (
          <li className="profile-section-list-item" key={need}>
            {props.hotSwapYouText ? replaceYouText(need) : need}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Needs;
