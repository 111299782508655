/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import PkgJSON from "../../../../package.json";
import * as Storage from "data/storage";
import {
  Icon,
  Image,
  Input,
  Link,
  MenuButton,
  UserThumb,
  UserThumbLoading,
} from "components/common/";
import { Props as LinkProps } from "components/common/link/";
import {
  getPublicImagePath,
  logoutUser,
  makeRelPathAbsolute,
} from "data/helpers/";
import { useHomeLinkSrc } from "data/network/hooks/";
import {
  DEFAULT_APP_BRAND,
  R3_LOGO,
  INTERFACE_PATHS,
  IS_LOCAL_HOST,
} from "data/constants/";
import {
  useNormalizedTrackEvent,
  useNormalizedTrackMetric,
} from "data/tracking/hooks";
import { FooterContent } from "components/meta/";
import { ActiveTab } from "components/meta/footer/";
// import { Haptics } from "data/utils/";

import { StoreContext as AppStoreContext } from "data/stores/AppStore";
import { StoreContext as GeneralStoreContext } from "data/stores/GeneralStore";

import NotificationsBell from "./subcomponents/NotificationsBell";
// import ActiveQueries from "./subcomponents/ActiveQueries";

import "./style.scss";

function getQualifiedThumbnailPathURL() {
  const thumbnailPathURL =
    Storage.SessionHandling.getActiveSession()?.profileThumbPath;

  if (!thumbnailPathURL) {
    if (IS_LOCAL_HOST) {
      console.log(
        "%cNo thumbnailPathURL: ",
        Storage.SessionHandling.getActiveSession()
      );
    }
    return undefined;
  }

  return makeRelPathAbsolute(thumbnailPathURL, true);
}

interface Props {
  activeTab?: keyof typeof ActiveTab;
  className?: string;
  navClassName?: string;
  centerButtons?: LinkProps[];
  showToggleBtnWhenInsideParent?: boolean;
  onSearchClick?: () => void;
  searchInNav?: boolean;
  searchTerm?: string;
  onSearchTermChange?: (newVal: string) => void;
  closeSearch?: () => void;
}

function findProfileImageAsset(profile?: TytoData.Person) {
  if (
    !profile ||
    !profile.profileImageID ||
    !profile.profileImageAssets.length
  ) {
    return undefined;
  }

  return profile.profileImageAssets.find(
    (asset) => asset.assetID === profile.profileImageID
  );
}

export const Nav = (props: Props) => {
  let AppStore = React.useContext(AppStoreContext);
  let GeneralStore = React.useContext(GeneralStoreContext);

  const [sessionThumbnailPathURL] = React.useState(() => {
    return getQualifiedThumbnailPathURL();
  });
  const [activeSession] = React.useState(() => {
    return Storage.SessionHandling.getActiveSession();
  });

  // const [profileImageAsset, updateProfileImageAsset] = React.useState(
  //   findProfileImageAsset(_.get(AppStore, "state.personInfo"))
  // );
  const [loggingOut, updateLoggingOut] = React.useState(false);
  const [errorMsg, updateErrorMsg] = React.useState("");
  const [menuButtonIsShowing, updateMenuButtonIsShowing] = React.useState(
    !!GeneralStore?.state?.showMenu
  );

  const AppInsights = useAppInsightsContext();
  // const { data: homeLinkData } = useHomeLinkSrc();

  const trackMenuToggle = useNormalizedTrackEvent(
    AppInsights,
    "Menu Button Toggled",
    menuButtonIsShowing,
    true
  );
  const trackUserThumbClick = useNormalizedTrackMetric(
    AppInsights,
    "Nav User Thumb Clicked"
  );
  const trackHomeImageLinkClick = useNormalizedTrackMetric(
    AppInsights,
    "Nav Home Image Clicked"
  );

  // * If profile data changes, redetermine image asset
  // React.useEffect(() => {
  //   updateProfileImageAsset(
  //     findProfileImageAsset(_.get(AppStore, "state.personInfo"))
  //   );
  // }, [_.get(AppStore, "state.personInfo")]);

  React.useEffect(() => {
    const openValue = !!GeneralStore?.state?.showMenu ? "open" : "closed";

    updateMenuButtonIsShowing(!!GeneralStore?.state?.showMenu);

    trackMenuToggle({
      isNow: openValue,
    } as Tracking.EventPaylods.MenuToggle);
  }, [GeneralStore?.state?.showMenu]);

  const personInfo = _.get(AppStore, "state.personInfo") as
    | TytoData.Person
    | undefined;

  const isMobile = _.get(GeneralStore, "state.isMobile", false);
  const hasParentSite = !!GeneralStore.state?.hasParentSite;
  const searching = props.searchInNav && props.searchTerm !== undefined;

  const showContent = !hasParentSite || true;

  return (
    <nav className={cx("cm-nav", props.navClassName)}>
      <section className="cm-nav-left">
        {/* <Icon
          icon={
            hasParentSite
              ? (`angle-${GeneralStore.state?.showMenu ? "left" : "right"}` as
                  | "angle-left"
                  | "angle-right")
              : `${GeneralStore.state?.showMenu ? "bars" : "bars"}`
          }
          buttonProps={{
            className: "cm-nav-toggle-btn",
            name: "Menu Toggle Button",
          }}
          isButton={true}
          onClick={() => {
            if (GeneralStore.dispatch) {
              GeneralStore.dispatch({
                payload: {},
                type: "GENERAL_STORE_TOGGLE_NAVMENU",
              });
            }
          }}
          size={isMobile ? 20 : 25}
        /> */}

        {showContent && (
          <Link
            className={cx(
              "cm-nav-company-logo-anchor-cont",
              searching && "searching"
            )}
            href={INTERFACE_PATHS.HOME}
            value="Domain Logo Home Link"
            ariaTitle="Home Page Link"
            onClick={trackHomeImageLinkClick}
          >
            <Image
              alt="Tryyb Logo"
              className="cm-nav-tryyb-logo"
              src={getPublicImagePath(R3_LOGO)}
            />
            {/* {homeLinkData.homeLinkSrc ? (
                <Image
                  alt="Domain Logo"
                  className="cm-nav-company-logo"
                  src={homeLinkData.homeLinkSrc}
                />
              ) : (
                <Image
                  alt="Tryyb Logo"
                  className="cm-nav-tryyb-logo"
                  src={getPublicImagePath(PPF_LOGO)}
                />
              )} */}
          </Link>
        )}
      </section>

      {showContent && (
        <section className="cm-nav-middle">
          {props.centerButtons &&
            !!props.centerButtons.length &&
            props.centerButtons.map(
              ({ className: btnClassName, label, value, ...rest }) => (
                <Link
                  key={label || value}
                  className={cx("cm-nav-center-btn", btnClassName)}
                  label={label}
                  value={value}
                  {...rest}
                />
              )
            )}

          {errorMsg && <p>{errorMsg}</p>}
        </section>
      )}

      {showContent && (
        <section className="cm-nav-right">
          <section
            className={cx(
              "cm-nav-search-cont",
              props.searchInNav && "searching"
            )}
          >
            <Icon
              className={cx(
                "cm-nav-search",
                !props.onSearchClick && !props.searchInNav && "hide-search-icon"
              )}
              size={18}
              isButton={true}
              buttonProps={{
                disabled: !props.onSearchClick,
                name: "Search Courses",
              }}
              onClick={() => {
                props.onSearchClick?.();

                // Haptics.startVibration("INTERACTION");
              }}
              icon="search"
            />

            {props.searchInNav && props.searchTerm !== undefined && (
              <>
                <Input
                  autoFocus={true}
                  className="cm-nav-search-input"
                  name="Search Catalog"
                  onChange={props.onSearchTermChange || _.noop}
                  type="input"
                  value={props.searchTerm}
                />

                <Icon
                  className="cm-nav-search-input-close-btn"
                  buttonProps={{
                    name: "Close Search Input",
                  }}
                  isButton={true}
                  onClick={props.closeSearch}
                  size={16}
                  icon="close"
                />
              </>
            )}
          </section>
          {/* <NotificationsBell isMobile={isMobile} /> */}
          <FooterContent activeTab={props.activeTab} />
          {
            //* TOP MENU "SETTINGS LINK in Desktop"
            // If Iframe then remove thumb and place settings cog
          }
          {!hasParentSite ? (
            <MenuButton
              className="cm-nav-menubtn"
              // omitButtonBGColor={true}
              menuItemClassName="cm-nav-menubtn-menuitem"
              menuListClassName="cm-nav-menubtn-menulist"
              menuItems={[
                {
                  as: "link",
                  key: "Settings",
                  label: "Settings",
                  href: `${INTERFACE_PATHS.SETTINGS}`,
                },
                {
                  key: "Logout",
                  label: loggingOut ? "..." : "Logout",
                  disabled: loggingOut,
                  onSelect: () => {
                    const confirmed = window.confirm(
                      `Logout of ${DEFAULT_APP_BRAND ?? "App"}?`
                    );

                    if (confirmed) {
                      updateLoggingOut(true);
                      updateErrorMsg("");

                      logoutUser({
                        AppStore,
                        onSuccess: () => {},
                        onError: (errorMsg) => {
                          updateErrorMsg(errorMsg);
                          updateLoggingOut(false);
                        },
                      });
                    }
                  },
                },
                {
                  key: "Details",
                  label: `Version (${PkgJSON.version ?? "N/A"})`,
                  onSelect: _.noop,
                },
              ]}
            >
              <UserThumb
                altImageAssetID={_.get(personInfo, "profileImageID")}
                // asset={profileImageAsset}
                onClick={trackUserThumbClick}
                userName={`${activeSession?.userName}`}
                // userName={`${personInfo.givenName} ${personInfo.familyName}`}
                size={isMobile ? 34 : 42}
                userThumbPathURL={sessionThumbnailPathURL}
                withBorder={true}
              />
            </MenuButton>
          ) : (
            <>
              <ul>
                <li className="cm-footer-list-item" title="Settings">
                  <Link
                    className={cx(
                      "cm-footer-link",
                      props.activeTab === "Settings" && "is-active-link"
                    )}
                    href={`${INTERFACE_PATHS.SETTINGS}`}
                    type="link"
                    value="Settings"
                  >
                    <Icon
                      size={25}
                      className="cm-footer-link-icon"
                      icon="cog"
                    />

                    <h3 className="cm-footer-link-text">Settings</h3>
                  </Link>
                </li>
              </ul>
            </>
          )}
        </section>
      )}
    </nav>
  );
};

const NavWrapper = (props: Props) => {
  let GeneralStore = React.useContext(GeneralStoreContext);

  const hasParentSite = !!GeneralStore.state?.hasParentSite;

  return (
    <div
      className={cx(
        "cm-nav-cont",
        props.className,
        hasParentSite && "has-parent-site"
      )}
    >
      <Nav {...props} />
    </div>
  );
};

export default NavWrapper;
