/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import {
  useFindAssessmentsV2,
  useFindAssessmentsV4,
  usePeopleKeysV4ImportMutation,
} from "data/network/hooks/";
import { ActiveSession } from "data/storage";
import { Input, Message, Select, TextButton } from "components/common/";

import FoundAssessment from "./subcomponents/found-item";

type ActiveVersion = "v2" | "v4";

const VERSION_OPTS: {
  label: string;
  value: ActiveVersion;
}[] = [
  {
    label: "Legacy Version (V2)",
    value: "v2",
  },
  {
    label: "Current Version (V4)",
    value: "v4",
  },
];

interface Props {
  emailAddress?: string;
  domainID?: number;
  userID: number;
  onSelect?: () => void;
}

const DISCImport = (props: Props) => {
  const [emailAddress, updateEmailAddress] = React.useState(
    props.emailAddress ?? ""
  );
  const [domainID, updateDomainID] = React.useState(() => {
    return props.domainID ?? ActiveSession.domainID() ?? 0;
  });
  const [displayedVersion, updateDisplayedVersion] =
    React.useState<ActiveVersion>("v4");
  const [importing, updateImporting] = React.useState(false);
  const [errorMsg, updateErrorMsg] = React.useState("");

  const faV2Query = useFindAssessmentsV2({
    emailAddress,
    domainID,
    isEnabled: displayedVersion === "v2",
  });

  const faV4Query = useFindAssessmentsV4({
    alternateEmailAddress: emailAddress,
    userID: props.userID ?? 0,
    isIncomplete: false,
    isEnabled: displayedVersion === "v4",
  });

  const v4ImportDISCMutation = usePeopleKeysV4ImportMutation({
    onError: (newErrorMsg) => {
      updateErrorMsg(newErrorMsg);
      updateImporting(false);
    },
    onSuccess: () => {
      updateImporting(false);
      props.onSelect?.();
    },
  });

  // React.useEffect(() => {
  //   // TODO
  //   LoadAssessments({
  //     emailAddress,
  //     domainID,
  //     userID: props.userID ?? 0,
  //     onError: newErrorMsg => {
  //       updateErrorMsg(`${newErrorMsg}`);
  //       updateIsLoading(false);
  //     },
  //     onSuccess(v4Resp, v2Resp) {

  //     },
  //   })
  // }, [props.userID]);

  return (
    <main className="disc-import-modal-main-cont">
      <h2 className="disc-import-modal-title title-font">DISC Import</h2>

      <section className="disc-import-modal-inputs-cont">
        <Input
          className="disc-import-modal-input"
          disabled={importing}
          name="Email Address"
          onChange={(newVal) => {
            updateEmailAddress(newVal);
          }}
          placeholder="Email Address"
          type="input"
          value={emailAddress}
        />

        {/* <Select
          className="disc-import-modal-version-select"
          options={VERSION_OPTS}
          onChange={(newVal) => {
            updateDisplayedVersion(newVal as ActiveVersion);
          }}
          value={displayedVersion}
        /> */}
      </section>

      {errorMsg && (
        <div>
          <p>{errorMsg}</p>
        </div>
      )}

      <ul
        className={cx(
          "disc-import-modal-found-items",
          importing && "importing-item"
        )}
      >
        {importing && (
          <div className="disc-import-modal-found-items-importing-msg-cont">
            <p className="disc-import-modal-found-items-importing-msg">
              Importing...
            </p>
          </div>
        )}

        {displayedVersion === "v2" ? (
          <>
            {faV2Query.isLoading ? (
              <Message className="disc-import-modal-msg" text="Loading..." />
            ) : (
              <>
                {!!faV2Query.eagerData?.peopleKeysResponse?.assessments
                  ?.length ? (
                  faV2Query.eagerData?.peopleKeysResponse?.assessments?.map?.(
                    (data, curIdx) => <li key={`${curIdx}`}>{data}</li>
                  )
                ) : (
                  <Message
                    className="disc-import-modal-msg"
                    text={faV2Query.error ? "Error" : "No Results Found"}
                  />
                )}
              </>
            )}
          </>
        ) : null}

        {displayedVersion === "v4" ? (
          <>
            {faV4Query.isLoading ? (
              <Message className="disc-import-modal-msg" text="Loading..." />
            ) : (
              <>
                {!!faV4Query.eagerData?.found ? (
                  faV4Query.eagerData?.found?.map?.((data, curIdx) => (
                    <FoundAssessment
                      key={data.assessmentExternalId}
                      foundItem={data}
                      importing={importing}
                      onSelect={(foundItem) => {
                        updateErrorMsg("");
                        updateImporting(true);

                        v4ImportDISCMutation.mutate({
                          foundItem,
                          userID: props.userID,
                        });
                      }}
                    />
                  ))
                ) : (
                  <>
                    <Message
                      className="disc-import-modal-msg"
                      text={faV4Query.error ? "Error" : "No Results Found"}
                    />
                    {faV4Query.error && !faV4Query.isFetching && (
                      <li className="disc-import-modal-msg-retry-btn-cont">
                        <TextButton
                          className="disc-import-modal-msg-retry-btn"
                          onClick={() => {
                            faV4Query.refetch();
                          }}
                          value="Retry"
                        />
                      </li>
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : null}
      </ul>
    </main>
  );
};

// async function LoadAssessments({
//   emailAddress,
//   domainID,
//   userID,
//   onError,
//   onSuccess
// }: {
//   emailAddress: string;
//   domainID: number;
//   userID: number;
//   onError: (errorMsg: string) => void;
//   onSuccess: (v4Resp: Endpoints.Responses.PeopleKeys.FindAssessments.V4.Get, v2Resp: Endpoints.Responses.PeopleKeys.FindAssessments.Get) => void;
// }) {
//   try {
//     const resps = await Promise.all([API.PeopleKeys.FindAssessments.V4.get({ tryybPersonID: userID, alternateEmail: emailAddress }), API.PeopleKeys.FindAssessments.get({ email: emailAddress, domainID })]);

//     onSuccess?.(...resps);
//   } catch (err) {
//     // TODO
//   }
// }

export default DISCImport;
