/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { Redirect } from "react-router-dom";

import { StoreContext as AppStoreContext } from "data/stores/AppStore";
import { ActiveSession } from "data/storage";
import { INTERFACE_PATHS, SUB_INTERFACE_PATHS } from "data/constants";

import AssessmentTakerWelcome from "./subcomponents/welcome/";
import Taker from "./subcomponents/taker/";

import "./AssessmentTaker.scss";

export type AssessmentTakerSubInterface =
  | "welcome"
  | "locked"
  | "taker"
  | "restart"
  | "completed";

export interface Props extends SITE.GeneralStoreInjectedProps {
  isMobile: boolean;
  discProfileMini?: TytoData.DISCProfileMini;
}

const AssessmentTaker = ({
  discProfileMini,
  isMobile,
  GeneralStore,
}: Props) => {
  let AppStore = React.useContext(AppStoreContext);

  const [loggedInUserID] = React.useState(() => {
    return ActiveSession.userID() ?? 0;
  });
  const [curSubInterface, updateCurSubInterface] =
    React.useState<AssessmentTakerSubInterface>("welcome");

  if (!!discProfileMini?.styleKey3 || !!discProfileMini?.atsInitialsAdaptive) {
    //   return (
    //     <div className="interface-assessment-taker-inner-wrapper">
    //         <main className="interface-assessment-taker-inner-cont centered-content-wrapper">
    //             <section>
    //                 <h2 className="profile-section-title title-font">You already have a Completed Assessment</h2>

    //                 <p className="profile-section-title"></p>
    //             </section>
    //         </main>
    //   </div>
    //   );
    return (
      <Redirect
        to={`${INTERFACE_PATHS.PROFILE}/${loggedInUserID}/${SUB_INTERFACE_PATHS.PROFILE.HOW_TO_READ}`}
      />
    );
  }

  return (
    <div className="interface-assessment-taker-inner-wrapper">
      <main className="interface-assessment-taker-inner-cont centered-content-wrapper">
        <SubInterfaceRouter
          curSubInterface={curSubInterface}
          discProfileMini={discProfileMini}
          isMobile={isMobile}
          GeneralStore={GeneralStore}
          updateCurSubInterface={updateCurSubInterface}
        />
      </main>
    </div>
  );
};

interface SubInterfaceRouterProps
  extends AssessmentTakerInterface.UpdateSubInterfaceProps,
    Pick<Props, "discProfileMini" | "isMobile" | "GeneralStore"> {}

const SubInterfaceRouter = (props: SubInterfaceRouterProps) => {
  switch (props.curSubInterface) {
    case "taker":
      return (
        <Taker
          curSubInterface={props.curSubInterface}
          isMobile={props.isMobile}
          updateCurSubInterface={props.updateCurSubInterface}
        />
      );
    case "welcome":
    default:
      return (
        <AssessmentTakerWelcome
          discProfileMini={props.discProfileMini}
          onContinue={() => {
            props.updateCurSubInterface?.("taker");
          }}
        />
      );
  }
};

export default AssessmentTaker;
