/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

const MINUTE = 1000 * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const MONTH = DAY * 30.5; // * Sort of...
const YEAR = DAY * 365;

interface FriendlyRelativeTime {
  years: number;
  months: number;
  days: number;
  hours: number;
  minutes: number;
}

type TimeQuantityName = "year" | "month" | "day" | "hour" | "minute";

interface Props {
  className?: string;
  children?: any;
  startCompareDate?: string | Date;
  date: string | Date;
  debug?: boolean;
  show1900?: boolean;
  style?: React.CSSProperties;
  terse?: boolean;
}

const Timestamp = (props: Props) => {
  const [parsedTimeStampMsg, updateParsedTimeStampMsg] = React.useState(() => {
    return parseTimeStamp(props) ?? "";
  });

  React.useEffect(() => {
    updateParsedTimeStampMsg(parseTimeStamp(props) ?? "");
  }, [props.date]);

  return (
    <span
      className={cx("cc-timestamp", props.className)}
      title={`${props.date}`}
      style={props.style}
    >
      {parsedTimeStampMsg}
      {(parsedTimeStampMsg && props.children) ?? null}
    </span>
  );
};

function parseTimeStamp({
  date,
  debug,
  startCompareDate,
  terse = true,
}: Props) {
  const asDate = new Date(date);

  if (+asDate === NaN || +asDate < 0) {
    return "";
  }

  const compareDate = startCompareDate
    ? +new Date(startCompareDate)
    : undefined;

  if (debug) {
    debugger;
  }

  const helpfulObject = compareDate
    ? getTimeDiffItems(compareDate, +asDate)
    : getTimeDiffItems(+asDate, Date.now());

  return getFriendlyString(helpfulObject, terse);
}

function getFriendlyString(
  { years, months, days, hours, minutes }: FriendlyRelativeTime,
  terse: boolean
) {
  let quantity = years;
  let quantityName: TimeQuantityName = "year";

  if (years) {
    // * Simply here to stop other blocks to run
  } else if (months) {
    quantity = months;
    quantityName = "month";
  } else if (days) {
    quantity = days;
    quantityName = "day";
  } else if (hours) {
    quantity = hours;
    quantityName = "hour";
  } else if (minutes) {
    quantity = minutes;
    quantityName = "minute";
  } else {
    return "Just Now";
  }

  return getTimeQuanitityName({
    quantity,
    quantityName,
    terse,
  });
}

function getPluralString(quantity: number) {
  return quantity > 1 ? "s" : "";
}

function getTimeQuanitityName({
  quantity,
  quantityName,
  terse,
}: {
  quantityName: TimeQuantityName;
  quantity: number;
  terse: boolean;
}) {
  switch (quantityName) {
    case "minute":
      return `${quantity}${terse ? "" : " "}${
        terse ? "min" : quantityName
      }${getPluralString(quantity)}`;
    case "hour":
      return `${quantity}${terse ? "" : " "}${
        terse ? "hr" : quantityName
      }${getPluralString(quantity)}`;
    case "day":
      return `${quantity}${terse ? "" : " "}${
        terse ? "dy" : quantityName
      }${getPluralString(quantity)}`;
    case "month":
      return `${quantity}${terse ? "" : " "}${
        terse ? "mnth" : quantityName
      }${getPluralString(quantity)}`;
    case "year":
      return `${quantity}${terse ? "" : " "}${
        terse ? "yr" : quantityName
      }${getPluralString(quantity)}`;
  }

  return "";
}

function getTimeDiffItems(
  firstDateMS: number,
  secondDateMS: number
): FriendlyRelativeTime {
  const diff = secondDateMS - firstDateMS;

  let years = 0;
  let months = 0;
  let days = 0;
  let hours = 0;
  let minutes = 0;

  years = diff >= YEAR ? Math.floor(diff / YEAR) : 0;
  const daysOffYearRemainder = diff % YEAR;

  months =
    daysOffYearRemainder >= MONTH
      ? Math.floor(daysOffYearRemainder / MONTH)
      : months;
  const monthsRemainder = daysOffYearRemainder % MONTH;

  days = monthsRemainder >= DAY ? Math.floor(monthsRemainder / DAY) : days;
  const daysRemainder = monthsRemainder % DAY;

  hours = daysRemainder >= HOUR ? Math.floor(daysRemainder / HOUR) : hours;
  const hoursRemainder = daysRemainder % HOUR;

  minutes = hoursRemainder >= MINUTE ? Math.floor(hoursRemainder / MINUTE) : 0;
  const minutesRemainder = hoursRemainder % MINUTE;

  return {
    years,
    months,
    days,
    hours,
    minutes,
  };
}

export default Timestamp;
