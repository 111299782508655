import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import "./AssessmentMarkers.scss";

const MARKERS = Array.from({ length: 24 }, (__, curIdx) => curIdx);

interface Props {
  questionOffsetNumber: number;
  curPageQuestionIdx: number;
}

const AssessmentMarkers = (props: Props) => {
  const currentQuestionNumber =
    props.questionOffsetNumber + props.curPageQuestionIdx;

  return (
    <section className="assessment-taker-markers-wrapper">
      <div className="assessment-taker-markers">
        {MARKERS.map((marker) => (
          <div
            className={cx(
              "assessment-taker-marker",
              currentQuestionNumber >= marker && "is-filled"
            )}
          />
        ))}
      </div>

      <div className="assessment-taker-markers-number-cont">
        <h3 className="assessment-taker-markers-number">
          <span className="assessment-taker-markers-number-current title-font">
            {currentQuestionNumber + 1}
          </span>
          /24
        </h3>
      </div>
    </section>
  );
};

export default AssessmentMarkers;
