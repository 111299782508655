/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { Redirect } from "react-router-dom";

import { SECURITY_FUNCTIONS_BY_NAME } from "data/constants/system";
import { INTERFACE_PATHS } from "data/constants/";

import { Message } from "components/common";

import HasResults from "./subcomponents/has-results/";
import NoResults from "./subcomponents/no-results/";

interface Props {
  isMobile: boolean;
  discProfileMini?: TytoData.DISCProfileMini;
  isLoadingDISCMini: boolean;
}

const SEARCH_OPTS = {
  operation: "ocVIEW",
  functionName: SECURITY_FUNCTIONS_BY_NAME["Person TraitR3"].functionName,
} as Partial<Endpoints.Tyto.PersonAdvanced.GetParameters>;

const Home = ({ discProfileMini, isLoadingDISCMini, isMobile }: Props) => {
  return (
    <div className="home-inner-cont centered-content-wrapper">
      {!discProfileMini ? (
        <>
          {isLoadingDISCMini && (
            <article>
              <Message text="Loading..." />
            </article>
          )}
        </>
      ) : (
        <>
          {!discProfileMini.styleKey3 ? (
            <>
              {discProfileMini?.teamToolsPermit?.mayTakeDisc ? (
                <Redirect to={`${INTERFACE_PATHS.ASSESSMENT}`} />
              ) : (
                <NoResults
                  discProfileMini={discProfileMini}
                  message="No completed assessment found for your account, and you do not have permission to take the assessment."
                />
              )}
            </>
          ) : (
            <HasResults discProfileMini={discProfileMini} isMobile={isMobile} />
          )}
        </>
      )}
    </div>
  );
};

export default Home;
