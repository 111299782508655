import { ENDPOINT_DISC_PROFILE_INTERACTIVE_PERSON_COMM_TIPS } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_DISC_PROFILE_INTERACTIVE_PERSON_COMM_TIPS;

export const DISCProfileInteractivePersonCommTips = {
  get(
    params: Endpoints.Tyto.DISCProfile.Interactive.PersonCommTips.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.DISCProfile.Interactive.PersonCommTips.Get>;
  },
};
