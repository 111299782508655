/*
 * Store for General information like "is the menu open?" and "Is the App being viewed on a mobile device?"
 */
import * as React from "react";
import * as _ from "lodash";
import {} from "react-router-dom";

import { BROWSER_HISTORY } from "../constants/";
import { isMobileSize as browserIsMobileSize } from "data/helpers";
// import { n } from "react-router-dom";

import { MOBILE_WIDTH_BREAKPOINT, SITE_THEMES } from "../constants/";

import * as Storage from "../storage/";

function resetScrollSideEffect() {
  window.scrollTo?.(0, 0);
}

function isMobileSize(): boolean {
  return window && window.innerWidth ? browserIsMobileSize() : false;
}

function getSafeSiteTheme({
  curSiteTheme,
  newSiteTheme,
}: {
  curSiteTheme: keyof typeof SITE.SiteTheme;
  newSiteTheme?: keyof typeof SITE.SiteTheme;
}): keyof typeof SITE.SiteTheme {
  let safeCurSiteTheme = curSiteTheme;

  if (
    safeCurSiteTheme !== SITE_THEMES.dark &&
    safeCurSiteTheme !== SITE_THEMES.light &&
    safeCurSiteTheme !== SITE_THEMES.system
  ) {
    safeCurSiteTheme = SITE_THEMES.light;
  }

  if (!newSiteTheme) {
    return safeCurSiteTheme;
  }

  switch (newSiteTheme) {
    case SITE_THEMES.dark:
      return newSiteTheme;
    case SITE_THEMES.light:
      return newSiteTheme;
    case SITE_THEMES.system:
      return SITE_THEMES.system;
    default:
      return SITE_THEMES.dark;
  }
}

const MAX_HISTORY_LENGTH = 15;

function getHasParentSiteSafely() {
  const fromURL = /hasParent\=true/gi.test(window.location.search);

  if (fromURL) {
    return fromURL;
  }

  try {
    return Storage.SES_STOR.get(Storage.keys.SITE_HAS_PARENT) === "true";
  } catch (err) {
    return false;
  }
}

// export interface HistoryItem {
//   // url: string;
//   // uri: string;
//   // friendlyText?: string;
//   hash: string;
//   key: string; // window.History key
//   pathname: string; // window.location.pathname
//   search: string; // window.location.search
//   state: {
//     friendlyText: string;
//     [x: string]: any;
//   }; // * state supplied in History.push() invocation, along with folded in friendlyText
// }

let StoreContext: React.Context<StoreAPI.GeneralStoreProps> =
  React.createContext({});

let initialState: StoreAPI.GeneralStoreState = {
  curRouteHistoryIdx: 0,
  hasParentSite: getHasParentSiteSafely(),
  history: [],
  historyRecords: BROWSER_HISTORY,
  initialLoadDestination: window.location.href,
  isMobile: isMobileSize(),
  isOnline: !!window.navigator.onLine,
  showMenu: false,
  siteTheme: getSafeSiteTheme({
    curSiteTheme: "dark",
    newSiteTheme: Storage.LOC_STOR.get(
      Storage.keys.SITE_THEME
    ) as keyof typeof SITE.SiteTheme,
  }),
};

export let DISPATCH:
  | React.Dispatch<StoreAPI.StoreAction<StoreAPI.GeneralStoreActionType>>
  | undefined;

let reducer = (
  state: StoreAPI.GeneralStoreState,
  action: StoreAPI.StoreAction<StoreAPI.GeneralStoreActionType>
) => {
  console.log(action);

  switch (action.type) {
    case "GENERAL_STORE_CONFIGURATION_CLIENT_LOADED":
      return {
        ...state,
        configurationClient: action.payload.configurationClient,
        uploadURL: _.get(
          action.payload,
          "configurationClient.uploadServices[0]",
          undefined
        ),
      };
    case "GENERAL_STORE_NAVIGATE_BACK":
      if (state.history.length) {
        const [lastRoute, ...previousHistory] = state.history;

        // navigate(lastRoute.uri);
        window.location.href = lastRoute.pathname;

        return {
          ...state,
          history: previousHistory,
        };
      }

      return state;
    case "GENERAL_STORE_ONLINE_STATUS_CHANGE":
      return {
        ...state,
        isOnline: !!window.navigator.onLine,
      };
    case "GENERAL_STORE_UPDATE_HISTORY":
      const { historyItem } = action.payload;

      if (historyItem) {
        const matchingStateKeyIdx = (state.history || []).findIndex(
          ({ key }) => key === historyItem.key
        );

        /**
         * CASES:
         * 1. No matching item, curIdx is 0 (update state)
         * 2. Matching item, curIdx is 0 (don't update state.history, update state.curRouteHistoryIdx)
         * 3. No matching item, curIdx is > 0 (prune state.history, update state.curRouteHistoryIdx)
         * 4. Matching item, curIdx is > 0 (don't update state.history, update state.curRouteHistoryIdx) <-- mimic action of 'Case 2'
         * ! WARNING: Case 4. does NOT account for using browser back button more than 15 times beyond record length
         * ! which would then cause an item waaaay in the past be treated as a new item at the front of the state.history
         * ! and make the browser button function differently than the App's navigation functionality.
         */

        if (matchingStateKeyIdx < 0) {
          // * Case 1
          if (state.curRouteHistoryIdx === 0) {
            resetScrollSideEffect();

            return {
              ...state,
              history: [historyItem, ...state.history],
            };
          } else {
            // * Case 3
            const prunedHistory = (state.history || []).slice(
              state.curRouteHistoryIdx,
              state.curRouteHistoryIdx + MAX_HISTORY_LENGTH
            );

            return {
              ...state,
              curRouteHistoryIdx: 0, // * New item is now front of the line after pruning
              history: [historyItem, ...prunedHistory].slice(
                0,
                MAX_HISTORY_LENGTH
              ),
            };
          }
        }

        if (matchingStateKeyIdx >= 0) {
          // * Case 2 / Case 4
          return {
            ...state,
            curRouteHistoryIdx: matchingStateKeyIdx,
          };

          // TODO: Better handle 'Case 4' (see warning message above)
        }

        // * Should never reach this point, but just in case: add historyItem to front of line
        return {
          ...state,
          history: [historyItem, ...state.history].slice(0, MAX_HISTORY_LENGTH),
        };
      }

      return state;
    case "GENERAL_STORE_UPDATE_SIZE":
      if (action.payload) {
        return {
          ...state,
          isMobile: action.payload.isMobile,
        };
      }

      return state;
    case "GENERAL_STORE_UPDATE_SITE_THEME":
      if (action.payload && action.payload.siteTheme) {
        const safeSiteTheme = getSafeSiteTheme({
          newSiteTheme: action.payload.siteTheme,
          curSiteTheme: state.siteTheme,
        });

        try {
          Storage.LOC_STOR.set(Storage.keys.SITE_THEME, safeSiteTheme);
        } catch (err) {
          return {
            ...state,
            siteTheme: safeSiteTheme,
          };
        }

        return {
          ...state,
          siteTheme: safeSiteTheme,
        };
      }

      return state;
    case "GENERAL_STORE_TOGGLE_NAVMENU":
      return {
        ...state,
        showMenu:
          action.payload.showMenu !== undefined
            ? !!action.payload.showMenu
            : !state.showMenu,
      };
    default:
      return state;
  }
};

function StoreContextProvider(props: any) {
  // [A]
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch };

  // console.log("Setting DISPATCH_REF");
  DISPATCH = dispatch;

  // [B]
  return (
    <StoreContext.Provider value={value}>
      {props.children}
    </StoreContext.Provider>
  );
}

let StoreContextConsumer = StoreContext.Consumer;

// [C]
export { StoreContext, StoreContextProvider, StoreContextConsumer };
