import { ENDPOINT_DISC_PROFILE_INTERACTIVE_PERSON_COMM_TIP } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_DISC_PROFILE_INTERACTIVE_PERSON_COMM_TIP;

export const DISCProfileInteractivePersonCommTip = {
  post(
    params: Endpoints.Tyto.DISCProfile.Interactive.PersonCommTip.PostParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "post",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.DISCProfile.Interactive.PersonCommTip.Post>;
  },
  put(
    params: Endpoints.Tyto.DISCProfile.Interactive.PersonCommTip.PutParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "put",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.DISCProfile.Interactive.PersonCommTip.Put>;
  },
};
