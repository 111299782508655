import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";
import { Icon } from "components/common";

import "./style.scss";

interface Props {
  groupProfile: TytoData.DISCTeamProfile;
}

const GroupStrengths = (props: Props) => {
  const [strengths, updateStrengths] = React.useState(() => {
    return strengthsAsArray(props.groupProfile);
  });

  React.useEffect(() => {
    updateStrengths(strengthsAsArray(props.groupProfile));
  }, [props.groupProfile]);

  return (
    <div className="group-section group-strengths">
      <h4 className="group-section-title title-font">Group Strengths</h4>

      <ul className="group-section-list">
        {strengths.map((strength) => (
          <li className="group-section-list-item group-strength" key={strength}>
            <Icon
              className={cx("cd-commtips-list-item-icon", "commtip-icon-do")}
              icon={"check-circle-2"}
              size={18}
            />
            <div>{strength}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

function strengthsAsArray(groupProfile: Props["groupProfile"]) {
  if (!groupProfile?.strengthAreas) {
    return [];
  }

  return groupProfile.strengthAreas.split("\r\n");
}

export default GroupStrengths;
