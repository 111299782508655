import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import {
  calcGroupStyle,
  getGroupStyleWithStdDeviation,
  loadTeamProfile,
} from "data/helpers/DISC";
import { BackNav } from "components/meta/";
import { UserThumbByID, ReactSelect, TextButton } from "components/common/";

import { useTeamStyleReport } from "data/network/hooks/";
import { scrollElementIntoView } from "data/helpers/";

import GroupStyleKey from "./GroupStyleKey";
import GroupGraphs from "../sections/graphs/";
import GroupStrengths from "../sections/strengths/";
import DecisionMaking from "../sections/decision-making/";
import FascilitatingMeetings from "../sections/fascilitating-meeting/";
import ReadingHeatMap from "../sections/reading-map/";

import "./style.scss";

interface Props extends GroupStyleWrapperProps {
  discProfileMinis: TytoData.DISCProfileMini[];
  isMobile?: boolean;
}

export const GroupStyle = (props: Props) => {
  const [groupStyle, updateGroupStyle] = React.useState(
    calcGroupStyle(props.discProfileMinis)
  );
  const [groupStyleKeyWithStdDeviation, updateGroupStyleKeyWithStdDeviation] =
    React.useState(getGroupStyleWithStdDeviation(groupStyle));
  const { eagerData, isLoading } = useTeamStyleReport({
    styleKey: groupStyleKeyWithStdDeviation,
    isEnabled: !!groupStyleKeyWithStdDeviation,
  });

  const [activeSection, updateActiveSection] = React.useState("GS");

  // * [E-1] - Update GroupStyle and grab existing teamProfile as profileArray changes
  React.useEffect(() => {
    const newGroupStyle = calcGroupStyle(props.discProfileMinis);

    if (Array.isArray(newGroupStyle) && newGroupStyle.length) {
      updateGroupStyle(newGroupStyle);

      const teamStyleKeyWithStdDeviation =
        getGroupStyleWithStdDeviation(newGroupStyle);

      updateGroupStyleKeyWithStdDeviation(teamStyleKeyWithStdDeviation);
    }
  }, [props.discProfileMinis]);

  return (
    <article className="team-group-interface">
      <header className="teams-group-comparison-top">
        <BackNav onBack={props.goBack} title={`Group Dynamic`} />

        <section className="teams-group-comparison-userthumbs">
          <div className="teams-group-comparison-userthumbs-inner-cont">
            {props.discProfileMinis.map((profile) => (
              <div className="teams-group-comparision-user-container">
                <UserThumbByID
                  className="teams-group-comparison-userthumb"
                  size={30}
                  key={profile.personID}
                  userName={profile.personName}
                  userID={profile.personID}
                  withBorder={true}
                />
                <p className="teams-group-comparision-userName">
                  {profile.personName.split(" ")[0]}
                  {` `}
                  {profile.personName.split(" ")[1].charAt(0)}
                  {`.`}
                </p>
              </div>
            ))}
          </div>
        </section>
        <section>
          {props.isMobile && (
            <ReactSelect
              className="team-group-comparision-selector"
              options={[
                {
                  label: "Group Styles",
                  value: "GS",
                },
                {
                  label: "Reading the Team Heat Map",
                  value: "RHM",
                },
                {
                  label: "Decision Making Style",
                  value: "DMS",
                },
                {
                  label: "Facilitating Meeting",
                  value: "FM",
                },
                {
                  label: "Strength Areas",
                  value: "SA",
                },
              ]}
              onChange={(value) => {
                updateActiveSection(value.value);
                scrollToTarget(value.value);
              }}
              value={activeSection}
            />
          )}
          {!props.isMobile && (
            <div className="teams-group-menu-tab">
              <TextButton
                className="teams-group-menu-tabs"
                onClick={() => {
                  updateActiveSection("GS");
                  scrollToTarget("GS");
                }}
                value={"Group Styles"}
              />
              <TextButton
                className="teams-group-menu-tabs"
                onClick={() => {
                  updateActiveSection("DMS");
                  scrollToTarget("DMS");
                }}
                value={"Decision Making Style"}
              />
              <TextButton
                className="teams-group-menu-tabs"
                onClick={() => {
                  updateActiveSection("FM");
                  scrollToTarget("FM");
                }}
                value={"Facilitating Meeting"}
              />
              <TextButton
                className="teams-group-menu-tabs"
                onClick={() => {
                  updateActiveSection("SA");
                  scrollToTarget("SA");
                }}
                value={"Strength Areas"}
              />
            </div>
          )}
        </section>

        <section className="teams-group-comparison-type-cont">
          Group Style
          <h2 className="teams-group-comparison-type title-font">
            {groupStyleKeyWithStdDeviation ? (
              <GroupStyleKey styleKey={groupStyleKeyWithStdDeviation} />
            ) : (
              "No Determined Style"
            )}
          </h2>
        </section>
      </header>
      <section id="GS">
        <GroupGraphs
          discProfilesMini={props.discProfileMinis}
          isMobile={props.isMobile}
          disablePlotGraph={true}
        />
      </section>
      <section id="RHM">
        {eagerData?.discStyle && (
          <ReadingHeatMap groupProfile={eagerData?.discStyle} />
        )}
      </section>
      <section id="DMS">
        {eagerData?.discStyle && (
          <DecisionMaking groupProfile={eagerData?.discStyle} />
        )}
      </section>
      <section id="FM">
        {eagerData?.discStyle && (
          <FascilitatingMeetings groupProfile={eagerData?.discStyle} />
        )}
      </section>
      <section id="SA">
        {eagerData?.discStyle?.strengthAreas && (
          <GroupStrengths groupProfile={eagerData.discStyle} />
        )}
      </section>
    </article>
  );

  function scrollToTarget(id: string) {
    var temp = document.getElementById(id);
    console.log(temp);
    if (temp) {
      scrollElementIntoView(temp);
    }
  }
};

interface GroupStyleWrapperProps {
  discProfileMinis?: TytoData.DISCProfileMini[];
  goBack: () => void;
  isMobile?: boolean;
}

const GroupStyleWrapper = (props: GroupStyleWrapperProps) => {
  if (!props.discProfileMinis?.length) {
    return (
      <article>
        <h3 className="title-font font-size-title">No Profiles Found.</h3>
      </article>
    );
  }

  return <GroupStyle {...props} discProfileMinis={props.discProfileMinis} />;
};

export default GroupStyleWrapper;
