/*
 * Component Description
 */
import * as React from "react";

import { getLetterColor } from "data/helpers/DISC";

interface Props {
  discLetter: string;
  discValue: number;
  showLetterValues: boolean;
  style?: React.CSSProperties;
}

export default ({ discLetter, discValue, showLetterValues, style }: Props) => {
  return (
    <p
      className="cc-disc-plot-graph-column-value"
      style={{
        ...(style || {}),
        color: getLetterColor(discLetter),
      }}
    >
      {discLetter === "c" ? "A" : discLetter.toUpperCase()}
      {showLetterValues && (
        <>
          {" "}
          {discValue < 0 ? "" : "+"}
          {discValue.toFixed(1)}
        </>
      )}
    </p>
  );
};
