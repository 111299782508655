/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { SessionHandling } from "data/storage/";
import { URL_BASE, ENDPOINT_PERSON_PROFILE_PHOTO } from "data/constants/";

import Image from "../image/";

import "./style.scss";

export interface Props {
  altImageAssetID?: number;
  asset?: Data.Asset;
  className?: string;
  discValues?: {
    d: number;
    i: number;
    s: number;
    c: number;
  };
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  omitBaseURL?: boolean;
  omitHoverIcon?: boolean;
  size: number;
  style?: React.CSSProperties;
  userName: string;
  userThumbPathURL?: string;
  withBorder?: boolean;
}

const DEFAULT_IMAGE_SIZE = 24;

const UserThumb = ({
  altImageAssetID,
  asset,
  className,
  onClick,
  omitBaseURL,
  size = DEFAULT_IMAGE_SIZE,
  style,
  userName,
  userThumbPathURL,
  withBorder,
}: Props) => {
  const [src, updateSrc] = React.useState(
    userThumbPathURL ?? getSrc({ altImageAssetID, asset })
  );
  const [initials, updateInitials] = React.useState(() => {
    return userName
      .split(" ")
      .map((word) => word.charAt(0))
      .filter((letter) => !!letter)
      .slice(0, 2)
      .join(". ");
  });

  // * [E-1] - Update Initials when userName changes
  React.useEffect(() => {
    updateInitials(
      userName
        .split(" ")
        .map((word) => word.charAt(0))
        .filter((letter) => !!letter)
        .join(". ")
    );
  }, [userName]);

  // * [E-2] - Update Src when asset changes
  React.useEffect(() => {
    updateSrc(userThumbPathURL ?? getSrc({ altImageAssetID, asset }));
  }, [altImageAssetID, asset, userThumbPathURL]);
  // * END OF EFFECTS ======================

  // const pseudoBorderWidth = getPseudoBorderWidth(size);
  // const sizeAccountingForPadding = size - pseudoBorderWidth * 2;

  const contStyle = {
    height: `${size}px`,
    width: `${size}px`,
    ...(style || {}),
    // borderImage: `${pseudoBorderWidth}px `,
  };

  const imageStyle = {
    height: "100%",
    width: "100%",
    // height: `${size}px`,
    // width: `${size}px`,
  };

  // const contStyle = {
  //   height: `${size}px`,
  //   width: `${size}px`,
  //   ...(style || {}),
  //   padding: `${pseudoBorderWidth}px`,
  // };
  // const imageStyle = {
  //   height: `${sizeAccountingForPadding}px`,
  //   width: `${sizeAccountingForPadding}px`,
  // };

  return (
    <div
      className={cx(
        "cc-userthumb-cont",
        withBorder && "cc-userthumb-accent-border",
        className
      )}
      style={contStyle}
      title={userName}
      onClick={onClick}
      role={onClick ? "button" : undefined}
    >
      {src ? (
        <Image
          alt={userName}
          className={cx("cc-userthumb", className)}
          style={imageStyle}
          src={src}
        />
      ) : (
        <p
          className="cc-userthumb-no-img"
          style={{ fontSize: `${size * 0.3}px` }}
        >
          {initials}.
        </p>
      )}
    </div>
  );
};

const getPseudoBorderWidth = (size: number) => {
  return Math.ceil(size / 25);
};

const getSrc = ({
  altImageAssetID,
  asset,
}: {
  altImageAssetID?: number;
  asset?: Data.Asset;
}) => {
  if (!asset || !Array.isArray(asset.encodings)) {
    return altImageAssetID
      ? `https://${URL_BASE}/v25/user/photo/?assetID=${altImageAssetID}&encoding=ocDEFAULT`
      : "";
  }

  const encoding = asset.encodings.find(
    (enc) => enc.encodingType.toLowerCase() === "ocdefault"
  );

  return encoding ? `https://${URL_BASE}/${encoding.pathURL}` : "";
};

interface UserThumbLoadingProps
  extends Pick<Props, "className" | "onClick" | "style" | "size"> {}

export const UserThumbLoading = ({
  className,
  onClick,
  size,
  style,
}: UserThumbLoadingProps) => {
  const contStyle = React.useMemo(() => {
    return {
      height: `${size}px`,
      width: `${size}px`,
      ...(style || {}),
      // borderImage: `${pseudoBorderWidth}px `,
    };
  }, [size]);

  return (
    <div
      className={cx("cc-userthumb-cont loading-blink", className)}
      style={contStyle}
      title={"Placeholder User Thumbnail while loading Information.."}
      onClick={onClick}
      role={onClick ? "button" : undefined}
    >
      <p
        className="cc-userthumb-no-img"
        style={{ fontSize: `${size * 0.3}px` }}
      >
        {" "}
      </p>
    </div>
  );
};

function determineURL(props: UserThumbByIDProps) {
  const activeSessionKey = SessionHandling.getActiveSessionKey();

  const safeURL = new URL(`${ENDPOINT_PERSON_PROFILE_PHOTO}`);

  safeURL.searchParams.set("sessionKey", activeSessionKey ?? "");
  safeURL.searchParams.set("personID", `${props.userID ?? 0}`);
  safeURL.searchParams.set("silhouette", "dark");

  if (props.opts?.encoding) {
    safeURL.searchParams.set("encoding", props.opts?.encoding ?? "");
  }

  return safeURL.href;
}

interface UserThumbByIDProps extends Props {
  userID: number;
  opts?: Partial<Endpoints.Tyto.Person.ProfilePhoto.GetParameters>;
}

export const UserThumbByID = (props: UserThumbByIDProps) => {
  const [url, updateURL] = React.useState(() => {
    return determineURL(props);
  });

  React.useEffect(() => {
    updateURL(determineURL(props));
  }, [props.userID]);

  return <UserThumb {...props} userThumbPathURL={url} />;
};

export default UserThumb;
