import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import {
  getGroupValuesFromCompareProfiles,
  keyIntensitiesByLetter,
} from "components/disc/DISCPlotGraph/utils";
import DISCPlotGraph from "components/disc/DISCPlotGraph";
import { Button } from "components/common/";

import "./style.scss";

type FocusedLetter = "d" | "i" | "s" | "c";

interface Props {
  closePopup?: () => void;
  discCompareProfile: TytoData.DISCCompareProfile;
  graphWidth?: number;
  startingColumn?: "d" | "i" | "s" | "c";
}

const DISCPlotGraphWrapper = (props: Props) => {
  const [width, updateWidth] = React.useState(350);
  const [intensitiesByLetter, updateIntensitiesByLetter] = React.useState(
    () => {
      return keyIntensitiesByLetter(props.discCompareProfile);
    }
  );
  const [groupStyle, updateGroupStyle] = React.useState(() => {
    return getGroupValuesFromCompareProfiles([props.discCompareProfile]) ?? [];
  });
  const [focusedLetter, updateFocusedLetter] = React.useState<
    FocusedLetter | undefined
  >(() => {
    return props.startingColumn;
  });

  React.useEffect(() => {
    updateFocusedLetter(props.startingColumn);
  }, [props.startingColumn]);

  React.useEffect(() => {
    updateGroupStyle(
      getGroupValuesFromCompareProfiles([props.discCompareProfile]) ?? []
    );
    updateIntensitiesByLetter(keyIntensitiesByLetter(props.discCompareProfile));
  }, [props.discCompareProfile]);

  return (
    <article className="modals-disc-plotgraph-main">
      <DISCPlotGraph
        onLetterSelect={(newFocusedLetter) => {
          updateFocusedLetter(newFocusedLetter);
        }}
        groupStyle={groupStyle}
        showLetterValues={true}
        focusLetter={focusedLetter}
        size={props.graphWidth ?? 350}
      />

      {focusedLetter && intensitiesByLetter[focusedLetter] && (
        <section
          className={cx(
            "modals-disc-plotgraph-percentile-cont",
            focusedLetter && `letter-${focusedLetter}`
          )}
        >
          <p className="modals-disc-plotgraph-percentile-text">
            {intensitiesByLetter[focusedLetter].description}
          </p>
        </section>
      )}

      <section className="modals-disc-plotgraph-btn-cont">
        <Button
          className="modals-disc-plotgraph-btn large-btn"
          hoverBGSize="hidden"
          theme="grey"
          onClick={props.closePopup ?? _.noop}
          value="Close"
        />
      </section>
    </article>
  );
};

export default DISCPlotGraphWrapper;
