import _ from "lodash";

export const plotPlacements = {
  // 2: 0.5,
  // 4: 0.75,
  // 6: 0.875,
  // 8: 1
  2: 0.25,
  4: 0.5,
  6: 0.75,
  8: 1,
};

export const heightFromItem: { [x: number]: number } = {
  0: 1 - plotPlacements[6],
  1: plotPlacements[6] - plotPlacements[4],
  2: plotPlacements[4] - plotPlacements[2],
  3: plotPlacements[2],
  4: plotPlacements[2],
  5: plotPlacements[4] - plotPlacements[2],
  6: plotPlacements[6] - plotPlacements[4],
  7: 1 - plotPlacements[6],
};

export const gridItemToPlotMap: { [x: number]: number } = {
  0: 0,
  1: 1 - plotPlacements[6],
  2: 1 - plotPlacements[4],
  3: 1 - plotPlacements[2],
  4: 0,
  5: plotPlacements[2],
  6: plotPlacements[4],
  7: plotPlacements[6],
  8: 1,
};

export function getItemPosition(itemNumber: number) {
  const topValue = gridItemToPlotMap[itemNumber] || 0;
  const heightValue = heightFromItem[itemNumber];

  return {
    // left: `${colNumber * 25}%`,
    top: `${50 * topValue + (itemNumber >= 4 ? 50 : 0)}%`,
    height: `${heightValue * 50}%`,
  };
}

interface GroupStyleItem {
  d: number;
  i: number;
  s: number;
  c: number;
  personName?: string;
  percentile?: {
    d: number;
    i: number;
    s: number;
    c: number;
  };
  asset?: Data.Asset;
}

const DEFAULT_ARRAY: GroupStyleItem[] = [];

export function keyIntensitiesByLetter(
  compareProfile: TytoData.DISCCompareProfile
) {
  return (compareProfile?.intensity ?? []).reduce(
    (
      accum: { [styleKey: string]: TytoData.DISCCompareProfile.Intensity },
      intensity
    ) => {
      if (intensity?.styleKey) {
        const key = intensity?.styleKey ?? "";
        const keyLowerCase = `${key}`.toLocaleLowerCase();

        accum[key] = intensity;
        accum[keyLowerCase] = intensity;
      }

      return accum;
    },
    {}
  );
}

export function getGroupValuesFromDISCMinis(
  discMinis: TytoData.DISCProfileMini[]
) {
  if (!discMinis?.length) {
    return DEFAULT_ARRAY;
  }

  return discMinis.map(({ d3, i3, s3, c3 }) => ({
    d: d3,
    i: i3,
    s: s3,
    c: c3,
  }));
}

export function getGroupValuesFromCompareProfiles(
  compareProfiles: TytoData.DISCCompareProfile[]
) {
  if (!compareProfiles?.length) {
    return DEFAULT_ARRAY;
  }

  return compareProfiles.map((compareProfile) => {
    return {
      d: compareProfile?.result?.d3 ?? 0,
      i: compareProfile?.result?.i3 ?? 0,
      s: compareProfile?.result?.s3 ?? 0,
      c: compareProfile?.result?.c3 ?? 0,
      percentile: {
        d: compareProfile.result.d3percentile ?? 0,
        i: compareProfile.result.i3percentile ?? 0,
        s: compareProfile.result.s3percentile ?? 0,
        c: compareProfile.result.c3percentile ?? 0,
      },
    };
  });
}

export const letterSpread: { [x: string]: { key: string; value: number }[] } = {
  d: [
    { key: "8", value: 0.00031 },
    { key: "7", value: 0.00362 },
    { key: "6", value: 0.006929 },
    { key: "5", value: 0.011894 },
    { key: "4", value: 0.020892 },
    { key: "3", value: 0.060399 },
    { key: "2", value: 0.05771 },
    { key: "1", value: 0.079222 },
    { key: "0", value: 0.110146 },
    { key: "-1", value: 0.174682 },
    { key: "-2", value: 0.130107 },
    { key: "-3", value: 0.123591 },
    { key: "-4", value: 0.138484 },
    { key: "-5", value: 0.06557 },
    { key: "-6", value: 0.015927 },
    { key: "-7", value: 0.000517 },
  ],
  i: [
    { key: "8", value: 0.01 },
    { key: "7", value: 0.015 },
    { key: "6", value: 0.035 },
    { key: "5", value: 0.03 },
    { key: "4", value: 0.05 },
    { key: "3", value: 0.09 },
    { key: "2", value: 0.08 },
    { key: "1", value: 0.095 },
    { key: "0", value: 0.095 },
    { key: "-1", value: 0.1 },
    { key: "-2", value: 0.12 },
    { key: "-3", value: 0.08 },
    { key: "-4", value: 0.07 },
    { key: "-5", value: 0.08 },
    { key: "-6", value: 0.03 },
    { key: "-7", value: 0.025 },
  ],
  s: [
    { key: "8", value: 0.02 },
    { key: "7", value: 0.091 },
    { key: "6", value: 0.1203 },
    { key: "5", value: 0.14 },
    { key: "4", value: 0.13 },
    { key: "3", value: 0.149 },
    { key: "2", value: 0.099 },
    { key: "1", value: 0.092 },
    { key: "0", value: 0.04095 },
    { key: "-1", value: 0.035 },
    { key: "-2", value: 0.04035 },
    { key: "-3", value: 0.023992 },
    { key: "-4", value: 0.008894 },
    { key: "-5", value: 0.007446 },
    { key: "-6", value: 0.001965 },
    { key: "-7", value: 0.000103 },
  ],
  c: [
    { key: "8", value: 0.04503 },
    { key: "7", value: 0.034562 },
    { key: "6", value: 0.09 },
    { key: "5", value: 0.085 },
    { key: "4", value: 0.104 },
    { key: "3", value: 0.11 },
    { key: "2", value: 0.14 },
    { key: "1", value: 0.11 },
    { key: "0", value: 0.070005 },
    { key: "-1", value: 0.075 },
    { key: "-2", value: 0.040391 },
    { key: "-3", value: 0.05 },
    { key: "-4", value: 0.03 },
    { key: "-5", value: 0.0107 },
    { key: "-6", value: 0.005 },
    { key: "-7", value: 0.000312 },
  ],
};

// -7	0.000517	0.025	0.000103	0.000312
// -6	0.015927	0.03	0.001965	0.005
// -5	0.06557	0.08	0.007446	0.0107
// -4	0.138484	0.07	0.008894	0.03
// -3	0.123591	0.08	0.023992	0.05
// -2	0.130107	0.12	0.04035	0.040391
// -1	0.174682	0.1	0.035	0.075
// 0	0.110146	0.095	0.04095	0.070005
// 1	0.079222	0.09	0.092	0.11
// 2	0.05771	0.08	0.099	0.14
// 3	0.060399	0.09	0.149	0.11
// 4	0.020892	0.05	0.13	0.104
// 5	0.011894	0.03	0.14	0.085
// 6	0.006929	0.035	0.1203	0.09
// 7	0.00362	0.015	0.091	0.034562
// 8	0.00031	0.01	0.02	0.04503
