import * as React from "react";

import { IconProps } from "../typings";

const DragDots = ({ className, color, gradientKey, size }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      //   viewBox="0 0 18 25"
      height={size}
      width={size}
      fill={color}
    >
      <g filter={`url(#${gradientKey})`}>
        <path
          d="M7.58408 8.20796C7.58408 7.21995 6.78005 6.41592 5.79204 6.41592C4.80403 6.41592 4.00001 7.21995 4.00001 8.20796C4.00001 9.19597 4.80403 9.99999 5.79204 9.99999C6.78005 9.99999 7.58408 9.19597 7.58408 8.20796Z"
          fill="white"
          fill-opacity="0.5"
        />
        <path
          d="M7.58408 1.79203C7.58408 0.804026 6.78005 -3.51451e-08 5.79204 -7.83323e-08C4.80403 -1.2152e-07 4.00001 0.804026 4.00001 1.79203C4.00001 2.78004 4.80403 3.58407 5.79204 3.58407C6.78005 3.58407 7.58408 2.78004 7.58408 1.79203Z"
          fill="white"
          fill-opacity="0.5"
        />
        <path
          d="M7.58408 14.6239C7.58408 13.6359 6.78005 12.8318 5.79204 12.8318C4.80403 12.8318 4.00001 13.6359 4.00001 14.6239C4.00001 15.6119 4.80403 16.4159 5.79204 16.4159C6.78005 16.4159 7.58408 15.6119 7.58408 14.6239Z"
          fill="white"
          fill-opacity="0.5"
        />
        <path
          d="M14 8.20796C14 7.21995 13.196 6.41592 12.208 6.41592C11.22 6.41592 10.4159 7.21995 10.4159 8.20796C10.4159 9.19597 11.22 9.99999 12.208 9.99999C13.196 9.99999 14 9.19597 14 8.20796Z"
          fill="white"
          fill-opacity="0.5"
        />
        <path
          d="M14 1.79203C14 0.804026 13.196 -3.51451e-08 12.208 -7.83323e-08C11.22 -1.2152e-07 10.4159 0.804026 10.4159 1.79203C10.4159 2.78004 11.22 3.58407 12.208 3.58407C13.196 3.58407 14 2.78004 14 1.79203Z"
          fill="white"
          fill-opacity="0.5"
        />
        <path
          d="M14 14.6239C14 13.6359 13.196 12.8319 12.208 12.8319C11.22 12.8319 10.4159 13.6359 10.4159 14.6239C10.4159 15.6119 11.22 16.4159 12.208 16.4159C13.196 16.4159 14 15.6119 14 14.6239Z"
          fill="white"
          fill-opacity="0.5"
        />
      </g>
      <defs>
        <filter
          id={`${gradientKey}`}
          x="0"
          y="0"
          width="18"
          height="24.4159"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result={`${gradientKey}-2`}
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2={`${gradientKey}-2`}
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default DragDots;
