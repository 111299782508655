import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { DISCPlotGraph, DISCHeatMap } from "components/disc/";
import { CheckBox, TextButton, ToggleButton } from "components/common/";
import { useIntersectionObserver } from "data/helpers/Hooks";

import "./style.scss";

type ActiveGraph = "heatmapGraph" | "plotGraph";

const OPTIONS: {
  label: string;
  value: ActiveGraph;
}[] = [
  { label: "Heatmap", value: "heatmapGraph" },
  { label: "Plot Graph", value: "plotGraph" },
];

interface Props {
  discProfilesMini: TytoData.DISCProfileMini[];
  isMobile?: boolean;
  disablePlotGraph?: boolean;
}

const GroupGraphs = (props: Props) => {
  const [width] = React.useState(() => {
    const size = Math.min((window.innerWidth - 23 * 2) * 0.9, 425);

    return size;
  });
  const [showStressed, updateShowStressed] = React.useState(false);
  const [collections, updateCollections] = React.useState(() => {
    return getGraphInfoFromMinis(props.discProfilesMini);
  });

  const [currentlyViewedItem, updateCurrentlyViewedItem] =
    React.useState<ActiveGraph>("heatmapGraph");
  const heatmapGraphRef = React.useRef<HTMLDivElement | null>(null);
  const plotGraphRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    updateCollections(getGraphInfoFromMinis(props.discProfilesMini));
  }, [props.discProfilesMini, showStressed]);

  const DISCGraphIntersect = useIntersectionObserver({
    threshold: [0.8],
    onEntry: (entryData) => {
      if (entryData.isIntersecting) {
        updateCurrentlyViewedItem("heatmapGraph");
      }
      // updateCurrentlyViewedItem("r3Graph");
    },
  });
  const plotGraphIntersect = useIntersectionObserver({
    threshold: [0.8],
    onEntry: (entryData) => {
      if (entryData.isIntersecting) {
        updateCurrentlyViewedItem("plotGraph");
      }
    },
  });

  const manualHeatmapGraphRef = React.useCallback(
    (node: HTMLDivElement | null) => {
      heatmapGraphRef.current = node;

      if (node !== null) {
        DISCGraphIntersect.updateNode?.(node);
      } else {
        // TODO?
      }
    },
    []
  );
  const manualPlotGraphRef = React.useCallback(
    (node: HTMLDivElement | null) => {
      plotGraphRef.current = node;

      if (node !== null) {
        plotGraphIntersect.updateNode?.(node);
      } else {
        // TODO?
      }
    },
    []
  );

  const { normal, stressed } = collections;

  return (
    <section className="group-section group-section-graphs">
      {props.isMobile && !props.disablePlotGraph && (
        <ToggleButton
          buttonItems={OPTIONS}
          activeItem={currentlyViewedItem}
          onChange={(value) => {
            updateCurrentlyViewedItem(value as ActiveGraph);

            const targetElement =
              value === "plotGraph"
                ? plotGraphRef.current
                : heatmapGraphRef.current;

            if (targetElement) {
              targetElement.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: value === "plotGraph" ? "start" : "end",
              });
            }
          }}
          className="group-graph-toggle-button"
        />
      )}
      <div className="group-graphs-wrapper">
        <div className="group-discgraphs-graph-cont disc-heatmap-cont">
          <DISCHeatMap
            heatMapType={showStressed ? 2 : 3}
            profiles={props.discProfilesMini}
            size={width}
            innerRef={manualHeatmapGraphRef}
          />
        </div>
        {!props.disablePlotGraph && (
          <div className="group-discgraphs-graph-cont plot-graph-cont">
            <DISCPlotGraph
              canSelectUser={true}
              groupStyle={showStressed ? stressed : normal}
              size={width}
              innerRef={manualPlotGraphRef}
            />
          </div>
        )}
        <div className="group-graph-bar-cont">
          <label className="group-graph-bar-label">Most Intense</label>
          <div className="group-graph-bar"></div>
          <label className="group-graph-bar-label">Less Intense</label>
        </div>
      </div>

      <div className="group-graphs-toggle-wrapper">
        <CheckBox
          className="group-graphs-stressed-toggle-checkbox"
          isChecked={showStressed}
          label="Show Stressed"
          onChange={(newVal) => {
            updateShowStressed(newVal);
          }}
          size={24}
        />

        <TextButton
          className="group-graphs-stressed-toggle-btn"
          value="Show Under Stress"
          onClick={() => {
            updateShowStressed(!showStressed);
          }}
        />
      </div>
    </section>
  );
};

export function getGraphInfoFromMinis(
  discProfilesMini: TytoData.DISCProfileMini[]
) {
  //   const data = discProfilesMini.map((discMiniProfile) => ({
  //     d: discMiniProfile[asStressed ? "d2" : "d3"],
  //     i: discMiniProfile[asStressed ? "i2" : "i3"],
  //     s: discMiniProfile[asStressed ? "s2" : "s3"],
  //     c: discMiniProfile[asStressed ? "c2" : "c3"],
  //     // d: asStressed ? discMiniProfile.d2 : discMiniProfile.d3,
  //     // i: asStressed ? discMiniProfile.i2 : discMiniProfile.i3,
  //     // s: asStressed ? discMiniProfile.s2 : discMiniProfile.s3,
  //     // c: asStressed ? discMiniProfile.c2 : discMiniProfile.c3,
  //     personName: discMiniProfile.personName,
  //     personID: discMiniProfile.personID,
  //   }));

  const { normal, stressed } = discProfilesMini.reduce(
    (
      accum: {
        normal: any[];
        stressed: any[];
      },
      discMiniProfile
    ) => {
      // * normal
      accum.normal.push({
        d: discMiniProfile.d3,
        i: discMiniProfile.i3,
        s: discMiniProfile.s3,
        c: discMiniProfile.c3,
        personName: discMiniProfile.personName,
        personID: discMiniProfile.personID,
      });

      // * stressed
      accum.stressed.push({
        d: discMiniProfile.d2,
        i: discMiniProfile.i2,
        s: discMiniProfile.s2,
        c: discMiniProfile.c2,
        personName: discMiniProfile.personName,
        personID: discMiniProfile.personID,
      });

      return accum;
    },
    {
      normal: [],
      stressed: [],
    }
  );

  return {
    normal,
    stressed,
  };
  //   return data;
}

export default GroupGraphs;
