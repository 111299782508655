import * as React from "react";
import * as _ from "lodash";
import { useQuery, useMutation, UseQueryResult } from "react-query";

import { queryClient } from "../";

import { IMAGE_BASE_URL, REACT_QUERY_KEYS } from "../../constants/";
import {
  findIfCourseIsPrimaryOrSubBlock,
  makeLocalForageKey,
  timeInMS,
} from "../../helpers/";
import { LF, SessionHandling, ActiveSession } from "../../storage/";
import API from "../tyto/";

// const { LF, SessionHandling } = Storage;

export const DEFAULT_RQ_OPTS = {
  refetchOnWindowFocus: false,
  staleTime: timeInMS({ timeQuantity: 30, timeType: "min" }),
};

export function useLocalForage<T>(
  key: string,
  params?: Array<string | number> | null,
  opts?: {
    onError?: (data: Data.TytoErrorObject) => void;
    onSuccess?: (data: any) => void;
  }
) {
  const localForageKey = makeLocalForageKey(key, params ?? undefined);

  return useQuery(
    localForageKey,
    async () => {
      const locallyStoredData = await LF.getItem(localForageKey);

      return locallyStoredData;
    },
    {
      refetchOnWindowFocus: false,
      onError: (err) => {
        opts?.onError?.(err);
      },
      onSuccess: (data) => {
        opts?.onSuccess?.(data);
      },
      // ...opts
    }
  ) as UseQueryResult<Omit<T, "session" | "error">, any>;
}

// * wrapper function that allows storage of responses
// * and returns useQuery *with* the storedItemResponse folded in
// export function tytoHook(
//   key: string,
//   opts: {
//     includeStoreData?: boolean;
//   },
//   reactQueryOpts?: UseQueryOptions
// ) {
//   const safeOpts = opts || {};

//   const storedValueQuery = useLocalForage(key);

//   switch (key) {
//     // case REACT_QUERY_KEYS.TRAINING:
//     //   return {
//     //     storedValueQuery,
//     //     ...useTraining(reactQueryOpts),
//     //   };
//     case REACT_QUERY_KEYS.CATALOG:
//       return {
//         storedValueQuery,
//         ...useCatalog(reactQueryOpts),
//       };
//     // default:
//     //     return {};
//   }

//   //   return {};

//   // return {
//   //     storedValueQuery: useLocalForage(key),
//   //     ...useTraining()
//   // };
// }

export function useAssetEncoding({
  assetID,
  enrollmentID,
  isEnabled = true,
  onError,
  onSuccess,
}: {
  assetID: number;
  enrollmentID?: number;
  isEnabled?: boolean;
  onError?: (errorMsg: string) => void;
  onSuccess?: (
    data: Omit<Endpoints.Responses.Asset.Encoding.Post, "session" | "error">
  ) => void;
}) {
  // const storedValueQuery = useLocalForage(REACT_QUERY_KEYS.ASSET_ENCODING, [
  //   assetID ?? 0,
  // ]);

  return {
    // storedValueQuery,
    ...useQuery(
      [REACT_QUERY_KEYS.ASSET_ENCODING, assetID],
      async () => {
        const data = await API.Asset.Encoding.post({
          assetID,
        });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        enabled: !!isEnabled && !!assetID,
        onError: (err: any) => {
          onError?.(err);
        },
        retry: 3,
        onSuccess: (data: Endpoints.Responses.Asset.Encoding.Post) => {
          // debugger;
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.ASSET_ENCODING, [assetID]),
            _.omit(data, ["session", "error"])
          );

          // // * If an enrollmentID was passed, invalidate the cache so the launchEnrollment will
          // // * reload with updated encodings array in the first comment's asset attachment
          // if (enrollmentID) {
          //   queryClient.invalidateQueries([
          //     REACT_QUERY_KEYS.LAUNCH_ENROLLMENT,
          //     enrollmentID,
          //   ]);
          // }

          onSuccess?.(_.omit(data, ["session", "error"]));
        },
      }
    ),
  };
}

export function useCatalog() {
  const storedValueQuery = useLocalForage(REACT_QUERY_KEYS.CATALOG);

  return {
    storedValueQuery,
    ...useQuery(
      REACT_QUERY_KEYS.CATALOG,
      async () => {
        const data = await API.CatalogCurriculumPublication.get({});

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        staleTime: timeInMS({ timeQuantity: 2, timeType: "hours" }),
        onSuccess: (data) => {
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.CATALOG),
            _.omit(data, ["session", "error"])
          );
        },
      }
    ),
  };
}

export function useTaskStructure({
  taskID,
  rqOpts,
  onError,
  onSuccess,
}: {
  taskID: number;
  rqOpts?: any;
  onError?: (errorData: any) => void;
  onSuccess?: (data: Endpoints.Responses.Task.Structure.Get) => void;
}) {
  console.log(
    `%c Should be loading TaskStructure with ID ${taskID}`,
    "background-color: green; color: #ececec;"
  );
  const storedValueQuery = useLocalForage(REACT_QUERY_KEYS.TASK_STRUCTURE, [
    taskID,
  ]);

  return {
    storedValueQuery,
    ...useQuery(
      `${REACT_QUERY_KEYS.TASK_STRUCTURE}/${taskID}`,
      async () => {
        const data = await API.Task.Structure.get({ taskID });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        ...(rqOpts || {}),
        onError: (err: any) => {
          if (onError) {
            onError(err);

            // TODO Auto enroll user is err.sts === -301
          }
        },
        onSuccess: (data) => {
          // debugger;
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.TASK_STRUCTURE, [taskID]),
            _.omit(data, ["session", "error"])
          );

          if (onSuccess) {
            onSuccess(data);
          }
        },
      }
    ),
  };
}

export function useCourse({
  blockID,
  onSuccess,
}: {
  blockID: number;
  onSuccess?: (data: Endpoints.Responses.Block.Get) => void;
}) {
  const storedValueQuery = useLocalForage(REACT_QUERY_KEYS.BLOCK, [blockID]);

  return {
    storedValueQuery,
    ...useQuery(
      [REACT_QUERY_KEYS.BLOCK, blockID],
      async () => {
        const data = await API.Block.get({ blockID });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        onSuccess: (data) => {
          // debugger;
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.BLOCK, [blockID]),
            _.omit(data, ["session", "error"])
          );

          onSuccess?.(data);
        },
      }
    ),
  };
}

// * Not a hook, but putting it here
export async function enrollUser({
  blockID,
  memberID: memID,
  onSuccess,
  onError,
}: {
  blockID: number;
  memberID?: number;
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  try {
    const memberID = memID || SessionHandling.getUserIDOfActiveSession();

    if (!memberID) {
      onError("UserID not found.");
      debugger;
      return;
    }

    const resp = await API.Block.Enrollment.post({ blockID, memberID });

    if (!resp.registrationCount) {
      onError(
        "Something wetn awry; successful request suggests not enrollment records exist, still."
      );
      debugger;
      return;
    }

    onSuccess();
  } catch (err: any) {
    onError(
      typeof err === "string" ? err : _.get(err, "error.msg", "Error Occurred")
    );
  }
}

// * Not a hook, but putting it here next to hook below it, since it is a non-hook functionally equivalent
// * method for exams, which themselves automatically update status to ocCOMPLETE
export function triggerEnrollmentReload({
  enrollmentID,
  blockID,
  memberID,
  onSuccess,
  onError,
}: {
  blockID: number;
  memberID?: number;
  enrollmentID: number;
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  if (!blockID || !enrollmentID) {
    return;
  }

  try {
    const memID = memberID || SessionHandling.getUserIDOfActiveSession();

    queryClient.invalidateQueries([
      REACT_QUERY_KEYS.PREREQUISITE_ENROLLMENTS,
      blockID,
      memID,
    ]);

    onSuccess();
  } catch (err) {
    const msg = _.get(err, "msg", "Error occurred.");

    onError(`${msg}`);
  }
}

// * Not a hook, but putting it here next to hook below it, since it is a non-hook functionally equivalent
// * method for exams, which themselves automatically update status to ocCOMPLETE
export function triggerTaskStructureReload({
  rootTaskID,
  onSuccess,
  onError,
}: {
  rootTaskID?: number;
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  if (!rootTaskID) {
    return;
  }

  try {
    queryClient.invalidateQueries(
      `${REACT_QUERY_KEYS.TASK_STRUCTURE}/${rootTaskID}`
    );

    onSuccess();
  } catch (err) {
    const msg = _.get(err, "msg", "Error occurred.");

    onError(`${msg}`);
  }
}

export function useEnrollmentMutation({
  enrollmentID,
  blockID,
  memberID,
  showContent,
  onSuccess,
  onError,
}: {
  blockID: number;
  memberID?: number;
  enrollmentID: number;
  showContent?: boolean;
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  // * This still needs handles for onSuccess mutating cached GET response info
  return useMutation(
    (updateData: Partial<Endpoints.Tyto.Launch.Enrollment.PutParameters>) => {
      debugger;
      return API.LaunchEnrollment.put({ enrollmentID, ...updateData });
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: (result, variables, context) => {
        /**
         * NOTE:
         * This is an absurdly long and complex method, doing a lot of things. It...
         * [1] First finds and updates the prerequisite for the locally cached item/list,
         * [2] Then checks if this is a prerequisite in a subBlock,
         * [3 (Case 1)] Updates the SubBlock in the 'subBlocks' list *if* it is,
         * [4 (Case 1)] If that subBlock is now complete, it finds any parents and updates them as well
         * [5 (Case 2)] If it is *not* a 'subBlock', it looks for the item in the 'training' list and updates that item
         */

        const memID = memberID || SessionHandling.getUserIDOfActiveSession();

        const enrID = variables.enrollmentID || enrollmentID;
        const showContentKey = `showContent=${
          !!showContent ? "true" : "false"
        }`;

        // * [1] - Update ReactQuery cache to mark enrollment completStatus and appropriately reflect what just happened server-side
        const currentPreReqEnrollmentsQuery = queryClient.getQueryState([
          REACT_QUERY_KEYS.PREREQUISITE_ENROLLMENTS,
          blockID,
          memID,
          showContentKey,
        ]);

        const currentPreReqEnrollmentsValue = _.get(
          currentPreReqEnrollmentsQuery,
          "data"
        ) as Endpoints.Responses.PrerequisiteEnrollments.Get;

        if (!currentPreReqEnrollmentsQuery || !currentPreReqEnrollmentsValue) {
          // TODO: Handle weird scenario where such is undefined?
          debugger;
          onSuccess();
          return;
        }

        // * Cycle through prereqEnrollments for course and update one with matching enrollmentID
        const newPreReqEnrollmentsValue = _.update(
          { ...currentPreReqEnrollmentsValue },
          "prerequisiteEnrollments",
          (prereqEnrollments: TytoData.Blocks.PrerequisiteEnrollment[]) => {
            return prereqEnrollments.map((prereqEnrollment) => {
              // * If this is the matching prereqEnrollment, update the 'prereqEnrollment.enrollment' object with the updates
              if (
                _.get(prereqEnrollment, "enrollment.enrollmentID", 0) === enrID
              ) {
                return _.update(
                  { ...prereqEnrollment },
                  "enrollment",
                  (innerEnrollmentObj) => {
                    return {
                      ...innerEnrollmentObj,
                      ..._.pick(variables, ["bookMark", "completeStatus"]), // * Pull out whitelisted variables from PUT call (don't want to include things like 'sessionKey'!)
                    };
                  }
                );
              }

              // * Not the matching prereqEnrollment; just return it as it is, untouched.
              return prereqEnrollment;
            });
          }
        );

        // * Update the cached ReacyQuery data with what we just updated locally
        queryClient.setQueryData(
          [
            REACT_QUERY_KEYS.PREREQUISITE_ENROLLMENTS,
            blockID,
            memID,
            showContentKey,
          ],
          newPreReqEnrollmentsValue
        );

        // * If all prereqs are updated now, create variable to update training list item appropriately
        const allRequiredStepsAreNowComplete = (
          (newPreReqEnrollmentsValue?.prerequisiteEnrollments as TytoData.Blocks.PrerequisiteEnrollment[]) ||
          []
        ).every(
          (prereqEnrollment) =>
            prereqEnrollment.completeStatus === "ocCOMPLETE" ||
            prereqEnrollment.enrollment?.completeStatus === "ocCOMPLETE" ||
            !!prereqEnrollment.isOptional
        );

        // * If all required steps are now complete, then invalidate taskStructure

        // ? [2] - Update LocalForage stored query appropriately. NOT SURE IF onSuccess GETS CALLED IN ORIGINAL QUERY AFTER UPDATE
        // TODO

        // * If update was NOT setting the prereqEnrollment to complete, then we don't need to update the training collection
        // * since the 'subTaskCompleteCount' doesn't need to be increased
        if (variables.completeStatus !== "ocCOMPLETE") {
          onSuccess();
          return;
        }

        // * [3] - If new completeStatus is ocCOMPLETE, update the block inside Training appropriately
        const currentTrainingQuery = queryClient.getQueryState(
          REACT_QUERY_KEYS.TRAINING
        );

        let currentTrainingValue = _.get(
          currentTrainingQuery,
          "data"
        ) as Endpoints.Responses.Training.Get;

        if (!currentTrainingValue || !currentTrainingValue) {
          // TODO: Handle weird scenario where training query doesn't exist or isn't found??
          debugger;
          onSuccess();
          return;
        }

        // * Determine if this is a subCourse and appropriately update data
        const { isSubBlock } = findIfCourseIsPrimaryOrSubBlock({
          curriculumID: blockID,
          primaryTraining: currentTrainingValue.training,
          subBlocks: currentTrainingValue.subBlocks,
        });

        debugger;
        // * Case 1: This is a SubCourse
        if (isSubBlock) {
          const targetSubBlock = currentTrainingValue?.subBlocks?.find(
            (subBlock) => subBlock.curriculumID === blockID
          );
          // let newSubCourseCompleteStatus =
          //   subCourse?.completeStatus === "ocNOTSTARTED" ||
          //   subCourse?.completeStatus === "ocNOTATTEMPTED"
          //     ? "ocINCOMPLETE"
          //     : subCourse?.completeStatus;

          // if (allRequiredStepsAreNowComplete) {
          //   newSubCourseCompleteStatus = "ocCOMPLETE";
          // }

          // debugger;
          // const newTrainingValueWithMutatedSubBlocks =
          //   isSubCourse && subCourse
          //     ? _.set(
          //         { ...currentTrainingValue },
          //         `subBlocks[${subCourseIdx}]`,
          //         {
          //           ...subCourse,
          //           completeStatus: newSubCourseCompleteStatus,
          //           subTaskCompleteCount:
          //             (subCourse?.subTaskCompleteCount ?? 0) + 1, // ! Spooky; could reset to '1' locally
          //         }
          //       )
          //     : currentTrainingValue;

          if (allRequiredStepsAreNowComplete && targetSubBlock) {
            // * This SubCourse just became complete, which means we need to mutated it's parent courses/plans as well
            const parentIDsSet = new Set([
              ...(targetSubBlock.parentTasks?.map(
                (parentTask) => parentTask.rootTaskID
              ) ?? []),
              ...(targetSubBlock.parentBlocks?.map(
                (parentBlock) => parentBlock.blockID
              ) ?? []),
            ]);

            const newTrainingValue = _.update(
              { ...currentTrainingValue },
              "training",
              (training) => {
                return training.map(
                  (
                    trainingCourseOrDevPlan:
                      | TytoData.Training.Enrollment
                      | TytoData.Training.Task
                  ) => {
                    // * If this item matches an ID of the course's parents, update it approriately.
                    if (
                      parentIDsSet.has(
                        _.get(trainingCourseOrDevPlan, "curriculumID", 0)
                      )
                    ) {
                      let newCompleteStatus =
                        trainingCourseOrDevPlan.completeStatus ===
                          "ocNOTSTARTED" ||
                        trainingCourseOrDevPlan.completeStatus ===
                          "ocNOTATTEMPTED"
                          ? "ocINCOMPLETE"
                          : trainingCourseOrDevPlan.completeStatus;

                      // if (allRequiredStepsAreNowComplete) {
                      //   newCompleteStatus = "ocCOMPLETE";
                      // }

                      const subTaskCompleteCount =
                        trainingCourseOrDevPlan.subTaskCompleteCount + 1;

                      if (
                        trainingCourseOrDevPlan.subTaskCount &&
                        subTaskCompleteCount ===
                          trainingCourseOrDevPlan.subTaskCount
                      ) {
                        newCompleteStatus = "ocCOMPLETE";
                      }

                      return {
                        ...trainingCourseOrDevPlan,
                        completeStatus: newCompleteStatus,
                        subTaskCompleteCount: subTaskCompleteCount,
                      };
                    }

                    // * Does not match course we just updated, return as is.
                    return trainingCourseOrDevPlan;
                  }
                );
              }
            );

            // * Update with any/all updated parents as well
            // queryClient.setQueryData(
            //   REACT_QUERY_KEYS.TRAINING,
            //   newTrainingValue
            // );

            currentTrainingValue = newTrainingValue;
          }

          const newTrainingValue = _.update(
            { ...currentTrainingValue },
            "subBlocks",
            (subBlocks) => {
              return subBlocks.map((subBlock: TytoData.Training.SubBlock) => {
                // * If this item matches the blockID of the course whose enrollment we just updated, update it approriately
                if (_.get(subBlock, "curriculumID", 0) === blockID) {
                  let newCompleteStatus =
                    subBlock.completeStatus === "ocNOTSTARTED" ||
                    subBlock.completeStatus === "ocNOTATTEMPTED"
                      ? "ocINCOMPLETE"
                      : subBlock.completeStatus;

                  if (allRequiredStepsAreNowComplete) {
                    newCompleteStatus = "ocCOMPLETE";
                  }

                  return {
                    ...subBlock,
                    completeStatus: newCompleteStatus,
                    subTaskCompleteCount: subBlock.subTaskCompleteCount + 1,
                  };
                }

                // * Does not match course we just updated, return as is.
                return subBlock;
              });
            }
          );

          debugger;

          // * Once again, update the cached ReacyQuery data with what we just updated locally
          queryClient.setQueryData(REACT_QUERY_KEYS.TRAINING, newTrainingValue);
        } else {
          // * Case 2: This is a Primary Course (in the 'training' Array, not 'subBlocks' Array)
          const newTrainingValue = _.update(
            { ...currentTrainingValue },
            "training",
            (training) => {
              return training.map(
                (
                  trainingCourseOrDevPlan:
                    | TytoData.Training.Enrollment
                    | TytoData.Training.Task
                ) => {
                  // * If this item matches the blockID of the course whose enrollment we just updated, update it approriately
                  if (
                    _.get(trainingCourseOrDevPlan, "curriculumID", 0) ===
                    blockID
                  ) {
                    let newCompleteStatus =
                      trainingCourseOrDevPlan.completeStatus ===
                        "ocNOTSTARTED" ||
                      trainingCourseOrDevPlan.completeStatus ===
                        "ocNOTATTEMPTED"
                        ? "ocINCOMPLETE"
                        : trainingCourseOrDevPlan.completeStatus;

                    if (allRequiredStepsAreNowComplete) {
                      newCompleteStatus = "ocCOMPLETE";
                    }

                    return {
                      ...trainingCourseOrDevPlan,
                      completeStatus: newCompleteStatus,
                      subTaskCompleteCount:
                        trainingCourseOrDevPlan.subTaskCompleteCount + 1,
                    };
                    // return _.update(
                    //   { ...trainingCourseOrDevPlan },
                    //   "subTaskCompleteCount",
                    //   (currentSubTaskCompleteCountValue) =>
                    //     typeof currentSubTaskCompleteCountValue === "number"
                    //       ? currentSubTaskCompleteCountValue + 1
                    //       : 1
                    // );
                  }

                  // * Does not match course we just updated, return as is.
                  return trainingCourseOrDevPlan;
                }
              );
            }
          );

          debugger;

          // * Once again, update the cached ReacyQuery data with what we just updated locally
          queryClient.setQueryData(REACT_QUERY_KEYS.TRAINING, newTrainingValue);
        }

        // // * Once again, update the cached ReacyQuery data with what we just updated locally
        // queryClient.setQueryData(REACT_QUERY_KEYS.TRAINING, newTrainingValue);

        // ? [4] - Update LocalForage appropriately for Training. NOT SURE IF onSuccess GETS CALLED IN ORIGINAL QUERY AFTER UPDATE
        // TODO

        onSuccess();
      },
    }
  );
}

export function useEnrollmentAssignmentMutation({
  enrollmentID,
  lessonID,
  blockID,
  memberID,
  showContent,
  onSuccess,
  onError,
}: {
  enrollmentID: number;
  lessonID: number;
  memberID?: number;
  blockID: number;
  showContent: boolean;
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  // * This still needs handles for onSuccess mutating cached GET response info
  return useMutation(
    (
      updateData: Partial<Endpoints.Tyto.Enrollment.VerificationRequest.PutParameters>
    ) => {
      debugger;
      return API.Enrollment.VerificationRequest.put({
        enrollmentID,
        ...updateData,
      });
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: async (result, variables, context) => {
        const showContentKey = `showContent=${
          !!showContent ? "true" : "false"
        }`;

        try {
          const memID = memberID || SessionHandling.getUserIDOfActiveSession();
          const bID = blockID;
          console.log("bID: ", bID);
          // const stepTaskID = variables.taskID || taskID;
          debugger;

          // * [0] - Invalidate /Launch/Enrollment data so it can start reloading
          console.log("variables: ", variables);
          if (!!variables.commentAboutIDs) {
            queryClient.invalidateQueries([
              REACT_QUERY_KEYS.LAUNCH_ENROLLMENT,
              enrollmentID,
            ]);
          }

          // * [1] - Update ReactQuery cache to mark enrollment completStatus and appropriately reflect what just happened server-side
          const currentPreReqEnrollmentsQuery = queryClient.getQueryState([
            REACT_QUERY_KEYS.PREREQUISITE_ENROLLMENTS,
            blockID,
            memID,
            showContentKey,
          ]);

          const currentPreReqEnrollmentsValue = _.get(
            currentPreReqEnrollmentsQuery,
            "data"
          ) as Endpoints.Responses.PrerequisiteEnrollments.Get;

          if (
            !currentPreReqEnrollmentsQuery ||
            !currentPreReqEnrollmentsValue
          ) {
            // TODO: Handle weird scenario where such is undefined?
            debugger;
            onSuccess();
            return;
          }

          // * Cycle through prereqEnrollments for course and update one with matching enrollmentID
          const prereqEnrollmentsResp = await API.PrerequisiteEnrollments.get({
            memberID: memID,
            blockID: bID,
            showContent: true,
          });

          // * Update the cached ReacyQuery data with what we just updated locally
          queryClient.setQueryData(
            [
              REACT_QUERY_KEYS.PREREQUISITE_ENROLLMENTS,
              blockID,
              memID,
              showContentKey,
            ],
            prereqEnrollmentsResp
          );

          // * [3] - If new completeStatus is ocCOMPLETE, update the block inside Training appropriately
          const currentTrainingQuery = queryClient.getQueryState(
            REACT_QUERY_KEYS.TRAINING
          );

          const currentTrainingValue = _.get(
            currentTrainingQuery,
            "data"
          ) as Endpoints.Responses.Training.Get;

          if (!currentTrainingValue || !currentTrainingValue) {
            // TODO: Handle weird scenario where training query doesn't exist or isn't found??
            debugger;
            onSuccess();
            return;
          }

          const { isPrimaryTraining, isSubBlock } =
            findIfCourseIsPrimaryOrSubBlock({
              curriculumID: blockID,
              primaryTraining: currentTrainingValue.training,
              subBlocks: currentTrainingValue.subBlocks,
            });

          if (isPrimaryTraining) {
            const newTrainingValue = _.update(
              { ...currentTrainingValue },
              "training",
              (training) => {
                return training.map(
                  (
                    trainingCourseOrDevPlan:
                      | TytoData.Training.Enrollment
                      | TytoData.Training.Task
                  ) => {
                    // * If this item matches the blockID of the course whose enrollment we just updated, update it approriately
                    if (
                      _.get(trainingCourseOrDevPlan, "curriculumID", 0) ===
                      blockID
                    ) {
                      const newCompleteStatus =
                        trainingCourseOrDevPlan.completeStatus ===
                          "ocNOTSTARTED" ||
                        trainingCourseOrDevPlan.completeStatus ===
                          "ocNOTATTEMPTED"
                          ? "ocINCOMPLETE"
                          : trainingCourseOrDevPlan.completeStatus;

                      return {
                        ...trainingCourseOrDevPlan,
                        completeStatus: newCompleteStatus,
                        subTaskCompleteCount:
                          trainingCourseOrDevPlan.subTaskCompleteCount + 1,
                      };
                      // return _.update(
                      //   { ...trainingCourseOrDevPlan },
                      //   "subTaskCompleteCount",
                      //   (currentSubTaskCompleteCountValue) =>
                      //     typeof currentSubTaskCompleteCountValue === "number"
                      //       ? currentSubTaskCompleteCountValue + 1
                      //       : 1
                      // );
                    }

                    // * Does not match course we just updated, return as is.
                    return trainingCourseOrDevPlan;
                  }
                );
              }
            );

            // * Once again, update the cached ReacyQuery data with what we just up
            queryClient.setQueryData(
              REACT_QUERY_KEYS.TRAINING,
              newTrainingValue
            );
          } else if (isSubBlock) {
            const newTrainingValueWithUpdatedSubBlocks = _.update(
              { ...currentTrainingValue },
              "subBlocks",
              (subBlocks) => {
                return subBlocks.map((subBlock: TytoData.Training.SubBlock) => {
                  // * If this item matches the blockID of the course whose enrollment we just updated, update it approriately
                  if (_.get(subBlock, "curriculumID", 0) === blockID) {
                    const newCompleteStatus =
                      subBlock.completeStatus === "ocNOTSTARTED" ||
                      subBlock.completeStatus === "ocNOTATTEMPTED"
                        ? "ocINCOMPLETE"
                        : subBlock.completeStatus;

                    return {
                      ...subBlock,
                      completeStatus: newCompleteStatus,
                      subTaskCompleteCount: subBlock.subTaskCompleteCount + 1,
                    };
                  }

                  // * Does not match course we just updated, return as is.
                  return subBlock;
                });
              }
            );

            // * Once again, update the cached ReacyQuery data with what we just up
            queryClient.setQueryData(
              REACT_QUERY_KEYS.TRAINING,
              newTrainingValueWithUpdatedSubBlocks
            );
          }

          // ? [4] - Update LocalForage appropriately for Training. NOT SURE IF onSuccess GETS CALLED IN ORIGINAL QUERY AFTER UPDATE
          // TODO

          onSuccess();
        } catch (err) {
          const errMsg = typeof err === "string" ? err : JSON.stringify(err);

          onError?.(`${errMsg}`);
        }
      },
    }
  );
}

export function useTaskAssignmentMutation({
  taskID,
  rootTaskID,
  onSuccess,
  onError,
}: {
  rootTaskID: number;
  taskID: number;
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  // * This still needs handles for onSuccess mutating cached GET response info
  return useMutation(
    (
      updateData: Partial<Endpoints.Tyto.Task.VerificationRequest.PutParameters>
    ) => {
      debugger;
      return API.Task.VerificationRequest.put({ taskID, ...updateData });
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: async (result, variables, context) => {
        try {
          const rTaskID = rootTaskID;
          console.log("rTaskID: ", rTaskID);
          // const stepTaskID = variables.taskID || taskID;
          debugger;

          // * [1] - Update ReactQuery cache to mark enrollment completStatus and appropriately reflect what just happened server-side
          const currentTaskStructureData = queryClient.getQueryState(
            `${REACT_QUERY_KEYS.TASK_STRUCTURE}/${rTaskID}`
          );

          const currentTaskStructureValue = _.get(
            currentTaskStructureData,
            "data"
          ) as Endpoints.Responses.Task.Structure.Get;

          console.log("currentTaskStructureData: ", currentTaskStructureData);
          console.log("currentTaskStructureValue: ", currentTaskStructureValue);

          if (!currentTaskStructureData || !currentTaskStructureValue) {
            // TODO: Handle weird scenario where such is undefined?
            debugger;
            onSuccess();
            return;
          }

          // * Cycle through prereqEnrollments for course and update one with matching enrollmentID
          const taskResp = await API.Task.Structure.get({
            taskID: rTaskID,
          });

          // * Update the cached ReacyQuery data with what we just updated locally
          queryClient.setQueryData(
            `${REACT_QUERY_KEYS.TASK_STRUCTURE}/${rTaskID}`,
            taskResp
          );

          // * [3] - If new completeStatus is ocCOMPLETE, update the block inside Training appropriately
          const currentTrainingQuery = queryClient.getQueryState(
            REACT_QUERY_KEYS.TRAINING
          );

          const currentTrainingValue = _.get(
            currentTrainingQuery,
            "data"
          ) as Endpoints.Responses.Training.Get;

          if (!currentTrainingValue || !currentTrainingValue) {
            // TODO: Handle weird scenario where training query doesn't exist or isn't found??
            debugger;
            onSuccess();
            return;
          }

          const newTrainingValue = _.update(
            { ...currentTrainingValue },
            "training",
            (training) => {
              return training.map(
                (
                  trainingCourseOrDevPlan:
                    | TytoData.Training.Enrollment
                    | TytoData.Training.Task
                ) => {
                  // * If this item matches the blockID of the course whose enrollment we just updated, update it approriately
                  if (_.get(trainingCourseOrDevPlan, "taskID", 0) === rTaskID) {
                    const newCompleteStatus =
                      taskResp?.task.tasks[0]?.taskStatus ||
                      trainingCourseOrDevPlan.completeStatus;
                    const rootTaskCompleteChildrenCount = (
                      taskResp?.task?.tasks || []
                    ).reduce((accum, task) => {
                      if (task.aboutType !== "ocDEVPLAN") {
                        if (task.taskStatus === "ocCOMPLETE") {
                          accum += 1;
                        }
                      }

                      return accum;
                    }, 0);

                    return {
                      ...trainingCourseOrDevPlan,
                      completeStatus: newCompleteStatus,
                      subTaskCompleteCount: Math.max(
                        rootTaskCompleteChildrenCount,
                        trainingCourseOrDevPlan.subTaskCompleteCount
                      ),
                    };
                  }

                  // * Does not match course we just updated, return as is.
                  return trainingCourseOrDevPlan;
                }
              );
            }
          );

          // * Once again, update the cached ReacyQuery data with what we just updated locally
          queryClient.setQueryData(REACT_QUERY_KEYS.TRAINING, newTrainingValue);

          // ? [4] - Update LocalForage appropriately for Training. NOT SURE IF onSuccess GETS CALLED IN ORIGINAL QUERY AFTER UPDATE
          // TODO

          onSuccess();
        } catch (err) {
          const errMsg = typeof err === "string" ? err : JSON.stringify(err);

          onError?.(`${errMsg}`);
        }
      },
    }
  );
}

export function useTaskMutation({
  taskID,
  rootTaskID,
  onSuccess,
  onError,
}: {
  rootTaskID: number;
  taskID: number;
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  // * This still needs handles for onSuccess mutating cached GET response info
  return useMutation(
    (updateData: Partial<Endpoints.Tyto.Tasks.PutParameters>) => {
      debugger;
      return API.Tasks.put({ taskID, ...updateData });
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: async (result, variables, context) => {
        try {
          const rTaskID = rootTaskID;
          console.log("rTaskID: ", rTaskID);
          // const stepTaskID = variables.taskID || taskID;
          debugger;

          // * [1] - Update ReactQuery cache to mark enrollment completStatus and appropriately reflect what just happened server-side
          const currentTaskStructureData = queryClient.getQueryState(
            `${REACT_QUERY_KEYS.TASK_STRUCTURE}/${rTaskID}`
          );

          const currentTaskStructureValue = _.get(
            currentTaskStructureData,
            "data"
          ) as Endpoints.Responses.Task.Structure.Get;

          console.log("currentTaskStructureData: ", currentTaskStructureData);
          console.log("currentTaskStructureValue: ", currentTaskStructureValue);

          if (!currentTaskStructureData || !currentTaskStructureValue) {
            // TODO: Handle weird scenario where such is undefined?
            debugger;
            onSuccess();
            return;
          }

          // * Cycle through prereqEnrollments for course and update one with matching enrollmentID
          const taskResp = await API.Task.Structure.get({
            taskID: rTaskID,
          });

          // * Update the cached ReacyQuery data with what we just updated locally
          queryClient.setQueryData(
            `${REACT_QUERY_KEYS.TASK_STRUCTURE}/${rTaskID}`,
            taskResp
          );

          // * [3] - If new completeStatus is ocCOMPLETE, update the block inside Training appropriately
          const currentTrainingQuery = queryClient.getQueryState(
            REACT_QUERY_KEYS.TRAINING
          );

          const currentTrainingValue = _.get(
            currentTrainingQuery,
            "data"
          ) as Endpoints.Responses.Training.Get;

          if (!currentTrainingValue || !currentTrainingValue) {
            // TODO: Handle weird scenario where training query doesn't exist or isn't found??
            debugger;
            onSuccess();
            return;
          }

          const newTrainingValue = _.update(
            { ...currentTrainingValue },
            "training",
            (training) => {
              return training.map(
                (
                  trainingCourseOrDevPlan:
                    | TytoData.Training.Enrollment
                    | TytoData.Training.Task
                ) => {
                  // * If this item matches the blockID of the course whose enrollment we just updated, update it approriately
                  if (_.get(trainingCourseOrDevPlan, "taskID", 0) === rTaskID) {
                    const newCompleteStatus =
                      taskResp?.task.tasks[0]?.taskStatus ||
                      trainingCourseOrDevPlan.completeStatus;
                    const rootTaskCompleteChildrenCount = (
                      taskResp?.task?.tasks || []
                    ).reduce((accum, task) => {
                      if (task.aboutType !== "ocDEVPLAN") {
                        if (task.taskStatus === "ocCOMPLETE") {
                          accum += 1;
                        }
                      }

                      return accum;
                    }, 0);

                    return {
                      ...trainingCourseOrDevPlan,
                      completeStatus: newCompleteStatus,
                      subTaskCompleteCount: Math.max(
                        rootTaskCompleteChildrenCount,
                        trainingCourseOrDevPlan.subTaskCompleteCount
                      ),
                    };
                  }

                  // * Does not match course we just updated, return as is.
                  return trainingCourseOrDevPlan;
                }
              );
            }
          );

          // * Once again, update the cached ReacyQuery data with what we just updated locally
          queryClient.setQueryData(REACT_QUERY_KEYS.TRAINING, newTrainingValue);

          // ? [4] - Update LocalForage appropriately for Training. NOT SURE IF onSuccess GETS CALLED IN ORIGINAL QUERY AFTER UPDATE
          // TODO

          onSuccess();
        } catch (err) {
          const errMsg = typeof err === "string" ? err : JSON.stringify(err);

          onError?.(`${errMsg}`);
        }
      },
    }
  );
}

export function useLesson({
  lessonID,
  isEnabled = true,
}: {
  lessonID: number;
  isEnabled?: boolean;
}) {
  const storedValueQuery = useLocalForage<Endpoints.Responses.Lesson.Get>(
    REACT_QUERY_KEYS.LESSON,
    [lessonID]
  );
  const query = useQuery(
    [REACT_QUERY_KEYS.LESSON, lessonID],
    async () => {
      const data = await API.Lesson.get({ lessonID });

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      enabled: !!isEnabled,
      onSuccess: (data) => {
        // debugger;
        LF.setItem(
          makeLocalForageKey(REACT_QUERY_KEYS.LESSON, [lessonID]),
          _.omit(data, ["session", "error"])
        );
      },
    }
  );

  return {
    storedValueQuery,
    ...query,
    eagerData: query.data ?? storedValueQuery?.data,
  };
}

export function useDISCVendorForSearch({
  isEnabled = true,
}: {
  isEnabled?: boolean;
}) {
  const query = useQuery(
    [REACT_QUERY_KEYS.DISC_VENDOR_FOR_SEARCH],
    async () => {
      const data = await API.DISC.Vendor.ForSearch.get({});

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      retry: 2,
      enabled: !!isEnabled,
      onSuccess: (data) => {
        return data;
      },
    }
  );

  return {
    ...query,
    eagerData: query.data,
  };
}

export function useDISCVendorForTake({
  isEnabled = true,
}: {
  isEnabled?: boolean;
}) {
  const query = useQuery(
    [REACT_QUERY_KEYS.DISC_VENDOR_FOR_TAKE],
    async () => {
      const data = await API.DISC.Vendor.ForTake.get({});

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      retry: 2,
      enabled: !!isEnabled,
      onSuccess: (data) => {
        return data;
      },
    }
  );

  return {
    ...query,
    eagerData: query.data,
  };
}

export function useFindAssessments247({
  domainID,
  userID,
  emailAddress = "",
  personName = "",
  isEnabled = true,
}: {
  userID: number;
  domainID?: number;
  emailAddress?: string;
  isEnabled?: boolean;
  personName?: string;
}) {
  const forSeach = useDISCVendorForSearch({});

  const apiTag = forSeach?.data?.discVendors[0]?.tryybApiTag ?? "";
  const [firstname, lastname] = personName.split(" ");

  const query = useQuery(
    [
      REACT_QUERY_KEYS.DISC_247_FIND_ASSESSMENTS,
      emailAddress,
      firstname,
      lastname,
      userID,
      domainID ?? 0,
      apiTag,
    ],
    async () => {
      const data = await API.DISC247.FindSelfAssessments.get({
        tryybPersonID: userID,
        email: emailAddress,
        firstname,
        lastname,
        tryybApiTag: apiTag,
      });

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      retry: 2,
      enabled: !!isEnabled && !!apiTag,
      onSuccess: (data) => {
        return data;
      },
    }
  );

  return {
    ...query,
    eagerData: query.data,
  };
}

export function useFindAssessmentsV2({
  domainID,
  emailAddress = "",
  isEnabled = true,
}: {
  domainID?: number;
  emailAddress?: string;
  isEnabled?: boolean;
}) {
  // const storedValueQuery = useLocalForage<Endpoints.Responses.Lesson.Get>(
  //   REACT_QUERY_KEYS.PEOPLE_KEYS_FIND_ASSESSMENTS_V4,
  //   [userID, alternateEmailAddress ?? "", domainID ?? 0]
  // );

  const query = useQuery(
    [
      REACT_QUERY_KEYS.PEOPLE_KEYS_FIND_ASSESSMENTS_V2,
      emailAddress,
      domainID ?? 0,
    ],
    async () => {
      const data = await API.PeopleKeys.FindAssessments.get({
        email: emailAddress,
        domainID,
      });

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      retry: 2,
      enabled: !!isEnabled,
      onSuccess: (data) => {
        return data;
      },
    }
  );

  return {
    ...query,
    eagerData: query.data,
  };
}

export function useFindAssessmentsV4({
  alternateEmailAddress,
  userID,
  isIncomplete,
  isEnabled = true,
}: {
  userID: number;
  alternateEmailAddress?: string;
  isIncomplete?: boolean;
  isEnabled?: boolean;
}) {
  // const storedValueQuery = useLocalForage<Endpoints.Responses.Lesson.Get>(
  //   REACT_QUERY_KEYS.PEOPLE_KEYS_FIND_ASSESSMENTS_V4,
  //   [userID, alternateEmailAddress ?? "", domainID ?? 0]
  // );

  const query = useQuery(
    [
      REACT_QUERY_KEYS.PEOPLE_KEYS_FIND_ASSESSMENTS_V4,
      userID,
      alternateEmailAddress ?? "",
      isIncomplete ? "YES" : "NO",
    ],
    async () => {
      const data = await API.PeopleKeys.FindAssessments.V4.get({
        tryybPersonID: userID,
        ...(alternateEmailAddress
          ? { alternateEmail: alternateEmailAddress }
          : {}),
        isIncomplete,
      });

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      retry: false,
      enabled: !!isEnabled,
      onSuccess: (data) => {
        return data;
      },
    }
  );

  return {
    ...query,
    eagerData: query.data,
  };
}

export function usePerson({
  userID,
  isEnabled = true,
}: {
  userID: number;
  isEnabled?: boolean;
}) {
  const storedValueQuery = useLocalForage<Endpoints.Responses.Person.Get>(
    REACT_QUERY_KEYS.PERSON,
    [userID]
  );

  return {
    storedValueQuery,
    ...useQuery(
      [REACT_QUERY_KEYS.PERSON, userID],
      async () => {
        const data = await API.Person.get({ personID: userID });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        enabled: !!isEnabled,
        onSuccess: (data) => {
          // debugger;
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.PERSON, [userID]),
            _.omit(data, ["session", "error"])
          );
        },
      }
    ),
  };
}

export function usePrerequisites({
  blockID,
  isEnabled = true,
}: {
  blockID: number;
  isEnabled?: boolean;
}) {
  const storedValueQuery = useLocalForage(
    REACT_QUERY_KEYS.BLOCK_PREREQUISITES,
    [blockID]
  );

  return {
    storedValueQuery,
    ...useQuery(
      [REACT_QUERY_KEYS.BLOCK_PREREQUISITES, blockID],
      async () => {
        const data = await API.Block.Prerequisites.get({ blockID });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        enabled: isEnabled,
        onSuccess: (data) => {
          // debugger;
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.BLOCK_PREREQUISITES, [blockID]),
            _.omit(data, ["session", "error"])
          );
        },
      }
    ),
  };
}

export function usePrerequisiteEnrollments({
  blockID,
  memberID,
  showContent,
  retry = false,
  isEnabled = true,
  onError,
  onLocalStorageSucess,
  onSuccess,
}: {
  blockID: number;
  isEnabled?: boolean;
  memberID: number;
  showContent: boolean;
  retry?: boolean | number;
  onError?: (err: any) => void;
  onLocalStorageSucess?: (
    data: Endpoints.Responses.PrerequisiteEnrollments.Get
  ) => void;
  onSuccess?: (data: Endpoints.Responses.PrerequisiteEnrollments.Get) => void;
}) {
  const showContentKey = `showContent=${!!showContent ? "true" : "false"}`;
  const storedValueQuery = useLocalForage(
    REACT_QUERY_KEYS.PREREQUISITE_ENROLLMENTS,
    [blockID, memberID, showContentKey],
    {
      onSuccess: onLocalStorageSucess,
    }
  );

  return {
    storedValueQuery,
    ...useQuery(
      [
        REACT_QUERY_KEYS.PREREQUISITE_ENROLLMENTS,
        blockID,
        memberID,
        showContentKey,
      ],
      async () => {
        const data = await API.PrerequisiteEnrollments.get({
          blockID,
          memberID,
          showContent: !!showContent,
        });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        enabled: isEnabled,
        onError,
        onSuccess: (data) => {
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.PREREQUISITE_ENROLLMENTS, [
              blockID,
              memberID,
              showContentKey,
            ]),
            _.omit(data, ["session", "error"])
          );

          if (onSuccess) {
            onSuccess(data);
          }
        },
        retry,
      }
    ),
  };
}

export function useLaunchEnrollment({
  enrollmentID,
  retry = false,
  isEnabled = true,
  onError,
  onSuccess,
}: {
  enrollmentID: number;
  retry?: boolean | number;
  isEnabled?: boolean;
  onError?: (err: any) => void;
  onSuccess?: (data: Endpoints.Responses.Launch.Enrollment.Get) => void;
}) {
  const storedValueQuery = useLocalForage(
    REACT_QUERY_KEYS.PREREQUISITE_ENROLLMENTS,
    [enrollmentID]
  );

  return {
    storedValueQuery,
    ...useQuery(
      [REACT_QUERY_KEYS.LAUNCH_ENROLLMENT, enrollmentID],
      async () => {
        const data = await API.LaunchEnrollment.get({
          enrollmentID,
        });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        enabled: isEnabled,
        onError,
        onSuccess: (data) => {
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.LAUNCH_ENROLLMENT, [
              enrollmentID,
            ]),
            _.omit(data, ["session", "error"])
          );

          if (onSuccess) {
            onSuccess(data);
          }
        },
        retry,
      }
    ),
  };
}

export function useTrending() {
  const storedValueQuery = useLocalForage(REACT_QUERY_KEYS.TRENDING);

  return {
    storedValueQuery,
    ...useQuery(
      REACT_QUERY_KEYS.TRENDING,
      async () => {
        const data = await API.CurriculumPubCatalogItemsTrending.get({});

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        staleTime: timeInMS({ timeQuantity: 1, timeType: "tomorrow-morning" }),
        onSuccess: (data) => {
          // debugger;
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.TRENDING),
            _.omit(data, ["session", "error"])
          );
        },
      }
    ),
  };
}

export function useTraining(opts?: {
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.Training.Get) => void;
}) {
  const storedValueQuery = useLocalForage(
    REACT_QUERY_KEYS.TRAINING,
    null,
    opts
  );

  return {
    storedValueQuery,
    ...useQuery(
      REACT_QUERY_KEYS.TRAINING,
      async () => {
        const data = await API.Training.get({});

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        onSuccess: (data) => {
          try {
            LF.setItem(
              makeLocalForageKey(REACT_QUERY_KEYS.TRAINING),
              _.omit(data, ["session", "error"])
            );
          } catch (err) {
            console.log("ERROR: ", err);
            debugger;
          }

          opts?.onSuccess?.(data);
        },
        onError: (data) => {
          const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
          console.log("errorSts: ", errorSts, " onError data: ", data);

          if (opts && opts.onError) {
            opts.onError(data as Data.TytoErrorObject);
          }
        },
      }
    ),
  };
}

export function useHomeLinkSrc() {
  const [homeLinkSrc] = React.useState(() => {
    const domainID = SessionHandling.getActiveSession()?.domainID ?? 0;

    return domainID
      ? `${IMAGE_BASE_URL}/v2/domains/${domainID}/images/home_link.png`
      : "";
  });

  return {
    data: {
      homeLinkSrc,
    },
  };
}

export function usePlanSubCourseCompletionCounts(
  innerCourse: TytoData.Tasks.Task,
  prerequisiteEnrollments?: TytoData.Training.Enrollment[]
) {
  const completionData = React.useMemo(() => {
    const {
      countallchildren = 0,
      countcompletechildren = 0,
      taskStatus,
    } = innerCourse;

    let total = countallchildren;
    let completed = countcompletechildren;

    // * If both counts are 0, attempt to figure out completion data a different way
    if (!countallchildren && !countcompletechildren) {
      // * If prerequisiteEnrollments is supplied, use such to determine counts manually
      if (prerequisiteEnrollments && prerequisiteEnrollments.length) {
        const manualCompletedTally = prerequisiteEnrollments.reduce(
          (accum, prereq) => {
            if (prereq.completeStatus === "ocCOMPLETE") {
              accum += 1;
            }

            return accum;
          },
          0
        );

        completed = manualCompletedTally;
        total = prerequisiteEnrollments.length;
      } else if (taskStatus === "ocCOMPLETE") {
        // * Prereqs were not supplied, so just check if taskStatus is complete
        total = 1;
        completed = 1;
      }
    }

    return {
      total,
      completed,
      progressDecimal: completed / total,
    };
  }, [innerCourse, prerequisiteEnrollments]);

  return completionData;
}

export function useTimezones(
  args?: Partial<Endpoints.Tyto.TimeZone.GetParameters>
) {
  const storedValueQuery = useLocalForage<Endpoints.Responses.TimeZones.Get>(
    REACT_QUERY_KEYS.TIMEZONES
  );

  return {
    storedValueQuery,
    ...useQuery(
      REACT_QUERY_KEYS.TIMEZONES,
      async () => {
        const data = await API.Timezones.get({ ...(args || {}) });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        staleTime: timeInMS({ timeQuantity: 4, timeType: "hours" }),
        onSuccess: (data) => {
          // debugger;
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.TRAINING),
            _.omit(data, ["session", "error"])
          );
        },
      }
    ),
  };
}

export function useAdvancedPersonSearch(opts: {
  searchTerm: string;
  extraOpts?: Partial<Endpoints.Tyto.PersonAdvanced.GetParameters>;
  isEnabled?: boolean;
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.Person.AdvancedSearched.Get) => void;
}) {
  // const storedValueQuery =
  //   useLocalForage<Endpoints.Responses.GS.Plan.Notices.Get>(
  //     REACT_QUERY_KEYS.ADVANCED_PERSON_SEARCH,
  //     [opts.searchTerm]
  //   );

  return {
    // storedValueQuery,
    ...useQuery(
      [
        REACT_QUERY_KEYS.ADVANCED_SEARCH_PERSON,
        opts.searchTerm,
        opts.extraOpts?.operation,
        opts.extraOpts?.functionName,
      ],
      async () => {
        const data = await API.PersonAdvanced.get({
          generalName: `%${opts.searchTerm.replace(/\s/i, "%")}%`,
          ...(opts.extraOpts ?? {}),
        });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        enabled: (opts?.isEnabled ?? true) && !!opts.searchTerm,
        onSuccess: (data) => {
          // try {
          //   LF.setItem(
          //     makeLocalForageKey(REACT_QUERY_KEYS.ADVANCED_PERSON_SEARCH, [
          //       opts.searchTerm,
          //     ]),
          //     _.omit(data, ["session", "error"])
          //   );
          // } catch (err) {
          //   console.log("ERROR: ", err);
          //   debugger;
          // }

          opts?.onSuccess?.(data);
        },
        onError: (data) => {
          const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
          console.log("errorSts: ", errorSts, " onError data: ", data);

          if (opts && opts.onError) {
            opts.onError(data as Data.TytoErrorObject);
          }
        },
      }
    ),
  };
}

// * ===================================
// * DISC RELATED HOOKS ================
export function useDISCMini({
  userID,
  retry = false,
  isEnabled = true,
  onError,
  onSuccess,
}: {
  userID: number;
  retry?: boolean | number;
  isEnabled?: boolean;
  onError?: (err: any) => void;
  onSuccess?: (data: Endpoints.Responses.DISCProfiles.Mini.Get) => void;
}) {
  const storedValueQuery =
    useLocalForage<Endpoints.Responses.DISCProfiles.Mini.Get>(
      REACT_QUERY_KEYS.DISC_MINI_USER,
      [userID]
    );
  const query = useQuery(
    [REACT_QUERY_KEYS.DISC_MINI_USER, userID],
    async () => {
      const data = await API.DISCProfilesMini.get({
        personIDs: `${userID}`,
      });

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      enabled: isEnabled,
      onError,
      onSuccess: (data) => {
        LF.setItem(
          makeLocalForageKey(REACT_QUERY_KEYS.DISC_MINI_USER, [userID]),
          _.omit(data, ["session", "error"])
        );

        if (onSuccess) {
          onSuccess(data);
        }
      },
      retry,
    }
  );

  return {
    storedValueQuery,
    ...query,
    eagerData: query.data ?? storedValueQuery?.data,
  };
}

// * Simple Wrapper around useDISCMini to always load own profile
export function usePersonalDISCMini(opts: {
  retry?: boolean | number;
  isEnabled?: boolean;
  onError?: (err: any) => void;
  onSuccess?: (data: Endpoints.Responses.DISCProfiles.Mini.Get) => void;
}) {
  const [loggedInUserID] = React.useState(
    SessionHandling.getActiveSession()?.userID ?? 0
  );

  return useDISCMini({
    ...opts,
    isEnabled: (opts.isEnabled ?? true) && !!loggedInUserID,
    userID: loggedInUserID,
  });
}

export function useDISCInteractive({
  userID,
  retry = false,
  isEnabled = true,
  onError,
  onSuccess,
}: {
  userID: number;
  retry?: boolean | number;
  isEnabled?: boolean;
  onError?: (err: any) => void;
  onSuccess?: (data: Endpoints.Responses.DISCProfile.Interactive.Get) => void;
}) {
  const storedValueQuery =
    useLocalForage<Endpoints.Responses.DISCProfile.Interactive.Get>(
      REACT_QUERY_KEYS.DISC_MINI_INTERACTIVE,
      [userID]
    );
  const query = useQuery(
    [REACT_QUERY_KEYS.DISC_MINI_INTERACTIVE, userID],
    async () => {
      const data = await API.DISCProfile.Interactive.get({
        personID: userID,
      });

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      enabled: isEnabled,
      onError,
      onSuccess: (data) => {
        LF.setItem(
          makeLocalForageKey(REACT_QUERY_KEYS.DISC_MINI_INTERACTIVE, [userID]),
          _.omit(data, ["session", "error"])
        );

        if (onSuccess) {
          onSuccess(data);
        }
      },
      retry,
    }
  );

  return {
    storedValueQuery,
    ...query,
    eagerData: query.data ?? storedValueQuery?.data,
  };
}

export function useDISCInteractiveMockup({
  userID,
  otherUserID,
  retry = false,
  isEnabled = true,
  onError,
  onSuccess,
}: {
  userID: number;
  otherUserID: number;
  retry?: boolean | number;
  isEnabled?: boolean;
  onError?: (err: any) => void;
  onSuccess?: (
    data: Endpoints.Responses.DISCProfile.Interactive.Mockup.Get
  ) => void;
}) {
  const storedValueQuery =
    useLocalForage<Endpoints.Responses.DISCProfile.Interactive.Mockup.Get>(
      REACT_QUERY_KEYS.DISC_MINI_INTERACTIVE_MOCKUP,
      [userID, otherUserID]
    );
  const query = useQuery(
    [REACT_QUERY_KEYS.DISC_MINI_INTERACTIVE_MOCKUP, userID, otherUserID],
    async () => {
      const data = await API.DISCProfile.Interactive.Mockup.get({
        personID: userID,
        interactPersonID: otherUserID,
      });

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      enabled: isEnabled,
      onError,
      onSuccess: (data) => {
        LF.setItem(
          makeLocalForageKey(REACT_QUERY_KEYS.DISC_MINI_INTERACTIVE_MOCKUP, [
            userID,
            otherUserID,
          ]),
          _.omit(data, ["session", "error"])
        );

        if (onSuccess) {
          onSuccess(data);
        }
      },
      retry,
    }
  );

  return {
    storedValueQuery,
    ...query,
    eagerData: query.data ?? storedValueQuery?.data,
  };
}

export function useTeamDISCMini({
  teamID,
  retry = false,
  isEnabled = true,
  onError,
  onSuccess,
}: {
  teamID: number;
  retry?: boolean | number;
  isEnabled?: boolean;
  onError?: (err: any) => void;
  onSuccess?: (data: Endpoints.Responses.DISCProfiles.Mini.Get) => void;
}) {
  const storedValueQuery =
    useLocalForage<Endpoints.Responses.DISCProfiles.Mini.Get>(
      REACT_QUERY_KEYS.DISC_MINI_TEAM,
      [teamID]
    );
  const query = useQuery(
    [REACT_QUERY_KEYS.DISC_MINI_TEAM, teamID],
    async () => {
      const data = await API.DISCProfilesMini.get({
        teamID,
      });

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      enabled: isEnabled,
      onError,
      onSuccess: (data) => {
        LF.setItem(
          makeLocalForageKey(REACT_QUERY_KEYS.DISC_MINI_TEAM, [teamID]),
          _.omit(data, ["session", "error"])
        );

        if (onSuccess) {
          onSuccess(data);
        }
      },
      retry,
    }
  );

  return {
    storedValueQuery,
    ...query,
    eagerData: query.data ?? storedValueQuery?.data,
  };
}

export function useTeamStyleReport({
  styleKey,
  retry = false,
  isEnabled = true,
  onError,
  onSuccess,
}: {
  styleKey: keyof typeof TytoData.DISCStyleKey;
  retry?: boolean | number;
  isEnabled?: boolean;
  onError?: (err: any) => void;
  onSuccess?: (data: Endpoints.Responses.DISCProfile.Team.Get) => void;
}) {
  const storedValueQuery =
    useLocalForage<Endpoints.Responses.DISCProfile.Team.Get>(
      REACT_QUERY_KEYS.DISC_PROFILE_TEAM,
      [styleKey]
    );
  const query = useQuery(
    [REACT_QUERY_KEYS.DISC_PROFILE_TEAM, styleKey],
    async () => {
      const data = await API.DISCProfile.Team.get({
        styleKey,
      });

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      enabled: isEnabled,
      onError,
      onSuccess: (data) => {
        LF.setItem(
          makeLocalForageKey(REACT_QUERY_KEYS.DISC_PROFILE_TEAM, [styleKey]),
          _.omit(data, ["session", "error"])
        );

        if (onSuccess) {
          onSuccess(data);
        }
      },
      retry,
    }
  );

  return {
    storedValueQuery,
    ...query,
    eagerData: query.data ?? storedValueQuery?.data,
  };
}

export function useFunctionsASP({
  command = "",
  functionNames = "Team Membership",
  isEnabled = true,
  onError,
  onSuccess,
}: {
  command?: string;
  functionNames?: keyof typeof Data.SecurityFunctionName;
  isEnabled?: boolean;
  onError?: (err: any) => void;
  onSuccess?: (data: Endpoints.Responses.FunctionsASP.Get) => void;
}) {
  const storedValueQuery = useLocalForage<Endpoints.Responses.FunctionsASP.Get>(
    REACT_QUERY_KEYS.FUNCTIONS_ASP,
    [command ?? "", functionNames ?? ""]
  );
  const query = useQuery(
    [REACT_QUERY_KEYS.FUNCTIONS_ASP, command ?? "", functionNames ?? ""],
    async () => {
      const data = await API.FunctionsASP.get({
        command,
        functionNames,
      });

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      enabled: isEnabled,
      onError,
      onSuccess: (data) => {
        LF.setItem(
          makeLocalForageKey(REACT_QUERY_KEYS.FUNCTIONS_ASP, [
            command ?? "",
            functionNames ?? "",
          ]),
          _.omit(data, ["session", "error"])
        );

        if (onSuccess) {
          onSuccess(data);
        }
      },
    }
  );

  return {
    storedValueQuery,
    ...query,
    eagerData: query.data ?? storedValueQuery?.data,
  };
}

export function useCanImportDISC(args: {
  isEnabled?: boolean;
  onError?: (err: any) => void;
  onSuccess?: (data: Endpoints.Responses.FunctionsASP.Get) => void;
}) {
  return useFunctionsASP({
    ...args,
    command: "getRolePermissions",
    functionNames: "Person TraitR3",
  });
}

export function useTeamsByFunction(opts: {
  extraOpts?: Partial<Endpoints.Tyto.TeamsByFunction.GetParameters>;
  isEnabled?: boolean;
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.TeamsByFunction.Get) => void;
}) {
  // const storedValueQuery =
  //   useLocalForage<Endpoints.Responses.GS.Plan.Notices.Get>(
  //     REACT_QUERY_KEYS.TEAMS_BY_FUNCTION
  //   );

  return {
    // storedValueQuery,
    ...useQuery(
      [REACT_QUERY_KEYS.TEAMS_BY_FUNCTION],
      async () => {
        const data = await API.TeamsByFunction.get({
          ...(opts.extraOpts ?? {}),
        });

        return data;
      },
      {
        ...DEFAULT_RQ_OPTS,
        enabled: opts?.isEnabled ?? true,
        onSuccess: (data) => {
          // try {
          //   LF.setItem(
          //     makeLocalForageKey(REACT_QUERY_KEYS.TEAMS_BY_FUNCTION),
          //     _.omit(data, ["session", "error"])
          //   );
          // } catch (err) {
          //   console.log("ERROR: ", err);
          //   debugger;
          // }
          opts.onSuccess?.(data);
        },
        onError: (data) => {
          const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
          console.log("errorSts: ", errorSts, " onError data: ", data);

          if (opts && opts.onError) {
            opts.onError(data as Data.TytoErrorObject);
          }
        },
      }
    ),
  };
}

export function usePeopleKeysInitialize(opts: {
  domainID: number;
  isEnabled?: boolean;
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.PeopleKeys.Initialize.Post) => void;
}) {
  // const storedValueQuery =
  //   useLocalForage<Endpoints.Responses.GS.Plan.Notices.Get>(
  //     REACT_QUERY_KEYS.TEAMS_BY_FUNCTION
  //   );
  const query = useQuery(
    [REACT_QUERY_KEYS.PEOPLE_KEYS_INITIALIZE, opts.domainID],
    async () => {
      const data = await API.PeopleKeys.Initialize.post({
        domainID: opts.domainID,
      });

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      enabled: opts?.isEnabled ?? true,
      onSuccess: (data) => {
        // try {
        //   LF.setItem(
        //     makeLocalForageKey(REACT_QUERY_KEYS.TEAMS_BY_FUNCTION),
        //     _.omit(data, ["session", "error"])
        //   );
        // } catch (err) {
        //   console.log("ERROR: ", err);
        //   debugger;
        // }
        opts.onSuccess?.(data);
      },
      onError: (data) => {
        const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
        console.log("errorSts: ", errorSts, " onError data: ", data);

        if (opts && opts.onError) {
          opts.onError(data as Data.TytoErrorObject);
        }
      },
    }
  );

  return {
    // storedValueQuery,
    ...query,
    eagerData: query.data,
  };
}

export function usePeopleKeysPages(opts: {
  isEnabled?: boolean;
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.PeopleKeys.Pages.Get) => void;
}) {
  // const storedValueQuery =
  //   useLocalForage<Endpoints.Responses.GS.Plan.Notices.Get>(
  //     REACT_QUERY_KEYS.TEAMS_BY_FUNCTION
  //   );
  const query = useQuery(
    [REACT_QUERY_KEYS.PEOPLE_KEYS_PAGES],
    async () => {
      const data = await API.PeopleKeys.Pages.get({});

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      enabled: opts?.isEnabled ?? true,
      onSuccess: (data) => {
        // try {
        //   LF.setItem(
        //     makeLocalForageKey(REACT_QUERY_KEYS.TEAMS_BY_FUNCTION),
        //     _.omit(data, ["session", "error"])
        //   );
        // } catch (err) {
        //   console.log("ERROR: ", err);
        //   debugger;
        // }
        opts.onSuccess?.(data);
      },
      onError: (data) => {
        const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
        console.log("errorSts: ", errorSts, " onError data: ", data);

        if (opts && opts.onError) {
          opts.onError(data as Data.TytoErrorObject);
        }
      },
    }
  );

  return {
    // storedValueQuery,
    ...query,
    eagerData: query.data,
  };
}

export function usePeopleKeysNextPage(opts: {
  resultID: number;
  isEnabled?: boolean;
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (data: Endpoints.Responses.PeopleKeys.NextPage.Get) => void;
}) {
  // const storedValueQuery =
  //   useLocalForage<Endpoints.Responses.GS.Plan.Notices.Get>(
  //     REACT_QUERY_KEYS.TEAMS_BY_FUNCTION
  //   );
  const query = useQuery(
    [REACT_QUERY_KEYS.PEOPLE_KEYS_NEXT_PAGE, opts.resultID],
    async () => {
      const data = await API.PeopleKeys.NextPage.get({
        resultID: opts.resultID,
      });

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      enabled: opts?.isEnabled ?? true,
      onSuccess: (data) => {
        // try {
        //   LF.setItem(
        //     makeLocalForageKey(REACT_QUERY_KEYS.TEAMS_BY_FUNCTION),
        //     _.omit(data, ["session", "error"])
        //   );
        // } catch (err) {
        //   console.log("ERROR: ", err);
        //   debugger;
        // }
        opts.onSuccess?.(data);
      },
      onError: (data) => {
        const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
        console.log("errorSts: ", errorSts, " onError data: ", data);

        if (opts && opts.onError) {
          opts.onError(data as Data.TytoErrorObject);
        }
      },
    }
  );

  return {
    // storedValueQuery,
    ...query,
    eagerData: query.data,
  };
}

export function usePersonCommTips(opts: {
  discID: number;
  isEnabled?: boolean;
  onError?: (data: Data.TytoErrorObject) => void;
  onSuccess?: (
    data: Endpoints.Responses.DISCProfile.Interactive.PersonCommTips.Get
  ) => void;
}) {
  const storedValueQuery =
    useLocalForage<Endpoints.Responses.DISCProfile.Interactive.PersonCommTips.Get>(
      REACT_QUERY_KEYS.PERSON_COMM_TIPS,
      [opts.discID]
    );

  const query = useQuery(
    [REACT_QUERY_KEYS.PERSON_COMM_TIPS, opts.discID],
    async () => {
      const data =
        await API.DISCProfile.Interactive.PersonCommunicationTips.get({
          discID: opts.discID,
        });

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      enabled: opts?.isEnabled ?? true,
      onSuccess: (data) => {
        try {
          LF.setItem(
            makeLocalForageKey(REACT_QUERY_KEYS.PERSON_COMM_TIPS, [
              opts.discID,
            ]),
            _.omit(data, ["session", "error"])
          );
        } catch (err) {
          console.log("ERROR: ", err);
          debugger;
        }
        opts.onSuccess?.(data);
      },
      onError: (data) => {
        const errorSts = _.get(data, "sts", _.get(data, "error.sts", 0));
        console.log("errorSts: ", errorSts, " onError data: ", data);

        if (opts && opts.onError) {
          opts.onError(data as Data.TytoErrorObject);
        }
      },
    }
  );

  return {
    storedValueQuery,
    ...query,
    eagerData: query.data ?? storedValueQuery?.data,
  };
}

export function usePeopleKeysResponseMutation({
  resultID,
  pageData,
  discID,
  onSuccess,
  onError,
}: {
  resultID: number;
  discID: number;
  pageData: TytoData.PeopleKeys.Page;
  onSuccess: (isFinalPage: boolean) => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  // * This still needs handles for onSuccess mutating cached GET response info
  return useMutation(
    (
      updateData: Pick<
        Endpoints.Tyto.PeopleKeys.Response.PostParameters,
        "pageID" | "response"
      >
    ) => {
      debugger;
      return API.PeopleKeys.Response.post({ resultID, ...updateData });
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: async (result, variables, context) => {
        try {
          // TODO: Invalidate NextPage?

          if (pageData?.sort_order !== 3) {
            queryClient.invalidateQueries([
              REACT_QUERY_KEYS.PEOPLE_KEYS_NEXT_PAGE,
              resultID,
            ]);
          } else {
            await API.PeopleKeys.Callback.Report.get({
              assessmentResultID: resultID,
              discID,
              pdfBehavior: "replaceIfNotFound",
              isClient: true,
            });

            queryClient.invalidateQueries([
              REACT_QUERY_KEYS.DISC_MINI_USER,
              ActiveSession.userID() ?? 0,
            ]);
          }

          onSuccess(pageData?.sort_order === 3);
        } catch (err) {
          const errMsg = typeof err === "string" ? err : JSON.stringify(err);

          onError?.(`${errMsg}`);
        }
      },
    }
  );
}

// TODO
export function use247ImportMutation({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  // * This still needs handles for onSuccess mutating cached GET response info
  return useMutation(
    async (importData: {
      foundItem: TytoData.FindAssessments.Found247Assessment;
      existingDISCID?: number;
      userID: number;
    }) => {
      if (importData.existingDISCID) {
        await API.DISCProfiles.delete({
          discID: importData.existingDISCID,
          personID: importData.userID,
        });
      }

      const url = new URL(
        `${window.location.origin}/tyto/${importData.foundItem.importUrl ?? ""}`
      );

      const searchParamsFromSuppliedURL: {
        [k: string]: any;
      } = {};

      url.searchParams.forEach((value, key) => {
        if (key !== "sessionKey") {
          searchParamsFromSuppliedURL[key] = value;
        }
      });

      return API.DISC247.ImportSelfAssessment.get(
        searchParamsFromSuppliedURL as any
      );
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: async (result, variables, context) => {
        try {
          // * Invalidate current request caches for this person's DISC info
          queryClient.invalidateQueries([
            REACT_QUERY_KEYS.DISC_MINI_USER,
            variables.userID,
          ]);
          queryClient.invalidateQueries([
            REACT_QUERY_KEYS.DISC_MINI_INTERACTIVE,
            variables.userID,
          ]);
          // TODO Ought to go through cached queries and invalidate all queries target this userID

          onSuccess();
        } catch (err) {
          const errMsg = typeof err === "string" ? err : JSON.stringify(err);

          onError?.(`${errMsg}`);
        }
      },
    }
  );
}

export function usePeopleKeysV4ImportMutation({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  // * This still needs handles for onSuccess mutating cached GET response info
  return useMutation(
    (importData: {
      foundItem: TytoData.FindAssessments.FoundAssesment;
      userID: number;
    }) => {
      debugger;
      const url = new URL(
        `${window.location.origin}/tyto/${importData.foundItem.importUrl ?? ""}`
      );

      const searchParamsFromSuppliedURL: {
        [k: string]: any;
      } = {};

      url.searchParams.forEach((value, key) => {
        if (key !== "sessionKey") {
          searchParamsFromSuppliedURL[key] = value;
        }
      });

      return API.PeopleKeys.V4.Import.get(searchParamsFromSuppliedURL as any);
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: async (result, variables, context) => {
        try {
          // * Invalidate current request caches for this person's DISC info
          queryClient.invalidateQueries([
            REACT_QUERY_KEYS.DISC_MINI_USER,
            variables.userID,
          ]);
          queryClient.invalidateQueries([
            REACT_QUERY_KEYS.DISC_MINI_INTERACTIVE,
            variables.userID,
          ]);
          // TODO Ought to go through cached queries and invalidate all queries target this userID

          onSuccess();
        } catch (err) {
          const errMsg = typeof err === "string" ? err : JSON.stringify(err);

          onError?.(`${errMsg}`);
        }
      },
    }
  );
}

export function useCreatePersonCommTipsMutation({
  discID,
  onSuccess,
  onError,
}: {
  discID: number;
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  // * This still needs handles for onSuccess mutating cached GET response info
  return useMutation(
    (
      updateData: Omit<
        Endpoints.Tyto.DISCProfile.Interactive.PersonCommTip.PostParameters,
        "discID"
      >
    ) => {
      return API.DISCProfile.Interactive.PersonCommunicationTip.post({
        discID,
        ...updateData,
      });
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: async (result, variables, context) => {
        try {
          // TODO: Invalidate NextPage?

          queryClient.invalidateQueries([
            REACT_QUERY_KEYS.DISC_MINI_INTERACTIVE,
            ActiveSession.userID() ?? 0,
          ]);

          queryClient.invalidateQueries([
            REACT_QUERY_KEYS.PERSON_COMM_TIPS,
            discID,
          ]);

          onSuccess();
        } catch (err) {
          const errMsg = typeof err === "string" ? err : JSON.stringify(err);

          onError?.(`${errMsg}`);
        }
      },
    }
  );
}

export function usePersonCommTipsMutation({
  discCommTipSelectID,
  discID,
  onSuccess,
  onError,
}: {
  discCommTipSelectID: number;
  discID: number;
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  // * This still needs handles for onSuccess mutating cached GET response info
  return useMutation(
    (
      updateData: Omit<
        Endpoints.Tyto.DISCProfile.Interactive.PersonCommTip.PutParameters,
        "discCommTipSelectID"
      >
    ) => {
      return API.DISCProfile.Interactive.PersonCommunicationTip.put({
        discCommTipSelectID,
        ...updateData,
      });
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");

        onError(`${msg}`);
      },
      onSuccess: async (result, variables, context) => {
        try {
          // TODO: Invalidate NextPage?
          const commTipsQuery:
            | Endpoints.Responses.DISCProfile.Interactive.PersonCommTips.Get
            | undefined = queryClient.getQueryData([
            REACT_QUERY_KEYS.PERSON_COMM_TIPS,
            discID,
          ]);

          // * Updated cached response data so there isn't a temporary flash to previous tip text while reloading tips
          if (commTipsQuery?.personCommunicationTips?.length) {
            const updatedCommTipsCopy = [
              ...commTipsQuery.personCommunicationTips,
            ].map((commTip) => {
              if (commTip.discCommTipSelectID === discCommTipSelectID) {
                return {
                  ...commTip,
                  ...variables,
                };
              }

              return commTip;
            });

            queryClient.setQueryData(
              [REACT_QUERY_KEYS.PERSON_COMM_TIPS, discID],
              {
                ...commTipsQuery,
                personCommunicationTips: updatedCommTipsCopy,
              }
            );
          }

          queryClient.invalidateQueries([
            REACT_QUERY_KEYS.DISC_MINI_USER,
            ActiveSession.userID() ?? 0,
          ]);
          queryClient.invalidateQueries([
            REACT_QUERY_KEYS.PERSON_COMM_TIPS,
            discID,
          ]);

          onSuccess();
        } catch (err) {
          const errMsg = typeof err === "string" ? err : JSON.stringify(err);

          onError?.(`${errMsg}`);
        }
      },
    }
  );
}

export function useDISCMiniMutation({
  personID,
  onSuccess,
  onError,
}: {
  personID: number;
  onSuccess?: () => void;
  onError?: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  // * This still needs handles for onSuccess mutating cached GET response info
  return useMutation(
    (updateData: Partial<Endpoints.Tyto.DISCProfileMini.PutParameters>) => {
      return API.DISCProfile.Mini.put({ personID, ...updateData });
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");
        if (onError) onError(`${msg}`);
      },
      onSuccess: async (result, variables, context) => {
        try {
          const pID = personID;
          // * [1] - Update ReactQuery cache to mark enrollment completStatus and appropriately reflect what just happened server-side
          const currentDISCMiniData = queryClient.getQueryState(
            `${REACT_QUERY_KEYS.DISC_MINI_USER}/${pID}`
          );

          const currentDISCMiniValue = _.get(
            currentDISCMiniData,
            "data"
          ) as Endpoints.Responses.DISCProfiles.Mini.Get;

          if (!currentDISCMiniData || !currentDISCMiniValue) {
            // TODO: Handle weird scenario where such is undefined?
            debugger;
            if (onSuccess) onSuccess();
            return;
          }

          // * Cycle through prereqEnrollments for course and update one with matching enrollmentID
          const taskResp = await API.DISCProfilesMini.get({
            personIDs: `${pID}`,
          });
          // * Update the cached ReacyQuery data with what we just updated locally
          queryClient.setQueryData(
            `${REACT_QUERY_KEYS.DISC_MINI_USER}/${pID}`,
            taskResp
          );

          // * [3] - If new completeStatus is ocCOMPLETE, update the block inside Training appropriately

          if (onSuccess) onSuccess();
        } catch (err) {
          const errMsg = typeof err === "string" ? err : JSON.stringify(err);

          onError?.(`${errMsg}`);
        }
      },
    }
  );
}

export function useDISCDeleteMutation({
  personID,
  onSuccess,
  onError,
}: {
  personID: number;
  onSuccess?: () => void;
  onError?: (errorMsg: string) => void;
}) {
  // * https://react-query.tanstack.com/guides/mutations
  // * This still needs handles for onSuccess mutating cached GET response info
  return useMutation(
    (updateData: { discID: number }) => {
      return API.DISCProfiles.delete(
        { personID, ...updateData },
        {
          paramsAsData: true,
        }
      );
    },
    {
      onError: (error, variables, context) => {
        const msg = _.get(error, "msg", "Error occurred.");
        if (onError) onError(`${msg}`);
      },
      onSuccess: async (result, variables, context) => {
        try {
          const pID = personID;

          if (!pID) {
            onSuccess?.();
            return;
          }

          // * [1] - Invalidate DISC Mini
          queryClient.invalidateQueries([REACT_QUERY_KEYS.DISC_MINI_USER, pID]);

          // * [2] - Invalidate Interactions
          queryClient.invalidateQueries([
            REACT_QUERY_KEYS.DISC_MINI_INTERACTIVE,
            pID,
          ]);

          // ! This will not invalidate any team queries recently loaded that contains the person's previous
          // TODO: invalidate all team DISC mini queries?

          if (onSuccess) onSuccess();
        } catch (err) {
          const errMsg = typeof err === "string" ? err : JSON.stringify(err);

          onError?.(`${errMsg}`);
        }
      },
    }
  );
}
