import { ENDPOINT_R3_DISC_VENDOR_247_DISC_QUESTIONNAIRE_VALIDATE } from "../../../constants/";
import { addParamsToURL, callWrapper, CallOpts } from "../utils";

import { SubmitAnswersPostParams, SubmitAnswersPostResponse } from "./types";

const endpoint = ENDPOINT_R3_DISC_VENDOR_247_DISC_QUESTIONNAIRE_VALIDATE;

export const DISC247QuestionnaireValidate = {
  post(params: SubmitAnswersPostParams, callOpts?: CallOpts) {
    const { answers, skipOverUnder, ...restOfParams } = params;

    const mutatedURL = addParamsToURL(endpoint, restOfParams);

    return callWrapper(
      "post",
      mutatedURL,
      { answers, skipOverUnder },
      callOpts
    ) as Promise<SubmitAnswersPostResponse>;
  },
};
