import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { PROFILE_SECTION_IDS } from "data/constants";

import "./style.scss";

interface Props {
  interactions?: TytoData.DISCCompareProfile;
}

const ChallengeAreas = (props: Props) => {
  const [personChallengeAreasList, updatePersonChallengeAreasList] =
    React.useState(() => {
      return splitString(props.interactions?.style?.challengeAreas);
    });

  React.useEffect(() => {
    updatePersonChallengeAreasList(
      splitString(props.interactions?.style?.challengeAreas)
    );
  }, [props.interactions]);

  return (
    <section
      className="profile-section"
      data-section={PROFILE_SECTION_IDS.CHALLENGE_AREAS}
    >
      <h2 className="profile-section-title title-font">Challenge Areas</h2>

      <ul className="profile-section-list">
        {personChallengeAreasList.map((growthArea) => (
          <li className="profile-section-list-item" key={growthArea}>
            {growthArea}
          </li>
        ))}
      </ul>
    </section>
  );
};

const DEFAULT_ARR: string[] = [];

function splitString(data?: string) {
  if (!data) {
    return DEFAULT_ARR;
  }

  return data.split("\n") ?? DEFAULT_ARR;
}

export default ChallengeAreas;
