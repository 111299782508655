/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import "./style.scss";

type InputType = "input" | "textarea" | "password";

export interface Props {
  autoFocus?: boolean;
  containerClassName?: string;
  className?: string;
  disabled?: boolean;
  form?: string;
  id?: string;
  maxLength?: number;
  name: string;
  htmlFor?: string;
  onChange: (newVal: string) => void;
  onEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  readOnly?: boolean;
  rows?: number;
  placeholder?: string;
  showCount?: boolean;
  style?: React.CSSProperties;
  type?: InputType;
  value: string;
}

const DEFAULT_INPUT_TYPE = "input";

const Input = ({
  autoFocus,
  containerClassName,
  className,
  disabled,
  form,
  id,
  maxLength,
  name,
  onBlur,
  onChange,
  onEnter,
  placeholder,
  readOnly,
  rows,
  showCount,
  style,
  type = DEFAULT_INPUT_TYPE,
  value,
}: Props) => {
  if (type === "textarea") {
    return (
      <div
        className={cx("cc-input-textarea-wrapper", containerClassName)}
        data-replicated-value={value}
      >
        <textarea
          autoFocus={autoFocus}
          className={cx("cc-input text-color textarea", className)}
          disabled={disabled}
          form={form}
          id={id}
          maxLength={maxLength}
          name={name}
          onBlur={onBlur ? () => onBlur?.() : undefined}
          onChange={(e: React.SyntheticEvent<HTMLTextAreaElement>) => {
            const newVal = (e.target as HTMLInputElement).value;

            if (onChange) {
              onChange(newVal);
            }
          }}
          placeholder={placeholder}
          readOnly={readOnly}
          rows={rows}
          style={style}
          value={value}
        >
          {value}
        </textarea>
      </div>
    );
  }

  return (
    <input
      autoFocus={autoFocus}
      className={cx("cc-input text-color", className)}
      disabled={disabled}
      form={form}
      id={id}
      maxLength={maxLength}
      name={name}
      onBlur={onBlur ? () => onBlur?.() : undefined}
      onChange={(e: React.SyntheticEvent<HTMLInputElement>) => {
        const newVal = (e.target as HTMLInputElement).value;

        if (onChange) {
          onChange(newVal);
        }
      }}
      onKeyPress={
        onEnter
          ? (e) => {
              if (e.key === "Enter") {
                onEnter(e);
              }
            }
          : undefined
      }
      placeholder={placeholder}
      readOnly={readOnly}
      style={style}
      type={type === "input" ? "text" : type}
      value={value}
    />
  );
};

export default Input;
