import type { KeyedType } from "data/helpers/Vendor247Hooks";
import {
  useKeyedExtensionData,
  SECTION_KEYS,
} from "data/helpers/Vendor247Hooks";

import { DISCValueRender } from "components/disc";

import DISCGraphs from "../disc-graphs/";
import { CommunicationTips247 } from "../comm-tips/";
import CompareGraph from "../compare-graph";
import { IntroductionGeneric, IntroductionWrapper } from "../intro";
import Motivators from "../motivators/";
import Needs from "../needs/";
import Provide from "../provide/";

interface Props {
  isMobile?: boolean;
  discMiniProfile: TytoData.DISCProfileMini;
  interactions?: TytoData.DISCCompareProfile;
  firstName: string;
  isOwnProfile: boolean;
}

const Vendor247Wrapper = (props: Props) => {
  const keyedData = useKeyedExtensionData({
    extensionURL: props.discMiniProfile.extUrl,
  });

  return (
    <>
      <IntroductionWrapper discMiniProfile={props.discMiniProfile}>
        <IntroductionGeneric
          text={
            keyedData?.[SECTION_KEYS.STYLE_OVERVIEW]?.text?.join?.("\n\n") ?? ""
          }
        />
      </IntroductionWrapper>

      <DISCGraphs
        discMiniProfile={props.discMiniProfile}
        interactions={props.interactions}
        userID={props.discMiniProfile.personID}
      />

      <CommunicationTips247
        isMobile={props.isMobile}
        interactions={props.interactions}
        discMiniProfile={props.discMiniProfile}
        contentSectionDo={keyedData?.[SECTION_KEYS.COMM_TIPS_DO]}
        contentSectionDont={keyedData?.[SECTION_KEYS.COMM_TIPS_DONT]}
      />

      <DISCValueRender
        discMiniProfile={props.discMiniProfile}
        isCompareValue={false}
        targetKey="interaction"
        className="profile-graph-valueRender"
      >
        <CompareGraph discMiniProfile={props.discMiniProfile} />
        {/* <Perspectives interactions={interactions} /> */}
      </DISCValueRender>

      <Motivators
        motivators={keyedData?.[SECTION_KEYS.MOTIVATORS]?.text ?? []}
        firstName={props.firstName}
        isOwnProfile={props.isOwnProfile}
        hotSwapYouText={!props.isOwnProfile}
      />

      <Needs
        needs={keyedData?.[SECTION_KEYS.NEEDS]?.text ?? []}
        firstName={props.firstName}
        isOwnProfile={props.isOwnProfile}
        hotSwapYouText={!props.isOwnProfile}
      />

      <Provide
        provideItems={keyedData?.[SECTION_KEYS.ENVIRONMENT]?.text ?? []}
        firstName={props.firstName}
        isOwnProfile={props.isOwnProfile}
        hotSwapYouText={!props.isOwnProfile}
      />
    </>
  );
};

export default Vendor247Wrapper;
