/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { SECURITY_FUNCTIONS_BY_NAME } from "data/constants/system";
import { SearchInput } from "components/common/";
import { useAdvancedPersonSearch } from "data/network/hooks/";

import { useThrottledSearchTerm } from "./useThrottledSearchTerm";

import SearchResults from "./subcomponents/search/";
import { Button } from "components/common/";

const SEARCH_FORM_ID = "directory-search-form";
const SUB_KEY = "directory-search";

interface Props {
  isMobile: boolean;
  searchTerm?: string;
}

const SEARCH_OPTS = {
  operation: "ocVIEW",
  functionName: SECURITY_FUNCTIONS_BY_NAME["Person TraitR3"].functionName,
} as Partial<Endpoints.Tyto.PersonAdvanced.GetParameters>;

const Home = ({ searchTerm: startingSearchTerm }: Props) => {
  const { searchTermForInput, searchTermForFiltering, setSearchTerm } =
    useThrottledSearchTerm({
      subKey: SUB_KEY,
      delayMS: 280,
    });

  const [shouldAutoFocus] = React.useState(
    typeof startingSearchTerm !== "string" || !startingSearchTerm
  );

  const { isFetching, data } = useAdvancedPersonSearch({
    searchTerm: searchTermForFiltering,
    extraOpts: SEARCH_OPTS,
    isEnabled: !!searchTermForFiltering,
  });

  return (
    <div className="directory-inner-cont centered-content-wrapper">
      <form
        className="directory-search-wrapper"
        onSubmit={(e) => {
          setSearchTerm(`${searchTermForInput}`);
          e.preventDefault?.();
        }}
        id={SEARCH_FORM_ID}
      >
        <SearchInput
          autoFocus={shouldAutoFocus}
          className="directory-search-input font-size-medium"
          placeholder="Search for people"
          name="search-for-people"
          id="search-for-people"
          form={SEARCH_FORM_ID}
          onChange={(newSearchTerm: any) => {
            setSearchTerm(`${newSearchTerm}`);
            if (newSearchTerm.length > 1) {
              setSearchTerm(newSearchTerm);
            } else {
            }
          }}
          onEnter={(e) => {
            e.preventDefault?.();
          }}
          value={searchTermForInput}
        />
        <div className="directory-search-btn-cont">
          <Button
            className="directory-search-btn"
            disabled={isFetching}
            theme="action"
            value={isFetching ? "..." : "Search"}
            type="submit"
            onClick={_.noop}
          />
        </div>
      </form>

      <SearchResults
        people={data?.ret?.people ?? []}
        isFetching={isFetching}
        searchTerm={searchTermForFiltering}
      />
    </div>
  );
};

export default Home;
