import * as React from "react";

import { getUserHas247UpdatesAccess } from "data/helpers/session";
import { useDISCInteractive } from "data/network/hooks/";
import { DISCValueRender } from "components/disc";

import ProfileHeader from "./ProfileHeader";
import SectionMenu from "../section-menu/";
import DISCGraphs from "../disc-graphs/";
import Downloads from "../downloads/";
import CommunicationTips from "../comm-tips/";
import Characteristics from "../characteristics/";
import Contributions from "../contributions/";
import Perspectives from "../perspectives/";
import CompareGraph from "../compare-graph";
import Introduction from "../intro";
import Vendor247Wrapper from "../own-profile/Vendor247Wrapper";

import "./CompareProfile.scss";

interface Props {
  canDeleteDISC: boolean;
  canImportDISC: boolean;
  discMiniProfile: TytoData.DISCProfileMini;
  isLoading: boolean;
  isMobile: boolean;
  userID: number;
}

const CompareProfile = (props: Props) => {
  const [firstName, updateFirstName] = React.useState(() => {
    return props.discMiniProfile?.personName?.split?.(" ")[0];
  });

  const interactionsQuery = useDISCInteractive({
    userID: props.discMiniProfile.personID,
  });

  React.useEffect(() => {
    updateFirstName(props.discMiniProfile?.personName?.split?.(" ")[0]);
  }, [props.discMiniProfile]);

  const show247Data =
    getUserHas247UpdatesAccess() &&
    props.discMiniProfile.provider === "Assessments247.net";

  const interactions =
    interactionsQuery.data?.discProfile ??
    interactionsQuery.storedValueQuery?.data?.discProfile;

  return (
    <article className="profile-compareprofile-wrapper">
      <div className="centered-content-wrapper">
        <ProfileHeader
          canDeleteDISC={props.canDeleteDISC}
          canImportDISC={props.canImportDISC}
          discMiniProfile={props.discMiniProfile}
          interactions={interactions}
          isMobile={props.isMobile}
          show247Data={show247Data}
        />
      </div>

      <div className="profile-compareprofile-content-wrapper">
        <div className="centered-content-wrapper">
          <SectionMenu
            isMobile={props.isMobile}
            discMiniProfile={props.discMiniProfile}
            use247Data={show247Data}
            isOwnProfile={false}
          />

          {show247Data ? (
            <Vendor247Wrapper
              isMobile={props.isMobile}
              discMiniProfile={props.discMiniProfile}
              interactions={interactions}
              firstName={firstName}
              isOwnProfile={false}
            />
          ) : (
            <>
              <Introduction discMiniProfile={props.discMiniProfile} />
              <DISCGraphs {...props} interactions={interactions} />
              <CommunicationTips
                interactions={interactions}
                discMiniProfile={props.discMiniProfile}
              />
              <Characteristics
                interactions={interactions}
                discMiniProfile={props.discMiniProfile}
              />
              <Contributions
                interactions={interactions}
                discMiniProfile={props.discMiniProfile}
              />

              <DISCValueRender
                discMiniProfile={props.discMiniProfile}
                isCompareValue={false}
                targetKey="interaction"
                className="profile-graph-valueRender"
              >
                <CompareGraph discMiniProfile={props.discMiniProfile} />
                <Perspectives interactions={interactions} />
              </DISCValueRender>
            </>
          )}

          <DISCValueRender
            discMiniProfile={props.discMiniProfile}
            isCompareValue={false}
            targetKey="pdfLessonID"
            targetKeys={["pdfLessonID"]}
            className="profile-graph-valueRender"
          >
            <Downloads
              discProfileMini={props.discMiniProfile}
              isMobile={props.isMobile}
              title="Results PDF"
            />
          </DISCValueRender>
        </div>
      </div>
    </article>
  );
};

export default CompareProfile;
