/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

// import { StoreContext as AppStoreContext } from "data/stores/AppStore";

import TeamsSearch from "./subcomponents/team-search/";

export interface Props extends SITE.GeneralStoreInjectedProps {
  isMobile: boolean;
  teamID: number;
}

const Teams = ({ teamID, isMobile }: Props) => {
  return (
    <div className="interface-teams-inner-wrapper">
      <main className="interface-teams-inner-cont centered-content-wrapper">
        <TeamsSearch teamID={teamID} isMobile={isMobile} />
      </main>
    </div>
  );
};

export default Teams;
