import { useState } from "react";
import { Redirect } from "react-router-dom";

import { Button, Icon } from "components/common";
import { INTERFACE_PATHS } from "data/constants";

import type { AssessmentTakerSubInterface } from "./types";

import "./ConfirmationPage.scss";

interface Props {
  isMobile?: boolean;
  updateCurSubInterface: (newSubInterface: AssessmentTakerSubInterface) => void;
}

const ConfirmationPage = (props: Props) => {
  const [redirectPath, updateRedirectPath] = useState<string | null>(null);

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  return (
    <div className="assessment-247-confirmation-wrapper">
      <div className="assessment-247-confirmation-cont">
        <section className="assessment-247-confirmation-icon-cont">
          <Icon
            className="assessment-247-confirmation-icon"
            icon="check"
            size={48}
          />
        </section>

        <section className="assessment-247-confirmation-text">
          <h1 className="title-font">Thank you for submitting your answers!</h1>
          <p>We are calculating your results</p>
        </section>

        <section className="assessment-247-confirmation-btns">
          <Button
            className="assessment-247-confirmation-btn"
            theme="white-ghost"
            value="Go to Home"
            onClick={() => updateRedirectPath(INTERFACE_PATHS.HOME)}
          />

          <Button
            className="assessment-247-confirmation-btn"
            theme="action"
            value="Go to Profile"
            onClick={() => updateRedirectPath(INTERFACE_PATHS.PROFILE)}
          />
        </section>
      </div>
    </div>
  );
};

export default ConfirmationPage;
