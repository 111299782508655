import * as React from "react";

import { UserThumbByID, Icon, Link, TextButton } from "components/common/";
import { DISCImport } from "components/modals/";
import { getThumbnailPathFromDISCMini } from "data/helpers/";
import { getUserHas247UpdatesAccess } from "data/helpers/session";
import { calcGradient } from "data/helpers/DISC";
import { INTERFACE_PATHS } from "data/constants/";
import { useDISCDeleteMutation } from "data/network/hooks";
import {
  // useKeyedExtensionData,
  SECTION_KEYS,
} from "data/helpers/Vendor247Hooks";

import "./ProfileHeader.scss";

interface Props {
  canDeleteDISC: boolean;
  canImportDISC: boolean;
  discMiniProfile: TytoData.DISCProfileMini;
  isMobile: boolean;
  interactions?: TytoData.DISCCompareProfile;
  show247Data?: boolean;
}

function getConsumableStyleGradient(discMiniProfile: TytoData.DISCProfileMini) {
  const styleGradientValuesAsString = calcGradient({
    d: discMiniProfile.d3,
    i: discMiniProfile.i3,
    s: discMiniProfile.s3,
    c: discMiniProfile.c3,
  });

  return `linear-gradient(to bottom right${styleGradientValuesAsString})`;
}

const ProfileHeader = ({
  canDeleteDISC,
  canImportDISC,
  discMiniProfile,
  interactions,
  isMobile,
  show247Data,
}: Props) => {
  const [thumbnailPathURL, updateThumbnailPathURL] = React.useState(() =>
    getThumbnailPathFromDISCMini(discMiniProfile)
  );
  const [showDISCImportModal, updateShowDISCImportModal] =
    React.useState(false);
  // const [styleGradient, updateStyleGradient] = React.useState(() =>
  //   getConsumableStyleGradient(discMiniProfile)
  // );

  // const keyedData = useKeyedExtensionData({
  //   extensionURL: discMiniProfile.extUrl,
  //   isEnabled: !!show247Data,
  // });

  React.useEffect(() => {
    updateThumbnailPathURL(getThumbnailPathFromDISCMini(discMiniProfile));
    // updateStyleGradient(getConsumableStyleGradient(discMiniProfile));
  }, [discMiniProfile]);

  const deleteMutation = useDISCDeleteMutation({
    personID: discMiniProfile.personID,
    onError: () => {
      // TODO
    },
    onSuccess: () => {
      // TODO
    },
  });

  const { atsInitialsAdaptive, atsInitialsNatural, personName, styleName3 } =
    discMiniProfile;
  const showNewDISCUpdates = getUserHas247UpdatesAccess();
  const headlineText = show247Data ? "" : interactions?.style?.styleHeadline;
  // const headlineText = show247Data
  //   ? keyedData?.[SECTION_KEYS.HOW_OTHERS_ARE_VALUED]?.text?.[0]
  //   : interactions?.style?.styleHeadline;

  return (
    <header className="profile-otherperson-header">
      <section className="profile-otherperson-header-top-cont">
        <div className="profile-otherperson-header-user">
          <UserThumbByID
            size={45}
            userName={personName}
            userID={discMiniProfile.personID}
            // userID={discMiniProfile.personID}
          />
          <h3 className="profile-otherperson-header-top-name">{personName}</h3>
        </div>
        {isMobile && (
          <Link href={INTERFACE_PATHS.SETTINGS} type="link" value="settigs">
            <Icon icon="cog" size={20} />
          </Link>
        )}
      </section>

      <section className="profile-otherperson-header-bottom-cont">
        <span
          className="profile-otherperson-header-style-name title-font"
          // style={{ backgroundImage: styleGradient }}
        >
          {showNewDISCUpdates
            ? `${atsInitialsAdaptive}/${atsInitialsNatural}`
            : styleName3}
        </span>
        {isMobile ? (
          !!headlineText && ` - ${headlineText}`
        ) : (
          <p className="profile-otherperson-header-style-traits">
            {headlineText}
          </p>
        )}

        {(canDeleteDISC || canImportDISC) && (
          <div className="profile-otherperson-header-action-btns">
            {canImportDISC && (
              <TextButton
                className="profile-otherperson-header-action-btn action"
                onClick={() => {
                  updateShowDISCImportModal(true);
                }}
                value="Import Existing Assessment"
              />
            )}

            {canDeleteDISC && (
              <TextButton
                className="profile-otherperson-header-action-btn alert"
                disabled={deleteMutation.isLoading}
                onClick={() => {
                  if (deleteMutation.isLoading) {
                    return;
                  }

                  const confirmed = window.confirm(
                    `Are you sure you want to remove ${
                      personName ?? "this person?"
                    }'s Assessment Results?`
                  );

                  if (!confirmed) {
                    return;
                  }

                  const discID = interactions?.result.discID;

                  if (!discID) {
                    return;
                  }

                  deleteMutation.mutate({ discID });
                }}
                value="Reset Results"
              />
            )}

            <DISCImport
              userID={discMiniProfile.personID}
              emailAddress={discMiniProfile.emails?.[0]}
              personName={discMiniProfile.personName}
              // existingDISCID={discMiniProfile.id}
              isOpen={showDISCImportModal}
              existingDISCID={
                discMiniProfile.discID ?? interactions?.result.discID
              }
              onDismiss={() => {
                updateShowDISCImportModal(false);
              }}
              use247={showNewDISCUpdates}
            />
          </div>
        )}
      </section>
    </header>
  );
};

export default ProfileHeader;
