/*
 * Component Description
 */
import * as _ from "lodash";
import cx from "classnames";

import {
  INTERFACE_PATHS,
  SUB_INTERFACE_PATHS,
} from "../../../../../data/constants/";
import { Icon, Link } from "../../../../common/";

// import { MenuWrapperProps } from "../../";
// import GenericMenuContent from "../../GenericMenuContent";

import "./style.scss";

interface LinkItem {
  disabled?: boolean;
  title: string;
  to: string;
  subItems?: LinkItem[];
}

const primaryLinks: LinkItem[] = [
  {
    title: "Home",
    to: INTERFACE_PATHS.HOME,
  },
  {
    disabled: true,
    title: "Profile",
    to: INTERFACE_PATHS.PROFILE,
  },
];

interface Props {
  hasContentAbove?: boolean;
  hasParentSite?: boolean;
}

const DefaultMenu = ({ hasContentAbove, hasParentSite }: Props) => {
  // if (hasParentSite) {
  //   return (
  //     <Link
  //       className="cm-menu-default-hasparent-menu title-font"
  //       href={`${INTERFACE_PATHS.CERTIFICATIONS}`}
  //       value="My Certifications"
  //     >
  //       <Icon
  //         className="cm-menu-default-hasparent-menu-icon"
  //         icon="report"
  //         size={24}
  //       />
  //       My Certifications
  //     </Link>
  //   );
  // }

  return (
    // <GenericMenuContent {...props}>
    <>
      {hasContentAbove && (
        <hr className="cm-menu-default-menu-hr border-color" />
      )}

      <div className="cm-menu-default-menu">
        <ul className="cm-menu-primary-links-list">
          {primaryLinks.map((primLink) =>
            primLink.disabled ? null : (
              <MenuLinkItem linkItem={primLink} key={primLink.title} />
            )
          )}
        </ul>

        {/* <ul className="cm-menu-primary-links-list" style={{ marginTop: "0px" }}>
          {secondaryLinks.map((secondaryLink) => (
            secondaryLink.disabled ? null :
            <MenuLinkItem linkItem={secondaryLink} key={secondaryLink.title} />
          ))}
        </ul> */}
      </div>
    </>
    // </GenericMenuContent>
  );
};

interface MenuLinkItemProps {
  linkItem: LinkItem;
}

const MenuLinkItem = ({ linkItem }: MenuLinkItemProps) => {
  const isCurrentLocation = linkItem.to === window.location.pathname;

  return (
    <>
      <li
        className={cx(
          "cm-menu-default-menu-list-item",
          linkItem.disabled && "is-disabled",
          isCurrentLocation && "is-current-location"
        )}
        key={linkItem.title}
      >
        <Link
          className={cx(
            "cm-menu-default-menu-link text-color",
            linkItem.disabled && "fade"
          )}
          disabled={linkItem.disabled || isCurrentLocation}
          href={linkItem.to}
          value={linkItem.title}
        />

        {linkItem.disabled && (
          <span className="cm-menu-default-menu-link-disabled-msg font-color">
            Disabled
          </span>
        )}
      </li>

      {linkItem.subItems && !!linkItem.subItems.length && (
        <ul className="cm-menu-primary-links-list sub-list">
          {linkItem.subItems.map((subLinkItem) => (
            <MenuLinkItem key={subLinkItem.title} linkItem={subLinkItem} />
          ))}
        </ul>
      )}
    </>
  );
};

export default DefaultMenu;
