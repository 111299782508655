/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { Nav, Footer } from "components/meta";

import "./style.scss";
import Learning from "./Learning";

interface Props extends SITE.GeneralStoreInjectedProps {}

const LearningWrapper = (props: Props) => {
  const isMobile = !!props.GeneralStore?.state?.isMobile;

  return (
    <div>
      {!isMobile && <Nav activeTab="Learning" className="home-nav" />}

      <main className="learning-interface">
        <Learning GeneralStore={props.GeneralStore} />
      </main>

      {isMobile && <Footer activeTab="Learning" />}
    </div>
  );
};

export default LearningWrapper;
