/*
 * Component Description
 */
import cx from "classnames";

import { getItemPosition } from "../utils";

const gridItems: number[] = [0, 1, 2, 3, 4, 5, 6, 7];

interface RenderColumnSectionsProps {
  columnNumber: number;
}

export default (props: RenderColumnSectionsProps) => {
  return (
    <>
      {gridItems.map((item) => (
        <div
          className={cx(
            "cc-disc-plot-graph-column-section",
            item === 3 && "has-dif-border-bottom",
            item === 4 && "has-border-top"
          )}
          key={`item-${item}`}
          style={{
            ...getItemPosition(item),
            ...{
              "--_block-opacity": 0.4,
              // "--_block-opacity": ((7 - item) / 7) * 0.6 + 0.25,
            },
          }}
        />
      ))}
    </>
  );
};
