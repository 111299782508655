import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";
import { Redirect } from "react-router-dom";

import { INTERFACE_PATHS, SUB_INTERFACE_PATHS } from "data/constants/";
import { ActiveSession } from "data/storage";
import {
  usePeopleKeysNextPage,
  usePeopleKeysResponseMutation,
} from "data/network/hooks/";
import { Icon, Message } from "components/common/";

import AssessmentMarkers from "./AssessmentMarkers";
import PreAssessment from "./PreAssessment";
import Question from "./Question";

import "./style.scss";

interface Props {
  assessmentResultID: number;
  discID: number;
  isMobile: boolean;
  pages: TytoData.PeopleKeys.Page[];
  keyedPages: _.Dictionary<TytoData.PeopleKeys.Page>;
}

const AssessmentPageWrapper = (props: Props) => {
  const { data, isLoading, isRefetching, isFetching } = usePeopleKeysNextPage({
    resultID: props.assessmentResultID,
    isEnabled: !!props.assessmentResultID,
    onError: () => {
      // TODO ?
    },
  });

  if (isLoading || isRefetching || isFetching) {
    return (
      <article className="assessment-taker-taker">
        <div className="assessment-taker-initializing-cont">
          <Icon
            className={cx(
              "assessment-taker-initializing-icon",
              !isFetching && "no-animation"
            )}
            size={props.isMobile ? 125 : 145}
            icon="r3"
          />
          <Message
            className="assessment-taker-initializing-msg"
            text={`Prepping Questions...`}
          />
        </div>
      </article>
    );
  }

  const pageData = props.keyedPages[data?.nextPageID ?? 0];
  const isPreQuestion = pageData?.sort_order === 0;

  if (isPreQuestion) {
    return (
      <div className="assessment-taker-page">
        <PreAssessment
          assessmentResultID={props.assessmentResultID}
          discID={props.discID}
          pageData={pageData}
          onStart={() => {
            // TODO: Maybe need to do something with this? Should actually be account for with 'NextPage'
            // TODO: being 'invalidated' via mutation inside PreAssessment
          }}
        />
      </div>
    );
  }

  return (
    <div className="assessment-taker-page">
      {!data?.pageItems ? (
        <Message text="ERROR: Question Details could not be loaded." />
      ) : (
        <AssessmentPage
          assessmentResultID={props.assessmentResultID}
          discID={props.discID}
          key={data.nextPageID}
          pageID={data.nextPageID}
          pages={props.pages}
          pageData={props.keyedPages[data.nextPageID]}
          questions={data.pageItems}
        />
      )}
    </div>
  );
};

interface AssessmentPageProps {
  assessmentResultID: number;
  discID: number;
  pageID: number;
  pages: TytoData.PeopleKeys.Page[];
  pageData: TytoData.PeopleKeys.Page;
  questions: TytoData.PeopleKeys.PageItem[];
}

const AssessmentPage = (props: AssessmentPageProps) => {
  const [loggedInUserID] = React.useState(() => {
    return ActiveSession.userID() ?? 0;
  });
  const [snapshottedQuestions, updateSnapshottedQuestions] = React.useState(
    props.questions
  );
  const [questionOffsetNumber, updateQuestionOffsetNumber] = React.useState(
    () => {
      return getQuestionOffsetNumber(props.pageData?.sort_order);
    }
  );
  const [redirectURL, updateRedirectURL] = React.useState("");
  const [curQuestionNumber, updateCurQuestionNumber] = React.useState(0);

  const pageSubmitionMutation = usePeopleKeysResponseMutation({
    resultID: props.assessmentResultID,
    pageData: props.pageData,
    discID: props.discID,
    onError: () => {
      // TODO
    },
    onSuccess: (isFinalPage: boolean) => {
      // TODO
      if (isFinalPage) {
        updateRedirectURL(
          `${INTERFACE_PATHS.PROFILE}/${loggedInUserID}/${SUB_INTERFACE_PATHS.PROFILE.HOW_TO_READ}`
        );
      }
    },
  });

  // * 'OnMount'
  React.useEffect(() => {
    debugger;
  }, []);

  // * If pageData changes update offsetNumber accordingly
  React.useEffect(() => {
    updateQuestionOffsetNumber(
      getQuestionOffsetNumber(props.pageData?.sort_order)
    );
  }, [props.pageData]);

  // * Function for updating answers order for a question
  const updateQuestionAnswerOrder = ({
    questionIdx,
    answers,
  }: {
    questionIdx: number;
    answers: TytoData.PeopleKeys.PageItemContentOption[];
  }) => {
    // const questionDataCopy = getQuestionByIdxSafely(snapshottedQuestions ?? [], questionIdx ?? 0);
    const questionDataCopy = snapshottedQuestions[questionIdx];

    if (questionDataCopy) {
      const questionsCopy = [...(snapshottedQuestions ?? [])];

      questionDataCopy.content.Options = answers;

      questionsCopy[questionIdx] = questionDataCopy;

      updateSnapshottedQuestions(questionsCopy);
    }
  };

  const savePageAnswers = () => {
    // TODO
    const formattedResponse = formatResponse(snapshottedQuestions);

    if (!formattedResponse) {
      // TODO
      return;
    }

    pageSubmitionMutation.mutate({
      response: formattedResponse,
      pageID: props.pageID,
    });
  };

  const questionData = getQuestionByIdxSafely(
    snapshottedQuestions ?? [],
    curQuestionNumber ?? 0
  );
  //   const questionData = snapshottedQuestions[curQuestionNumber];

  if (redirectURL) {
    return <Redirect to={redirectURL} />;
  }

  return (
    <div className="assessment-taker-page-question-wrapper">
      <AssessmentMarkers
        questionOffsetNumber={questionOffsetNumber}
        curPageQuestionIdx={curQuestionNumber}
      />

      {questionData ? (
        <Question
          questionIdx={questionData?.sort_order ?? 0}
          questionData={questionData}
          questionOffsetNumber={questionOffsetNumber}
          updateAnswerOrder={updateQuestionAnswerOrder}
          isSaving={pageSubmitionMutation.isLoading}
          nextQuestion={() => {
            if (curQuestionNumber >= 7) {
              savePageAnswers();
            } else {
              updateCurQuestionNumber((curQuestionNumber ?? 0) + 1);
            }
          }}
        />
      ) : (
        <Message text="ERROR: Question Data Not Found." />
      )}
    </div>
  );
};

// * Utils....
function getQuestionByIdxSafely(
  questions: TytoData.PeopleKeys.PageItem[],
  questionIdx: number
) {
  if (!questions?.length || questionIdx < 0) {
    return undefined;
  }

  const q = questions[questionIdx];

  if (q.sort_order === questionIdx) {
    return q;
  }

  const qRetry = questions.find((q) => q.sort_order === questionIdx);

  return qRetry;
}

function getQuestionOffsetNumber(sort_order?: number) {
  debugger;
  if (typeof sort_order !== "number") {
    return 0;
  }

  return Math.max(0, (sort_order - 1) * 8);
}

function formatResponse(questions: TytoData.PeopleKeys.PageItem[]) {
  if (!questions?.length) {
    return undefined;
  }

  const formattedAnswers = questions.map(({ item_id, content }) => {
    const options = content.Options.map((opt, curIdx) => ({
      order: curIdx + 1,
      value: opt.Value,
    }));

    return {
      itemID: item_id,
      options,
    };
  });

  return formattedAnswers;
}
// * End Utils......

export default AssessmentPageWrapper;
