/*
 * Component Description
 */
import { gridItemToPlotMap } from "../utils";

const markerValues = [
  {
    key: "mv1",
    objKey: 0,
    label: "+8",
  },
  {
    key: "mv2",
    objKey: 1,
    label: "+6",
  },
  {
    key: "mv3",
    objKey: 2,
    label: "+4",
  },
  {
    key: "mv4",
    objKey: 4,
    label: "+2",
  },
  {
    key: "mv5",
    objKey: 5,
    label: "-2",
  },
  {
    key: "mv6",
    objKey: 6,
    label: "-4",
  },
  {
    key: "mv7",
    objKey: 7,
    label: "-6",
  },
  {
    key: "mv8",
    objKey: 8,
    label: "-8",
  },
];

export default () => {
  return (
    <>
      {markerValues.map((markerValue, curIdx) => (
        <span
          className="cc-disc-plot-graph-column-range-marker"
          key={markerValue.key}
          style={getMarkerValuePosition(markerValue.objKey, curIdx)}
        >
          {markerValue.label}
        </span>
      ))}
    </>
  );
};

const getMarkerValuePosition = (key: number, curIdx: number) => {
  const topValue = gridItemToPlotMap[key] || 0;

  return {
    top: `${50 * (key === 4 ? 0.75 : topValue) + (key > 4 ? 50 : 0)}%`,
  };
};
