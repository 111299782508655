import * as React from "react";

interface Props {
  title: string;
  unparsedList: string;
}

function parseListString(unparsedList: string) {
  if (!unparsedList) {
    return [];
  }

  return unparsedList.split("\n");
}

const ContributionList = ({ title, unparsedList }: Props) => {
  const [parsedList, updateParsedList] = React.useState(() =>
    parseListString(unparsedList)
  );

  React.useEffect(() => {
    updateParsedList(parseListString(unparsedList));
  }, [unparsedList]);

  return (
    <div className="">
      <h5 className="profile-contributions-title profile-section-subtitle title-font">
        {title || ""}
      </h5>

      <ul className="profile-section-list">
        {parsedList.map((attribute) => (
          <li className="profile-section-list-item" key={attribute}>
            {attribute}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContributionList;
