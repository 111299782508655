import * as React from "react";
import { Message, Button } from "components/common/";
import CompareProfile from "./CompareProfile";
import { INTERFACE_PATHS } from "data/constants/";
import { DISCImport } from "components/modals/";

import "./style.scss";

interface Props {
  canDeleteDISC: boolean;
  canImportDISC: boolean;
  discMiniProfile?: TytoData.DISCProfileMini;
  isLoading: boolean;
  isMobile: boolean;
  userID: number;
}

const OtherProfile = (props: Props) => {
  const [showImportModal, updateShowImportModal] = React.useState(false);

  if (!props.discMiniProfile) {
    return (
      <div className="other-profile-no-access-cont">
        <LockedProfile
          message={
            props.isLoading
              ? "Loading DISC Information..."
              : `No DISC Information Found.`
          }
        />
      </div>
    );
  } else if (!props.discMiniProfile.styleKey3) {
    return (
      <div className="other-profile-no-access-cont">
        <LockedProfile
          message={`${props.discMiniProfile.personName} does not have a completed DISC Profile.`}
          className="other-profile-error-no-DISC"
        />

        {props.canImportDISC && (
          <Button
            className="other-profile-no-access-btn"
            theme="action"
            disabled={showImportModal}
            onClick={() => {
              updateShowImportModal(true);
            }}
            style={{
              marginRight: "12px",
            }}
            value="Import Existing Assessment"
          />
        )}

        <Button
          className="other-profile-no-access-btn white-ghost"
          theme={"action"}
          value={"Back to Search"}
          onClick={() => (window.location.href = INTERFACE_PATHS.SEARCH)}
        />

        <DISCImport
          userID={props.userID}
          emailAddress={props.discMiniProfile?.emails?.[0]}
          isOpen={showImportModal}
          onDismiss={() => {
            updateShowImportModal(false);
          }}
          use247={true}
        />
      </div>
    );
  } else if (
    props.discMiniProfile?.permitMatrix?.discStyle?.characteristic1?.HIDE &&
    props.discMiniProfile.permitMatrix.discCommTip_.HIDE &&
    props.discMiniProfile.permitMatrix.SCORES_.HIDE
  ) {
    return (
      <div className="other-profile-no-access-cont">
        <LockedProfile
          message={
            `${props.discMiniProfile.personName} DISC Profile is unavailable to view.` +
            `You do not have permission. Please contact an administrator.`
          }
        />

        <Button
          className="other-profile-no-access-btn white-ghost"
          theme={"action"}
          value={"Back to Search"}
          onClick={() => (window.location.href = INTERFACE_PATHS.SEARCH)}
        />
      </div>
    );
  }

  return (
    <div className="">
      <CompareProfile
        canDeleteDISC={props.canDeleteDISC}
        canImportDISC={props.canImportDISC}
        discMiniProfile={props.discMiniProfile}
        isLoading={props.isLoading}
        isMobile={props.isMobile}
        userID={props.userID}
      />
    </div>
  );
};

interface LockedProfileProps {
  message: string;
  className?: string;
}

const LockedProfile = (props: LockedProfileProps) => {
  return (
    <div className={props.className}>
      <Message text={props.message} />
    </div>
  );
};

export default OtherProfile;
