import { ENDPOINT_DISC_VENDOR_FOR_TAKE } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_DISC_VENDOR_FOR_TAKE;

export const DISCVendorForTake = {
  get(
    params: Endpoints.Tyto.DISC.Vendor.ForTake.Get.Parameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Tyto.DISC.Vendor.ForTake.Get.Response>;
  },
};
