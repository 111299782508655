import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import CommTipsList from "./CommTipsList";

import "./style.scss";

interface Props {
  compareProfile: TytoData.DISCCompareProfile;
  canEdit: boolean;
  ownProfile?: boolean;
  personCommunicationTips?: TytoData.DISCCompareProfile.PersonCommTip[];
  isMobile?: boolean;
}

const CommunicationTipsEditable = (props: Props) => {
  return (
    <CommTipsList
      isMobile={props.isMobile}
      canEdit={props.canEdit}
      interactions={props.compareProfile}
      ownProfile={props.ownProfile}
      personCommunicationTips={props.personCommunicationTips}
    />
  );
};

export default CommunicationTipsEditable;
