import * as _ from "lodash";
import { useMutation } from "react-query";
import TytoCalls from "../../tyto/";

import { SubmitAnswersPostParams } from "../../tyto/vendor-247/types";

export function useSubmitAnswersMutation() {
  return useMutation({
    mutationFn: async (
      args: SubmitAnswersPostParams & { suppliedURL: string }
    ) => {
      const pulledArgs = pullParamsFromSuppliedURL(args.suppliedURL);

      const allArgs: SubmitAnswersPostParams = { ...pulledArgs, ...args };

      debugger;

      const data = await TytoCalls.Vendor247.DISCQuestionnaire.Validate.post({
        ...allArgs,
      });

      return data;
    },
  });
}

function pullParamsFromSuppliedURL(
  suppliedURL: string
): SubmitAnswersPostParams {
  const url = new URL(
    `${window.location.origin}${
      /^\//i.test(suppliedURL) ? "" : "/"
    }${suppliedURL}`
  );

  const params: any = {} as any;

  url.searchParams.forEach((value: any, key: any) => {
    params[key] = value;
  });

  return params;
}
