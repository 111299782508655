import { ENDPOINT_DISC_PROFILE_TEAM } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_DISC_PROFILE_TEAM;

export const DISCProfileTeam = {
  get(
    params: Endpoints.Tyto.DISCProfile.Team.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.DISCProfile.Team.Get>;
  },
};
