/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { Nav, Footer } from "components/meta";
import { BackNav } from "components/meta/sub-navs";
import { SessionHandling } from "data/storage/";
import { usePersonalDISCMini, usePerson } from "data/network/hooks/";
import { INTERFACE_PATHS } from "data/constants/";

import "./style.scss";
import Settings from "./Settings";

interface Props extends SITE.GeneralStoreInjectedProps {
  userID?: string | number;
}

const SettingWrapper = (props: Props) => {
  const isMobile = !!props.GeneralStore?.state?.isMobile;
  const userID = SessionHandling.getPropertyFromActiveSession("userID") ?? 0;
  const { data: personData } = usePerson({ userID });
  const { data: miniData } = usePersonalDISCMini({});

  return (
    <div>
      {!isMobile && <Nav activeTab="Settings" className="home-nav" />}
      <BackNav
        className="settings-header"
        title="Settings"
        onBack={() =>
          (window.location.href = props.GeneralStore.state?.history[1]
            ? props.GeneralStore.state?.history[1].pathname
            : INTERFACE_PATHS.HOME)
        }
      />

      <main className="settings-interface">
        <Settings
          isMobile={isMobile}
          GeneralStore={props.GeneralStore}
          person={personData}
          DISCMini={miniData}
        />
      </main>

      {isMobile && <Footer activeTab="Settings" />}
    </div>
  );
};

export default SettingWrapper;
