/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { StoreContext as AppStoreContext } from "data/stores/AppStore";
import { useCanImportDISC, useDISCMini } from "data/network/hooks/";
import { calcGradient } from "data/helpers/DISC";

import OtherPersonProfile from "./subcomponents/other-person/";
import OwnProfile from "./subcomponents/own-profile/";
// import ProfileTabs from "./subcomponents/ProfileTabs";
// import ProfileHeader from "./subcomponents/ProfileHeader";

export interface Props extends SITE.GeneralStoreInjectedProps {
  isMobile: boolean;
  userID: number;
  isOwnProfile: boolean;
  subInterface?: ProfilePage.Subinterface;
}

const Profile = ({ isMobile, userID, isOwnProfile, subInterface }: Props) => {
  let AppStore = React.useContext(AppStoreContext);

  const [userGradient, updateUserGradient] = React.useState("");
  const [showGradient, updateShowGradient] = React.useState(() => {
    return shouldShowGradient(subInterface);
  });
  const [isPending, startTransition] = React.useTransition();

  // const personDataQuery = usePerson({ userID });
  const discMiniQuery = useDISCMini({
    userID,
    retry:
      subInterface === "communication-tips" ||
      subInterface === "communication-tips-overview" ||
      subInterface === "how-to-read",
    onSuccess: (data) => {
      // const discProf = data?.discProfiles?.[0];
      // if (discProf) {
      //   const grad = calcGradient(
      //     {
      //       d: discProf.d3 ?? 0,
      //       i: discProf.i3 ?? 0,
      //       s: discProf.s3 ?? 0,
      //       c: discProf.c3 ?? 0,
      //     },
      //     0.1
      //   );
      //   updateUserGradient(grad);
      // } else {
      //   updateUserGradient("");
      // }
    },
  });
  const canImportDISCQuery = useCanImportDISC({});

  React.useEffect(() => {
    // * TODO: This updated to be more optimized. This is going to result in more calculations than necessary.
    // * Not so much that it clogs the thread, but
    startTransition(() => {
      const discProf = discMiniQuery.eagerData?.discProfiles?.[0];

      if (discProf) {
        const grad = calcGradient(
          {
            d: discProf.d3 ?? 0,
            i: discProf.i3 ?? 0,
            s: discProf.s3 ?? 0,
            c: discProf.c3 ?? 0,
          },
          0.1
        );

        updateUserGradient(grad);
      } else {
        updateUserGradient("");
      }
    });
  }, [discMiniQuery.eagerData]);

  React.useEffect(() => {
    updateShowGradient(shouldShowGradient(subInterface));
  }, [subInterface]);

  // const personData =
  //   personDataQuery?.data?.person ??
  //   personDataQuery?.storedValueQuery?.data?.person;
  const discMiniProfile = discMiniQuery?.eagerData?.discProfiles[0];
  const canImportDISC =
    (canImportDISCQuery.eagerData?.functions?.["Person TraitR3"]?.changeScope ??
      0) >= 6;
  const canDeleteDISC =
    (canImportDISCQuery.eagerData?.functions?.["Person TraitR3"]?.deleteScope ??
      0) >= 6;

  return (
    <div
      className="interface-profile-inner-wrapper"
      style={
        userGradient && showGradient
          ? {
              background: `linear-gradient(to bottom right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), linear-gradient(96deg${userGradient})`,
            }
          : undefined
      }
    >
      <main className="interface-profile-inner-cont">
        {isOwnProfile ? (
          <OwnProfile
            canImportDISC={canImportDISC}
            canDeleteDISC={canDeleteDISC}
            discMiniProfile={discMiniProfile}
            isLoading={discMiniQuery.isLoading}
            isMobile={isMobile}
            userID={userID}
            subInterface={subInterface}
          />
        ) : (
          <OtherPersonProfile
            canImportDISC={canImportDISC}
            canDeleteDISC={canDeleteDISC}
            discMiniProfile={discMiniProfile}
            isLoading={discMiniQuery.isLoading}
            isMobile={isMobile}
            userID={userID}
          />
        )}
      </main>
    </div>
  );
};

function shouldShowGradient(subInterface: Props["subInterface"]) {
  switch (subInterface) {
    case "communication-tips":
    case "communication-tips-overview":
    case "how-to-read":
      return false;
    default:
      return true;
  }
}

export default Profile;
