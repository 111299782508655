/*
 * Renders top level component for Profile Interface
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

// import TytoCalls from "../../data/network/tyto/";
// import { SessionHandling } from "data/storage/";
import { Menu, Nav, Footer } from "../../components/meta";
// import { SUB_INTERFACE_PATHS } from "../../data/constants/";

import Teams from "./Teams";

import "./style.scss";

function parseIDFromString(id?: string | number) {
  if (!id) {
    return 0;
  }

  const parsedNum = parseInt(`${id}`, 10);

  return parsedNum === NaN ? 0 : parsedNum;
}

interface Props extends SITE.GeneralStoreInjectedProps {
  teamID?: string | number;
}

const TeamsWrapper = (props: Props) => {
  const [safeTeamID, updateSafeTeamID] = React.useState(
    parseIDFromString(props.teamID)
  );
  const [menuHasBeenOpen, updateMenuHasBeenOpen] = React.useState(
    _.get(props, "GeneralStore.state.showMenu", false)
  );

  React.useEffect(() => {
    const newSafeTeamID = parseIDFromString(props.teamID);

    updateSafeTeamID(newSafeTeamID);
  }, [props.teamID]);

  const storeMenuOpenValue = _.get(props, "GeneralStore.state.showMenu", false);

  // * Trigger flag so that turning menu off animates back left
  React.useEffect(() => {
    if (!menuHasBeenOpen && storeMenuOpenValue) {
      updateMenuHasBeenOpen(true);
    }
  }, [storeMenuOpenValue, menuHasBeenOpen]);

  const menuIsOpen = storeMenuOpenValue;
  const isMobile = !!props.GeneralStore?.state?.isMobile;

  return (
    <div className={cx("interface-wrapper", menuIsOpen && "menu-open")}>
      {!isMobile && <Nav activeTab="Team" className="profile-nav" />}
      <Menu className="teams-menu" />

      <div
        className={cx(
          "interface-main-cont interface-teams",
          menuHasBeenOpen && "animate-back"
        )}
      >
        <Teams
          isMobile={_.get(props, "GeneralStore.state.isMobile", false)}
          GeneralStore={props.GeneralStore}
          teamID={safeTeamID}
        />
      </div>

      {isMobile && <Footer activeTab="Team" />}
    </div>
  );
};

export default TeamsWrapper;
