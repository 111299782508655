import { SessionHandling } from "data/storage/";

import { getEncoding } from "./";

const KV_DOMAIN_ID = 551;
const SELENIUM_DOMAIN_ID = 4798;
const CV_DOMAIN_ID = 1825957; // * NOTE: 'CV' ***NOT*** 'Cardone Ventures'
const AUSTIN_USER_ID = 1503800;

export function getSessionWithThumbnailFromAuth4Result(
  authResult: TytoData.AuthResult
) {
  const { authSession, profileImage } = authResult ?? {};

  const enc = getEncoding(profileImage?.encodings ?? [], "ocDEFAULT");

  const profileThumbPath = enc?.pathURL ?? "";

  return {
    profileThumbPath,
    ...(authSession ?? {}),
  };
}

export function getUserHas247UpdatesAccess(): boolean {
  return true;
  // const domainID = SessionHandling.getPropertyFromActiveSession("domainID");

  // return (
  //   domainID === KV_DOMAIN_ID ||
  //   domainID === CV_DOMAIN_ID ||
  //   domainID === SELENIUM_DOMAIN_ID
  // );
}

export function showGraphNumbers(): boolean {
  const userID = SessionHandling.getPropertyFromActiveSession("userID");

  return userID === AUSTIN_USER_ID;
}
