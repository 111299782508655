/*
 * Component Description
 */
import * as React from "react";

import { PROFILE_SECTION_IDS } from "data/constants/";
import { scrollElementIntoView } from "data/helpers/";

import { ReactSelect, TextButton } from "components/common/";

import "./style.scss";

type OptionValue =
  | "graphs"
  | "challenge-areas"
  | "do-and-dont"
  | "3-things"
  | "what-to-expect"
  | "introduction"
  | "growth-areas"
  | "downloads"
  | "profile-motivators"
  | "profile-needs"
  | "profile-provide"
  | "comparisons";

interface Props {
  discMiniProfile?: TytoData.DISCProfileMini;
  isMobile: boolean;
  isOwnProfile?: boolean;
  use247Data?: boolean;
}

const SectionMenu = (props: Props) => {
  const [options, updateOptions] = React.useState(() => {
    return props.isOwnProfile
      ? getOwnProfileOptions(!!props.use247Data)
      : props.discMiniProfile
      ? createSelectOptions({
          discMiniProfile: props.discMiniProfile,
          isOwnProfile: !!props.isOwnProfile,
          use247Data: props.use247Data,
        })
      : SECTION_OPTIONS;
  });
  const [activeSection, updateActiveSection] = React.useState(options[0].value);

  React.useEffect(() => {
    const newOpts = props.isOwnProfile
      ? getOwnProfileOptions(!!props.use247Data)
      : props.discMiniProfile
      ? createSelectOptions({
          discMiniProfile: props.discMiniProfile,
          isOwnProfile: !!props.isOwnProfile,
          use247Data: props.use247Data,
        })
      : SECTION_OPTIONS;

    updateOptions(newOpts);
    updateActiveSection(newOpts[0].value);
  }, [props.isOwnProfile]);

  return (
    <div className="profile-section profile-section-section-menu">
      {props.isMobile ? (
        <ReactSelect
          className="profile-section-menu-main"
          // classNamePrefix="profile-section-menu"
          options={options}
          onChange={(newVal) => {
            updateActiveSection(newVal?.value);
            scrollToSection(newVal?.value);
          }}
          value={activeSection}
        />
      ) : (
        <>
          <section className="profile-section-menu-tabs">
            {options.map((option) => (
              <TextButton
                className="profile-section-menu-tab"
                key={option.value}
                onClick={() => {
                  updateActiveSection(option.value);

                  scrollToSection(option.value);
                }}
                value={option.label}
              />
            ))}
          </section>
        </>
      )}
    </div>
  );
};

function scrollToSection(value: OptionValue) {
  let id = undefined;

  switch (value) {
    case "3-things":
      id = PROFILE_SECTION_IDS.CHARACTERISTICS;
      break;
    case "challenge-areas":
      id = PROFILE_SECTION_IDS.CHALLENGE_AREAS;
      break;
    case "comparisons":
      id = PROFILE_SECTION_IDS.COMPARE_GRAPHS;
      break;
    case "downloads":
      id = PROFILE_SECTION_IDS.DOWNLOADS;
      break;
    case "graphs":
      id = PROFILE_SECTION_IDS.DISC_GRAPHS;
      break;
    case "growth-areas":
      id = PROFILE_SECTION_IDS.GROWTH_AREAS;
      break;
    case "introduction":
      id = PROFILE_SECTION_IDS.INTRODUCTION;
      break;
    case "do-and-dont":
      id = PROFILE_SECTION_IDS.COMM_TIPS;
      break;
    case "what-to-expect":
      id = PROFILE_SECTION_IDS.CONTRIBUTIONS;
      break;
    case "profile-motivators":
      id = PROFILE_SECTION_IDS.MOTIVATORS;
      break;
    case "profile-needs":
      id = PROFILE_SECTION_IDS.NEEDS;
      break;
    case "profile-provide":
      id = PROFILE_SECTION_IDS.PROVIDE;
      break;
    default:
      break;
  }

  if (id) {
    const sectionElement = document.querySelector(`[data-section="${id}"]`);

    if (sectionElement) {
      scrollElementIntoView(sectionElement);
    }
  }
}

function createSelectOptions({
  discMiniProfile,
  isOwnProfile,
  use247Data,
}: {
  discMiniProfile?: TytoData.DISCProfileMini;
  isOwnProfile: boolean;
  use247Data?: boolean;
}) {
  var arr: Array<{ label: string; value: OptionValue }> = [];

  if (!discMiniProfile) {
    return arr;
  }

  if (!discMiniProfile.permitMatrix.discStyle.descriptionFull.HIDE) {
    arr.push({
      label: "Introduction",
      value: "introduction",
    });
  }

  if (!discMiniProfile.permitMatrix.discStyle.graphic.HIDE) {
    arr.push({
      label: "Graphs",
      value: "graphs",
    });
  }
  if (!discMiniProfile.permitMatrix.discCommTip_.HIDE) {
    arr.push({
      label: "Communication Tips",
      value: "do-and-dont",
    });
  }

  if (use247Data) {
    arr.push(
      {
        label: "Comparisons",
        value: "comparisons",
      },
      {
        label: "Motivators",
        value: "profile-motivators",
      },
      {
        label: "Needs",
        value: "profile-needs",
      },
      {
        label: "Provide",
        value: "profile-provide",
      }
    );
  } else {
    if (
      !discMiniProfile.permitMatrix.discStyle.characteristic3.HIDE ||
      !discMiniProfile.permitMatrix.discStyle.characteristic2.HIDE ||
      !discMiniProfile.permitMatrix.discStyle.characteristic1.HIDE
    ) {
      arr.push({
        label: "3 Things to Know",
        value: "3-things",
      });
    }
    if (!discMiniProfile.permitMatrix.discStyle.teamContributions.HIDE) {
      arr.push({
        label: !isOwnProfile ? "Their Contributions" : "Your Contributions",
        value: "what-to-expect",
      });
    }
    if (!discMiniProfile.permitMatrix.discStyleInteraction_.HIDE) {
      arr.push({
        label: "Comparisons",
        value: "comparisons",
      });
    }
  }
  return arr;
}

function getOwnProfileOptions(use247Data: boolean) {
  return use247Data
    ? OWN_PROFILE_SECTION_OPTIONS_247
    : OWN_PROFILE_SECTION_OPTIONS;
}

const OWN_PROFILE_SECTION_OPTIONS: Array<{
  label: string;
  value: OptionValue;
}> = [
  {
    label: "Introduction",
    value: "introduction",
  },
  {
    label: "Graphs",
    value: "graphs",
  },
  {
    label: "Communication Tips",
    value: "do-and-dont",
  },
  {
    label: "Your Contributions",
    value: "what-to-expect",
  },
  {
    label: "Growth Areas",
    value: "growth-areas",
  },
  {
    label: "Challenge Areas",
    value: "challenge-areas",
  },
  {
    label: "Download",
    value: "downloads",
  },
];

const OWN_PROFILE_SECTION_OPTIONS_247: Array<{
  label: string;
  value: OptionValue;
}> = [
  {
    label: "Introduction",
    value: "introduction",
  },
  {
    label: "Graphs",
    value: "graphs",
  },
  {
    label: "Communication Tips",
    value: "do-and-dont",
  },
  {
    label: "Motivators",
    value: "profile-motivators",
  },
  {
    label: "Needs",
    value: "profile-needs",
  },
  {
    label: "Provide",
    value: "profile-provide",
  },
  {
    label: "Download",
    value: "downloads",
  },
];

const SECTION_OPTIONS: Array<{ label: string; value: OptionValue }> = [
  {
    label: "Introduction",
    value: "introduction",
  },
  {
    label: "Graphs",
    value: "graphs",
  },
  {
    label: "Communication Tips",
    value: "do-and-dont",
  },
  {
    label: "3 Things to Know",
    value: "3-things",
  },
  {
    label: "Your Contributions",
    value: "what-to-expect",
  },
  {
    label: "Comparisons",
    value: "comparisons",
  },
];

export default SectionMenu;
