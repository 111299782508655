import { ENDPOINT_R3_DISC_VENDOR_247_DISC_QUESTIONNAIRE_VALIDATE_ANSWERED } from "../../../constants";
import { callWrapper, CallOpts } from "../utils";

import {
  ValidateAnsweredPostParams,
  ValidateAnsweredPostResponse,
} from "./types";

const endpoint =
  ENDPOINT_R3_DISC_VENDOR_247_DISC_QUESTIONNAIRE_VALIDATE_ANSWERED;

export const Vendor247ValidateAnswered = {
  post(params: ValidateAnsweredPostParams, callOpts?: CallOpts) {
    return callWrapper(
      "post",
      endpoint,
      params,
      callOpts
    ) as Promise<ValidateAnsweredPostResponse>;
  },
};
