import * as React from "react";

import { IconProps } from "../typings";

const HomeFilled = ({ className, color, size }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 19"
      height={size}
      width={size}
      fill={color}
    >
      <path d="M0.293 7.70685L7.293 0.706849C7.48053 0.519378 7.73484 0.414063 8 0.414062C8.26516 0.414062 8.51947 0.519378 8.707 0.706849L15.707 7.70685C15.8946 7.89434 15.9999 8.14865 16 8.41385V17.9998C16 18.2651 15.8946 18.5194 15.7071 18.707C15.5196 18.8945 15.2652 18.9998 15 18.9998H10V11.9998H6V18.9998H1C0.734784 18.9998 0.48043 18.8945 0.292893 18.707C0.105357 18.5194 0 18.2651 0 17.9998V8.41385C5.66374e-05 8.14865 0.105451 7.89434 0.293 7.70685Z" />
    </svg>
  );
};

export default HomeFilled;
