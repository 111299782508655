import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { Timestamp } from "components/common";

import "./found-item.scss";

interface StyleData {
  styleName: string;
  letters: string[];
}

interface Props {
  importing: boolean;
  foundItem: TytoData.FindAssessments.FoundAssesment;
  onSelect: (foundItem: Props["foundItem"]) => void;
}

const FoundItem = (props: Props) => {
  const [resultData, updateResultData] = React.useState<StyleData>(() => {
    return getNameAndLetters(props.foundItem);
  });

  React.useEffect(() => {
    updateResultData(getNameAndLetters(props.foundItem));
  }, [props.foundItem]);

  return (
    <li className="disc-import-modal-found-item">
      <button
        className="disc-import-modal-found-item-inner-button"
        disabled={props.importing}
        onClick={() => {
          props.onSelect(props.foundItem);
        }}
      >
        <span className="disc-import-modal-found-item-personname">
          {props.foundItem.userFirstName} {props.foundItem.userLastName}
        </span>

        <span className="disc-import-modal-found-item-stylename">
          {resultData.styleName ?? ""}
        </span>
        {!!resultData.letters.length && (
          <div className="disc-import-modal-found-item-letters">
            {resultData.letters.map((letter) => (
              <span
                className={cx(
                  "disc-import-modal-found-item-letter",
                  `letter-${letter.toLocaleLowerCase()}`
                )}
              >
                {letter}
              </span>
            ))}
          </div>
        )}

        <div>
          <Timestamp date={props.foundItem.creationStamp} />
        </div>
      </button>
    </li>
  );
};

function getNameAndLetters(foundItem: Props["foundItem"]) {
  const tokens = foundItem.style.split(" ");

  let styleName = "";
  let letters: string[] = [];

  if (tokens.length > 2) {
    styleName = tokens.slice(0, styleName.length - 1).join(" ");
    letters = tokens.slice(-1)[0].split("");
  } else if (tokens.length == 2) {
    styleName = tokens[0];
    letters = tokens[1].split("");
  }

  return {
    styleName,
    letters,
  };
}

export default FoundItem;
