import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { Button, Video } from "components/common/";
import { makePathURLQualified } from "data/helpers/";

import "./style.scss";

const VIDEO_URL = makePathURLQualified(
  `/v2/domains/551/videos/r3_intro_video.mp4`,
  { omitSessionKey: true }
);
const VIDEO_POSTER_URL = makePathURLQualified(
  `/v2/domains/551/videos/r3_intro_video_poster.png`,
  { omitSessionKey: true }
);

interface Props {
  discProfileMini?: TytoData.DISCProfileMini;
  onContinue: () => void;
}

const AssessmentTakerWelcome = ({ discProfileMini, onContinue }: Props) => {
  const [firstName, updateFirstName] = React.useState(() => {
    return discProfileMini?.personName?.split?.(" ")?.[0] ?? "";
  });

  React.useEffect(() => {
    updateFirstName(discProfileMini?.personName?.split?.(" ")?.[0] ?? "");
  }, [discProfileMini]);

  return (
    <article className="assessment-taker-welcome">
      <h2 className="assessment-taker-welcome-title title-font">
        Welcome{firstName ? `, ${firstName}` : ""}!
      </h2>

      <p className="assessment-taker-welcome-text">
        Through your R3 assessment, you will learn more than ever before about
        your innate abilities, as well as their corresponding weaknesses.
      </p>

      <p className="assessment-taker-welcome-text">
        You'll learn about how you see yourself and how others see you. It's
        knowledge that will not only help you understand your most valuable
        skills, but will also reveal the connection between your passions and
        your expertise.
      </p>

      {/* <div className="video-placeholder-block" /> */}
      <Video
        className="assessment-taker-welcome-video"
        controls={true}
        poster={VIDEO_POSTER_URL}
        src={VIDEO_URL}
      />

      <Button
        className="large-btn"
        hoverBGSize="hidden"
        onClick={onContinue}
        theme="action"
        value="Continue to Assessment"
      />
    </article>
  );
};

export default AssessmentTakerWelcome;
