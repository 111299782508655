import * as React from "react";

interface Props {
  desc: string;
  title: string;
}

const Characteristic = ({ desc, title }: Props) => {
  return (
    <div className="profile-subsection profile-characteristic-subsection">
      <h5 className="profile-section-subtitle title-font">{title}</h5>
      <p className="profile-section-text">{desc}</p>
    </div>
  );
};

export default Characteristic;
