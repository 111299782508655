import { PROFILE_SECTION_IDS } from "data/constants";

import { replaceYouText } from "../../utils-247";

import "./style.scss";

interface Props {
  motivators: string[];
  firstName: string;
  isOwnProfile: boolean;
  hotSwapYouText?: boolean;
}

const Motivators = (props: Props) => {
  return (
    <>
      <section
        className="profile-section"
        data-section={PROFILE_SECTION_IDS.MOTIVATORS}
      >
        <h2 className="profile-section-title title-font">Motivators</h2>

        <p className="profile-section-motivators-body profile-section-text">
          Motivation is the enthusiasm or willingness to do something. Everybody
          is motivated; however, all people are motivated for their own reasons,
          not somebody else’s. Simply, people are motivated by what they want.
        </p>
      </section>

      <section className="profile-section">
        <h2 className="profile-section-title title-font">
          {props.isOwnProfile ? "You are" : <>{props.firstName} is</>} Motivated
          By:
        </h2>

        <ul className="profile-section-list">
          {props.motivators.map((motivators) => (
            <li className="profile-section-list-item" key={motivators}>
              {props.hotSwapYouText ? replaceYouText(motivators) : motivators}
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default Motivators;
