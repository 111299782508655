import * as React from "react";

import { PROFILE_SECTION_IDS } from "data/constants/";
import { DISCValueRender } from "components/disc";
import "./style.scss";

interface Props {
  discMiniProfile: TytoData.DISCProfileMini;
}

const Introduction = (props: Props) => {
  const text = props.discMiniProfile?.descriptionGeneral
    ? props.discMiniProfile?.descriptionGeneral.replaceAll(
        "[First Name]",
        props.discMiniProfile.personName.split(" ")[0]
      )
    : "Error loading introduction";

  return (
    <IntroductionWrapper discMiniProfile={props.discMiniProfile}>
      <IntroductionGeneric text={text} />
    </IntroductionWrapper>
  );
};

export const IntroductionWrapper = (props: React.PropsWithChildren<Props>) => {
  return (
    <DISCValueRender
      discMiniProfile={props.discMiniProfile}
      isCompareValue={false}
      targetKey="description"
      className="profile-graph-valueRender"
    >
      {props.children}
    </DISCValueRender>
  );
};

interface IntroGenericProps {
  text: string;
}

export const IntroductionGeneric = (props: IntroGenericProps) => {
  return (
    <section
      className="profile-section"
      data-section={PROFILE_SECTION_IDS.INTRODUCTION}
    >
      <h4 className="profile-section-title title-font">Introduction</h4>
      <p className="profile-section-intro-body profile-section-text">
        {props.text}
      </p>
    </section>
  );
};

export default Introduction;
