import * as React from "react";
import * as _ from "lodash";
import { Redirect, useLocation } from "react-router-dom";

import TytoCalls from "data/network/tyto/";
import {
  DEFAULT_APP_BRAND,
  DEFAULT_APP_VERSION,
  DEFAULT_COMPUTER_ID,
  HAS_LOCAL_STORAGE,
  INTERFACE_PATHS,
} from "data/constants/";
import * as Storage from "data/storage/";
import { StoreContext } from "data/stores/AppStore";
// import { StoreContext as GeneralStoreContext } from "data/stores/GeneralStore";
import { Link } from "components/common/";
import { getRedirectPathFromURL } from "data/helpers/";

import "./LoginToTargetSession.scss";

const { keys, SessionHandling, SES_STOR } = Storage;

interface Props extends SITE.GeneralStoreInjectedProps {}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const keyForOutsideSessionKey = "outsideSessionKey";
const keyForHasParentSite = "hasParent";

const LoginToTargetSession = (props: Props) => {
  let AppStore = React.useContext(StoreContext);
  // let GeneralStore = React.useContext(GeneralStoreContext);
  let query = useQuery();

  const [redirectPathFromURL] = React.useState(() => {
    return (
      getRedirectPathFromURL() ?? SES_STOR.get(keys.SES_CHECK_REDIRECT_URL)
    );
  });
  const [outsideSessionKey, updateOutsideSessionKey] = React.useState(
    query.has(keyForOutsideSessionKey) ? query.get(keyForOutsideSessionKey) : ""
  );
  const [tempSessionKey, updateTempSessionKey] = React.useState(
    outsideSessionKey ? "" : SES_STOR.get(keys.TEMP_SESSION_KEY) || ""
  );
  const [shouldRedirectHome, updateShouldRedirectHome] = React.useState(false);
  const [errorMsg, updateErrorMsg] = React.useState("");

  React.useEffect(() => {
    // * [0] - Check for and set hasParentSite
    const hasParentSiteValue = query.get(keyForHasParentSite);

    if (hasParentSiteValue) {
      Storage.SES_STOR.set(Storage.keys.SITE_HAS_PARENT, "true");
    }

    // * [1] - If key supplie in URL, save it, remove from URL, and reload
    if (outsideSessionKey && outsideSessionKey !== tempSessionKey) {
      SES_STOR.set(keys.SES_CHECK_REDIRECT_URL, redirectPathFromURL);
      SES_STOR.set(keys.TEMP_SESSION_KEY, outsideSessionKey);

      window.location.href = INTERFACE_PATHS.LOGIN_WITH_KEY;
    } else if (tempSessionKey) {
      // * [2] - If not found in URL but IS found in sessionStorage, us it!
      if (errorMsg) {
        updateErrorMsg("");
      }

      retrieveSession({
        tempSessionKey,
        onError: (errorMsg) => updateErrorMsg(errorMsg),
        onSuccess: (retrievedSession) => {
          if (AppStore.dispatch) {
            AppStore.dispatch({
              callback: () => {
                SES_STOR.remove(keys.TEMP_SESSION_KEY);
              },
              payload: {
                session: retrievedSession,
              },
              type: "USER_LOGGED_IN",
            });
          }
        },
      });
    } else {
      // * [3] - Not key was found in sessionStorage OR in URL, so something is wrong.
      updateErrorMsg("No Access Token was supplied.");
    }
  }, []);

  React.useEffect(() => {
    if (_.get(AppStore, "state.sessionData") && !shouldRedirectHome) {
      updateShouldRedirectHome(true);
    }
  }, [_.get(AppStore, "state.sessionData")]);

  // * Maybe works?
  React.useEffect(() => {
    if (shouldRedirectHome) {
      window.location.href = redirectPathFromURL ?? INTERFACE_PATHS.HOME;
    }
  }, [shouldRedirectHome]);

  // * Maybe works?
  React.useEffect(() => {
    if (!HAS_LOCAL_STORAGE) {
      window.location.href = INTERFACE_PATHS.NO_STORAGE;
    }
  }, [HAS_LOCAL_STORAGE]);

  // if (shouldRedirectHome) {
  //   return <Redirect to={redirectPathFromURL ?? INTERFACE_PATHS.HOME} />;
  // } else if (!HAS_LOCAL_STORAGE) {
  //   return <Redirect to={INTERFACE_PATHS.NO_STORAGE} />;
  // }

  return (
    <main className="interface-main-cont interface-login-with-sessionKey flex-col-centered">
      <section className="interface-login-with-sessionKey-inner-cont">
        {errorMsg ? (
          <>
            <p className="login-with-sessionkey-error-msg font-size-large">
              {errorMsg}
            </p>

            <Link
              className="login-with-sessionkey-login-link"
              href={INTERFACE_PATHS.LOGIN}
              value="Login Manually"
            />
          </>
        ) : (
          <h2 className="font-size-title">...</h2>
        )}
      </section>
    </main>
  );
};

async function retrieveSession({
  tempSessionKey,
  onError,
  onSuccess,
}: {
  tempSessionKey: string;
  onSuccess: (session: Data.SessionData) => void;
  onError: (errorMsg: string) => void;
}) {
  try {
    const allStoredSessions = SessionHandling.getAllSessions();
    const matchingSession = (allStoredSessions || []).find(
      (session) => session.sessionKey === tempSessionKey
    );

    if (matchingSession) {
      onSuccess(matchingSession);
      return;
    }

    const newSessionResp: any = await TytoCalls.AccountSession.post(
      {
        sessionKey: tempSessionKey,
        appBrand: DEFAULT_APP_BRAND,
        appVersion: DEFAULT_APP_VERSION,
        computerID: DEFAULT_COMPUTER_ID,
      },
      { omitSessionKey: true }
    );

    const newlyCreatedSessionKey = _.get(
      newSessionResp,
      "accountSession.sessionKey",
      ""
    );

    if (newlyCreatedSessionKey) {
      const sessionResp: any = await TytoCalls.AccountSession.get(
        { sessionKey: newlyCreatedSessionKey },
        { omitSessionKey: true }
      );

      // * Handle new session GET resp that excludes sessionKey in resp.session
      if (sessionResp && sessionResp.session) {
        const newSessionData =
          sessionResp && sessionResp.session
            ? {
                ...sessionResp.session,
                sessionKey: newlyCreatedSessionKey,
              }
            : undefined;

        if (newSessionData) {
          onSuccess(newSessionData);
          return;
        }
      }
    }

    onError(
      "Complete Session Data was no retieable with supplied session token."
    );
  } catch (err) {
    const errorMsg =
      typeof err === "string" ? err : _.get(err, "msg", "Error Occurred");

    onError(`${errorMsg}`);
  }
}

const StorageCheckWrapper = (props: Props) => {
  if (!HAS_LOCAL_STORAGE) {
    return <Redirect to={INTERFACE_PATHS.NO_STORAGE} />;
  }

  return <LoginToTargetSession {...props} />;
};

export default StorageCheckWrapper;
