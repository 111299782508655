import { ENDPOINT_R3_DISC_VENDOR_247_DISC_QUESTIONNAIRE } from "../../../constants/";
import { callWrapper, CallOpts } from "../utils";

import {
  DISCQuestionnaireGetParams,
  DISCQuestionnaireGetResponse,
} from "./types";

const endpoint = ENDPOINT_R3_DISC_VENDOR_247_DISC_QUESTIONNAIRE;

export const Vendor247DISCQuestionnaire = {
  get(params: DISCQuestionnaireGetParams, callOpts?: CallOpts) {
    return callWrapper(
      "get",
      endpoint,
      params,
      callOpts
    ) as Promise<DISCQuestionnaireGetResponse>;
  },
};
