/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { Nav, Menu, Footer } from "components/meta";
import { useDISCMini } from "data/network/hooks/";
import { SessionHandling } from "data/storage";
import { calcGradient } from "data/helpers/DISC";

// import { userPrefersReducedMotion } from "data/helpers/";

import Home from "./Home";

import "./style.scss";

interface Props extends SITE.GeneralStoreInjectedProps {}

const HomeWrapper = (props: Props) => {
  const [menuHasBeenOpen, updateMenuHasBeenOpen] = React.useState(
    _.get(props, "GeneralStore.state.showMenu", false)
  );
  const [loggedInUserID, updateLoggedInUserID] = React.useState(() => {
    return SessionHandling.getUserIDOfActiveSession() ?? 0;
  });
  const [userGradient, updateUserGradient] = React.useState("");

  const { eagerData, isLoading } = useDISCMini({
    isEnabled: !!loggedInUserID,
    userID: loggedInUserID,
    onSuccess: (data) => {
      const discProf = data?.discProfiles?.[0];

      if (discProf) {
        const grad = calcGradient({
          d: discProf.d3,
          i: discProf.i3,
          s: discProf.s3,
          c: discProf.c3,
        });

        updateUserGradient(grad);
      } else {
        updateUserGradient("");
      }
    },
  });

  const storeMenuOpenValue = _.get(props, "GeneralStore.state.showMenu", false);

  // * Trigger flag so that turning menu off animates back left
  React.useEffect(() => {
    if (!menuHasBeenOpen && storeMenuOpenValue) {
      updateMenuHasBeenOpen(true);
    }
  }, [storeMenuOpenValue, menuHasBeenOpen]);

  const menuIsOpen = storeMenuOpenValue;
  const isMobile = !!props.GeneralStore?.state?.isMobile;

  return (
    <div
      className={cx("interface-wrapper", menuIsOpen && "menu-open")}
      style={
        userGradient
          ? {
              background: `radial-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.8)), linear-gradient(to top right, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.65)), linear-gradient(to bottom right${userGradient})`,
            }
          : undefined
      }
    >
      {!isMobile && <Nav activeTab="Home" className="home-nav" />}
      <Menu className="home-menu" />

      <main
        className={cx(
          "interface-main-cont interface-home",
          menuHasBeenOpen && "animate-back"
        )}
      >
        <Home
          discProfileMini={eagerData?.discProfiles?.[0]}
          isLoadingDISCMini={isLoading}
          isMobile={_.get(props, "GeneralStore.state.isMobile", false)}
        />
      </main>

      {isMobile && <Footer activeTab="Home" />}
    </div>
  );
};

export default HomeWrapper;
