/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import {
  useFindAssessments247,
  use247ImportMutation,
} from "data/network/hooks/";
import { ActiveSession } from "data/storage";
import { IS_LOCAL_HOST } from "data/constants/";
import { Button, Input, Message, Select, TextButton } from "components/common/";

import Found247Item from "./subcomponents/vendor-247-found-item";

type ActiveVersion = "v2" | "v4" | "247";
type SearchByEnum = "email" | "name";

const SEARCH_BY_OPTS: {
  label: string;
  value: SearchByEnum;
}[] = [
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Name",
    value: "name",
  },
];

interface Props {
  emailAddress?: string;
  personName?: string;
  domainID?: number;
  userID: number;
  onSelect?: () => void;
  existingDISCID?: number;
}

const DISCImport247 = (props: Props) => {
  const [searchBy, updateSearchBy] = React.useState<SearchByEnum>(
    !props.emailAddress && !!props.personName ? "name" : "email"
  );
  const [searchTermForInput, updateSearchTermForInput] = React.useState(
    `${(searchBy === "email" ? props.emailAddress : props.personName) || ""}`
  );
  const [searchTermForSearching, updateSearchTermForSearching] =
    React.useState(searchTermForInput);
  const [displayedVersion] = React.useState<ActiveVersion>("247");
  const [importing, updateImporting] = React.useState(false);
  const [errorMsg, updateErrorMsg] = React.useState("");

  const vendor247FindSelfAssessments = useFindAssessments247({
    userID: props.userID,
    emailAddress: searchBy === "email" ? searchTermForSearching : undefined,
    personName: searchBy === "name" ? searchTermForSearching : undefined,
    isEnabled: displayedVersion === "247" && !!searchTermForSearching,
  });

  const v4ImportDISCMutation = use247ImportMutation({
    onError: (newErrorMsg) => {
      updateErrorMsg(newErrorMsg);
      updateImporting(false);
    },
    onSuccess: () => {
      updateImporting(false);
      props.onSelect?.();
    },
  });

  const searchResults = vendor247FindSelfAssessments.data?.found;
  const isLoadingResults =
    vendor247FindSelfAssessments.isLoading ||
    vendor247FindSelfAssessments.isFetching;
  const btnDisabled = importing || isLoadingResults || !searchTermForInput;

  return (
    <main className="disc-import-modal-main-cont">
      <h2 className="disc-import-modal-title title-font">DISC Import</h2>

      <p>Search by email address the person used to take the assessment.</p>

      <section className="disc-import-modal-inputs-cont">
        <Input
          className="disc-import-modal-input"
          disabled={importing}
          name="Email Address"
          onChange={(newVal) => {
            updateSearchTermForInput(newVal);
          }}
          placeholder="Email Address"
          type="input"
          value={searchTermForInput}
        />

        <Select
          className="disc-import-modal-version-select"
          options={SEARCH_BY_OPTS}
          onChange={(newVal) => {
            updateSearchBy(newVal as SearchByEnum);
            updateSearchTermForSearching(searchTermForInput);
          }}
          value={searchBy}
        />

        <Button
          disabled={btnDisabled}
          theme="action"
          onClick={() => {
            updateSearchTermForSearching(searchTermForInput || "");
          }}
          value="Search"
        />
      </section>

      <div></div>

      {errorMsg && (
        <div>
          <p>{errorMsg}</p>
        </div>
      )}

      <ul
        className={cx(
          "disc-import-modal-found-items",
          importing && "importing-item"
        )}
      >
        {importing && (
          <div className="disc-import-modal-found-items-importing-msg-cont">
            <p className="disc-import-modal-found-items-importing-msg">
              Importing...
            </p>
          </div>
        )}

        {isLoadingResults ? (
          <p>Loading...</p>
        ) : !!searchResults?.length ? (
          <>
            {searchResults.map((foundItem) => (
              <Found247Item
                key={foundItem.assessmentInternalId}
                foundItem={foundItem}
                importing={importing}
                onSelect={(foundItem) => {
                  updateErrorMsg("");
                  updateImporting(true);

                  v4ImportDISCMutation.mutate({
                    foundItem,
                    userID: props.userID,
                    existingDISCID: props.existingDISCID,
                  });
                }}
              />
            ))}
          </>
        ) : (
          <p>No Results</p>
        )}
      </ul>
    </main>
  );
};

export default DISCImport247;
