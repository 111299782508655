import * as _ from "lodash";
import { useQuery } from "react-query";

import { REACT_QUERY_KEYS } from "../../../constants/";
import API from "data/network/tyto";

import { DEFAULT_RQ_OPTS } from "../";

export function useDISCVendorForTake({
  isEnabled = true,
}: {
  isEnabled?: boolean;
}) {
  const query = useQuery(
    [REACT_QUERY_KEYS.DISC_VENDOR_FOR_TAKE],
    async () => {
      const data = await API.DISC.Vendor.ForTake.get({});

      return data;
    },
    {
      ...DEFAULT_RQ_OPTS,
      retry: 2,
      enabled: !!isEnabled,
      onSuccess: (data) => {
        return data;
      },
    }
  );

  return {
    ...query,
    eagerData: query.data,
  };
}
