import * as React from "react";

import { IconProps } from "../typings";

const Download = ({
  className,
  color,
  gradient,
  gradientKey,
  size,
}: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29 29"
      height={size}
      width={size}
      fill={color}
    >
      {Array.isArray(gradient) && (
        <defs>
          <linearGradient id={gradientKey} x1="0" x2="1" y1="0" y2="1">
            {gradient.map((grad) => (
              <stop
                key={`${grad.offsetPercent}-${grad.color}`}
                offset={`${grad.offsetPercent}%`}
                stopColor={grad.color}
              />
            ))}
          </linearGradient>
        </defs>
      )}
        <path fill={Array.isArray(gradient) ? `url(#${gradientKey})` : ""} d="M25.375 21.75V18.125C25.375 17.8846 25.2795 17.6541 25.1096 17.4842C24.9396 17.3142 24.7091 17.2188 24.4688 17.2188C24.2284 17.2188 23.9979 17.3142 23.8279 17.4842C23.658 17.6541 23.5625 17.8846 23.5625 18.125V21.75C23.5625 21.9904 23.467 22.2209 23.2971 22.3908C23.1271 22.5608 22.8966 22.6562 22.6562 22.6562H6.34375C6.1034 22.6562 5.87289 22.5608 5.70293 22.3908C5.53298 22.2209 5.4375 21.9904 5.4375 21.75V18.125C5.4375 17.8846 5.34202 17.6541 5.17207 17.4842C5.00211 17.3142 4.7716 17.2188 4.53125 17.2188C4.2909 17.2188 4.06039 17.3142 3.89043 17.4842C3.72048 17.6541 3.625 17.8846 3.625 18.125V21.75C3.625 22.4711 3.91144 23.1626 4.4213 23.6724C4.93117 24.1823 5.62269 24.4688 6.34375 24.4688H22.6562C23.3773 24.4688 24.0688 24.1823 24.5787 23.6724C25.0886 23.1626 25.375 22.4711 25.375 21.75ZM19.5931 17.0194L15.0619 20.6444C14.9019 20.7708 14.7039 20.8396 14.5 20.8396C14.2961 20.8396 14.0981 20.7708 13.9381 20.6444L9.40688 17.0194C9.24177 16.8635 9.14118 16.6514 9.125 16.4249C9.10882 16.1985 9.17824 15.9742 9.31951 15.7964C9.46079 15.6187 9.66363 15.5005 9.8879 15.4651C10.1122 15.4298 10.3415 15.4799 10.5306 15.6056L13.5938 18.0525V5.4375C13.5938 5.19715 13.6892 4.96664 13.8592 4.79668C14.0291 4.62673 14.2596 4.53125 14.5 4.53125C14.7404 4.53125 14.9709 4.62673 15.1408 4.79668C15.3108 4.96664 15.4062 5.19715 15.4062 5.4375V18.0525L18.4694 15.6056C18.5606 15.5195 18.6688 15.4532 18.787 15.4111C18.9052 15.3689 19.0308 15.3518 19.156 15.3607C19.2812 15.3696 19.4031 15.4045 19.5142 15.463C19.6252 15.5216 19.7228 15.6025 19.8009 15.7007C19.879 15.799 19.9358 15.9124 19.9678 16.0337C19.9998 16.1551 20.0062 16.2817 19.9867 16.4057C19.9671 16.5297 19.9221 16.6482 19.8543 16.7538C19.7866 16.8595 19.6976 16.9499 19.5931 17.0194Z"/>
    </svg>
  );
};

export default Download;


