import { createBrowserHistory } from "history";

import PkgJson from "../../../package.json";

export * from "./system";

// * URLs
const SAFE_APP_NAME = (PkgJson?.name ?? "").replace(/(\s|\_|\-)/gi, "");
export const URL_BASE =
  process.env.REACT_APP_API_HOSTNAME ||
  `${SAFE_APP_NAME ?? ""}api.mocaworks.com`;
export const API_URL = `https://${URL_BASE}/tyto/api`;
export const URL_ORIGIN = `https://${URL_BASE}`;
export const IMAGE_BASE_URL = URL_ORIGIN;
export const START_CONFIG_PATH = `/v2/domains/:domainid/start/mastery_start_config.json`;
export const WS_SERVER_URL = `wss://${
  process.env.REACT_APP_COMMAND_CHANNEL_HOSTNAME || "app.teamtools.com:8080"
}/`;
export const GMAIL_PLUGIN_URL =
  "https://chrome.google.com/webstore/detail/teamtools-browser-extensi/jibpnbbjcanbhijfikdbofnniiangnbd";
export const OUTLOOK_ADD_IN_URL =
  "https://appsource.microsoft.com/en-us/product/office/WA200001805";
export const IS_LOCAL_HOST = /^(localhost|192\.168\.(\d)+\.(\d)+)/i.test(
  window.location.host
);

// * Local Image Names
export const TRYYB_LOGO = "tryyb-mastery-logo.png";
export const R3_LOGO = "r3-logo-quad-small.jpg"; //r3-unconfirmed-logo.png
export const R3_LOGO_BIG = "r3-logo-quad-big.jpg"; //R3_LOGO_NO_TEXT = "r3-logo-no-text.png"
export const PLUGIN_ICONS = "plugin-icons.png";

// * Variables
export const MOBILE_WIDTH_BREAKPOINT = 800;
export const DEFAULT_SESSION_TIMEOUT_LENGTH = 60 * 24 * 90; // 60 minutes in an hour * 24 hours in a day * 90 Days = 90 days in minutes
export const DEFAULT_APP_BRAND = `${PkgJson.name ?? ""}`;
export const DEFAULT_APP_VERSION = `${PkgJson.version}`;
export const DEFAULT_COMPUTER_ID = `${window.navigator.userAgent}`;
export const AUTO_SCROLL_DELAY_IN_MS = 400;
export const NEXT_QUESTION_SCROLL_DELAY_IN_MS = 1600;
export const BROWSER_META_DETA = {
  SUPPORTS: {
    ASPECT_RATIO: "aspectRatio" in document.documentElement.style,
    CONTAINER_QUERIES: "container" in document.documentElement.style,
  },
};
export const BANNER_CAROUSEL_TOP_COUNT = 5;
export const HAS_LOCAL_STORAGE = !!localStorage && !!sessionStorage;
export const HAS_VIBRATION = "vibrate" in navigator;

// * Endpoints
export const ENDPOINT_ASSET_ENCODING = `${API_URL}/Asset/Encoding`;
export const ENDPOINT_BLOCK = `${API_URL}/Block`;
export const ENDPOINT_BLOCK_ENROLLMENT = `${API_URL}/BlockEnrollment`;
export const ENDPOINT_BLOCK_PREREQUISITES = `${API_URL}/Block/Prerequisites`;
export const ENDPOINT_CATALOG_CURRICULUM_PUBLICATION = `${API_URL}/CatalogCurriculumPublication`;
export const ENDPOINT_CATALOG_CURRICULUM_PUBLICATION_SEARCH_LESSON_CONTENT = `${API_URL}/CatalogCurriculumPublication/SearchLessonContent`;
export const ENDPOINT_CONFIGURATION_CLIENT = `${API_URL}/Configuration/Client`;
export const ENDPOINT_CURRICULUM_PUB_CATALOG_ITEMS_TRENDING = `${API_URL}/CurriculumPubCatalogItemsTrending`;
export const ENDPOINT_EXAM_TAKE_ANSWER = `${API_URL}/Exam/Take/Answer`;
export const ENDPOINT_EXAM_TAKE_COMPLETE = `${API_URL}/Exam/Take/Complete`;
export const ENDPOINT_EXAM_TAKE_EVALUATE_RESPONSE = `${API_URL}/Exam/Take/Evaluate/Response`;
export const ENDPOINT_EXAM_TAKE_QUESTION = `${API_URL}/Exam/Take/Question`;
export const ENDPOINT_EXAM_TAKE_TRAINING_INVITE = `${API_URL}/Exam/Take/TrainingInvite`;
export const ENDPOINT_EXAM_TAKE_TRAINING_INVITES = `${API_URL}/Exam/Take/TrainingInvites`;
export const ENDPOINT_DEV_PLAN_ENROLLMENT = `${API_URL}/DevPlanEnrollment`;
export const ENDPOINT_DISC_VENDOR_FOR_TAKE = `${API_URL}/R3Disc/Vendor/Definition/ForTake`;
export const ENDPOINT_DISC_VENDOR_FOR_SEARCH = `${API_URL}/R3Disc/Vendor/Definition/ForSearch`;
export const ENDPOINT_DISC_247_FIND_SELF_ASSESSMENTS = `${API_URL}/r3Disc/Vendor247/findSelfAssessments`;
export const ENDPOINT_DISC_247_IMPORT_SELF_ASSESSMENT = `${API_URL}/r3Disc/Vendor247/importSelfAssessment`;
export const ENDPOINT_DISC_PROFILES = `${API_URL}/DiscProfiles`;
export const ENDPOINT_DISC_PROFILE_INTERACTIVE = `${API_URL}/DiscProfile/Interactive`;
export const ENDPOINT_DISC_PROFILE_INTERACTIVE_PERSON_COMM_TIPS = `${API_URL}/DiscProfile/Interactive/PersonCommunicationTips`;
export const ENDPOINT_DISC_PROFILE_INTERACTIVE_PERSON_COMM_TIP = `${API_URL}/DiscProfile/Interactive/PersonCommunicationTip`;
export const ENDPOINT_DISC_PROFILE_INTERACTIVE_MOCKUP = `${API_URL}/DiscProfile/Interactive/Mockup`;
export const ENDPOINT_DISC_PROFILE_TEAM = `${API_URL}/DiscProfile/Team`;
export const ENDPOINT_DISC_PROFILE_MINI = `${API_URL}/DiscProfile/Mini`;
export const ENDPOINT_DISC_PROFILES_MINI = `${API_URL}/DiscProfiles/Mini`;
export const ENDPOINT_DISC_PROFILES_EMAIL_VIEW_READY = `${API_URL}/DiscProfiles/EmailViewReady`;
export const ENDPOINT_DOMAIN = `${API_URL}/Domain`;
export const ENDPOINT_DOMAIN_IMAGE = `${API_URL}/Domain/Image`;
export const ENDPOINT_DOMAIN_INVITATION_EMAIL = `${API_URL}/DomainInvitationEmail`;
export const ENDPOINT_DOMAIN_INVITATION_EMAIL_TEMPLATE = `${API_URL}/DomainInvitationEmail/Template`;
export const ENDPOINT_DOMAIN_BILLING = `${API_URL}/DomainBilling`;
export const ENDPOINT_DOMAIN_BILLINGS = `${API_URL}/DomainBillings`;
export const ENDPOINT_DOMAIN_MESSAGES = `${API_URL}/Domain/Messages`;
export const ENDPOINT_DOMAIN_MESSAGE = `${API_URL}/Domain/Message`;
export const ENDPOINT_ENROLLMENT_VERIFICATION_REQUEST = `${API_URL}/Enrollment/VerificationRequest`;
export const ENDPOINT_EMAIL_QUEUE_STATUS = `${API_URL}/EmailQueueStatus`;
export const ENDPOINT_EMAIL_QUEUE_STATUS_USER = `${API_URL}/EmailQueueStatus/User`;
export const ENDPOINT_FUNCTIONS_ASP = `${URL_ORIGIN}/v25/nl/functions.asp`;
export const ENDPOINT_LAUNCH_ENROLLMENT = `${API_URL}/Launch/Enrollment`;
export const ENDPOINT_LESSON = `${API_URL}/Lesson`;
export const ENDPOINT_LOGIN_URL = `${API_URL}/Login/Authenticate`;
export const ENDPOINT_LOGIN4_URL = `${API_URL}/Login/Authenticate4`;
export const ENDPOINT_LOGIN_RECOVER_URL = `${API_URL}/Login/Recover`;
export const ENDPOINT_LOGIN_RECOVER_TEAMTOOLS_URL = `${API_URL}/Login/Recover/TeamTools`;
export const ENDPOINT_LOGIN_RESET_PASSWORD_URL = `${API_URL}/Login/resetpassword`;
export const ENDPOINT_LOGIN_RESET_PASSWORD_VALIDATE_URL = `${API_URL}/Login/resetpassword/validate`;
export const ENDPOINT_LOGOUT_URL = `${API_URL}/Session/Logout`;
export const ENDPOINT_PREREQUISITE_ENROLLMENTS = `${API_URL}/PrerequisiteEnrollments`;
export const ENDPOINT_R3_DISC_VENDOR_247_ASSESSMENT = `${API_URL}/r3Disc/Vendor247/Assessment`;
export const ENDPOINT_R3_DISC_VENDOR_247_DISC_EXTENSION = `${API_URL}/r3Disc/Vendor247/Extension`;
export const ENDPOINT_R3_DISC_VENDOR_247_DISC_QUESTIONNAIRE = `${API_URL}/r3Disc/Vendor247/DiscQuestionnaire`;
export const ENDPOINT_R3_DISC_VENDOR_247_DISC_QUESTIONNAIRE_VALIDATE = `${API_URL}/r3Disc/Vendor247/DiscQuestionnaire/Validate`;
export const ENDPOINT_R3_DISC_VENDOR_247_DISC_QUESTIONNAIRE_CONVERT_TO_RESPONSE = `${API_URL}/r3Disc/Vendor247/DiscQuestionnaire/convertToResponse`;
export const ENDPOINT_R3_DISC_VENDOR_247_DISC_QUESTIONNAIRE_VALIDATE_ANSWERED = `${API_URL}/r3Disc/Vendor247/DiscQuestionnaire/ValidateAnswered`;
export const ENDPOINT_R3_DISC_VENDOR_247_DIRECT_DISC_QUESTIONNAIRE = `${API_URL}/r3Disc/Vendor247/Direct/DiscQuestionnaire`;
export const ENDPOINT_R3_DISC_VENDOR_247_FIND_USERS = `${API_URL}/r3Disc/Vendor247/findUsers`;
export const ENDPOINT_R3_DISC_VENDOR_247_REGISTER = `${API_URL}/r3Disc/Vendor247/Register`;
export const ENDPOINT_SESSION_CHECK = `${API_URL}/AccountSession`;
export const ENDPOINT_TEAM_INFO = `${API_URL}/TeamsByFunction`;
export const ENDPOINT_PEOPLE_KEYS_CALLBACK_REPORT = `${API_URL}/PeopleKeysCallback/Report`;
export const ENDPOINT_PEOPLE_KEYS_FIND_ASSESSMENTS = `${API_URL}/PeopleKeys/FindAssessments`;
export const ENDPOINT_PEOPLE_KEYS_FIND_ASSESSMENTS_V4 = `${API_URL}/PeopleKeys/v4/FindAssessments`;
export const ENDPOINT_PEOPLE_KEYS_INITIALIZE = `${API_URL}/PeopleKeys/Initialize`;
export const ENDPOINT_PEOPLE_KEYS_NEXT_PAGE = `${API_URL}/PeopleKeys/NextPage`;
export const ENDPOINT_PEOPLE_KEYS_PAGES = `${API_URL}/PeopleKeys/Pages`;
export const ENDPOINT_PEOPLE_KEYS_RESPONSE = `${API_URL}/PeopleKeys/Response`;
export const ENDPOINT_PEOPLE_KEYS_V4_IMPORT = `${API_URL}/peoplekeys/v4/import`;
export const ENDPOINT_PERSON_GET = `${API_URL}/Person`;
export const ENDPOINT_PERSON_MY_PASSWORD = `${API_URL}/Person/MyPassword`;
export const ENDPOINT_PERSON_PASSWORD = `${API_URL}/Person/Password`;
export const ENDPOINT_PERSON_PROFILE_PHOTO = `${API_URL}/Person/profilephoto`;
export const ENDPOINT_PLAN_ENROLL = `${API_URL}/DevPlan/Enrollment`;
export const ENDPOINT_SAVEFORLESSON = `${API_URL}/Asset/Saveforlesson`;
export const ENDPOINT_TASKS = `${API_URL}/Tasks`;
export const ENDPOINT_TASK_VERIFICATION_REQUEST = `${API_URL}/Task/VerificationRequest`;
export const ENDPOINT_TASK_STRUCTURE = `${API_URL}/Task/Structure`;
export const ENDPOINT_TEAM = `${API_URL}/Team`;
export const ENDPOINT_TEAMBOARD = `${API_URL}/Teamboard`;
export const ENDPOINT_TEAM_TOOLS_CONFIG = `${API_URL}/TeamTools/Config`;
export const ENDPOINT_TEAM_TOOLS_EMAIL_LOGIN = `${API_URL}/TeamTools/EmailLogin`;
export const ENDPOINT_TEAM_TOOLS_INVITE = `${API_URL}/TeamToolsInvite/`;
export const ENDPOINT_TEAM_TOOLS_INVITE_EMAIL = `${API_URL}/TeamToolsInvite/Email`;
export const ENDPOINT_TEAM_TOOLS_INVITE_TEMPSESSION = `${API_URL}/TeamToolsInvite/tempSession`;
export const ENDPOINT_TEAM_MEMBERSHIP_PERSON = `${API_URL}/TeamMembership/Person`;
export const ENDPOINT_TRAINING = `${API_URL}/Training`;
export const ENDPOINT_TIMEZONES = `${API_URL}/TimeZone`;
export const ENDPOINT_PERSON_GET_ADVANCED = `${API_URL}/People/AdvancedSearch`;
export const ENDPOINT_MENU_GET = `${API_URL}/Menu`;

// * Site Theme Options
export const SITE_THEMES = {
  dark: "dark" as SITE.SiteTheme.dark,
  light: "light" as SITE.SiteTheme.light,
  system: "system" as SITE.SiteTheme.system,
};

// * SET_STATUS SEMANTIC ALIASES
export const SET_STATUSES = {
  NOT_SPECIFIED: 0,
  SYSTEM: 1,
  AUTOMATIC: 1,
  MANUALLY_SET: 2,
  COMPLETE_AT_LAUNCH: 3,
  VERFIED_BY_3RD_PARTY: 4,
  COMPLETE_ON_UPLOAD: 5,
  COMPLETE_AFTER_UPLOAD_IS_VERIFIED: 6,
  AT_END_OF_VIDEO: 7,
  AT_END_OF_CONTENT: 7,
  VERIFY_LIST: 8,
  UPLOAD_VERIFIED_LIST: 9,
  VERIFY_CHOICE: 10,
  VERIFY_CHOICE_UPLOAD: 11,
};

// * DISC Colors
export const DISC_COLOR_D = "#f36062"; //classic = f75f5b    - Team Tool af4986
export const DISC_COLOR_I = "#69ade5"; //classic = 69ade5    - Team Tool 2597d4
export const DISC_COLOR_S = "#74f165"; //classic = 74f165    - Team Tool 67b26a
export const DISC_COLOR_C = "#bd5eda"; // classic = bd5eda   - Team Tool fec42c
export const DISC_COLOR_A = DISC_COLOR_C; // classic = bd5eda   - Team Tool fec42c
export const DISC_COLOR_D_DARK = "#8f3688";
export const DISC_COLOR_I_DARK = "#1073c7";
export const DISC_COLOR_S_DARK = "#0d7746";
export const DISC_COLOR_C_DARK = "#f89a30";
export const DISC_COLOR_A_DARK = DISC_COLOR_C_DARK;
// * For DISC stuff (Forked Constant from TeamTools)
export const GRADIENT_DIFF_PERCENT_WIDTH = 18;

// * Interface Paths
export const INTERFACE_PATHS = {
  ASSESSMENT: "/assessment",
  BROWSE: "/browse",
  COURSE: "/course",
  COURSES: "/courses",
  HOME: "/",
  LEARNING: "/learning",
  LOGIN: "/login",
  LOGIN_WITH_KEY: "/session-check",
  NO_STORAGE: "/no-storage",
  PROFILE: "/profile",
  RESULTS: "/results",
  SEARCH: "/search",
  TEAMS: "/teams",
  SETTINGS: "/settings",
};

export const SUB_INTERFACE_PATHS = {
  HOME: {
    SEARCH: "search",
  },
  PROFILE: {
    HOW_TO_READ: "how-to-read",
    RESULTS: "results",
    COMM_TIPS_OVERVIEW: "communication-tips-overview",
    COMM_TIPS: "communication-tips",
  },
};

// * Misc
export const INTERFACE_SEARCH_PARAMS = {
  COURSE: {
    TARGET_PREREQUISITE_ID: "targetPrereqID",
  },
  PLAN: {
    TARGET_STEP_ID: "targetStepID",
  },
};

export const REACT_QUERY_KEYS = {
  ADVANCED_SEARCH_PERSON: "person-adv-search",
  ASSET_ENCODING: "asset-encoding",
  FUNCTIONS_ASP: "functions-asp",
  EXAM_INVITES: "exam-invites",
  EXAM_QUESTION: "exam-question",
  EXAM_TRAINING_INVITES: "exam-training-invites",
  TASK_STRUCTURE: "task-structure",
  TRAINING: "training",
  TRENDING: "trending",
  CATALOG: "catalog",
  BLOCK: "block",
  BLOCK_PREREQUISITES: "block-prerequisites",
  LAUNCH_ENROLLMENT: "launch-enrollment",
  LESSON: "lesson",
  PERSON: "person",
  PERSON_COMM_TIPS: "person-comm-tips",
  DISC_VENDOR_FOR_TAKE: "disc-vendor-for-take",
  DISC_VENDOR_FOR_SEARCH: "disc-vendor-for-search",
  DISC_247_FIND_ASSESSMENTS: "disc-247-find-assessments",
  PEOPLE_KEYS_FIND_ASSESSMENTS_V2: "people-keys-fa-v2",
  PEOPLE_KEYS_FIND_ASSESSMENTS_V4: "people-keys-fa-v4",
  PEOPLE_KEYS_INITIALIZE: "people-keys-intialize",
  PEOPLE_KEYS_NEXT_PAGE: "people-keys-nextpage",
  PEOPLE_KEYS_PAGES: "people-keys-pages",
  PREREQUISITE_ENROLLMENTS: "block-prerequisite-enrollments",
  TIMEZONES: "timezones",
  DISC_MINI_USER: "disc-mini-user",
  DISC_MINI_INTERACTIVE: "disc-mini-interactive",
  DISC_MINI_INTERACTIVE_MOCKUP: "disc-mini-interactive-mockup",
  DISC_MINI_TEAM: "disc-mini-team",
  DISC_PROFILE_TEAM: "disc-profile-team",
  TEAMS_BY_FUNCTION: "teams-by-function",
  VENDOR_247_ASSESSMENT: "vendor-247-assessment",
  VENDOR_247_DISC_QUESTIONNAIRE: "vendor-247-disc-questionnaire",
  VENDOR_247_DISC_EXTENSION: "vendor-247-disc-extension",
  VENDOR_247_CONVERT_TO_RESPONSE: "vendor-247-convert-to-response",
  VENDOR_247_FIND_USERS: "vendor-247-find-users",
  VENDOR_247_REGISTER: "vendor-247-register",
  VENDOR_247_VALIDATE_ANSWERED: "vendor-247-va;idate-answered",
};

export const DOMAINS_MAP = {
  AMI: 1752244,
  AUDIGY: 15572,
  BELTONE: 1389407,
  BUILDING_AN_EMPIRE: 1964196,
  CARDONE_VENTURES: 1698652,
  CV: 1825957,
  HEALTH_PLAN_10X: 2001650,
};

export const EXAM_DISPLAY_RESULTS_DURING = {
  ocRESULTANDFB: "ocRESULTANDFB",
  ocRESULT: "ocRESULT",
  ocRESULTCORRECT: "ocRESULTCORRECT",
  ocRESCORANDFB: "ocRESCORANDFB",
  ocDISABLED: "ocDISABLED",
  ocVACANT: "ocVACANT",
};

export const TRACKING_EVENT_TYPES: {
  [friendlyName: string]: Tracking.InteractionEventType;
} = {
  MENU_BUTTON_TOGGLED: "Menu Button Toggled",
  // MENU_INTERFACE_LINK_CLICKED: "Menu Interface-Link Clicked",
  // MENU_COURSE_STEP_CLICKED: "Menu Course Step Clicked",
  // MENU_COURSE_SECTION_TOGGLED: "Menu Course Section Toggled",
  // NAV_LINK_CLICKED: "Nav Link Clicked",
  // NAV_HOME_IMAGE_CLICKED: "",
  // NAV_USER_THUMB_CLICKED: "",
  // NAV_USER_SEARCH_OPENED: "",
  // TRAINING_TILE_CLICKED: "",
  // COURSE_ASSET_TILE_CLICKED: "",
  // TRAINING_TILES_SCROLL_BUTTON_CLICKED: "",
};

export const PROFILE_SECTION_IDS = {
  CHARACTERISTICS: "profile-characterics",
  COMM_TIPS: "profile-comm-tips",
  COMPARE_GRAPHS: "profile-compare-graphs",
  CHALLENGE_AREAS: "challenge-areas",
  CONTRIBUTIONS: "profile-contributions",
  DOWNLOADS: "profile-downloads",
  DISC_GRAPHS: "profile-disc-graphs",
  GROWTH_AREAS: "growth-areas",
  INFO: "profile-info",
  INTRODUCTION: "profile-introduction",
  PERSPECTIVES: "profofile-perspectives",
  NEEDS: "profile-needs",
  MOTIVATORS: "profile-motivators",
  PROVIDE: "profile-provide",
};

// * GOAL IMAGE RATIOS
export const GOAL_IMAGE_RATIOS = {
  SQUARE: {
    height: 1000,
    width: 1000,
    aspectRatio: 1,
  },
  PORTRAIT: {
    width: 736,
    height: 1050,
    aspectRatio: 368 / 525,
  },
  LANDSCAPE: {
    width: 1104,
    height: 357,
    aspectRatio: 368 / 119,
  },
};

export const BROWSER_HISTORY = createBrowserHistory();
