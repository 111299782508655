import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import "./style.scss";

interface Props {
  groupProfile: TytoData.DISCTeamProfile;
}

const GroupDecisionMaking = (props: Props) => {
  return (
    <section className="group-section group-decision-making">
      <h4 className="group-section-title title-font">Decision Making Style</h4>

      <p className="group-section-text">
        {props.groupProfile?.decisionMaking ?? ""}
      </p>
    </section>
  );
};

export default GroupDecisionMaking;
