import { ENDPOINT_PERSON_GET } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_PERSON_GET;

export const Person = {
  get(
    params: Endpoints.Tyto.Person.GetParameters & Endpoints.Tyto.MetaArgs,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      { ...params },
      callOpts
    ) as Promise<Endpoints.Responses.Person.Get>;
  },
  post(params: Endpoints.Tyto.Person.PostParameters, callOpts?: CallOpts) {
    return callWrapper("post", endpoint, { ...params }, callOpts);
  },
  put(params: Endpoints.Tyto.Person.PutParameters, callOpts?: CallOpts) {
    return callWrapper("put", endpoint, { ...params }, callOpts);
  },
};
