/*
 * Component Description
 */
import * as React from "react";

import { Link } from "../../components/common/";
import { Props as LinkProps } from "../../components/common/link/";
import { INTERFACE_PATHS } from "../../data/constants/";

import "./404.scss";

interface Props {
  content?: string;
  linkData?: Partial<LinkProps>;
  title?: string;
}

const FourZeroFour = (props: Props) => {
  return (
    <main className="interface-main-cont interface-404-notfound">
      <h2 className="notfound-title title-font font-size-title">
        {props.title || "Page Not Found 🤔"}
      </h2>

      <p className="notfound-text font-size-normal">
        {props.content ? (
          <>
            {props.content}

            {props.linkData && (
              <Link
                className="notfound-text-link"
                href={INTERFACE_PATHS.HOME}
                value="This link to your home page"
                {...(props.linkData ?? {})}
              />
            )}
          </>
        ) : (
          <>
            The interface{" "}
            <span className="notfound-current-path">
              {window.location.pathname}
            </span>{" "}
            does not match any registered routes. <br /> <br /> You can follow{" "}
            <Link
              className="notfound-text-link"
              href={INTERFACE_PATHS.HOME}
              value="This link to your home page"
              {...(props.linkData ?? {})}
            />
          </>
        )}
      </p>
    </main>
  );
};

export default FourZeroFour;
