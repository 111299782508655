import * as _ from "lodash";

import API from "data/network/tyto/";

import {
  DISC_COLOR_D,
  DISC_COLOR_I,
  DISC_COLOR_S,
  DISC_COLOR_C,
  DISC_COLOR_D_DARK,
  DISC_COLOR_I_DARK,
  DISC_COLOR_S_DARK,
  DISC_COLOR_C_DARK,
  GRADIENT_DIFF_PERCENT_WIDTH,
  DISC_COLOR_A_DARK,
  DISC_COLOR_A,
} from "data/constants/";
import { getDISCColors } from "data/helpers/";
import { iconType } from "components/common/icon/typings/";

function getDISCColorsObject(modifier?: "dark") {
  const [d, i, s, c, a] = getDISCColors(modifier);

  return {
    D: d || (modifier === "dark" ? DISC_COLOR_D_DARK : DISC_COLOR_D),
    d: d || (modifier === "dark" ? DISC_COLOR_D_DARK : DISC_COLOR_D),
    I: i || (modifier === "dark" ? DISC_COLOR_I_DARK : DISC_COLOR_I),
    i: i || (modifier === "dark" ? DISC_COLOR_I_DARK : DISC_COLOR_I),
    S: s || (modifier === "dark" ? DISC_COLOR_S_DARK : DISC_COLOR_S),
    s: s || (modifier === "dark" ? DISC_COLOR_S_DARK : DISC_COLOR_S),
    C: c || (modifier === "dark" ? DISC_COLOR_C_DARK : DISC_COLOR_C),
    c: c || (modifier === "dark" ? DISC_COLOR_C_DARK : DISC_COLOR_C),
    A: a || (modifier === "dark" ? DISC_COLOR_A_DARK : DISC_COLOR_A),
    a: a || (modifier === "dark" ? DISC_COLOR_A_DARK : DISC_COLOR_A),
  };
}

// const discColorKeyMap = getDISCColorsObject();
// const discDarkColorKeyMap = getDISCColorsObject("dark");

// const discColorKeyMap = {
//   d: DISC_COLOR_D,
//   i: DISC_COLOR_I,
//   s: DISC_COLOR_S,
//   c: DISC_COLOR_C,
// };

export function calcGradient(
  discValues: {
    d: number;
    i: number;
    s: number;
    c: number;
  },
  singleLetterToOpactity = 0.4
) {
  if (!discValues) {
    return "";
  }

  // * [1] Remove non-positive values
  const positiveValues: {
    discKey: "d" | "i" | "s" | "c";
    value: number;
    labelSortValue: number;
  }[] = (
    [
      { discKey: "d", value: discValues.d, labelSortValue: 1 },
      { discKey: "i", value: discValues.i, labelSortValue: 2 },
      { discKey: "s", value: discValues.s, labelSortValue: 3 },
      { discKey: "c", value: discValues.c, labelSortValue: 4 },
    ] as {
      discKey: "d" | "i" | "s" | "c";
      value: number;
      labelSortValue: number;
    }[]
  )
    .filter(({ value }) => value > 0)
    .reduce(
      (
        accum: {
          discKey: "d" | "i" | "s" | "c";
          value: number;
          labelSortValue: number;
        }[],
        discItem
      ) => {
        if (accum.length < 1) {
          return [...accum, discItem];
        } else {
          return _.sortBy([...accum, discItem], ["value"]).slice(-2);
        }
      },
      []
    );

  // * [2] Get total sum of positive values
  const sum: number = positiveValues.reduce((accum, discItem) => {
    accum += discItem.value;

    return accum;
  }, 0);

  // * [3] Check if IC are next to each other (to avoid inaccurqte gradient)
  const hasNeighboringIC = false;
  // const hasNeighboringIC = positiveValues
  //   .map((discItem) => discItem.discKey)
  //   .join("")
  //   .includes("ic");

  // * [4] Use values to give equivalent percentage out of 100%, then sort into DISC order
  const valuesWithPercentage = positiveValues.map((discItem) => {
    return {
      ...discItem,
      percentage: (discItem.value * 100) / sum,
    };
  });

  const discColorKeyMap = getDISCColorsObject();

  /**
   * was previous this when onl one positive value
   * `, ${getDISCColor(
          valuesWithPercentage[0].discKey,
          false
        )} 0% 10%, ${getDISCColor(valuesWithPercentage[0].discKey, true)} 100%`
   */

  const gradient =
    valuesWithPercentage.length === 1
      ? `, ${getDISCColor(
          valuesWithPercentage[0].discKey,
          false
        )} 0% 10%, rgba(255, 255, 255, ${singleLetterToOpactity}) 100%`
      : valuesWithPercentage.reduce((accum, discItem, curIdx) => {
          let curItem = "";

          if (!curIdx) {
            const firstItemEnd = Math.max(
              Math.min(
                discItem.percentage - GRADIENT_DIFF_PERCENT_WIDTH / 2,
                100
              ),
              0
            );

            curItem = `0%, ${
              discColorKeyMap[discItem.discKey]
            } ${firstItemEnd.toFixed(3)}%`;
          } else {
            const secondItemStart = Math.max(
              Math.min(
                discItem.percentage + GRADIENT_DIFF_PERCENT_WIDTH / 2,
                100
              ),
              0
            );

            curItem = `${secondItemStart.toFixed(3)}%`;
          }

          return `${accum}, ${discColorKeyMap[discItem.discKey]} ${curItem}${
            hasNeighboringIC && discItem.discKey === "i" ? ", #fff 0%" : ""
          }`;
        }, "");

  return gradient;
}

export function calcGradientForIcon(discValues: {
  d: number;
  i: number;
  s: number;
  c: number;
}) {
  if (!discValues) {
    return undefined;
  }

  // * [1] Remove non-positive values
  const positiveValues: {
    discKey: "d" | "i" | "s" | "c";
    value: number;
    labelSortValue: number;
  }[] = (
    [
      { discKey: "d", value: discValues.d, labelSortValue: 1 },
      { discKey: "i", value: discValues.i, labelSortValue: 2 },
      { discKey: "s", value: discValues.s, labelSortValue: 3 },
      { discKey: "c", value: discValues.c, labelSortValue: 4 },
    ] as {
      discKey: "d" | "i" | "s" | "c";
      value: number;
      labelSortValue: number;
    }[]
  )
    .filter(({ value }) => value > 0)
    .reduce(
      (
        accum: {
          discKey: "d" | "i" | "s" | "c";
          value: number;
          labelSortValue: number;
        }[],
        discItem
      ) => {
        if (accum.length < 1) {
          return [...accum, discItem];
        } else {
          return _.sortBy([...accum, discItem], ["value"]).slice(-2);
        }
      },
      []
    );

  // * [2] Get total sum of positive values
  const sum: number = positiveValues.reduce((accum, discItem) => {
    accum += discItem.value;

    return accum;
  }, 0);

  // * [3] Check if IC are next to each other (to avoid inaccurqte gradient)
  const hasNeighboringIC = positiveValues
    .map((discItem) => discItem.discKey)
    .join("")
    .includes("ic");

  // * [4] Use values to give equivalent percentage out of 100%, then sort into DISC order
  const valuesWithPercentage = positiveValues.map((discItem) => {
    return {
      ...discItem,
      percentage: (discItem.value * 100) / sum,
    };
  });

  return valuesWithPercentage.map((value, curIdx) => ({
    offsetPercent: !curIdx
      ? value.percentage - GRADIENT_DIFF_PERCENT_WIDTH / 2
      : value.percentage + GRADIENT_DIFF_PERCENT_WIDTH / 2,
    color: getDISCColor(value.discKey),
  }));
}

function calcMean(values: number[]) {
  return values.reduce((total, number) => total + number, 0) / values.length;
}

export function calcStandardDeviation(values: number[]) {
  let m = calcMean(values);

  return Math.sqrt(
    values.reduce((sq, n) => {
      return sq + Math.pow(n - m, 2);
    }, 0) /
      (values.length - 1)
  );
}

const discColorKeyMap = getDISCColorsObject();
const discDarkColorKeyMap = getDISCColorsObject("dark");

const getDISCColor = (discKey: "d" | "i" | "s" | "c", useDark?: boolean) => {
  switch (discKey) {
    case "d":
      if (useDark) {
        return discDarkColorKeyMap.D;
      }

      return discColorKeyMap.D;
    case "i":
      if (useDark) {
        return discDarkColorKeyMap.I;
      }

      return discColorKeyMap.I;
    case "s":
      if (useDark) {
        return discDarkColorKeyMap.S;
      }

      return discColorKeyMap.S;
    case "c":
      if (useDark) {
        return discDarkColorKeyMap.C;
      }

      return discColorKeyMap.C;
    default:
      return "#ffffff";
  }
};

// export function findRecipientsWithoutPeopleInfo(
//   peopleData: { [x: string]: PersonData },
//   recipients: any[]
// ) {
//   const peopleWithoutPeopleData = recipients.filter(
//     person => !peopleData[person.emailAddress]
//   );
//   console.log("People without DISC data found: ", peopleWithoutPeopleData);

//   return peopleWithoutPeopleData;
// }

// export function findRecipientsWithoutDISCMiniInfo(
//   discMini: { [x: string]: Tyto.DISCProfileMini },
//   recipients: Office.EmailAddressDetails[]
// ): Office.EmailAddressDetails[] {
//   const peopleWithoutDISCMiniData = recipients.filter(
//     person => !discMini[person.emailAddress.toLowerCase()]
//   );
//   console.log(
//     `(${peopleWithoutDISCMiniData.length}) People without DISC data found: `,
//     peopleWithoutDISCMiniData
//   );

//   return peopleWithoutDISCMiniData;
// }

// export function getFocusedPerson({
//   focusedPerson,
//   recipients,
//   recentlyRetrieved
// }: {
//   recipients?: Office.EmailAddressDetails[];
//   focusedPerson?: string;
//   recentlyRetrieved?: Tyto.DISCProfileMini[];
// }) {
//   if (!focusedPerson && (!recipients || !recipients.length)) {
//     return undefined;
//   }

//   if (focusedPerson) {
//     if (
//       (!recipients || !recipients.length) &&
//       (!recentlyRetrieved || !recentlyRetrieved.length)
//     ) {
//       return undefined;
//     }

//     const personFromRecipients = focusedPerson
//       ? (recipients || []).find(
//         recipient => recipient.emailAddress.toLowerCase() === focusedPerson
//       )
//       : undefined;

//     if (personFromRecipients) {
//       return personFromRecipients;
//     }

//     const personFromRecent = focusedPerson
//       ? (recentlyRetrieved || []).find(
//         recent => recent.emails[0].toLowerCase() === focusedPerson
//       )
//       : undefined;

//     return personFromRecent
//       ? {
//         emailAddress: personFromRecent.emails[0],
//         displayName: personFromRecent.personName
//       }
//       : undefined;
//   }

//   return recipients ? recipients[0] : undefined;
// }

// export function getItemFromSessionData(key: keyof SessionData) {
//   const sessionData = Office.context.roamingSettings.get(SESSION_DATA_KEY);

//   if (!sessionData || typeof sessionData !== "object") {
//     return undefined;
//   }

//   return sessionData[key];
// }

export function getLetterColor(label: string, asDark?: boolean) {
  if (asDark) {
    const discDarkColorKeyMap = getDISCColorsObject("dark");

    switch (label) {
      case "d":
        return discDarkColorKeyMap.D;
      case "i":
        return discDarkColorKeyMap.I;
      case "s":
        return discDarkColorKeyMap.S;
      case "c":
        return discDarkColorKeyMap.C;
      case "a":
        return discDarkColorKeyMap.A;
      default:
        return "#fff";
    }
  }

  const discColorKeyMap = getDISCColorsObject();

  switch (label) {
    case "d":
      return discColorKeyMap.D;
    case "i":
      return discColorKeyMap.I;
    case "s":
      return discColorKeyMap.S;
    case "c":
      return discColorKeyMap.C;
    case "a":
      return discColorKeyMap.A;
    default:
      return "#fff";
  }
}

export function getNameFromLetter(label: string) {
  switch (label) {
    //CV LABELS
    case "d":
      return "Driver";
    case "i":
      return "Influencer";
    case "s":
      return "Stabilizer";
    case "c":
      return "Analyzer";
    //Classic Labels
    case "classic-d":
      return "Dominant";
    case "classic-i":
      return "Influential";
    case "classic-s":
      return "Steady";
    case "classic-c":
      return "Concientious";
    default:
      return "";
  }
}

const iconTypes: iconType[] = [
  "anchor",
  "angle-down",
  "angle-left",
  "angle-right",
  "angle-up",
  "balance-scale",
  "brain",
  "chess-knight",
  "chess-rook",
  "close",
  "circle",
  "comment-alt-exclamation",
  "compass",
  "dove",
  "drum",
  "envelope",
  "filter",
  "glass-cheers",
  "glasses",
  "handshake",
  "hat-cowboy-side",
  "id-card",
  "knight",
  "leaf-oak",
  "lightbulb-on",
  "list-ul",
  "minus",
  "pencil-ruler",
  "pennant",
  "person-dolly",
  "phone",
  "plus",
  "project-diagram",
  "projector",
  "puzzle-piece",
  "question",
  "question-circle",
  "rabbit-fast",
  "rocket-launch",
  "route",
  "ruler-triangle",
  "search",
  "seedling",
  "sign-out",
  "slash",
  "solar-system",
  "sword",
  "umbrella",
  "user",
  "user-alt",
  "user-alt-solid",
  "users",
];

export function isValidIcon(icon?: iconType): iconType {
  if (!icon) {
    return "question-circle";
  }

  const isValidIcon = iconTypes.some((validIcon) => validIcon === icon);

  return isValidIcon ? icon : "question-circle";
}

function mapMiniProfileByEmails(discMiniProfile: TytoData.DISCProfileMini): {
  [x: string]: TytoData.DISCProfileMini;
} {
  if (!discMiniProfile || !discMiniProfile.emails) {
    return {};
  }

  const profileMappedToEmails = _.reduce(
    discMiniProfile.emails,
    (accum: { [x: string]: TytoData.DISCProfileMini }, email) => {
      accum[email.toLowerCase()] = discMiniProfile;

      return accum;
    },
    {}
  );

  console.log("Profile Mapped to Emails: ", profileMappedToEmails);
  return profileMappedToEmails;
}

export function mapPersonEmailsByPersonIDs(
  discMiniProfiles: TytoData.DISCProfileMini[]
): { [x: number]: string } {
  const mappedProfiles = _.reduce(
    discMiniProfiles,
    (accum, discMiniProfile) => {
      // * For incomplete DISC Profiles
      if (!discMiniProfile.styleKey3) {
        return accum;
      }

      const mappedByEmailPersonID = {
        [discMiniProfile.personID]: discMiniProfile.emails[0],
      };

      return {
        ...accum,
        ...mappedByEmailPersonID,
      };
    },
    {}
  );

  return mappedProfiles;
}

export function mapMiniProfilesByEmails(
  discMiniProfiles: TytoData.DISCProfileMini[]
): { [x: string]: TytoData.DISCProfileMini } {
  const mappedProfiles = _.reduce(
    discMiniProfiles,
    (accum, discMiniProfile) => {
      // * For incomplete DISC Profiles
      if (!discMiniProfile.styleKey3) {
        return accum;
      }

      const mappedByEmails = mapMiniProfileByEmails(discMiniProfile);

      return {
        ...accum,
        ...mappedByEmails,
      };
    },
    {}
  );

  return mappedProfiles;
}

export function mapMiniProfilesByUserID(
  discMiniProfiles: TytoData.DISCProfileMini[],
  includeProfilesWithoutDISC?: boolean
): { [x: number]: TytoData.DISCProfileMini } {
  const mappedProfiles = _.reduce(
    discMiniProfiles,
    (accum: { [x: number]: TytoData.DISCProfileMini }, discMiniProfile) => {
      // * For incomplete DISC Profiles
      if (!discMiniProfile.styleKey3 && !includeProfilesWithoutDISC) {
        return accum;
      }

      accum[discMiniProfile.personID] = discMiniProfile;

      return accum;
    },
    {}
  );

  return mappedProfiles;
}

const DEFAULT_WIDTH = 225;

export function getSectionWidth(
  sectionContRef: React.MutableRefObject<null | HTMLElement>
) {
  if (sectionContRef.current) {
    const info = sectionContRef.current.getBoundingClientRect();
    return info.width;
  }

  return 0;
  //   return DEFAULT_WIDTH;
}

export function getIconTypeFromDISCType(discType: string): iconType {
  switch (discType.toLowerCase()) {
    case "advocate":
    case "peacemaker":
      return "dove";
    case "establisher":
    case "leader":
      return "knight";
    case "designer":
      return "pencil-ruler";
    case "logical thinker":
      return "brain";
    default:
      return "question";
  }
}

export function createPseudoRandomString(stringLength = 10) {
  const randomString = Array.from({ length: stringLength }, () =>
    String.fromCharCode(Math.round(Math.random() * 42) + 48)
  ).join("");

  return randomString;
}

// * ============================================================
// * Methods for retrieving DISC data that respects permitMatrix
// * ============================================================

export interface RetrievedPersonValue {
  permitItem: TytoData.PermitMatrixItem;
  value?: any;
}

const DEFAULT_PERMIT: TytoData.PermitMatrixItem = {
  HIDE: true,
  reason: "WAITADMINREVEAL" as TytoData.PermitMatrixReason.WAITADMINREVEAL,
};

// * =======================
// * DISCMini specific logic
// * =======================
const personPermitKeyMap: {
  [x: string]: string;
  // [x: string]: keyof Tyto.PermitMatrix;
  // [x: number]: keyof Tyto.PermitMatrix;
} = {
  commTips: "discCommTip_",
  d1: "SCORES_",
  d2: "SCORES_",
  d3: "SCORES_",
  i1: "SCORES_",
  i2: "SCORES_",
  i3: "SCORES_",
  s1: "SCORES_",
  s2: "SCORES_",
  s3: "SCORES_",
  c1: "SCORES_",
  c2: "SCORES_",
  c3: "SCORES_",
  characteristic1: "discStyle.characteristic1",
  characteristic2: "discStyle.characteristic2",
  characteristic3: "discStyle.characteristic3",
  graphic: "discStyle.graphic",
  styleKey: "discStyle.styleKey",
  styleKey3: "discStyle.styleKey",
  styleName: "discStyle.styleName",
  styleName3: "discStyle.styleName",
  pdfLessonID: "Pdf",
  contribution: "discStyle.teamContributions",
  description: "discStyle.descriptionFull",
  interaction: "discStyleInteraction_",
};

const personKeyAliasFix: {
  [x: string]: string;
} = {
  commTips: "discCommTip_",
  Pdf: "pdfLessonID",
  styleKey: "styleKey3",
  styleName: "styleName3",
};

const personPermitKeyCircumventList: Set<string> = new Set([
  "descriptionGeneral",
  "emails",
  // "graphic",
  "jobTitle",
  "lastActivity",
  "permitMatrix",
  "personID",
  "personName",
  "phone1",
  "primaryElementID",
  "profileImageAsset",
  "profileImageID",
  // "styleKey3",
  // "styleName3",
  "teamRoot",
  "teamToolsInviteEmail",
  "teamToolsPermit",
]);

export function getPersonValueRespectfully({
  discMiniProfile,
  key,
}: {
  // key: keyof Tyto.DISCCompareProfile | keyof Tyto.DISCProfileMini;
  key: keyof TytoData.DISCProfileMini;
  discMiniProfile: TytoData.DISCProfileMini;
}): RetrievedPersonValue {
  // * If key is a part of list of items irrelevant to check permitMatrix for, return value
  if (personPermitKeyCircumventList.has(key)) {
    return {
      permitItem: DEFAULT_PERMIT,
      value: _.get(discMiniProfile, key, undefined),
    };
  }

  // * If not enough information to determine, simply return undefined
  if (!discMiniProfile || !key || !personPermitKeyMap[key]) {
    return {
      permitItem: DEFAULT_PERMIT,
      value: undefined,
    };
  }

  // * Snag key to check permitMatrix against
  const permitKey = personPermitKeyMap[key];

  const permit = _.get(
    discMiniProfile,
    `permitMatrix.${permitKey}`,
    DEFAULT_PERMIT
  ) as TytoData.PermitMatrixItem;

  const properKey = personKeyAliasFix[key] || key;
  // * Return value based of permission for specific item
  return {
    permitItem: permit,
    value: permit.HIDE
      ? undefined
      : _.get(discMiniProfile, properKey, undefined),
  };
}

// * ==============================
// * Compare Profile specific logic
// * ==============================
const comparePermitKeyMap: { [x: string]: string } = {
  communicationTips: "discCommTip_",
  intensity: "discStyleIntensity_",
  interactions: "discStyleInteraction_",
  personCommunicationTips: "discCommTip_",
  result: "discCommTip_",
  style: "discStyle", // ! Is not PermitItem, but contains PermitItems
  "result.d3percentile": "percentile_",
  "result.i3percentile": "percentile_",
  "result.s3percentile": "percentile_",
  "result.c3percentile": "percentile_",
  "style.styleKey": "",
  "style.styleName": "discStyle.styleName",
  "style.styleNamePKapi": "discStyle.styleNamePKapi",
  "style.descriptionGeneral": "discStyle.descriptionGeneral",
  "style.descriptionFull": "discStyle.descriptionFull",
  "style.characterGeneral": "discStyle.characterGeneral",
  "style.teamValues": "discStyle.teamValues",
  "style.challengeAreas": "discStyle.challengeAreas",
  "style.fear": "discStyle.fear",
  "style.motivators": "discStyle.motivators",
  "style.environmentIdeal": "discStyle.environmentIdeal",
  "style.want": "discStyle.want",
  "style.communicationDo": "discStyle.communicationDo",
  "style.communicationDont": "discStyle.communicationDont",
  "style.analyticDispostion": "discStyle.analyticDispostion",
  "style.teamContributions": "discStyle.teamContributions",
  "style.personalGrowthAreas": "discStyle.personalGrowthAreas",
  "style.graphic": "discStyle.graphic",
  "style.styleHeadline": "discStyle.styleHeadline",
  "style.styleTraits": "discStyle.styleTraits",
  "style.characteristic1": "discStyle.characteristic1",
  "style.characteristic1details": "discStyle.characteristic1details",
  "style.characteristic2": "discStyle.characteristic2",
  "style.characteristic2details": "discStyle.characteristic2details",
  "style.characteristic3": "discStyle.characteristic3",
  "style.characteristic3details": "discStyle.characteristic3details",
};

export function getCompareValueRespectfully({
  compareProfile,
  discMiniProfile,
  key,
}: {
  // key: keyof Tyto.DISCCompareProfile | keyof Tyto.DISCProfileMini;
  key: keyof TytoData.DISCCompareProfile;
  discMiniProfile: TytoData.DISCProfileMini;
  compareProfile?: TytoData.DISCCompareProfile;
}): RetrievedPersonValue {
  // debugger;
  // * If not enough information to determine, simply return undefined
  if (
    !discMiniProfile ||
    !compareProfile ||
    !key ||
    !comparePermitKeyMap[key]
  ) {
    console.log(
      `Compare Check returned early because not all info present. Has... discMini: ${discMiniProfile}, compare: ${compareProfile}, key: ${key}, permitItem: ${comparePermitKeyMap[key]}`
    );

    return {
      permitItem: DEFAULT_PERMIT,
      value: undefined,
    };
  }

  // * Snag key to check permitMatrix against
  const permitKey = comparePermitKeyMap[key];

  const permit = _.get(
    discMiniProfile,
    `permitMatrix.${permitKey}`,
    DEFAULT_PERMIT
  ) as TytoData.PermitMatrixItem;

  // * Return value based of permission for specific item
  return {
    permitItem: permit,
    value:
      permit.HIDE || permit.HIDE === undefined
        ? undefined
        : _.get(compareProfile, key, undefined),
  };
}

const defaultDISCValue = {
  d: 0,
  i: 0,
  s: 0,
  c: 0,
};

export function calcGroupStyle(discMiniProfile: TytoData.DISCProfileMini[]) {
  //   const discValuesAsObj = calcGroupStyleAsObj(discMiniProfile);
  //   const discValuesAsObj = calcPositiveGroupStyleAsObj(discMiniProfile);
  const discMinisFiltered = discMiniProfile.filter(
    (profile) => !_.get(profile, "permitMatrix.SCORES_.HIDE", true)
  );

  const discValuesAsObj = calcSqrPositiveGroupStyleAsObj(discMinisFiltered);

  return _.orderBy(
    [
      { label: "d", value: discValuesAsObj.d },
      { label: "i", value: discValuesAsObj.i },
      { label: "s", value: discValuesAsObj.s },
      { label: "c", value: discValuesAsObj.c },
    ],
    ["value"],
    ["desc"]
  );
}

export function calcGroupStyleAsObj(
  discMiniProfile: TytoData.DISCProfileMini[]
) {
  if (!Array.isArray(discMiniProfile)) {
    return defaultDISCValue;
  }

  const discMinisFiltered = discMiniProfile.filter(
    (profile) => !_.get(profile, "permitMatrix.SCORES_.HIDE", true)
  );

  if (!discMinisFiltered.length) {
    return defaultDISCValue;
  }

  const letterSums = discMinisFiltered.reduce(
    (accum: { d: number; i: number; s: number; c: number }, profile) => ({
      d: accum.d + profile.d3,
      i: accum.i + profile.i3,
      s: accum.s + profile.s3,
      c: accum.c + profile.c3,
    }),
    defaultDISCValue
  );

  return {
    d: letterSums.d / discMinisFiltered.length,
    i: letterSums.i / discMinisFiltered.length,
    s: letterSums.s / discMinisFiltered.length,
    c: letterSums.c / discMinisFiltered.length,
  };
}

export function calcPositiveGroupStyleAsObj(
  discMiniProfile: TytoData.DISCProfileMini[]
) {
  if (!Array.isArray(discMiniProfile)) {
    return defaultDISCValue;
  }

  const discMinisFiltered = discMiniProfile.filter(
    (profile) => !_.get(profile, "permitMatrix.SCORES_.HIDE", true)
  );

  if (!discMinisFiltered.length) {
    return defaultDISCValue;
  }

  const letterSums = discMinisFiltered.reduce(
    (accum: { d: number; i: number; s: number; c: number }, profile) => ({
      d: accum.d + (profile.d3 > 0 ? profile.d3 : 0),
      i: accum.i + (profile.i3 > 0 ? profile.i3 : 0),
      s: accum.s + (profile.s3 > 0 ? profile.s3 : 0),
      c: accum.c + (profile.c3 > 0 ? profile.c3 : 0),
    }),
    defaultDISCValue
  );

  return {
    d: letterSums.d / discMinisFiltered.length,
    i: letterSums.i / discMinisFiltered.length,
    s: letterSums.s / discMinisFiltered.length,
    c: letterSums.c / discMinisFiltered.length,
  };
}

export function calcSqrPositiveGroupStyleAsObj(
  discMiniProfile: TytoData.DISCProfileMini[]
) {
  if (!Array.isArray(discMiniProfile)) {
    return defaultDISCValue;
  }

  const discMinisFiltered = discMiniProfile.filter(
    (profile) => !_.get(profile, "permitMatrix.SCORES_.HIDE", true)
  );

  if (!discMinisFiltered.length) {
    return defaultDISCValue;
  }

  const letterSums = discMinisFiltered.reduce(
    (accum: { d: number; i: number; s: number; c: number }, profile) => ({
      d: accum.d + (profile.d3 > 0 ? Math.pow(profile.d3, 2) : 0),
      i: accum.i + (profile.i3 > 0 ? Math.pow(profile.i3, 2) : 0),
      s: accum.s + (profile.s3 > 0 ? Math.pow(profile.s3, 2) : 0),
      c: accum.c + (profile.c3 > 0 ? Math.pow(profile.c3, 2) : 0),
    }),
    {
      d: 0,
      i: 0,
      s: 0,
      c: 0,
    }
  );

  return {
    d: letterSums.d / discMinisFiltered.length,
    i: letterSums.i / discMinisFiltered.length,
    s: letterSums.s / discMinisFiltered.length,
    c: letterSums.c / discMinisFiltered.length,
  };
}

// * Returns values that will be used for surfacing insight about team
export function getGroupStyleWithStdDeviation(
  disc: { label: string; value: number }[]
) {
  if (!Array.isArray(disc) || !disc.length) {
    return "" as any;
  }

  const values = disc.map((info) => info.value);
  const stdDeviation = calcStandardDeviation(values);

  const highestValue = _.orderBy(disc, ["value"], ["desc"])[0].value;
  const lowestAcceptedValue = highestValue - stdDeviation;

  const key = disc
    .filter((info) => info.value >= lowestAcceptedValue)
    .map((info) => info.label)
    .join("");

  return key as keyof typeof TytoData.DISCStyleKey;
}

export function loadTeamProfile(teamStyleKey: string) {
  return API.DISCProfile.Team.get({ styleKey: teamStyleKey });
}

// * ==================================================
// * Attempt at using normalize values ================
// * ==================================================

function alterValueBasedOnOpposite(value: number, oppValue: number) {
  if (value <= 0) {
    return value;
    // * oppValue greater than -3 -> remove from value
  } else if (oppValue >= -3) {
    return value - (3 - -oppValue);
    // * oppValue less than -6 -> add to value
  } else if (oppValue <= -6) {
    return value + (Math.abs(oppValue) - 6);
  }

  // oppValue between -6 and -3 -> return as is
  return value;
}

function reduceCompareProfile(discCompareProfile: TytoData.DISCCompareProfile) {
  const dVal = getValueFromPercentile(discCompareProfile.result.d3percentile);
  const iVal = getValueFromPercentile(discCompareProfile.result.i3percentile);
  const sVal = getValueFromPercentile(discCompareProfile.result.s3percentile);
  const cVal = getValueFromPercentile(discCompareProfile.result.c3percentile);

  return {
    d: alterValueBasedOnOpposite(dVal, sVal),
    i: alterValueBasedOnOpposite(iVal, cVal),
    s: alterValueBasedOnOpposite(sVal, dVal),
    c: alterValueBasedOnOpposite(cVal, iVal),
  };
}

function getValueFromPercentile(percentile: number) {
  return (percentile / 100) * 16 - 8;
}

export function getValuesFromPercentiles(
  discCompareProfiles: TytoData.DISCCompareProfile[]
) {
  if (!Array.isArray(discCompareProfiles)) {
    return defaultDISCValue;
  }

  const letterSums = discCompareProfiles.reduce(
    (accum: { d: number; i: number; s: number; c: number }, compareProfile) => {
      const reducedCompare = reduceCompareProfile(compareProfile);

      return {
        d: accum.d + (reducedCompare.d > 0 ? reducedCompare.d : 0),
        i: accum.i + (reducedCompare.i > 0 ? reducedCompare.i : 0),
        s: accum.s + (reducedCompare.s > 0 ? reducedCompare.s : 0),
        c: accum.c + (reducedCompare.c > 0 ? reducedCompare.c : 0),
      };
    },
    {
      d: 0,
      i: 0,
      s: 0,
      c: 0,
    }
  );

  return {
    d: letterSums.d / discCompareProfiles.length,
    i: letterSums.i / discCompareProfiles.length,
    s: letterSums.s / discCompareProfiles.length,
    c: letterSums.c / discCompareProfiles.length,
  };
}

export function calcGroupStyleAsPercent(
  discCompareProfiles: TytoData.DISCCompareProfile[]
) {
  //   const discValuesAsObj = calcGroupStyleAsObj(discMiniProfile);
  //   const discValuesAsObj = calcPositiveGroupStyleAsObj(discMiniProfile);
  // const discValuesAsObj = calcSqrPositiveGroupStyleAsObj(discMiniProfile);
  const discValuesAsObj = getValuesFromPercentiles(discCompareProfiles);

  return _.orderBy(
    [
      { label: "d", value: discValuesAsObj.d },
      { label: "i", value: discValuesAsObj.i },
      { label: "s", value: discValuesAsObj.s },
      { label: "c", value: discValuesAsObj.c },
    ],
    ["value"],
    ["desc"]
  );
}
