/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

export interface Props extends SITE.GeneralStoreInjectedProps {}

const Learning = ({}: Props) => {
  return (
    <div className="learning-body">
      <h1 className="learning-header">Learning Library</h1>
      <p className="learning-text">No videos are available right now</p>
    </div>
  );
};

export default Learning;
