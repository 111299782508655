/*
 * Component Description
 */
import * as React from "react";

import AgnosticModal from "../agnostic/";
import DocumentViewer from "./DocumentViewer";

import "./style.scss";

interface Props {
  asset?: Data.Asset;
  lessonID?: number;
  isOpen: boolean;
  onDismiss: () => void;
  markComplete?: () => void;
}

const DocumentViewerModal = (props: Props) => {
  const [isMobile] = React.useState(window.innerWidth < 800);

  return (
    <AgnosticModal
      ariaLabel="Document Viewer"
      className="modals-document-viewer-modal-main-wrapper"
      closeButtonSize={isMobile ? 25 : 32}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      overlayClassName="modals-document-viewer-modal-overlay"
    >
      <DocumentViewer
        asset={props.asset}
        lessonID={props.lessonID}
        markComplete={props.markComplete}
      />
    </AgnosticModal>
  );
};

export default DocumentViewerModal;
