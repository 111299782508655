import { useId } from "react";
import { noop } from "lodash";

import { Button, Icon } from "components/common";

import { useManagedQuestions } from "./Taker";
import AnswerOptions from "./PossibleAnswer";

import "./Question.scss";

type ManageQuestionsData = ReturnType<typeof useManagedQuestions>;

interface Props {
  questionIdx: number;
  questionsCount: number;
  curQuestion: ManageQuestionsData["curQuestion"];
  nextQuestion: ManageQuestionsData["nextQuestion"];
  previousQuestion: ManageQuestionsData["previousQuestion"];
  setQuestionAnswer: ManageQuestionsData["setQuestionAnswer"];
  answerLeast: ManageQuestionsData["answerLeast"];
  answerMost: ManageQuestionsData["answerMost"];
}

const Question = (props: Props) => {
  const formID = useId();

  if (!props.curQuestion?.Answers?.length) {
    return null;
  }

  const invalidOptions = !!(
    props.answerLeast &&
    props.answerMost &&
    props.answerLeast === props.answerMost
  );
  const cannotContinue =
    !props.answerMost || !props.answerLeast || invalidOptions;

  const isLastQuestion = props.questionIdx + 1 >= props.questionsCount;

  return (
    <div className="question-cont">
      <form
        className="question-wrapper"
        id={formID}
        onSubmit={(e) => {
          e.preventDefault?.();

          if (cannotContinue) return;

          props.nextQuestion();
        }}
      >
        <div className="question-column">
          <div className="question-column-cell label-cell" />

          <div className="question-column-cell most-like-cell">
            <h2 className="title-font most-text-color">MOST LIKE ME</h2>
          </div>

          <div className="question-column-cell least-like-cell">
            <h2 className="title-font least-text-color">LEAST LIKE ME</h2>
          </div>
        </div>

        {props.curQuestion.Answers.map((answer, index) => (
          <div key={answer.AId} className="question-column">
            <div className="question-column-cell label-cell">
              <h3 className="title-font">{answer.Word}</h3>
              {answer.Synonyms && <p>{answer.Synonyms}</p>}
            </div>

            <div className="question-column-cell most-like-cell">
              <RadioButton
                name="answers-most-options"
                value={answer.AId.toString()}
                label={`Most ${answer.Word}`}
                checked={props.answerMost === answer.AId}
                onChange={(e) => {
                  if (!e.target.checked) return;
                  props.setQuestionAnswer(
                    props.curQuestion?.QId ?? 0,
                    answer.AId,
                    "most"
                  );
                }}
              />
            </div>

            <div className="question-column-cell least-like-cell">
              <RadioButton
                name="answers-least-option"
                value={answer.AId.toString()}
                label={`Least ${answer.Word}`}
                checked={props.answerLeast === answer.AId}
                onChange={(e) => {
                  if (!e.target.checked) return;
                  props.setQuestionAnswer(
                    props.curQuestion?.QId ?? 0,
                    answer.AId,
                    "least"
                  );
                }}
              />
            </div>
          </div>
        ))}
      </form>

      <footer>
        {!!props.questionIdx && (
          <Button
            className="question-nav-btn"
            disabled={!props.questionIdx}
            value="< Previous"
            onClick={props.previousQuestion}
            theme="white-ghost"
          >
            <Icon icon="angle-left" size={16} />
            Previous
          </Button>
        )}

        <Button
          form={formID}
          className="question-nav-btn question-next-btn"
          disabled={cannotContinue}
          value="Next >"
          onClick={noop}
          theme="white-ghost"
          type="submit"
        >
          {isLastQuestion ? (
            <>
              Submit
              <Icon icon="check" size={16} />
            </>
          ) : (
            <>
              Next
              <Icon icon="angle-right" size={16} />
            </>
          )}
        </Button>
      </footer>
    </div>
  );
};

interface RadioButtonProps {
  name: string;
  value: string;
  label: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton = (props: RadioButtonProps) => {
  return (
    <input
      type="radio"
      name={props.name}
      value={props.value}
      checked={props.checked}
      onChange={props.onChange}
    />
  );
};

export default Question;
