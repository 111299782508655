import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { INTERFACE_PATHS, SUB_INTERFACE_PATHS } from "data/constants/";
import { ActiveSession } from "data/storage";
import { Link } from "components/common/";

import "./style.scss";

interface Props {}

const CommunicationTipsOverview = (props: Props) => {
  const [loggedInUserID] = React.useState(() => {
    return ActiveSession.userID() ?? 0;
  });

  return (
    <div className="centered-content-wrapper">
      <section className="profile-commtips-overview profile-subinterface">
        <h2 className="profile-section-title title-font">
          Why customize your communication tips?
        </h2>

        <p className="profile-section-text">
          This is were the real value begins. Customizing your communication
          preferences so you and your co-workers can better collaborate is how
          you and your company can take advantage of the R3 to multiply your
          effort!
        </p>

        <div className="fake-video-placeholder with-placeholder" />

        <Link
          buttonProps={{
            className: "large-btn",
            onClick: _.noop,
            theme: "action",
            value: "View Communication Tips",
            hoverBGSize: "hidden",
          }}
          className="profile-link-btn"
          href={`${INTERFACE_PATHS.PROFILE}/${loggedInUserID}/${SUB_INTERFACE_PATHS.PROFILE.COMM_TIPS}`}
          type="button"
          value="View Communication Tips"
        />
      </section>
    </div>
  );
};

export default CommunicationTipsOverview;
