/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { Nav, Menu, Footer } from "components/meta";

import Directory from "./Directory";

import "./style.scss";

interface Props extends SITE.GeneralStoreInjectedProps {
  searchTerm?: string;
}

const DirectoryWrapper = (props: Props) => {
  const [menuHasBeenOpen, updateMenuHasBeenOpen] = React.useState(
    _.get(props, "GeneralStore.state.showMenu", false)
  );

  const storeMenuOpenValue = _.get(props, "GeneralStore.state.showMenu", false);

  // * Trigger flag so that turning menu off animates back left
  React.useEffect(() => {
    if (!menuHasBeenOpen && storeMenuOpenValue) {
      updateMenuHasBeenOpen(true);
    }
  }, [storeMenuOpenValue, menuHasBeenOpen]);

  const menuIsOpen = storeMenuOpenValue;
  const isMobile = !!props.GeneralStore?.state?.isMobile;

  return (
    <div className={cx("interface-wrapper", menuIsOpen && "menu-open")}>
      {!isMobile && <Nav activeTab="Search" className="directory-nav" />}
      <Menu className="directory-menu" />

      <main
        className={cx(
          "interface-main-cont  interface-directory-cont interface-directory",
          menuHasBeenOpen && "animate-back"
        )}
      >
        <Directory isMobile={isMobile} searchTerm={props.searchTerm} />
      </main>

      {isMobile && <Footer activeTab="Search" />}
    </div>
  );
};

export default DirectoryWrapper;
