import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { ActiveSession } from "data/storage/";
import {
  usePeopleKeysInitialize,
  usePeopleKeysPages,
} from "data/network/hooks/";
import { Icon, Message } from "components/common/";

import AssessmentPage from "./AssessmentPage";

import "./style.scss";

interface Props extends AssessmentTakerInterface.UpdateSubInterfaceProps {
  isMobile: boolean;
}

const TakerWrapper = (props: Props) => {
  const [domainID] = React.useState(() => {
    return ActiveSession.domainID() ?? 0;
  });

  const { data, isLoading } = usePeopleKeysInitialize({
    domainID,
    isEnabled: !!domainID,
    onError: () => {
      // TODO ?
    },
  });

  if (isLoading) {
    return (
      <article className="assessment-taker-taker">
        <div className="assessment-taker-initializing-cont">
          <Icon
            className="assessment-taker-initializing-icon"
            size={props.isMobile ? 125 : 145}
            icon="r3"
          />
          <Message
            className="assessment-taker-initializing-msg"
            text="Initializing Assessment..."
          />
        </div>
      </article>
    );
  }

  return (
    <article className="assessment-taker-taker">
      {!data?.assessmentResultID ? (
        <Message text="Something went wrong 👎... Cannot initialize assessment at this time. Please reach out to an Associate." />
      ) : (
        <InitializedAssessment
          assessmentResultID={data.assessmentResultID}
          discID={data.discID}
          isClient={data.isClient}
          isNewDiscRequired={data.isNewDiscRequired}
          isMobile={props.isMobile}
        />
      )}
    </article>
  );
};

interface InitializedAssessmentProps {
  assessmentResultID: number;
  discID: number;
  isClient: boolean;
  isMobile: boolean;
  isNewDiscRequired: boolean;
}

const InitializedAssessment = (props: InitializedAssessmentProps) => {
  const { data, isLoading } = usePeopleKeysPages({
    onError: () => {
      // TODO ?
    },
  });

  const keyedPages = React.useMemo(() => {
    return _.keyBy(data?.pages ?? [], "page_id");
  }, [data?.pages]);

  if (isLoading) {
    return (
      <article className="assessment-taker-taker">
        <div className="assessment-taker-initializing-cont">
          <Icon
            className="assessment-taker-initializing-icon no-animation"
            size={props.isMobile ? 125 : 145}
            icon="r3"
          />
          <Message
            className="assessment-taker-initializing-msg"
            text="Retrieving Questions..."
          />
        </div>
      </article>
    );
  }

  return (
    <>
      {!data?.pages ? (
        <Message text="ERROR: Could not load questions." />
      ) : (
        <AssessmentPage
          assessmentResultID={props.assessmentResultID}
          discID={props.discID}
          isMobile={props.isMobile}
          pages={data?.pages}
          keyedPages={keyedPages}
        />
      )}
    </>
  );
};

export default TakerWrapper;
