import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { usePeopleKeysResponseMutation } from "data/network/hooks";
import { Button } from "components/common/";

// import "./style.scss";

interface Props {
  assessmentResultID: number;
  pageData: TytoData.PeopleKeys.Page;
  discID: number;
  onStart: () => void;
}

const PreAssessment = (props: Props) => {
  const pageSubmitionMutation = usePeopleKeysResponseMutation({
    resultID: props.assessmentResultID,
    pageData: props.pageData,
    discID: props.discID,
    onError: () => {
      // TODO
    },
    onSuccess: () => {
      // TODO
    },
  });

  return (
    <div className="assessment-taker-text-container">
      <h2 className="assessment-taker-title">
        The 24-Question DISC Assessment
      </h2>

      <p className="assessment-taker-text">
        To answer each question, tap and hold to re-arrange the four options
        from Most Like You (top of the list) to Least Like You (bottom of the
        list).
      </p>

      <p className="assessment-taker-text">
        The assessment will take approximately seven to ten minutes.
      </p>

      <p className="assessment-taker-text">
        Don’t deliberate too long on any question. There are no right or wrong
        answers.
      </p>

      <p className="assessment-taker-text">
        If you start the assessment and don’t complete it, you will resume from
        where you last left off. You cannot go back.
      </p>

      <p className="assessment-taker-text">Have fun!</p>

      <Button
        className="assessment-taker-btn large-btn"
        disabled={pageSubmitionMutation.isLoading}
        hoverBGSize="hidden"
        theme="action"
        onClick={() => {
          if (!pageSubmitionMutation.isLoading) {
            pageSubmitionMutation.mutate({
              pageID: props.pageData?.page_id,
            });
          }
        }}
        value="Start Assessment"
      />
    </div>
  );
};

export default PreAssessment;
