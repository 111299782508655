/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { INTERFACE_PATHS } from "data/constants/";
import { makeRelPathAbsolute } from "data/helpers/";
import { Link, Message, UserThumb } from "components/common/";
import { PersonTileDumb } from "components/disc/";

import "./style.scss";

interface Props {
  people: TytoData.AdvancedPerson[];
  isFetching: boolean;
  searchTerm: string;
}

const PeopleSearchResults = ({ people, searchTerm, isFetching }: Props) => {
  return (
    <section className="directory-search-results">
      {!!people?.length ? (
        <div
          className={cx(
            "directory-search-results-list",
            isFetching && "is-fetching"
          )}
        >
          {people.map((person) => (
            <PersonListItem
              key={person.userID}
              isFetching={isFetching}
              person={person}
              searchTerm={searchTerm}
            />
          ))}
        </div>
      ) : (
        // <ul
        //   className={cx(
        //     "directory-search-results-list",
        //     isFetching && "is-fetching"
        //   )}
        // >
        //   {people.map((person) => (
        //     <PersonListItem
        //       key={person.userID}
        //       isFetching={isFetching}
        //       person={person}
        //       searchTerm={searchTerm}
        //     />
        //   ))}
        // </ul>
        <>
          <div className="directory-search-result-wrapper">
            {isFetching ? (
              <Message text="Searching..." />
            ) : (
              <Message
                text={
                  searchTerm
                    ? `No one found matching name "${searchTerm}"`
                    : "Search for people"
                }
              />
            )}
          </div>
        </>
      )}
    </section>
  );
};

function getThumbnailPath(person: TytoData.AdvancedPerson) {
  const { profileImage } = person ?? {};

  if (!profileImage?.thumbnailPath) {
    return undefined;
  }

  return makeRelPathAbsolute(profileImage.thumbnailPath, true);
}

interface PersonListItemProps {
  isFetching: boolean;
  person: TytoData.AdvancedPerson;
  searchTerm: string;
}

const PersonListItem = ({
  isFetching,
  person,
  searchTerm,
}: PersonListItemProps) => {
  const [userName] = React.useState(`${person.givenName} ${person.familyName}`);
  const [thumbnailPath] = React.useState(getThumbnailPath(person));

  return (
    <Link
      className="directory-search-results-list-item-link"
      disabled={isFetching}
      href={`${INTERFACE_PATHS.PROFILE}/${person.userID}`}
      value={userName}
    >
      <PersonTileDumb
        personID={person.userID}
        personName={`${person.givenName} ${person.familyName.charAt(0)}.`}
      />
    </Link>
  );

  // return (
  //   <li className="directory-search-results-list-item">
  //     <Link
  //       className="directory-search-results-list-item-link"
  //       disabled={isFetching}
  //       href={`${INTERFACE_PATHS.PROFILE}/${person.userID}`}
  //       value={userName}
  //     >
  //       <UserThumb
  //         className="directory-search-results-list-item-userthumb"
  //         userName={userName}
  //         size={40}
  //         userThumbPathURL={thumbnailPath}
  //       />
  //       <h3 className="directory-search-results-list-item-name">{userName}</h3>
  //     </Link>
  //   </li>
  // );
};

export default PeopleSearchResults;
