/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { useDISCMiniMutation } from "data/network/hooks/";
import PkgJSON from "../../../package.json";
import { DEFAULT_APP_BRAND } from "data/constants/";
import { logoutUser } from "data/helpers/";
import { StoreContext as AppStoreContext } from "data/stores/AppStore";
import { Button } from "components/common/";

export interface Props extends SITE.GeneralStoreInjectedProps {
  isMobile?: boolean;
  person?: Endpoints.Responses.Person.Get;
  DISCMini?: Endpoints.Responses.DISCProfiles.Mini.Get;
}

const Settings = ({ isMobile, person, DISCMini }: Props) => {
  const [loggingOut, updateLoggingOut] = React.useState(false);
  const [errorMsg, updateErrorMsg] = React.useState("");
  let AppStore = React.useContext(AppStoreContext);

  const [selectedSharingLevel, updateSelectedSharingLevel] = React.useState<
    keyof typeof TytoData.DiscOptOut
  >(DISCMini?.discProfiles[0].discOptOut ?? "ocVACANT");

  React.useEffect(() => {
    updateSelectedSharingLevel(
      DISCMini?.discProfiles[0].discOptOut ?? "ocVACANT"
    );
  }, [DISCMini]);

  const DISCMutation = useDISCMiniMutation({
    personID: person?.person.personID ?? 0,
  });

  React.useEffect(() => {
    if (
      selectedSharingLevel !== "ocVACANT" &&
      selectedSharingLevel !== DISCMini?.discProfiles[0].discOptOut
    )
      DISCMutation.mutate({
        discOptOut: selectedSharingLevel,
      });
  }, [selectedSharingLevel]);

  return (
    <div className="settings-body">
      <div className="settings-sharing-selection">
        <h2 className="settings-sharing-selection-title">Sharing</h2>
        <p>
          How much do you want to share about yourself to other people inside
          the organization?
        </p>
        <form className="settings-sharing-form">
          <div className="setting-sharing-form-tex">
            <h3>Everything</h3>
            <p>Share everything, including the full download of your report.</p>
          </div>
          <div className="setting-sharing-form-options">
            <input
              type="radio"
              name="privacy"
              id="everything"
              onChange={() => {
                updateSelectedSharingLevel("ocLEVEL00");
              }}
              checked={
                selectedSharingLevel === "ocLEVEL00" ||
                selectedSharingLevel === "ocVACANT"
              }
            ></input>
            <label htmlFor="everything"></label>
          </div>
          <div className="setting-sharing-form-tex">
            <h3>Moderate</h3>
            <p>
              Communication tips, comparison reports, style intensity,
              personality overview, and positive traits (value to a team,
              analysis style, points of caution).
            </p>
          </div>
          <div className="setting-sharing-form-options">
            <input
              type="radio"
              name="privacy"
              id="moderate"
              onChange={() => {
                updateSelectedSharingLevel("ocLEVEL10");
              }}
              checked={selectedSharingLevel === "ocLEVEL10"}
            ></input>
            <label htmlFor="moderate"></label>
          </div>
          <div className="setting-sharing-form-tex">
            <h3>Minimal</h3>
            <p>Only share communication tips and the comparison report.</p>
          </div>
          <div className="setting-sharing-form-options">
            <input
              type="radio"
              name="privacy"
              id="minimal"
              onChange={() => {
                updateSelectedSharingLevel("ocLEVEL20");
              }}
              checked={selectedSharingLevel === "ocLEVEL20"}
            ></input>
            <label htmlFor="minimal"></label>
          </div>
        </form>
      </div>
      <div className="settings-personal-info">
        <h2>Personal Info</h2>
        <div className="settings-personal-info-section">
          <p className="settings-personal-info-section-label">First Name</p>
          <p className="settings-personal-info-section-data">
            {person?.person.givenName ? person?.person.givenName : "-"}
          </p>
        </div>
        <div className="settings-personal-info-section">
          <p className="settings-personal-info-section-label">Last Name</p>
          <p className="settings-personal-info-section-data">
            {person?.person.familyName ? person?.person.familyName : "-"}
          </p>
        </div>
        <div className="settings-personal-info-section">
          <p className="settings-personal-info-section-label">Email</p>
          <p className="settings-personal-info-section-data">
            {person?.person.email ? person?.person.email : "-"}
          </p>
        </div>
        <div className="settings-personal-info-section">
          <p className="settings-personal-info-section-label">Title</p>
          <p className="settings-personal-info-section-data">
            {person?.person.jobTitle ? person?.person.jobTitle : "-"}
          </p>
        </div>
        <div className="settings-personal-info-section">
          <p className="settings-personal-info-section-label">Website</p>
          <p className="settings-personal-info-section-data">
            {person?.person.website ? person?.person.website : "-"}
          </p>
        </div>
        <div className="settings-personal-info-section">
          <p className="settings-personal-info-section-label">Phone</p>
          <p className="settings-personal-info-section-data">
            {person?.person.phone1 ? person?.person.phone1 : "-"}
          </p>
        </div>
        <div className="settings-personal-info-section">
          <p className="settings-personal-info-section-label">Street</p>
          <p className="settings-personal-info-section-data">
            {person?.person.address1 ? person?.person.address1 : "-"}
          </p>
        </div>
        <div className="settings-personal-info-section">
          <p className="settings-personal-info-section-label">City</p>
          <p className="settings-personal-info-section-data">
            {person?.person.city ? person?.person.city : "-"}
          </p>
        </div>
        <div className="settings-personal-info-section">
          <p className="settings-personal-info-section-label">State</p>
          <p className="settings-personal-info-section-data">
            {person?.person.state ? person?.person.state : "-"}
          </p>
        </div>
        <div className="settings-personal-info-section">
          <p className="settings-personal-info-section-label">Zipcpde</p>
          <p className="settings-personal-info-section-data">
            {person?.person.postalCode ? person?.person.postalCode : "-"}
          </p>
        </div>
      </div>
      <div className="settings-bottom-cnt">
        <div className="settings-version-div">
          <h2 className="settings-version">
            App Version ({PkgJSON.version ?? "N/A"})
          </h2>
        </div>
        <div className="settings-logout-wrapper">
          <Button
            className="settings-logout-btn"
            onClick={() => {
              const confirmed = window.confirm(
                `Logout of ${DEFAULT_APP_BRAND ?? "App"}?`
              );

              if (confirmed) {
                updateLoggingOut(true);
                updateErrorMsg("");

                logoutUser({
                  AppStore,
                  onSuccess: () => {},
                  onError: (errorMsg) => {
                    updateErrorMsg(errorMsg);
                    updateLoggingOut(false);
                  },
                });
              }
            }}
            hoverBGSize="hidden"
            theme="action-ghost"
            type="button"
            value="Logout"
          />
        </div>
      </div>
    </div>
  );
};

export default Settings;
