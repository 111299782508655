/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { SITE_THEMES } from "../../../data/constants/";

const themeOptions = [
  {
    label: "Dark",
    value: SITE_THEMES.dark,
  },
  {
    label: "Light",
    value: SITE_THEMES.light,
  },
  {
    label: "Default (OS Setting)",
    value: SITE_THEMES.system,
  },
];

interface Props extends CMMenu.MenuWrapperProps {
  children?: any;
}

const GenericMenuContent = ({
  buildNumber,
  children,
  GeneralStore,
  hasParentSite,
  siteTheme,
  version,
  ...props
}: Props) => {
  const menuIsShowing = _.get(GeneralStore, "state.showMenu", false);
  const isMobile = _.get(GeneralStore, "state.isMobile", false);

  if (!menuIsShowing) {
    return null;
  }

  return (
    <aside className={cx("cm-menu", props.className)}>
      {menuIsShowing && isMobile && (
        <button
          className="cm-menu-black-overlay"
          onClick={() => {
            if (GeneralStore.dispatch) {
              GeneralStore.dispatch({
                payload: {},
                type: "GENERAL_STORE_TOGGLE_NAVMENU",
              });
            }
          }}
        />
      )}
      <div className="cm-menu-contents-wrapper">
        <section className="cm-menu-inner-cont">{children}</section>

        <footer className="cm-menu-footer">
          <span className="cm-menu-build text-color">
            Version: <span className="cm-menu-build-num">{version}</span>
          </span>
          {buildNumber && (
            <span className="cm-menu-build text-color">
              Build: <span className="cm-menu-build-num">{buildNumber}</span>
            </span>
          )}

          {/* <TextButton
            className="cm-menu-footer-sitetheme-toggle text-color"
            onClick={() => {
              let newSiteTheme = "dark";

              if (siteTheme === "dark") {
                newSiteTheme = "light";
              }

              if (GeneralStore.dispatch) {
                GeneralStore.dispatch({
                  payload: {
                    siteTheme: newSiteTheme,
                  },
                  type: "GENERAL_STORE_UPDATE_SITE_THEME",
                });
              }
            }}
            value={
              siteTheme === "dark"
                ? "Switch to Light Mode"
                : "Switch to Dark Mode (default)"
            }
          /> */}

          {/* <Select
            className="cm-menu-contents-theme-options"
            onChange={(newVal) => {
              if (GeneralStore.dispatch) {
                GeneralStore.dispatch({
                  payload: {
                    siteTheme: `${newVal}`,
                  },
                  type: "GENERAL_STORE_UPDATE_SITE_THEME",
                });
              }
            }}
            options={themeOptions}
            value={siteTheme}
          /> */}
        </footer>
      </div>
    </aside>
  );
};

export default GenericMenuContent;
