/*
 * Component Description
 */
import * as React from "react";

import AgnosticModal from "../agnostic/";
import DISCImport from "./DISCImport";
import DISCImport247 from "./DISCImport247";

import "./style.scss";

interface Props {
  asset?: Data.Asset;
  userID: number;
  emailAddress?: string;
  domainID?: number;
  existingDISCID?: number;
  personName?: string;
  isOpen: boolean;
  onDismiss: () => void;
  onComplete?: () => void;
  use247?: boolean;
}

const DISCImportModal = (props: Props) => {
  const [isMobile] = React.useState(window.innerWidth < 800);

  return (
    <AgnosticModal
      ariaLabel="Import DISC"
      className="modals-disc-import-modal-main-wrapper"
      closeButtonSize={isMobile ? 25 : 32}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      overlayClassName="modals-disc-import-modal-overlay"
    >
      {props.use247 ? (
        <DISCImport247
          userID={props.userID}
          existingDISCID={props.existingDISCID}
          emailAddress={props.emailAddress}
          domainID={props.domainID}
          personName={props.personName}
          onSelect={() => {
            // TODO
            props.onDismiss?.();
          }}
        />
      ) : (
        <DISCImport
          userID={props.userID}
          emailAddress={props.emailAddress}
          domainID={props.domainID}
          onSelect={() => {
            // TODO
            props.onDismiss?.();
          }}
        />
      )}
    </AgnosticModal>
  );
};

export default DISCImportModal;
