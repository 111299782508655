import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { ActiveSession } from "data/storage/";
import {
  Button,
  CheckBox,
  Link,
  Message,
  ReactSelect,
  SearchInput,
  TextButton,
} from "components/common/";
import { PersonTile, PersonTileDumb } from "components/disc/";
import { SECURITY_FUNCTIONS_BY_NAME } from "data/constants/system";
import {
  useAdvancedPersonSearch,
  useDISCMini,
  usePersonalDISCMini,
  useTeamDISCMini,
  useTeamsByFunction,
} from "data/network/hooks/";

import Comparison from "../comparison/";
import GroupStyle from "../group/";

import "./style.scss";

// * The Team Options is a reduction of the teams from `TeamsByFunction`.
// * So every option's `value` is actual the `teamID` of that team.
// * In this case we are creating a special case to have the value `-1`
// * Since it would never match an actual Team's ID, *and* is a Truthy value.
const ALL_ORG_TEAM_OPTION = {
  label: "Entire Organization",
  value: -1,
};

const ALL_ORG_OPT_LIST = [ALL_ORG_TEAM_OPTION];

const TIMEOUT_MS = 380;

interface Props {
  teamID?: number;
  isMobile?: boolean;
}

/**
 * Kinda difficult to read. There is 2 types of searching going on in here and in extension
 * 2 distinct patterns for doing said search and rendering each type's results.
 * [1] - Loading the DISC profiles for all members of a team and locally filtering the list
 * [2] - Selecting "Entire Organization' and Searching People via Advanced Person Search
 * ----- with a deffered search while typing (as to avoid searching on every key press)
 */
const TeamSearch = (props: Props) => {
  const timeoutKey = React.useRef<NodeJS.Timeout | undefined | null>(null);
  const [searchTerm, updateSearchTerm] = React.useState("");
  const [delayedAdvSearchTerm, updateDelayedAdvSearchTerm] = React.useState("");
  const [curTeamID, updateCurTeamID] = React.useState<number>(() => {
    if (props.teamID) {
      return props.teamID;
    }

    return (
      ActiveSession.teamRootID() ??
      ActiveSession.domainID() ??
      ALL_ORG_TEAM_OPTION.value
    );
  });
  const [filterD, updateFilterD] = React.useState(false);
  const [filterI, updateFilterI] = React.useState(false);
  const [filterS, updateFilterS] = React.useState(false);
  const [filterC, updateFilterC] = React.useState(false);
  const [selectedDiscMinis, updateSelectedDiscMinis] = React.useState<{
    [userID: number]: TytoData.DISCProfileMini;
  }>({});
  const [filteredResults, updateFilteredResults] = React.useState<
    TytoData.DISCProfileMini[]
  >([]);
  const [includeSelf, updateIncludeSelf] = React.useState(true);
  const [isPending, startTransition] = React.useTransition();
  const [showComparison, updateShowComparison] = React.useState(false);

  // * This query should only run (be `enabled`) when the curTeamID is the static 'Entire Organiziation` option
  const advPersonSearchQuery = useAdvancedPersonSearch({
    searchTerm: delayedAdvSearchTerm,
    extraOpts: {
      functionName: SECURITY_FUNCTIONS_BY_NAME["Team Membership"].functionName,
      operation: "ocVIEW",
    },
    isEnabled:
      curTeamID === ALL_ORG_TEAM_OPTION.value && !!delayedAdvSearchTerm,
    onSuccess: (resp) => {},
  });

  // * This query should NOT run (be `enabled`) IF the curTeamID is the static 'Entire Organiziation` option
  const teamsByFuncQuery = useTeamsByFunction({
    extraOpts: {
      functionName: SECURITY_FUNCTIONS_BY_NAME["Team Membership"].functionName,
      operation: "ocVIEW",
    },
    isEnabled: !!curTeamID,
    onSuccess: (resp) => {
      updateTeamOptions(makeTeamOptions(resp.teams));
    },
  });
  const teamDISCMinisQuery = useTeamDISCMini({
    teamID: curTeamID,
    isEnabled: curTeamID !== ALL_ORG_TEAM_OPTION.value,
    onSuccess: (data) => {
      // TODO
    },
  });

  const personalDiscMiniQuery = usePersonalDISCMini({});

  const [teamOptions, updateTeamOptions] = React.useState<
    { label: string; value: number }[]
  >(
    teamsByFuncQuery.data?.teams
      ? makeTeamOptions(teamsByFuncQuery.data?.teams)
      : ALL_ORG_OPT_LIST
  );

  const search = (searchTerm: string) => {
    updateSearchTerm(searchTerm ?? "");

    if (timeoutKey.current) {
      clearTimeout(timeoutKey.current);
      timeoutKey.current = null;
    }

    timeoutKey.current = setTimeout(() => {
      updateDelayedAdvSearchTerm(searchTerm ?? "");

      timeoutKey.current = null;
    }, TIMEOUT_MS);

    if (searchTerm || filterD || filterI || filterS || filterC) {
      startTransition(() => {
        let letters: Array<keyof typeof TytoData.DISCLetter> = [];

        // * Pretty low tech, but it works 🤷
        if (filterD) {
          letters.push("D");
        }
        if (filterI) {
          letters.push("I");
        }
        if (filterS) {
          letters.push("S");
        }
        if (filterC) {
          letters.push("C");
        }

        const passingDISCMinis = filterDISCMiniProfiles({
          searchTerm,
          letterFilters: letters?.length ? letters : undefined,
          discMiniProfiles: teamDISCMinisQuery.eagerData?.discProfiles,
        });

        updateFilteredResults(passingDISCMinis);
      });
    } else {
      updateFilteredResults(teamDISCMinisQuery.eagerData?.discProfiles ?? []);
    }
  };

  React.useEffect(() => {
    search(searchTerm);
  }, [teamDISCMinisQuery.eagerData, filterD, filterI, filterS, filterC]);

  const selectedProfilesEntries = Object.entries(selectedDiscMinis);
  const selectedUsersCount = selectedProfilesEntries?.length ?? 0;
  const showAction =
    selectedUsersCount > 1 || (selectedUsersCount === 1 && includeSelf);
  const isTeamView =
    selectedUsersCount > 2 || (selectedUsersCount === 2 && includeSelf);

  if (showComparison && selectedUsersCount) {
    if (
      selectedUsersCount === 1 ||
      (selectedUsersCount === 2 && !includeSelf)
    ) {
      return (
        <Comparison
          discMiniProfile={selectedProfilesEntries[0]?.[1]}
          otherDiscMini={selectedProfilesEntries[1]?.[1]}
          goBack={() => {
            updateShowComparison(false);
          }}
        />
      );
    } else {
      const profiles = selectedProfilesEntries
        .map(([_, profile]) => profile)
        .filter((profile) => !!profile.styleKey3);

      if (includeSelf && personalDiscMiniQuery.eagerData?.discProfiles[0]) {
        profiles.unshift(personalDiscMiniQuery.eagerData?.discProfiles[0]);
      }

      return (
        <GroupStyle
          discProfileMinis={profiles}
          goBack={() => {
            updateShowComparison(false);
          }}
          isMobile={props.isMobile}
        />
      );
    }
  }

  return (
    <article className="teams-search">
      <section className="teams-search-instructions">
        <div className="teams-search-instructions-htu">
          <h1 className="teams-search-instructions-htu-title">
            How to use the Team Collaboration Tool
          </h1>
          <p className="teams-search-instructions-htu-desc">
            Select one or more people from the selection below or search to find
            additional people. The tool will compare the R3 profiles and analyze
            the people you select below and map them to a team Heat Map. Along
            with the Heat map the tool will produce tips for how this team can
            work together better. This tool is great for beginning to understand
            how the different profile types interact with each other in group
            settings.
          </p>
        </div>
      </section>
      <section className="teams-search-filters">
        <ReactSelect
          className="teams-search-select"
          options={teamOptions}
          disabled={!teamOptions?.length && teamsByFuncQuery.isLoading}
          styles={{
            singleValue: (style: CSSStyleDeclaration) => ({
              ...style,
              height: "17px",
            }),
          }}
          onChange={(newTeamID) => {
            updateCurTeamID(
              typeof newTeamID === "number" ? newTeamID : newTeamID?.value
            );

            if (newTeamID?.value === ALL_ORG_TEAM_OPTION.value && searchTerm) {
              // * If switching from team to Entire Org, update other search term so
              // * It instantly searches that name (given that there is an existing searchTerm)
              updateDelayedAdvSearchTerm(searchTerm);
            }
          }}
          value={curTeamID}
        />

        <SearchInput
          containerClassName="teams-search-input"
          value={searchTerm}
          name="Find your colleague"
          placeholder="Find your colleague"
          onChange={search}
        />

        {curTeamID !== ALL_ORG_TEAM_OPTION.value && (
          <div className="teams-search-type-filters">
            <div className="teams-search-type-filters-inner-cont">
              <Button
                className="teams-search-type-filter-btn"
                onClick={() => {
                  updateFilterD(!filterD);
                }}
                type="button"
                theme={filterD ? "white" : "white-ghost"}
                value="Drivers"
              />
              <Button
                className="teams-search-type-filter-btn"
                onClick={() => {
                  updateFilterI(!filterI);
                }}
                type="button"
                theme={filterI ? "white" : "white-ghost"}
                value="Influencers"
              />
              <Button
                className="teams-search-type-filter-btn"
                onClick={() => {
                  updateFilterC(!filterC);
                }}
                type="button"
                theme={filterC ? "white" : "white-ghost"}
                value="Analyzers"
              />
              <Button
                className="teams-search-type-filter-btn"
                onClick={() => {
                  updateFilterS(!filterS);
                }}
                type="button"
                theme={filterS ? "white" : "white-ghost"}
                value="Stabilizers"
              />
            </div>
          </div>
        )}

        <div className="teams-search-includeself-cont">
          <CheckBox
            className="teams-search-includeself"
            isChecked={includeSelf}
            label="Include self"
            onChange={(newVal) => {
              updateIncludeSelf(newVal);
            }}
            size={21}
          />

          <TextButton
            className="teams-search-includeself-label"
            value="Include Me"
            onClick={() => {
              updateIncludeSelf(!includeSelf);
            }}
          />

          {!!filteredResults?.length &&
            curTeamID !== ALL_ORG_TEAM_OPTION.value && (
              <TextButton
                className="teams-search-select-all"
                onClick={() => {
                  const peopleWithDiscs = filteredResults.filter(
                    (disc) => disc.discStatus === "ocENABLED"
                  );
                  const showingUserIDs = peopleWithDiscs.reduce(
                    (
                      accum: { [userID: number]: TytoData.DISCProfileMini },
                      discMini
                    ) => {
                      accum[discMini.personID] = discMini;

                      return accum;
                    },
                    {}
                  );

                  updateSelectedDiscMinis({
                    ...(selectedDiscMinis ?? {}),
                    ...showingUserIDs,
                  });
                }}
                value="Select All"
              />
            )}

          <TextButton
            className="teams-search-clear-all"
            disabled={!selectedUsersCount}
            onClick={() => {
              updateSelectedDiscMinis({});
            }}
            value="Clear"
          />
        </div>
      </section>

      <section className="teams-search-people-cont">
        <div className="teams-search-people">
          {curTeamID !== ALL_ORG_TEAM_OPTION.value ? (
            <>
              {filteredResults?.map?.((discMiniProfile) => (
                <PersonTile
                  key={discMiniProfile.personID}
                  className={cx(
                    "teams-search-person",
                    selectedDiscMinis[discMiniProfile.personID] && "is-selected"
                  )}
                  discProfileMini={discMiniProfile}
                  onClick={(discMini) => {
                    const newSelectedUserIDs = selectedDiscMinis[
                      discMini.personID
                    ]
                      ? _.omit(selectedDiscMinis, discMini.personID)
                      : {
                          ...selectedDiscMinis,
                          [discMini.personID]: discMini,
                        };

                    updateSelectedDiscMinis(newSelectedUserIDs);
                  }}
                />
                // <li key={discMiniProfile.personID}>
                //   {discMiniProfile.personName} ({discMiniProfile.styleName3 ?? "--"}
                //   )
                // </li>
              )) ?? null}
            </>
          ) : (
            <>
              {advPersonSearchQuery.data?.ret?.people?.map?.((advPerson) => (
                <PersonSearchResult
                  key={advPerson.userID}
                  person={advPerson}
                  onClick={(discMini) => {
                    const newSelectedUserIDs = selectedDiscMinis[
                      discMini.personID
                    ]
                      ? _.omit(selectedDiscMinis, discMini.personID)
                      : {
                          ...selectedDiscMinis,
                          [discMini.personID]: discMini,
                        };

                    updateSelectedDiscMinis(newSelectedUserIDs);
                  }}
                  selectedDiscMinis={selectedDiscMinis}
                />
                // <li key={discMiniProfile.personID}>
                //   {discMiniProfile.personName} ({discMiniProfile.styleName3 ?? "--"}
                //   )
                // </li>
              )) ?? null}
              {!delayedAdvSearchTerm && (
                <Message text="Enter a Search Term to find your colleagues" />
              )}
            </>
          )}
        </div>
      </section>

      {showAction && (
        <section className="teams-search-people-cont selected-people-cont">
          <h2 className="teams-search-people-title">
            Selected People ({selectedProfilesEntries?.length ?? 0})
          </h2>
          <div className="teams-search-people">
            {selectedProfilesEntries.map(([__, selectedProfile]) => (
              <PersonTile
                key={selectedProfile.personID}
                className={cx(
                  "teams-search-person selected-people-person"
                  //   selectedDiscMinis[selectedProfile.personID] && "is-selected"
                )}
                discProfileMini={selectedProfile}
              />
            ))}
          </div>
        </section>
      )}

      {showAction && (
        <footer className="teams-search-footer">
          <Button
            className="teams-search-footer-btn"
            hoverBGSize="hidden"
            onClick={() => {
              updateShowComparison(true);
            }}
            theme="action"
            value={
              isTeamView
                ? "View Group Style"
                : `View ${selectedUsersCount} Comparison${
                    selectedUsersCount === 1 ? "" : "s"
                  }`
            }
          />
        </footer>
      )}
    </article>
  );
};

interface PersonSearchResultProps {
  person: TytoData.AdvancedPerson;
  onClick: (discMini: TytoData.DISCProfileMini) => void;
  selectedDiscMinis: {
    [userID: number]: TytoData.DISCProfileMini;
  };
}

const PersonSearchResult = (props: PersonSearchResultProps) => {
  const [loadMini, updateLoadMini] = React.useState(false);
  const [callbackOnLoad, updateCallbackOnLoad] = React.useState(false);
  const [hasDisc, updateHasDisc] = React.useState(true);

  const discMiniQuery = useDISCMini({
    userID: props.person.userID,
    isEnabled: !!loadMini,
    onSuccess: (data) => {
      if (data.discProfiles[0].discStatus !== "ocENABLED") {
        updateHasDisc(false);
      } else if (callbackOnLoad) {
        if (data.discProfiles?.[0]) {
          props.onClick(data.discProfiles?.[0]);
        }

        updateCallbackOnLoad(false);
      }
    },
  });

  React.useEffect(() => {
    if (
      discMiniQuery.isFetched &&
      discMiniQuery.data?.discProfiles[0].discStatus !== "ocENABLED"
    ) {
      updateHasDisc(false);
    }
  }, []);

  return (
    <div
      title={hasDisc ? "" : "This user has not completed R3 assessment"}
      className={
        hasDisc ? "team-search-person" : "team-search-person has-no-disc"
      }
      onMouseEnter={() => {
        if (
          discMiniQuery.isSuccess &&
          discMiniQuery.data.discProfiles[0].discStatus !== "ocENABLED"
        ) {
          updateHasDisc(false);
        }
        if (!discMiniQuery.isLoading && !loadMini) {
          updateLoadMini(true);
        }
      }}
      onClick={
        hasDisc
          ? () => {
              const loadedDISCMini = discMiniQuery.data?.discProfiles?.[0];

              if (loadedDISCMini) {
                props.onClick(loadedDISCMini);
              } else if (!discMiniQuery.isLoading && !loadMini) {
                updateLoadMini(true);
                updateCallbackOnLoad(true);
              } else if (discMiniQuery.isLoading && !callbackOnLoad) {
                updateCallbackOnLoad(true);
              }
            }
          : () => {}
      }
    >
      <PersonTileDumb
        className={cx(
          "teams-search-person",
          props.selectedDiscMinis?.[props.person.userID] && "is-selected"
        )}
        personID={props.person.userID}
        personName={`${props.person.givenName ?? ""} ${
          props.person.familyName.charAt(0) + "." ?? ""
        }`}
      />
    </div>
  );
};

// * So it always the same Array in memory and won't trigger unnecessary rerenders
const DEFAULT_LIST: TytoData.DISCProfileMini[] = [];

function filterDISCMiniProfiles({
  discMiniProfiles,
  letterFilters,
  searchTerm,
}: {
  discMiniProfiles?: TytoData.DISCProfileMini[];
  letterFilters?: Array<keyof typeof TytoData.DISCLetter>;
  searchTerm: string;
}) {
  if (!searchTerm && !letterFilters?.length) {
    return discMiniProfiles ?? DEFAULT_LIST;
  } else if (!discMiniProfiles) {
    return DEFAULT_LIST;
  }

  const escapedSearchTerm = _.escapeRegExp(searchTerm ?? "");
  const regExp = new RegExp((letterFilters ?? []).join("|"), "i");

  return !discMiniProfiles.length
    ? discMiniProfiles
    : discMiniProfiles.filter((discMiniProfile) => {
        // * If there are filter letters and this profile name doesn't pass, return this person does not match
        if (letterFilters?.length && !regExp.test(discMiniProfile.styleKey3)) {
          return false;
        }

        const nameRegExp = new RegExp(escapedSearchTerm, "i");

        return nameRegExp.test(discMiniProfile.personName);
      });
}

function makeTeamOptions(teams: TytoData.Team[]) {
  if (!teams?.length) {
    return ALL_ORG_OPT_LIST;
  }

  const teamOptions = teams.map((team) => ({
    label: `${team.name}`,
    value: team.teamID,
  }));

  teamOptions.unshift(ALL_ORG_TEAM_OPTION);

  return teamOptions;
}

export default TeamSearch;
