import * as React from "react";
import * as _ from "lodash";

import { IMAGE_BASE_URL } from "../constants/";
import { SessionHandling, LOC_STOR, SES_STOR } from "../storage/";
import {
  clearLFInputValue,
  createLFInputKey,
  getCoursePlanContext,
  findCourseInTraining,
  findParentTraining,
} from "./";

function findThumbnailOverride(images?: Data.LessonImage[]) {
  if (!images || !images.length) {
    return undefined;
  }

  return images.find((img) => img.imageName === "thumbnailOverride");
}

function getThumbnailOverride(images?: Data.LessonImage[]) {
  const thumbnailOverride = findThumbnailOverride(images);

  return thumbnailOverride;
}

function getFormattedOverridePathURLFromImage({
  image,
  includeSessionKey = true,
}: {
  image?: Data.LessonImage;
  includeSessionKey?: boolean;
}) {
  if (!image) {
    return undefined;
  }

  const paramPrefix = /\?(\w)+=/i.test(image.pathURL) ? "&" : "?";

  return `${IMAGE_BASE_URL}${image.pathURL}${
    includeSessionKey
      ? `${paramPrefix}sessionKey=${SessionHandling.getActiveSessionKey()}`
      : ""
  }`;
}

function getFormattedOverridePathURL({
  images,
  includeSessionKey = true,
}: {
  images?: Data.LessonImage[];
  includeSessionKey?: boolean;
}) {
  const thumbnailOverride = findThumbnailOverride(images);

  if (!thumbnailOverride) {
    return undefined;
  }

  const paramPrefix = /\?(\w)+=/i.test(thumbnailOverride.pathURL) ? "&" : "?";

  return `${IMAGE_BASE_URL}${thumbnailOverride.pathURL}${
    includeSessionKey
      ? `${paramPrefix}sessionKey=${SessionHandling.getActiveSessionKey()}`
      : ""
  }`;
}

export function useLessonImage(images?: Data.LessonImage[]) {
  const [thumbnailOverride, updateThumbnailOverride] = React.useState(
    findThumbnailOverride(images)
  );

  React.useEffect(() => {
    updateThumbnailOverride(findThumbnailOverride(images));
  }, [images]);

  return thumbnailOverride;
}

export function useLessonThumbnailOverrideData(images?: Data.LessonImage[]) {
  const [thumbnailOverride, updateThumbnailOverride] = React.useState(
    getThumbnailOverride(images)
  );
  const [thumbnailOverridePathURL, updateThumbnailOverridePathURL] =
    React.useState(
      getFormattedOverridePathURLFromImage({ image: thumbnailOverride })
    );

  React.useEffect(() => {
    const newThumbnailOverride = getThumbnailOverride(images);
    const newThumbnailPath = getFormattedOverridePathURLFromImage({
      image: newThumbnailOverride,
    });

    updateThumbnailOverride(newThumbnailOverride);
    updateThumbnailOverridePathURL(newThumbnailPath);
  }, [images]);

  return {
    thumbnailOverride,
    thumbnailOverridePathURL,
  };
}

export function useLessonThumbnailOverride(images?: Data.LessonImage[]) {
  const [thumbnailOverridePathURL, updateThumbnailOverridePathURL] =
    React.useState(getFormattedOverridePathURL({ images }));

  React.useEffect(() => {
    updateThumbnailOverridePathURL(getFormattedOverridePathURL({ images }));
  }, [images]);

  return thumbnailOverridePathURL;
}

export function useCourseData({
  courseID,
  // coursePlanContextID,
  trainingQuery,
}: {
  courseID: number;
  // coursePlanContextID?: string | number;
  trainingQuery?: SITE.QueryProp<Endpoints.Responses.Training.Get>;
}) {
  // * [1] - Find course
  const courseFromTraining = React.useMemo(() => {
    console.log("Running useCourseData -> courseFromTraining (useMemo)");

    return findCourseInTraining({
      curriculumID: courseID,
      primaryTraining:
        trainingQuery?.data?.training ??
        trainingQuery?.storedValueQuery?.data?.training ??
        [],
      subBlocks:
        trainingQuery?.data?.subBlocks ??
        trainingQuery?.storedValueQuery?.data?.subBlocks ??
        [],
    });
    // return pullCourseFromTrainingList({
    //   courseID,
    //   trainingResp: _.get(
    //     trainingQuery,
    //     "data",
    //     _.get(trainingQuery, "storedValueQuery.data")
    //   ),
    // });
  }, [
    courseID,
    trainingQuery?.data?.subBlocks,
    trainingQuery?.data?.training,
    trainingQuery?.storedValueQuery.data?.subBlocks,
    trainingQuery?.storedValueQuery.data?.training,
  ]);

  // * [2] - Get Course Context parentID
  const courseParentContextID = React.useMemo(() => {
    let storedCntxID = getCoursePlanContext(courseID) ?? undefined;

    if (
      !storedCntxID &&
      ((courseFromTraining as TytoData.Training.SubBlock)?.parentTasks
        ?.length ||
        (courseFromTraining as TytoData.Training.SubBlock)?.parentBlocks
          ?.length)
    ) {
      const parentID =
        (courseFromTraining as TytoData.Training.SubBlock)?.parentTasks?.[0]
          ?.rootTaskID ??
        (courseFromTraining as TytoData.Training.SubBlock)?.parentBlocks?.[0]
          ?.blockID;

      if (parentID) {
        storedCntxID = `${parentID}`;
      }
    }

    return storedCntxID;
  }, [courseFromTraining]);

  // * [3] - Find course's target parent
  const parentCourseOrPlan = React.useMemo(() => {
    if (
      !courseFromTraining ||
      (!(courseFromTraining as TytoData.Training.SubBlock).parentBlocks
        ?.length &&
        !(courseFromTraining as TytoData.Training.SubBlock).parentTasks?.length)
    ) {
      return undefined;
    }

    return findParentTraining({
      primaryTraining:
        trainingQuery?.data?.training ??
        trainingQuery?.storedValueQuery?.data?.training ??
        [],
      targetParentID: courseParentContextID,
    });
  }, [
    courseFromTraining,
    courseParentContextID,
    trainingQuery?.data?.subBlocks,
    trainingQuery?.data?.training,
  ]);

  return {
    courseFromTraining,
    courseParentContextID,
    parentCourseOrPlan,
  };
}

export function useCourseFromTraining({
  courseID,
  taskID,
  training,
}: {
  courseID?: number;
  taskID?: number;
  training?: Array<TytoData.Training.Enrollment | TytoData.Training.Task>;
}) {
  return React.useMemo(() => {
    if (!courseID || !taskID || !training?.length) {
      return undefined;
    }

    return training.find((courseOrPlan) => {
      if (courseID) {
        return courseOrPlan.curriculumID === courseID;
      } else if (taskID) {
        return (courseOrPlan as TytoData.Training.Task).taskID === taskID;
      }

      return false;
    });
  }, [courseID, taskID, training]);
}

export function useCourseParentIDs(
  courseID: number,
  AppStoreState?: StoreAPI.AppStoreState
) {
  return React.useMemo(() => {
    return AppStoreState?.coursesParentMap.get(`${courseID}`);
  }, [courseID, AppStoreState?.coursesParentMap]);
}

const DEFAULT_PARENTS_DATA = {
  blocks: [],
  plans: [],
};

export function useCourseParents(
  parentMap?: StoreAPI.CourseParentsList,
  training?: Array<TytoData.Training.Enrollment | TytoData.Training.Task>
) {
  return React.useMemo(() => {
    if (!parentMap || !training?.length) {
      return DEFAULT_PARENTS_DATA;
    }

    const blockIDsSet = new Set(parentMap.blockIDs || []);
    const taskIDsSet = new Set(parentMap.planTaskIDs || []);

    if (!taskIDsSet.size && !blockIDsSet.size) {
      // TODO
      return DEFAULT_PARENTS_DATA as StoreAPI.CourseParentsListRetrieved;
    }

    const data = training.reduce(
      (
        accum: {
          blocks: TytoData.Training.Enrollment[];
          plans: TytoData.Training.Task[];
        },
        courseOrPlan
      ) => {
        if ((courseOrPlan as TytoData.Training.Task).taskID) {
          if (
            taskIDsSet.size &&
            taskIDsSet.has((courseOrPlan as TytoData.Training.Task).taskID)
          ) {
            accum.plans = accum.plans.concat(
              courseOrPlan as TytoData.Training.Task
            );
          }
        } else {
          if (blockIDsSet.size && blockIDsSet.has(courseOrPlan.curriculumID)) {
            accum.blocks = accum.blocks.concat(
              courseOrPlan as TytoData.Training.Enrollment
            );
          }
        }

        return accum;
      },
      {
        blocks: [],
        plans: [],
      }
    );

    return data as StoreAPI.CourseParentsListRetrieved;
    // return AppStoreState?.coursesParentMap.get(`${courseID}`);
  }, [parentMap, training]);
}

function getMainScrollElement() {
  // return window;
  return document.documentElement;
}

export function usePageScrollObserver(
  callback?: (data: {
    scrollTop: number;
    isScrolled: boolean;
    scrollDirection: "down" | "up" | null;
  }) => void
) {
  const scrollTop = React.useRef(document.documentElement.scrollTop ?? 0);
  // const isScrolling = React.useRef(false);
  // const scrollDirection = React.useRef<"down" | "up" | null>(null);

  // const [scrollTop, updateScrollTop] = React.useState(
  // document.documentElement.scrollTop ?? 0
  // );
  const [isScrolled, updateIsScrolled] = React.useState(!!scrollTop.current);
  const [scrollDirection, updateScrollDirection] = React.useState<
    "down" | "up" | null
  >(null);

  React.useEffect(() => {
    function onScroll(e: Event) {
      console.log(
        "%conScroll even triggered.",
        "background-color: #434343; color: #ececec;"
      );

      const mainScrollElement = getMainScrollElement();

      // const isDifferent = mainScrollElement.scrollTop !== scrollTop.current;
      const isDifferent = mainScrollElement.scrollTop !== scrollTop.current;
      const newIsScrolled = !!mainScrollElement.scrollTop;

      if (isDifferent) {
        const newScrollDirValue =
          scrollTop.current > mainScrollElement.scrollTop ? "up" : "down";

        updateScrollDirection(newScrollDirValue);
        // updateScrollTop(mainScrollElement.scrollTop);
        scrollTop.current = mainScrollElement.scrollTop;
      }

      console.log(
        `mainElement.scrollTop: `,
        mainScrollElement.scrollTop,
        " newIsScrolled: ",
        newIsScrolled,
        " cur isScrolled: ",
        isScrolled
      );

      updateIsScrolled(newIsScrolled);

      callback?.({
        scrollTop: scrollTop.current,
        isScrolled,
        scrollDirection,
      });
      // callback?.({
      //   scrollTop: scrollTop.current,
      //   isScrolling: isScrolling.current,
      //   scrollDirection: scrollDirection.current,
      // });
    }

    window.addEventListener(
      "scroll",
      _.throttle(onScroll, 200, { leading: true, trailing: true })
    );

    return () =>
      window?.removeEventListener(
        "scroll",
        _.throttle(onScroll, 200, { leading: true, trailing: true })
      );
  }, []);

  const data = React.useMemo(() => {
    return {
      scrollTop: scrollTop.current,
      isScrolled,
      scrollDirection,
    };
  }, [scrollTop.current, isScrolled, scrollDirection]);

  return data;

  // return {
  //   scrollTop,
  //   isScrolling,
  //   scrollDirection,
  //   // scrollTop: scrollTop.current,
  //   // isScrolling: isScrolling.current,
  //   // scrollDirection: scrollDirection.current,
  // };
}

function getDesiredStorage(storageType: "localStorage" | "sessionStorage") {
  if (storageType === "sessionStorage") {
    return SES_STOR;
  }

  return LOC_STOR;
}

export function useStoredInputValue({
  subKey,
  initialValue,
  storageType = "localStorage",
}: {
  subKey: string;
  initialValue?: string;
  storageType?: "localStorage" | "sessionStorage";
}) {
  const [inputValue, updateStoredValue] = React.useState(() => {
    return (
      initialValue ??
      getDesiredStorage(storageType).get(createLFInputKey(subKey)) ??
      ""
    );
  });

  // * If subKey changes, get stored value for the new key
  React.useEffect(() => {
    updateStoredValue(
      getDesiredStorage(storageType).get(createLFInputKey(subKey)) ?? ""
    );
  }, [subKey]);

  // * Simple 'set' function override that both updates the state value *and* updates LocalStorage value
  // * NOTE: LocalStorage update is after incase it fails (updateStoredValue will always occur)
  const updateValue = (newValue: string) => {
    try {
      updateStoredValue(newValue);

      getDesiredStorage(storageType).set(createLFInputKey(subKey), newValue);
    } catch (err) {
      // TODO
    }
  };

  // * Dev Friendly method to have on hand
  // * Intended for clearing Data after a Successful 'PUT' or 'POST'
  const clearStoredValue = (resetLocalValue = true) => {
    if (resetLocalValue) {
      console.log("RESETTING VALUE: ", subKey);
      updateStoredValue("");
    }

    return clearLFInputValue(subKey);
  };

  return [inputValue, updateValue, clearStoredValue] as [
    string,
    (newValue: string) => void,
    () => void
  ];
}

// * Straight reipped from https://usehooks.com/useEventListener/
export function useEventListener(
  eventName: keyof WindowEventMap,
  handler: (e: Event) => void,
  element = window
) {
  // Create a ref that stores handler
  const savedHandler = React.useRef<(e: Event) => void | undefined>();
  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  React.useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  React.useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;

      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event: Event) => savedHandler.current?.(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}

export function useIntersectionObserver({
  forceOff = false,
  onEntry,
  rootMargin,
  threshold = [0.1],
  root = null,
}: // root = null,
{
  forceOff?: boolean;
  onEntry?: (entryData: IntersectionObserverEntry) => void;
  rootMargin?: string;
  root?: Element | null;
  threshold?: number[];
}) {
  const [entry, updateEntry] = React.useState<IntersectionObserverEntry>(
    {} as IntersectionObserverEntry
  );
  const [node, updateNode] = React.useState<Element | null>(null);

  const observer = React.useRef<IntersectionObserver | null>(null);

  React.useEffect(() => {
    // * If IntersectionObserver is not found on window don't even try to do anything
    if (!("IntersectionObserver" in window)) {
      console.log("No IntersectionObserver in window");
      return;
    }

    // const { rootMargin, threshold = [0.1] } = observerOpts;

    // * If observer previously created, trash it and restart with changed parameters
    if (observer.current) {
      (observer.current as IntersectionObserver).disconnect();
    }

    if (!node || forceOff) {
      return;
    }

    // * Create IntersectionObserver with given params
    observer.current = new IntersectionObserver(
      (entries) => {
        // * If a callback was supplied, envoke it here.
        if (onEntry) {
          onEntry(entries[0]);
        }

        // * Set value that gets returned from this hook incase a useEffect in that component is watching for changes off of it
        updateEntry(entries[0]);
      },
      {
        root,
        rootMargin,
        threshold,
      }
    );

    const { current: currentObserver } = observer;

    // * If a React Ref has been set via return value of this hook, create an IntersectionObserver with it
    if (node) {
      currentObserver.observe(node);
    }

    // * Functions returned from useEffect get called on component dismount
    return () => currentObserver.disconnect();
  }, [node, forceOff]);

  return {
    updateNode,
    entry,
  };
}

export function useDISCPersonName(personName?: string) {
  const [names, updateName] = React.useState(() => {
    const [firstName, ...lastNames] = (personName ?? "").split(" ");
    const lastName = lastNames.slice(-1)[0] ?? "";

    return [firstName ?? "", lastName ?? ""];
  });

  React.useEffect(() => {
    const [firstName, ...lastNames] = (personName ?? "").split(" ");
    const lastName = lastNames.slice(-1)[0] ?? "";

    const newNames = [firstName ?? "", lastName ?? ""];

    updateName(newNames);
  }, [personName]);

  return names;
}

export function useFirstName(personName?: string) {
  const [firstName] = useDISCPersonName(personName);

  return firstName;
}
