import {
  ENDPOINT_PEOPLE_KEYS_FIND_ASSESSMENTS,
  ENDPOINT_PEOPLE_KEYS_FIND_ASSESSMENTS_V4,
} from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_PEOPLE_KEYS_FIND_ASSESSMENTS;
const endpoint_v4 = ENDPOINT_PEOPLE_KEYS_FIND_ASSESSMENTS_V4;

export const PeopleKeysFindAssessment = {
  get(
    params: Endpoints.Tyto.PeopleKeys.FindAssessments.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      { ...params },
      callOpts
    ) as Promise<Endpoints.Responses.PeopleKeys.FindAssessments.Get>;
  },
};

export const PeopleKeysFindAssessmentV4 = {
  get(
    params: Endpoints.Tyto.PeopleKeys.FindAssessments.V4.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint_v4,
      { ...params },
      callOpts
    ) as Promise<Endpoints.Responses.PeopleKeys.FindAssessments.V4.Get>;
  },
  post(
    params: Endpoints.Tyto.PeopleKeys.FindAssessments.V4.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "post",
      endpoint_v4,
      { ...params },
      callOpts
    ) as Promise<Endpoints.Responses.PeopleKeys.FindAssessments.V4.Post>;
  },
};
