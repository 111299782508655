import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { INTERFACE_PATHS, SUB_INTERFACE_PATHS } from "data/constants/";
import { ActiveSession } from "data/storage";
import { Link } from "components/common/";

import "./style.scss";

interface Props {}

const HowToReadProfile = (props: Props) => {
  const [loggedInUserID] = React.useState(() => {
    return ActiveSession.userID() ?? 0;
  });

  return (
    <div className="centered-content-wrapper">
      <section className="profile-howtoread profile-subinterface">
        <h2 className="profile-section-title title-font">
          How to read your R3 graph and report
        </h2>

        <p className="profile-section-text">
          Your R3 profile is based on four core energies: Driver, Influencer,
          Stabilizer and Analyzer.
        </p>

        {/* <p className="profile-section-text">
          Before continuing you to your results watch this video to better
          understand your graph and report.
        </p>

        <div className="fake-video-placeholder with-placeholder" /> */}

        <Link
          className="profile-section-link"
          buttonProps={{
            className: "profile-section-btn large-btn",
            hoverBGSize: "hidden",
            onClick: _.noop,
            value: "See Results",
            theme: "action",
          }}
          value="See Results"
          type="button"
          href={`${INTERFACE_PATHS.PROFILE}/${loggedInUserID}/${SUB_INTERFACE_PATHS.PROFILE.RESULTS}`}
        />
      </section>
    </div>
  );
};

export default HowToReadProfile;
