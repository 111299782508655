import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import "./style.scss";

// * DISCMini should still return just not containing a styleKey?
interface Props {
  discProfileMini?: TytoData.DISCProfileMini;
  message?: string;
}

const HomeNoResults = (props: Props) => {
  return (
    <article className="no-results-cont">
      <h2 className="no-results-title title-font font-size-title">
        No Results
      </h2>
      {props.message && <p className="no-results-msg">{props.message}</p>}
    </article>
  );
};

export default HomeNoResults;
