/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import AgnosticModal from "../agnostic";
import PlotGraphWrapper from "./PlotGraphWrapper";

import "./style.scss";

interface Props {
  className?: string;
  discCompareProfile: TytoData.DISCCompareProfile;
  overlayClassName?: string;
  isOpen?: boolean;
  graphWidth?: number;
  modalStyle?: React.CSSProperties;
  onDismiss?: () => void;
  overlayStyle?: React.CSSProperties;
  startingColumn?: "d" | "i" | "s" | "c";
}

const DISCPlotGraphModal = (props: Props) => {
  return (
    <AgnosticModal
      className={cx("plotgraph-modal-dialog-container", props.className)}
      overlayClassName={cx(
        "plotgraph-modal-dialog-overlay",
        props.overlayClassName
      )}
      hasCloseBtn={false}
      isOpen={props.isOpen}
      modalStyle={props.modalStyle}
      onDismiss={props.onDismiss}
      overlayStyle={props.overlayStyle}
    >
      <PlotGraphWrapper
        closePopup={props.onDismiss}
        discCompareProfile={props.discCompareProfile}
        graphWidth={props.graphWidth}
        startingColumn={props.startingColumn}
      />
    </AgnosticModal>
  );
};

export default DISCPlotGraphModal;
