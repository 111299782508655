import { ENDPOINT_PEOPLE_KEYS_CALLBACK_REPORT } from "../../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_PEOPLE_KEYS_CALLBACK_REPORT;

export const PeopleKeysCallbackReport = {
  get(
    params: Endpoints.Tyto.PeopleKeys.Callback.Report.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      { ...params },
      callOpts
    ) as Promise<Endpoints.Responses.PeopleKeys.Callback.Report.Get>;
  },
};
