/*
 * Renders top level component for Profile Interface
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { SessionHandling } from "data/storage/";
import { Nav, Menu, Footer } from "components/meta";
import { IS_LOCAL_HOST, SUB_INTERFACE_PATHS } from "data/constants/";
import { useDISCMini } from "data/network/hooks/";
import { Message } from "components/common/";
import { getUserHas247UpdatesAccess } from "data/helpers/session";

import AssessmentTaker from "./AssessmentTaker";
import AssessmentTaker247 from "./247-taker/AssessmentTaker";

import "./style.scss";

interface Props extends SITE.GeneralStoreInjectedProps {}

const AssessmentTakerWrapper = (props: Props) => {
  const [menuHasBeenOpen, updateMenuHasBeenOpen] = React.useState(
    _.get(props, "GeneralStore.state.showMenu", false)
  );
  const [loggedInUserID] = React.useState(() => {
    return SessionHandling.getUserIDOfActiveSession() ?? 0;
  });

  const discMiniQuery = useDISCMini({
    userID: loggedInUserID,
    isEnabled: !!loggedInUserID,
  });

  const storeMenuOpenValue = _.get(props, "GeneralStore.state.showMenu", false);

  // * Trigger flag so that turning menu off animates back left
  React.useEffect(() => {
    if (!menuHasBeenOpen && storeMenuOpenValue) {
      updateMenuHasBeenOpen(true);
    }
  }, [storeMenuOpenValue, menuHasBeenOpen]);

  const menuIsOpen = storeMenuOpenValue;
  const isMobile = !!props.GeneralStore?.state?.isMobile;

  const discMiniProfile = discMiniQuery?.eagerData?.discProfiles[0];
  const use247Taker = IS_LOCAL_HOST || getUserHas247UpdatesAccess();

  return (
    <div className={cx("interface-wrapper", menuIsOpen && "menu-open")}>
      {!isMobile && (
        <Nav activeTab="Assessment" className="assessment-taker-nav" />
      )}
      <Menu className="assessment-taker-menu" />

      <div
        className={cx(
          "interface-main-cont interface-assessment-taker",
          menuHasBeenOpen && "animate-back"
        )}
      >
        {discMiniQuery.isLoading ? (
          <Message text="Loading..." />
        ) : (
          <>
            {use247Taker ? (
              <AssessmentTaker247
                isMobile={isMobile}
                GeneralStore={props.GeneralStore}
                discProfileMini={discMiniProfile}
                loggedInUserID={loggedInUserID}
              />
            ) : (
              <AssessmentTaker
                isMobile={isMobile}
                GeneralStore={props.GeneralStore}
                discProfileMini={discMiniProfile}
              />
            )}
          </>
        )}
      </div>

      {isMobile && <Footer activeTab="Assessment" />}
    </div>
  );
};

export default AssessmentTakerWrapper;
