import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { usePersonCommTips } from "data/network/hooks/";
import { CommTipsEditable } from "components/disc/";

import "./style.scss";

interface Props {
  compareProfile?: TytoData.DISCCompareProfile;
  isOwnProfile: boolean;
}

const CommunicationTipsEditable = (props: Props) => {
  const personCommTips = usePersonCommTips({
    discID: props.compareProfile?.result.discID ?? 0,
    isEnabled: !!props.compareProfile?.result.discID,
  });

  return (
    <div className="centered-content-wrapper">
      <section className="profile-commtips-editable profile-subinterface">
        <h2 className="profile-section-title title-font">
          Your Communication Tips
        </h2>
      </section>

      {props.compareProfile && personCommTips.eagerData && (
        <CommTipsEditable
          canEdit={props.isOwnProfile}
          compareProfile={props.compareProfile}
          personCommunicationTips={
            personCommTips.eagerData.personCommunicationTips
          }
        />
      )}
    </div>
  );
};

export default CommunicationTipsEditable;
