/* @flow */
import _ from "lodash";
import React, { Component } from "react";
import cx from "classnames";
import { fabric } from "fabric";

import {
  DISC_COLOR_D,
  DISC_COLOR_I,
  DISC_COLOR_S,
  DISC_COLOR_C,
} from "../../../data/constants/";

import "./style.scss";

const labels: {
  label: string;
  letterStyle: React.CSSProperties;
  type: string;
}[] = [
  {
    label: "Driver",
    letterStyle: {
      top: "0px",
      left: "0px",
    },
    type: "d",
  },
  {
    label: "Influencer",
    letterStyle: {
      top: "0px",
      left: "50%",
    },
    type: "i",
  },
  {
    label: "Stabilizer",
    letterStyle: {
      top: "50%",
      left: "50%",
    },
    type: "s",
  },
  {
    label: "Analyzer",
    letterStyle: {
      top: "50%",
      left: "0px",
    },
    type: "c",
  },
];

interface Props {
  className?: string;
  contStyle?: Object; // React.CSSProperties,
  hideNull?: boolean;
  left?: number;
  onCanvasRendered?: (
    canvas?: HTMLCanvasElement,
    // @ts-ignore
    DISCProfile: TytoData.DISCProfileMini
  ) => void;
  onClick?: () => void;
  innerRef?: (
    node: HTMLDivElement | null
  ) => void | React.MutableRefObject<HTMLDivElement | null>;
  // innerRef?: React.MutableRefObject<HTMLDivElement | null>;
  profile?: TytoData.DISCProfileMini;
  scale?: number;
  size: number;
  showInprogress?: boolean;
  title?: string;
  top?: number;
  userID?: number;
  version: number | string;
}

interface State {
  cPercentStr?: string;
  completedAssessment: boolean;
  dPercentStr?: string;
  hasDisc: boolean;
  iPercentStr?: string;
  inProgress: boolean;
  loading: boolean;
  profile?: TytoData.DISCProfileMini;
  sPercentStr?: string;
  version: number | string;
}

export default class DISC extends Component<Props, State> {
  canvas?: HTMLCanvasElement | null;

  static defaultProps = {
    size: 50,
  };

  static displayName = "DISC";

  constructor(props: Props) {
    super(props);

    if (process.env.PERFORM_SANITY_CHECK) {
      if (!props.profile && !props.userID) {
        debugger;
        throw new Error("Either userID or profile must be defined");
      }
    }
    this.state = {
      hasDisc: _.has(props, "profile"),
      // TODO: Decide how to properly determine "completedAssessment" and "inProgress"
      completedAssessment: !!_.get(props, ["profile", "styleKey3"]),
      inProgress: !_.get(props, ["profile", "styleKey3"]),
      loading: !props.profile,
      profile: props.profile,
      version: props.version,
    };

    this._addCanvasRef = this._addCanvasRef.bind(this);
  }

  componentDidMount() {
    //   Promise.all([
    //     import("./vendor/fabric_static"),
    //     // @ts-ignore
    //     import("./vendor/prism")
    //   ]).then(([fabric]) => {
    //     this.setState(
    //       {
    //         loading: !this.props.profile
    //       },
    //       // @ts-ignore
    //       this.props.profile ? this.beginDraw : this.loadProfile
    //     );
    //   });
    if (this.props.profile) {
      this.beginDraw();
    }
  }

  // loadProfile() {
  //     this.subscription = sub(new SubscriptionOpts({ callOpts: { parseProfileOnly: true } }))
  //         .server("DiscProfiles", { personIDs: this.props.userID })
  //         .withCache()
  //         .onChange(profiles => {
  //             const profile = profiles.find(({ personID }) => personID == this.props.userID);
  //             const completedAssessment =
  //                 profile && profile.discPersonActiveStatus == "ocENABLED";
  //             const inProgress = profile && profile.discPersonActiveStatus == "ocPENDING";
  //             this.setState(
  //                 {
  //                     loading: false,
  //                     hasDisc: !!profile,
  //                     completedAssessment,
  //                     inProgress,
  //                     profile
  //                 },
  //                 completedAssessment ? this.beginDraw : this._onNonCompleteDISC
  //             );
  //         });
  // }

  _addCanvasRef(n: HTMLCanvasElement) {
    this.canvas = n;
  }

  _onCanvasRendered(canvas?: HTMLCanvasElement) {
    if (this.props.onCanvasRendered) {
      // @ts-ignore
      this.props.onCanvasRendered(canvas, this.state.profile || {});
    }
  }

  _onNonCompleteDISC() {
    this._onCanvasRendered();
  }

  render() {
    if (
      (!this.state.loading && this.state.hasDisc === false) ||
      (!this.props.showInprogress && this.state.inProgress)
    ) {
      if (this.props.hideNull) {
        return <noscript />;
      }
      return (
        <div style={{ height: this.props.size, width: this.props.size }} />
      );
    }

    return (
      <div
        className={cx(
          "cc-disc",
          this.state.hasDisc && "cc-disc-has-bg disc-grid-bg-with-grid",
          this.props.className
        )}
        onClick={this.props.onClick}
        ref={this.props.innerRef}
        style={{
          ...this.props.contStyle,
          height: this.props.size,
          width: this.props.size,
        }}
        title={this.props.title}
      >
        {this.state.loading ? (
          <p>Waiting...</p>
        ) : (
          <div>
            {/* {labels.map(({ label, letterStyle, type }) => (
              <p
                className="cc-disc-grid-letter"
                key={label}
                style={{
                  ...letterStyle,
                  fontSize: `${Math.floor(this.props.size / 4)}px`,
                }}
              >
                {type.toUpperCase()}
              </p>
            ))} */}

            {this.state.completedAssessment && (
              <canvas
                className="cc-disc-graph"
                ref={this._addCanvasRef}
                height={`${this.props.size}px`}
                width={`${this.props.size}px`}
              />
            )}
            {this.state.dPercentStr && (
              <span className="cc-disc-label-sm cc-disc-label-sm-d">
                {" "}
                {this.state.dPercentStr}
              </span>
            )}
            {this.state.iPercentStr && (
              <span className="cc-disc-label-sm cc-disc-label-sm-i">
                {" "}
                {this.state.iPercentStr}
              </span>
            )}
            {this.state.sPercentStr && (
              <span className="cc-disc-label-sm cc-disc-label-sm-s">
                {" "}
                {this.state.sPercentStr}
              </span>
            )}
            {this.state.cPercentStr && (
              <span className="cc-disc-label-sm cc-disc-label-sm-c">
                {" "}
                {this.state.cPercentStr}
              </span>
            )}
          </div>
        )}
      </div>
    );
  }

  //* You can pass a version number or string to get specific graph 3(Percieved), 2(Core) or 1(Public)
  beginDraw() {
    if (this.state.completedAssessment) {
      const { profile, version } = this.state;
      if (profile && (version === 3 || version === "percieved")) {
        this.drawDISC(
          {
            d: profile.d3,
            i: profile.i3,
            s: profile.s3,
            c: profile.c3,
          },
          {
            scale: this.props.scale || this.props.size / 115.5,
            round: true,
            left: this.props.left || this.props.size / 2,
            top: this.props.top || this.props.size / 2,
          }
        );
      } else if (profile && (version === 2 || version === "core")) {
        this.drawDISC(
          {
            d: profile.d2,
            i: profile.i2,
            s: profile.s2,
            c: profile.c2,
          },
          {
            scale: this.props.scale || this.props.size / 115.5,
            round: true,
            left: this.props.left || this.props.size / 2,
            top: this.props.top || this.props.size / 2,
          }
        );
      } else if (profile) {
        // Public Self
        this.drawDISC(
          {
            d: profile.d1,
            i: profile.i1,
            s: profile.s1,
            c: profile.c1,
          },
          {
            scale: this.props.scale || this.props.size / 115.5,
            round: true,
            left: this.props.left || this.props.size / 2,
            top: this.props.top || this.props.size / 2,
          }
        );
      }
    } else {
      this._onNonCompleteDISC();
    }
  }

  /** Draw a disc quadrant
		@param {Object} scores Scores object
		@param {Number} scores.d D score
		@param {Number} scores.i I score
		@param {Number} scores.s S score
		@param {Number} scores.c C score
		@param {Object} options Draw options {scale {Number}, round {Boolean}, left: {Number}, top: {Number}}
		@param {Number} options.scale
		@param {Boolean} options.round
		@param {Number} options.left
		@param {Number} options.top */
  drawDISC(
    scores: { d: number; i: number; s: number; c: number },
    options: {
      scale?: number;
      top?: number;
      left?: number;
      round?: boolean;
    } = {}
  ) {
    /* eslint-disable camelcase */

    const { left = 50, scale = 2.6, top = 45.28 } = options;

    // Enter raw -8 to 8 DISC scores here
    const D_origin = Math.min(8, Math.max(scores.d, -8));
    const I_origin = Math.min(8, Math.max(scores.i, -8));
    const S_origin = Math.min(8, Math.max(scores.s, -8));
    const C_origin = Math.min(8, Math.max(scores.c, -8));

    // Convert scale of 0 to 50
    let D = ((D_origin + 8) * 50) / 16;
    let I = ((I_origin + 8) * 50) / 16;
    let S = ((S_origin + 8) * 50) / 16;
    let C = ((C_origin + 8) * 50) / 16;

    const DSCORE = D * scale;
    const ISCORE = I * scale;
    const SSCORE = S * scale;
    const CSCORE = C * scale;

    const total = D + I + S + C;
    D = (D / total) * 100;
    I = (I / total) * 100;
    S = (S / total) * 100;
    C = (C / total) * 100;

    // Get rounded percentages
    if (options.round) {
      D = Math.round(D);
      I = Math.round(I);
      S = Math.round(S);
      C = Math.round(C);
    }

    // Handle potential rounding issues. The rounded scores may not equal 100% (Consider a D=12.2, I=30.4, S=28.1, C=29.3,
    // when rounded all will round down...). PeopleKeys seems to handle this by adjusting the D score (note this is true
    // even when it might make more sense to adjust a different score, as with the example numbers above) so we add the difference
    // of Sum(DISC) and 100 to D.
    D += 100 - (D + I + S + C);

    // m = (Y2 - Y1) / (X2 - X1)
    const DI_Slope = (DSCORE - ISCORE) / (-DSCORE - ISCORE); // DI
    const IS_Slope = (ISCORE + SSCORE) / (SSCORE - ISCORE); // IS
    const SC_Slope = (-SSCORE + CSCORE) / (SSCORE + CSCORE); // SC
    const CD_Slope = (-CSCORE - DSCORE) / (-DSCORE + CSCORE); // CD

    // b = Y - (m * x)
    const DI_Yinter = DSCORE - DI_Slope * -DSCORE;
    const CS_Yinter = SSCORE - SC_Slope * -SSCORE;

    // x_int = -b / m
    let CD_Xinter = (CSCORE - CD_Slope * -CSCORE) / CD_Slope;
    let IS_Xinter = (SSCORE - IS_Slope * SSCORE) / IS_Slope;

    const canvas = new fabric.StaticCanvas(this.canvas as HTMLCanvasElement);

    if (isNaN(CD_Xinter)) {
      CD_Xinter = CSCORE;
    }

    if (isNaN(IS_Xinter)) {
      IS_Xinter = -CSCORE;
    }

    /* The D Score Polygon ***************************************/

    // const Dpoly = new fabric.Polygon(
    //   [
    //     { x: 0, y: 0 }, // 0,0
    //     { x: 0, y: DI_Yinter }, // D1
    //     { x: DSCORE, y: DSCORE }, // D0
    //     { x: CD_Xinter, y: 0 }
    //   ],
    //   {
    //     // D2

    //     stroke: "#000",
    //     strokeWidth: 2,
    //     fill: DISC_COLOR_D,
    //     flipY: true,
    //     flipX: true,
    //     opacity: scores.d < 0 ? 0.1 : 0.7
    //   }
    // );

    // canvas.add(Dpoly.set({ left, top })); // Render the D Polygon

    const Dpoly = new fabric.Polygon(
      [
        { x: 0, y: 0 }, // 0,0
        { x: 0, y: DI_Yinter }, // D1
        { x: DSCORE, y: DSCORE }, // D0
        { x: CD_Xinter, y: 0 },
      ],
      {
        // D2

        stroke: "#000",
        strokeWidth: 1,
        fill: DISC_COLOR_D,
        flipY: true,
        flipX: true,
        opacity: scores.d < 0 ? 0.1 : 0.7,
      }
    );

    const dXMinusValue = Math.max(Math.abs(CD_Xinter), Math.abs(DSCORE));
    const dYMinusValue = Math.max(Math.abs(DI_Yinter), Math.abs(DSCORE));

    canvas.add(
      Dpoly.set({ left: left - dXMinusValue - 1, top: top - dYMinusValue - 1 })
    ); // Render the D Polygon

    /* The I Score Polygon ***************************************/

    // const Ipoly = new fabric.Polygon(
    //   [
    //     { x: 0, y: 0 }, // 0,0
    //     { x: 0, y: DI_Yinter }, // I1
    //     { x: ISCORE, y: ISCORE }, // I0
    //     { x: -IS_Xinter, y: 0 }
    //   ],
    //   {
    //     // I2

    //     stroke: "#000",
    //     strokeWidth: 2,
    //     fill: DISC_COLOR_I,
    //     flipX: false,
    //     flipY: true,
    //     opacity: scores.i < 0 ? 0.1 : 0.7
    //   }
    // );

    // debugger;

    // canvas.add(Ipoly.set({ left: left, top })); // Render the I Polygon

    const Ipoly2 = new fabric.Polygon(
      [
        { x: 0, y: 0 }, // 0,0
        { x: 0, y: DI_Yinter }, // I1
        { x: ISCORE, y: ISCORE }, // I0
        { x: -IS_Xinter, y: 0 },
      ],
      {
        // I2

        stroke: "#000",
        strokeWidth: 1,
        fill: DISC_COLOR_I,
        flipX: false,
        flipY: true,
        opacity: scores.i < 0 ? 0.1 : 0.7,
      }
    );

    const iMinusValue = Math.max(Math.abs(DI_Yinter), Math.abs(ISCORE));

    // debugger;
    canvas.add(Ipoly2.set({ left, top: top - iMinusValue - 1 })); // Render the I Polygon

    /* The S Score Polygon ***************************************/

    const Spoly = new fabric.Polygon(
      [
        { x: 0, y: 0 }, // 0,0
        { x: 0, y: CS_Yinter }, // S1
        { x: SSCORE, y: SSCORE }, // S0
        { x: -IS_Xinter, y: 0 },
      ],
      {
        // S2

        stroke: "#000",
        strokeWidth: 1,
        fill: DISC_COLOR_S,
        flipX: false,
        angle: 0,
        flipY: false,
        opacity: scores.s < 0 ? 0.1 : 0.7,
      }
    );

    canvas.add(Spoly.set({ left, top })); // Render the S Polygon

    /* The C1 Score Polygon ***************************************/

    // const Cpoly = new fabric.Polygon(
    //   [
    //     { x: 0, y: 0 }, // 0,0
    //     { x: 0, y: CD_Xinter }, // C1
    //     { x: CSCORE, y: CSCORE }, // C0
    //     { x: CS_Yinter, y: 0 }
    //   ],
    //   {
    //     stroke: "#000",
    //     strokeWidth: 2,
    //     fill: DISC_COLOR_C,
    //     flipY: false,
    //     flipX: false,
    //     angle: 90,
    //     opacity: scores.c < 0 ? 0.1 : 0.7
    //   }
    // );

    // canvas.add(Cpoly.set({ left, top })); // Render the C Polygon

    const Cpoly = new fabric.Polygon(
      [
        { x: 0, y: 0 }, // 0,0
        { x: 0, y: CD_Xinter }, // C1
        { x: CSCORE, y: CSCORE }, // C0
        { x: CS_Yinter, y: 0 },
      ],
      {
        stroke: "#000",
        strokeWidth: 1,
        fill: DISC_COLOR_C,
        flipY: false,
        flipX: false,
        angle: 90,
        opacity: scores.c < 0 ? 0.1 : 0.7,
      }
    );

    canvas.add(Cpoly.set({ left: left, top })); // Render the C Polygon

    this.setState({
      dPercentStr: `${D}%`,
      iPercentStr: `${I}%`,
      sPercentStr: `${S}%`,
      cPercentStr: `${C}%`,
    });

    requestAnimationFrame(() => {
      this._onCanvasRendered(this.canvas as HTMLCanvasElement);
    });

    /* eslint-enable camelcase */
  }
}
