import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import {
  useDISCInteractive,
  useDISCInteractiveMockup,
} from "data/network/hooks/";
import {
  Link,
  Message,
  UserThumbByID,
  SessionUserThumb,
} from "components/common/";
import { BackNav } from "components/meta/";

import CompareGraph from "interfaces/profile/subcomponents/compare-graph/";
import Perspective from "interfaces/profile/subcomponents/perspectives/Perspective";

import "interfaces/profile/style.scss";
import "./style.scss";
import { INTERFACE_PATHS } from "data/constants";

interface Props {
  discMiniProfile: TytoData.DISCProfileMini;
  otherDiscMini?: TytoData.DISCProfileMini;
  goBack: () => void;
}

const ComparisonProfile = ({
  discMiniProfile,
  otherDiscMini,
  goBack,
}: Props) => {
  const [otherPersonName, updateOtherPersonName] = React.useState(() => {
    return otherDiscMini?.personName?.split(" ")?.[0] ?? "They";
  });

  const { eagerData, isLoading } = useDISCInteractive({
    userID: discMiniProfile?.personID,
    isEnabled: !!discMiniProfile?.styleKey3 && !otherDiscMini,
  });
  const { eagerData: compareEagerData, isLoading: compareIsLoading } =
    useDISCInteractiveMockup({
      userID: discMiniProfile?.personID,
      otherUserID: otherDiscMini?.personID ?? 0,
      isEnabled:
        !!discMiniProfile?.styleKey3 &&
        !!otherDiscMini?.styleKey3 &&
        !!otherDiscMini,
    });

  React.useEffect(() => {
    updateOtherPersonName(otherDiscMini?.personName?.split(" ")?.[0] ?? "They");
  }, [otherDiscMini]);

  const youAndOtherNotLoaded = !otherDiscMini && !isLoading && !eagerData;
  const compareNotLoaded =
    !!otherDiscMini && !compareIsLoading && !compareEagerData;

  let safeEagerData = !!otherDiscMini ? compareEagerData : eagerData;

  return (
    <article className="teams-person-comparison">
      <header className="teams-person-comparison-top">
        <BackNav
          onBack={goBack}
          title={`${!!otherDiscMini ? otherPersonName : "You"} and ${
            eagerData?.discProfile?.result?.firstName ?? "Them"
          }`}
        />

        <section className="teams-person-comparison-userthumbs">
          {!otherDiscMini && (
            <SessionUserThumb
              className="teams-person-comparison-userthumb"
              size={58}
              // size={48}
              userName=""
              withBorder={true}
            />
          )}
          <UserThumbByID
            className="teams-person-comparison-userthumb"
            size={58}
            // size={48}
            userName={discMiniProfile.personName}
            userID={discMiniProfile.personID}
            withBorder={true}
          />
          {!!otherDiscMini && (
            <UserThumbByID
              className="teams-person-comparison-userthumb"
              size={58}
              // size={48}
              userName={otherDiscMini.personName}
              userID={otherDiscMini.personID}
              withBorder={true}
            />
          )}
        </section>
      </header>

      {!eagerData && isLoading && <Message text="Loading..." />}

      {(youAndOtherNotLoaded || compareNotLoaded) && (
        <Message text="Could not retrieve Comparison Information." />
      )}

      {safeEagerData && (
        <PerspectivesWrapper
          discMiniProfile={discMiniProfile}
          discInteractive={safeEagerData.discProfile}
          otherPersonName={otherPersonName}
          otherProfile={otherDiscMini}
        />
      )}
    </article>
  );
};

interface PerspectivesWrapperProps {
  discInteractive?: TytoData.DISCCompareProfile;
  discMiniProfile: TytoData.DISCProfileMini;
  otherProfile?: TytoData.DISCProfileMini;
  otherPersonName?: string;
}

const PerspectivesWrapper = ({
  discInteractive,
  discMiniProfile,
  otherProfile,
  otherPersonName,
}: PerspectivesWrapperProps) => {
  const [interactionsKeyed, updateInteractionsKeyed] = React.useState(() =>
    _.keyBy(discInteractive?.interactions ?? [], "interactContext")
  );

  React.useEffect(() => {
    updateInteractionsKeyed(
      _.keyBy(discInteractive?.interactions ?? [], "interactContext")
    );
  }, [discInteractive]);

  return (
    <section className="profile-section teams-comparison-interface">
      {/* <Perspectives interactions={eagerData.discProfile} /> */}
      <Perspective
        title={`How ${discInteractive?.result.firstName ?? "they will"} sees ${
          otherProfile && otherPersonName ? `${otherPersonName}` : "you"
        }`}
        desc={interactionsKeyed?.["Presented"]?.interactText ?? ""}
      />
      <CompareGraph
        containerClassName="comparison-compare-graph-section"
        discMiniProfile={discMiniProfile}
        otherMiniProfile={otherProfile}
        interactions={discInteractive}
        plotGraphProps={{
          alwaysShowValues: true,
          canSelectUser: true,
        }}
        title="Comparison Graph"
      />
      <Perspective
        title={`How ${
          otherProfile && otherPersonName
            ? `${otherPersonName} sees`
            : "you see"
        } ${discInteractive?.result.firstName ?? "them"}`}
        desc={interactionsKeyed?.["Insight"]?.interactText ?? ""}
      />
      <Perspective
        title="Tips for working together"
        desc={interactionsKeyed?.["Tips"]?.interactText ?? ""}
      />

      {!otherProfile && (
        <section className="profile-section view-profile-action-button">
          <Link
            className="profile-action-link"
            buttonProps={{
              className: "large-btn",
              hoverBGSize: "hidden",
              value: "View Full Profile",
              theme: "action",
              onClick: _.noop,
            }}
            href={`${INTERFACE_PATHS.PROFILE}/${
              discMiniProfile?.personID ?? 0
            }`}
            type="button"
            value="View Full Profile"
          />
        </section>
      )}
    </section>
  );
};

export default ComparisonProfile;
