import * as React from "react";

import { IconProps } from "../typings";

const CloseCircle = ({ className, color, size }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      height={size}
      width={size}
      fill={color}
    >
      <path d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346627 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 6.94942 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0ZM8 14.4C6.7342 14.4 5.49683 14.0246 4.44435 13.3214C3.39188 12.6182 2.57157 11.6186 2.08717 10.4492C1.60277 9.27972 1.47603 7.9929 1.72298 6.75142C1.96992 5.50994 2.57946 4.36957 3.47452 3.47452C4.36958 2.57946 5.50995 1.96992 6.75142 1.72297C7.9929 1.47603 9.27973 1.60277 10.4492 2.08717C11.6186 2.57157 12.6182 3.39187 13.3214 4.44435C14.0246 5.49682 14.4 6.7342 14.4 8C14.4 9.69738 13.7257 11.3252 12.5255 12.5255C11.3253 13.7257 9.69739 14.4 8 14.4Z" />
      <path d="M10.168 5.83185C10.0936 5.75687 10.0052 5.69736 9.90767 5.65674C9.81018 5.61613 9.70561 5.59521 9.60001 5.59521C9.4944 5.59521 9.38983 5.61613 9.29234 5.65674C9.19486 5.69736 9.10638 5.75687 9.03201 5.83185L8.00001 6.87185L6.96801 5.83185C6.81736 5.68121 6.61305 5.59658 6.40001 5.59658C6.18696 5.59658 5.98265 5.68121 5.83201 5.83185C5.68136 5.9825 5.59673 6.18681 5.59673 6.39985C5.59673 6.61289 5.68136 6.81721 5.83201 6.96785L6.87201 7.99985L5.83201 9.03185C5.75702 9.10622 5.69751 9.1947 5.65689 9.29219C5.61628 9.38968 5.59537 9.49424 5.59537 9.59985C5.59537 9.70546 5.61628 9.81003 5.65689 9.90751C5.69751 10.005 5.75702 10.0935 5.83201 10.1679C5.90638 10.2428 5.99486 10.3024 6.09234 10.343C6.18983 10.3836 6.2944 10.4045 6.40001 10.4045C6.50562 10.4045 6.61018 10.3836 6.70767 10.343C6.80515 10.3024 6.89364 10.2428 6.96801 10.1679L8.00001 9.12785L9.03201 10.1679C9.10638 10.2428 9.19486 10.3024 9.29234 10.343C9.38983 10.3836 9.4944 10.4045 9.60001 10.4045C9.70561 10.4045 9.81018 10.3836 9.90767 10.343C10.0052 10.3024 10.0936 10.2428 10.168 10.1679C10.243 10.0935 10.3025 10.005 10.3431 9.90751C10.3837 9.81003 10.4046 9.70546 10.4046 9.59985C10.4046 9.49424 10.3837 9.38968 10.3431 9.29219C10.3025 9.1947 10.243 9.10622 10.168 9.03185L9.12801 7.99985L10.168 6.96785C10.243 6.89348 10.3025 6.805 10.3431 6.70751C10.3837 6.61003 10.4046 6.50546 10.4046 6.39985C10.4046 6.29424 10.3837 6.18968 10.3431 6.09219C10.3025 5.9947 10.243 5.90622 10.168 5.83185Z" />
    </svg>
  );
};

export default CloseCircle;
