/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import "./style.scss";

export interface Props {
  className?: string;
  wrapperClassName?: string;
  detailsClassName?: string;
  details?: "percent" | "percent-and-count";
  height: number;
  minValue?: number;
  progressCount: number;
  progressDecimal: number;
  style?: React.CSSProperties;
  totalCount: number;
  theme?: SITE.ComponentTheme;
}

function calcProgPercent(progDecimal: number) {
  if (!progDecimal || progDecimal < 0) {
    return 0;
  }

  return Math.floor(progDecimal * 100);
}

const ProgressBar = (props: Props) => {
  const [completeWidthPercent, updateCompleteWidthPercent] = React.useState(
    calcProgPercent(props.progressDecimal)
  );

  React.useEffect(() => {
    updateCompleteWidthPercent(calcProgPercent(props.progressDecimal));
  }, [props.progressDecimal]);

  let height;

  if (props.height && props.height !== 5) {
    height = props.height;
  }

  const style = height
    ? {
        ...(props.style || {}),
        height,
        borderRadius: `${Math.ceil(height / 2)}`,
      }
    : props.style;

  const valueNow =
    props.progressCount || props.progressDecimal * (props.totalCount || 1);
  const valueMin = props.minValue || 0;
  const valueMax = props.totalCount || 1;
  const valueText = `${valueNow} of ${valueMax} completed`;

  return (
    <div
      className={cx(
        "cc-progress-bar-wrapper",
        props.details && "has-details",
        props.wrapperClassName
      )}
    >
      <div
        className={cx(
          "cc-progress-bar opaque-bg",
          props.theme,
          props.className
        )}
        style={style}
      >
        {!!props.progressDecimal && props.progressDecimal > 0 && (
          <div
            className="cc-progress-complete-bar"
            role="progressbar"
            aria-valuenow={valueNow}
            aria-valuemin={valueMin}
            aria-valuemax={valueMax}
            aria-valuetext={valueText}
            style={{ width: `${completeWidthPercent}%` }}
          />
        )}
      </div>

      {props.details && (
        <span
          className={cx("cc-progress-bar-details", props.detailsClassName)}
          style={{ fontSize: props.height * 2.5 }}
        >
          {Math.floor(completeWidthPercent)}%
          {props.details === "percent-and-count" && (
            <span className="cc-progress-bar-details-count">
              {" "}
              ({props.progressCount} of {props.totalCount} completed)
            </span>
          )}
        </span>
      )}
    </div>
  );
};

export default ProgressBar;
