import * as React from "react";
import cx from "classnames";

import { getUserHas247UpdatesAccess } from "data/helpers/session";
import { useDISCInteractive } from "data/network/hooks/";

import AssessmentResults from "../assessment-results/";
import CommTipsOverview from "../comm-tips-overview/";
import CommTipsEditable from "../comm-tips-editable/";
import HowToReadProfile from "../how-to-read-profile/";

import Vendor247Wrapper from "./Vendor247Wrapper";

import ProfileHeader from "../other-person/ProfileHeader";
import SectionMenu from "../section-menu/";
import ChallengeAreas from "../challenge-areas/";
import DISCGraphs from "../disc-graphs/";
import CommunicationTips from "../comm-tips/";
import Characteristics from "../characteristics/";
import Contributions from "../contributions/";
import GrowthAreas from "../growth-areas/";
import Downloads from "../downloads/";
import Introduction from "../intro";

interface Props {
  canDeleteDISC: boolean;
  canImportDISC: boolean;
  discMiniProfile: TytoData.DISCProfileMini;
  isMobile: boolean;
  subInterface?: ProfilePage.Subinterface;
  userID: number;
}

const CompareProfile = (props: Props) => {
  const interactionsQuery = useDISCInteractive({
    userID: props.discMiniProfile.personID,
  });

  const interactions =
    interactionsQuery.data?.discProfile ??
    interactionsQuery.storedValueQuery?.data?.discProfile;

  return (
    <OwnProfileSubInterfaceRouter {...props} interactions={interactions} />
  );
};

interface OwnProfileProps extends Props {
  interactions?: TytoData.DISCCompareProfile;
}

const OwnProfile = (props: OwnProfileProps) => {
  const [firstName, updateFirstName] = React.useState(() => {
    return props.discMiniProfile?.personName?.split?.(" ")[0];
  });

  const show247Data =
    getUserHas247UpdatesAccess() &&
    props.discMiniProfile.provider === "Assessments247.net";

  React.useEffect(() => {
    updateFirstName(props.discMiniProfile?.personName?.split?.(" ")[0]);
  }, [props.discMiniProfile]);

  return (
    <article
      className={cx(
        "profile-compareprofile-wrapper",
        show247Data && "assessment-247"
      )}
    >
      <div className="centered-content-wrapper">
        <ProfileHeader
          canDeleteDISC={props.canDeleteDISC}
          canImportDISC={props.canImportDISC}
          discMiniProfile={props.discMiniProfile}
          interactions={props.interactions}
          isMobile={props.isMobile}
          show247Data={show247Data}
        />
      </div>

      <div className="profile-compareprofile-content-wrapper">
        <div className="centered-content-wrapper">
          <SectionMenu
            isMobile={props.isMobile}
            isOwnProfile={true}
            use247Data={show247Data}
          />

          {show247Data ? (
            <Vendor247Wrapper
              isMobile={props.isMobile}
              discMiniProfile={props.discMiniProfile}
              interactions={props.interactions}
              firstName={firstName}
              isOwnProfile={true}
            />
          ) : (
            <>
              <Introduction discMiniProfile={props.discMiniProfile} />
              <DISCGraphs {...props} />
              <CommunicationTips
                isMobile={props.isMobile}
                interactions={props.interactions}
                discMiniProfile={props.discMiniProfile}
              />
              <Characteristics
                interactions={props.interactions}
                discMiniProfile={props.discMiniProfile}
                isOwnProfile={true}
              />
              <Contributions
                interactions={props.interactions}
                discMiniProfile={props.discMiniProfile}
                isOwnProfile={true}
              />

              <GrowthAreas interactions={props.interactions} />

              <ChallengeAreas interactions={props.interactions} />
            </>
          )}

          <Downloads
            discProfileMini={props.discMiniProfile}
            isMobile={props.isMobile}
          />
        </div>
      </div>
    </article>
  );
};

interface OwnProfileSubInterfaceRouterProps extends Props {
  interactions?: TytoData.DISCCompareProfile;
}

const OwnProfileSubInterfaceRouter = (
  props: OwnProfileSubInterfaceRouterProps
) => {
  switch (props.subInterface) {
    case "results":
      return (
        <AssessmentResults
          discProfileMini={props.discMiniProfile}
          interactions={props.interactions}
          isMobile={props.isMobile}
        />
      );
    case "communication-tips-overview":
      return <CommTipsOverview />;
    case "communication-tips":
      return (
        <CommTipsEditable
          isOwnProfile={true}
          compareProfile={props.interactions}
        />
      );
    case "how-to-read":
      return <HowToReadProfile />;
    default:
      return <OwnProfile {...props} />;
  }
  return <div></div>;
};

export default CompareProfile;
