import * as React from "react";

import { PROFILE_SECTION_IDS } from "data/constants/";

import { DISCPlotGraphModal } from "components/modals";
import {
  DISC,
  DISCPlotGraph,
  DISCValueRender,
  DISCQuadrants,
} from "components/disc/";
import { ToggleButton } from "components/common";
import { useIntersectionObserver } from "data/helpers/Hooks";
import {
  getUserHas247UpdatesAccess,
  showGraphNumbers,
} from "data/helpers/session";

import "./style.scss";

type ActiveGraph = "r3Graph" | "r3GraphPublic"; //plotGraph

const OPTIONS: {
  label: string;
  value: ActiveGraph;
}[] = [
  { label: "R3 Graph", value: "r3Graph" },
  // { label: "Plot Graph", value: "plotGraph" },
  { label: "R3 - Public Self", value: "r3GraphPublic" },
];

interface Props {
  discMiniProfile: TytoData.DISCProfileMini;
  interactions?: TytoData.DISCCompareProfile;
  isMobile?: boolean;
  userID: number;
  // viewingGraph?: string;
}

const DISCGraphs = (props: Props) => {
  const [width, updateWidth] = React.useState(() => {
    return Math.min(
      getUserHas247UpdatesAccess() ? 400 : 325,
      (window.innerWidth - (props.isMobile ? 46 : 24)) * 0.9
    );
  });
  const [modalGraphWidth, updateModalGraphWidth] = React.useState(() => {
    return Math.min(450, (window.innerWidth - 23) * 0.9);
  });
  const [focusedLetter, updateFocusedLetter] = React.useState<
    "d" | "i" | "s" | "c" | undefined
  >(undefined);
  const [showPlotGraphModal, updateShowPlotGraphModal] = React.useState(false);
  const DISCGraphRef = React.useRef<HTMLDivElement | null>(null);
  // const plotGraphRef = React.useRef<HTMLDivElement | null>(null);
  const DISCGraphRefPublic = React.useRef<HTMLDivElement | null>(null);
  // // const [selectedItem, updateSelectedItem] = React.useState();
  const [currentlyViewedItem, updateCurrentlyViewedItem] =
    React.useState<ActiveGraph>("r3Graph");
  // const [collection, updateCollection] = React.useState(() => {
  //   return [
  //     {
  //       d: props.discMiniProfile.d3,
  //       i: props.discMiniProfile.i3,
  //       s: props.discMiniProfile.s3,
  //       c: props.discMiniProfile.c3,
  //       personName: props.discMiniProfile.personName,
  //       percentile: props.interactions
  //         ? {
  //             d: props.interactions.result.d3percentile,
  //             i: props.interactions.result.i3percentile,
  //             s: props.interactions.result.s3percentile,
  //             c: props.interactions.result.c3percentile,
  //             personName: props.discMiniProfile.personName,
  //             percentile: props.interactions,
  //           }
  //         : undefined,
  //     },
  //   ];
  // });

  const DISCGraphIntersect = useIntersectionObserver({
    threshold: [0.8],
    onEntry: (entryData) => {
      if (entryData.isIntersecting) {
        updateCurrentlyViewedItem("r3Graph");
      }
    },
  });
  const DISCGraphPublicIntersect = useIntersectionObserver({
    threshold: [0.8],
    onEntry: (entryData) => {
      if (entryData.isIntersecting) {
        updateCurrentlyViewedItem("r3GraphPublic");
      }
    },
  });
  // const plotGraphIntersect = useIntersectionObserver({
  //   threshold: [0.8],
  //   onEntry: (entryData) => {
  //     if (entryData.isIntersecting) {
  //       updateCurrentlyViewedItem("plotGraph");
  //     }
  //   },
  // });

  const manualDISCGraphRef = React.useCallback(
    (node: HTMLDivElement | null) => {
      DISCGraphRef.current = node;

      if (node !== null) {
        DISCGraphIntersect.updateNode?.(node);
      } else {
        // TODO?
      }
    },
    []
  );
  const manualDISCGraphRefPublic = React.useCallback(
    (node: HTMLDivElement | null) => {
      DISCGraphRefPublic.current = node;

      if (node !== null) {
        DISCGraphPublicIntersect.updateNode?.(node);
      } else {
        // TODO?
      }
    },
    []
  );
  // const manualPlotGraphRef = React.useCallback(
  //   (node: HTMLDivElement | null) => {
  //     plotGraphRef.current = node;

  //     if (node !== null) {
  //       plotGraphIntersect.updateNode?.(node);
  //     } else {
  //       // TODO?
  //     }
  //   },
  //   []
  // );

  // var r3Graph = document.getElementById("r3Graph");
  // var plotGraph = document.getElementById("plotGraph");
  // var graphs = document.getElementById("graphs");

  // React.useEffect(() => {
  //   updateCurrentlyViewedItem(props.viewingGraph);
  //   if (props.viewingGraph === "r3Graph") {
  //     r3Graph?.scrollIntoView({
  //       behavior: "smooth",
  //       block: "end",
  //       inline: "end",
  //     });
  //   } else {
  //     plotGraph?.scrollIntoView({
  //       behavior: "smooth",
  //       block: "end",
  //       inline: "start",
  //     });
  //   }
  // }, [props.viewingGraph]);

  const showNewGraphic = getUserHas247UpdatesAccess();

  return (
    <>
      <DISCValueRender
        discMiniProfile={props.discMiniProfile}
        isCompareValue={false}
        targetKey="graphic"
        className="profile-graph-valueRender"
      >
        {props.isMobile && (
          <ToggleButton
            buttonItems={OPTIONS}
            activeItem={currentlyViewedItem}
            onChange={(value) => {
              updateCurrentlyViewedItem(value as ActiveGraph);

              const targetElement =
                value === "r3GraphPublic" //plotGraph
                  ? DISCGraphRefPublic.current
                  : DISCGraphRef.current;

              if (targetElement) {
                targetElement.scrollIntoView({
                  behavior: "smooth",
                  block: "end",
                  inline: value === "r3Graph" ? "start" : "end", //plotGraph
                });
              }
            }}
            className="profile-graph-toggle-button"
          />
        )}

        <section
          className="profile-discgraphs-cont profile-section"
          data-section={PROFILE_SECTION_IDS.DISC_GRAPHS}
          id="graphs"
        >
          <div
            className="profile-discgraphs-graph-cont disc-graph-cont"
            id="r3GraphPublic"
          >
            {showNewGraphic ? (
              <DISCQuadrants
                className="profile-discgraphs-graph-disc-graph new-quadrants-graph"
                discValues={props.discMiniProfile}
                detailType={showGraphNumbers() ? "value" : "percentage"}
                graph="Public"
                size={width}
              />
            ) : (
              <DISC
                className="profile-discgraphs-graph-disc-graph"
                innerRef={manualDISCGraphRefPublic}
                profile={props.discMiniProfile}
                version={"public"}
                size={width}
              />
            )}
            <div className="profile-discgraphs-graph-label">Public Self</div>
          </div>

          <div
            className="profile-discgraphs-graph-cont disc-graph-cont"
            id="r3Graph"
          >
            {showNewGraphic ? (
              <DISCQuadrants
                className="profile-discgraphs-graph-disc-graph new-quadrants-graph"
                discValues={props.discMiniProfile}
                detailType={showGraphNumbers() ? "value" : "percentage"}
                graph="Private"
                size={width}
              />
            ) : (
              <DISC
                className="profile-discgraphs-graph-disc-graph"
                innerRef={manualDISCGraphRef}
                profile={props.discMiniProfile}
                size={width}
                version={"core"}
              />
            )}
            <div className="profile-discgraphs-graph-label">Private Self</div>
          </div>

          {/* <div
            className="profile-discgraphs-graph-cont plot-graph-cont"
            id="plotGraph"
          >
            <DISCPlotGraph
              groupStyle={collection}
              size={width}
              innerRef={manualPlotGraphRef}
              onLetterSelect={(newFocusedLetter) => {
                updateFocusedLetter(newFocusedLetter);
                updateShowPlotGraphModal(true);
              }}
            />
          </div> */}
        </section>

        {!!props.interactions && (
          <DISCPlotGraphModal
            discCompareProfile={props.interactions}
            isOpen={showPlotGraphModal}
            graphWidth={props.isMobile ? width : modalGraphWidth}
            startingColumn={focusedLetter}
            onDismiss={() => {
              updateShowPlotGraphModal(false);
            }}
          />
        )}
      </DISCValueRender>
    </>
  );
};

export default DISCGraphs;
