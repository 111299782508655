import axios, { AxiosRequestConfig } from "axios";
import * as _ from "lodash";

import { keys, SES_STOR, SessionHandling } from "../../storage/";

type CallType = "delete" | "get" | "post" | "post-form" | "put";

export type CallOpts = {
  circumventChangePasswordCheck?: boolean;
  paramsAsData?: boolean;
  omitSessionKey?: boolean;
  useLocalStorage?: boolean;
  localStorageAgeOverride?: number; // * In MS
  storeResponse?: boolean;
  respTarget?: string;
  axiosConfig?: AxiosRequestConfig;
};

const DEFAULT_OPTS = {
  validateStatus: (status: number) => {
    return status < 500; // Resolve only if the status code is less than 500
  },
};

export function callWrapper(
  callType: CallType,
  endpoint: string,
  params?: object,
  opts?: CallOpts
) {
  const sessionKey = SessionHandling.getActiveSessionKey();

  if (!!opts && !opts.omitSessionKey && !sessionKey) {
    console.log("no session key found in callWrapper function when expected.");
  }

  const paramsAltered =
    sessionKey && !(opts && opts.omitSessionKey)
      ? { ...(params || {}), sessionKey }
      : params || {};

  // * For DELETE Endpoints that want data as JSON and not as params
  const optsAltered =
    opts && opts.paramsAsData
      ? {
          ...opts,
          axiosConfig: {
            ...(opts.axiosConfig || {}),
            data: paramsAltered,
          },
        }
      : opts;

  switch (callType) {
    case "delete":
      return deleteCall(endpoint, paramsAltered, optsAltered);
    case "get":
      return getCall(endpoint, paramsAltered, opts);
    case "post":
      return postCall(endpoint, paramsAltered);
    case "post-form":
      return postFormCall(endpoint, paramsAltered);
    case "put":
      return putCall(endpoint, paramsAltered, opts);
    default:
      return new Promise((res, rej) => {
        rej(
          `Incorrect callType supplied. Expected 'delete', 'get', 'post', or 'put', but got ${callType}`
        );
      });
  }
}

export function deleteCall(endpoint: string, params: object, opts?: CallOpts) {
  const { axiosConfig = {} } = opts || {};

  return new Promise((res, rej) => {
    axios
      .delete(endpoint, { params, ...axiosConfig })
      .then((resp: any) => {
        updateSessionActivity();
        res(resp.data);
      })
      .catch((err: any) => {
        rej("An error occurred");
      });
  });
}

// axios.interceptors.response.use(
//   (onResp: any) => {
//     return onResp;
//   },
//   onRej => {
//     if (onRej && onRej.error) {
//       console.log("this got hit", onRej.error);
//     }

//     return onRej;
//   }
// );

export function getCall(endpoint: string, params: object, opts?: CallOpts) {
  return new Promise((res, rej) => {
    if (opts && opts.useLocalStorage) {
      // * See TeamTools Web project for implementation; shouldn't be relevant or necessary here.
    }

    axios
      .get(endpoint, { params })
      .then((resp: any) => {
        if (!_.get(opts, "circumventChangePasswordCheck", false)) {
          checkIfPasswordChangeIsTrueOnSession(
            endpoint,
            _.get(resp, "data.session", undefined)
          );
        }

        updateSessionActivity();
        const respTarget = _.get(opts, "respTarget", undefined);

        // * If a target was supplied return that, but if it isn't or data can't be found simply return resp
        const data = respTarget
          ? _.get(resp.data, respTarget, resp.data)
          : resp.data;

        if (opts && opts.storeResponse) {
          // * See TeamTools Web project for implementation; shouldn't be relevant or necessary here.
        }

        res(data);
      })
      .catch((err: any) => {
        console.warn("Post Call errored", err);
        const errAsString = JSON.stringify(err);

        const error = _.get(err, "response.data.error", {
          errorMsg: _.get(err, "message", errAsString),
        });
        const responseHeaderStatus = _.get(err, "response.status");

        rej(
          error
            ? { ...error, responseHeaderStatus }
            : "An error occurred (could not parse error)"
        );
      });
  });
}

export function postCall(endpoint: string, params: object, opts?: CallOpts) {
  return new Promise((res, rej) => {
    axios
      .post(endpoint, params)
      .then((resp: any) => {
        if (!_.get(opts, "circumventChangePasswordCheck", false)) {
          checkIfPasswordChangeIsTrueOnSession(
            endpoint,
            _.get(resp, "data.session", undefined)
          );
        }

        updateSessionActivity();
        res(resp.data);
      })
      .catch((err: any) => {
        const errAsString = JSON.stringify(err);
        const isAuthCall = /(login\/authenticate|password)/gi.test(errAsString);

        console.warn(
          "Post Call errored",
          isAuthCall
            ? " Error data omitted from log to keep crucial data safe."
            : err
        );

        const error = _.get(
          err,
          "response.data.error",
          _.get(err, "message", errAsString)
        );
        const safeErrorMsg = /(login\/authenticate|password)/gi.test(`${error}`)
          ? "Error occurred."
          : error;

        rej(safeErrorMsg || "An error occurred (could not parse error)");
      });
  });
}

export function postFormCall(
  endpoint: string,
  params: object,
  opts?: CallOpts
) {
  return new Promise((res, rej) => {
    try {
      // ? Might be other parameters that were passed in but are now unused ?
      const { sessionKey, files } = params as any;

      const form = new FormData();
      const file1 = files[0] as File;
      form.append("file1", file1);

      axios({
        method: "post",
        url: `${endpoint}?sessionKey=${sessionKey}`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: form,
      })
        .then((resp: any) => {
          if (!_.get(opts, "circumventChangePasswordCheck", false)) {
            checkIfPasswordChangeIsTrueOnSession(
              endpoint,
              _.get(resp, "data.session", undefined)
            );
          }

          updateSessionActivity();
          res(resp.data);
        })
        .catch((err: any) => {
          console.warn("Post Call errored", err);
          const errAsString = JSON.stringify(err);
          const error = _.get(
            err,
            "response.data.error",
            _.get(err, "message", errAsString)
          );
          rej(error || "An error occurred (could not parse error)");
        });
    } catch (err) {
      rej(`${err}`);
    }
  });
}

export function putCall(endpoint: string, params: object, opts?: CallOpts) {
  return new Promise((res, rej) => {
    axios
      .put(endpoint, {
        ...params,
        ..._.get(opts, "axiosConfig", {}),
        ...DEFAULT_OPTS,
      })
      .then((resp: any) => {
        if (!_.get(opts, "circumventChangePasswordCheck", false)) {
          checkIfPasswordChangeIsTrueOnSession(
            endpoint,
            _.get(resp, "data.session", undefined)
          );
        }

        updateSessionActivity();
        res(resp.data);
      })
      .catch((err: any) => {
        console.warn("Post Call errored", err);
        const errAsString = JSON.stringify(err);
        const error = _.get(
          err,
          "response.data.error",
          _.get(err, "message", errAsString)
        );

        rej(error || "An error occurred");
      });
  });
}

function checkIfPasswordChangeIsTrueOnSession(
  endpoint: string,
  sessionData: Data.SessionData
) {
  if (_.get(sessionData, "changePassword", false)) {
    console.log(
      `Session found to need password change after getting response from ${endpoint}`
    );
    let pathName = window.location.pathname;

    if (/set-password/i.test(pathName)) {
      // if (/redirect/i.test(window.location.search)) {
      //   // TODO Handle pulling and checking existing redirect?
      // }

      pathName = "/";
    }

    window.location.href = `/set-password/my-password?redirect=${pathName}`;
    // navigate(`/set-password/my-password?redirect=${pathName}`);
  }
}

function updateSessionActivity() {
  SES_STOR.set(keys.LAST_SESSION_ACTIVITY, `${Date.now()}`);
}

export function addParamsToURL(url: string, params: Record<string, any>) {
  const urlParsed = new URL(url);

  Object.keys(params).forEach((key) =>
    urlParsed.searchParams.append(key, params[key])
  );

  return urlParsed.href;
}
