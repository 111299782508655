import { ENDPOINT_FUNCTIONS_ASP } from "../../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_FUNCTIONS_ASP;

export const FunctionsASP = {
  get(
    params: {
      command: string;
      functionNames: keyof typeof Data.SecurityFunctionName;
    },
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Responses.FunctionsASP.Get>;
  },
};
