/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import "./style.scss";

export interface Props {
  buttonItems: { label: string; value: string }[];
  className?: string;
  activeItem: string;
  onChange: (selectedItem: string) => void;
}

const ToggleButton = ({
  buttonItems,
  className,
  activeItem,
  onChange,
}: Props) => {
  return (
    <form className={cx("cc-toggle-button", className)}>
      {buttonItems.map((item) => (
        <>
          <input
            type="radio"
            name="switch"
            className="cc-switch"
            id={item.label}
            value={item.value}
            checked={activeItem === item.value}
            onChange={() => {
              onChange(item.value);
            }}
          />
          <label htmlFor={item.label}>{item.label}</label>
        </>
      ))}
    </form>
  );
};

export default ToggleButton;
